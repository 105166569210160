import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { PolicyAllocationCategory } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { RANGE_FROM_MIN1_TO_1 } from '@console/core-api/typsy/console-api-client/dist/config/range.config';
import {
    IPolicySettingRuleBoundedNumberRangeField,
    IPolicySettingsRules,
} from 'models/ui/policySettings.ui.models';
import {
    allocationCategoriesList,
} from 'config/portfolioMgmt/policySettings.config';
import { SIZES } from 'config/styling/sizes';
import { getPoliciesBenchmarksConfig, isPolicyAssetClassEnabled } from 'state/appConfig/selectors';
import { isCannotDifferRule } from 'utils/entities/portfolioMgmt/policySettingsUtils';
import InputSelectField, { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import { IExtendedFormField, IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ContentTitle from 'views/common/layout/ContentTitle';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import ExtendedInputSlider, { IExtendedInputSliderProps } from 'views/common/inputs/extended/ExtendedInputSlider';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';
import { IOnChangeTextInputProps } from 'views/common/inputs/base/InputTextField';
import ExtendedInputNumber from 'views/common/inputs/extended/ExtendedInputNumber';
import { DEFAULT_NR_OF_DECIMALS_FOR_PERCENTAGE_VALUE } from 'config/ui.config';
import { IOpenBenchmarkAllocationCategoryProps, IPolicySettingsFormValues } from './types';

export function renderPolicyBenchmarkSectionFormContent(
    context: IExtendedInputFormContext<IPolicySettingsFormValues>,
    initialValues: IPolicySettingsFormValues,
    {
        openBenchmarkAllocationCategory,
        setOpenBenchmarkAllocationCategory,
    }: IOpenBenchmarkAllocationCategoryProps,
    policySettingsRules: IPolicySettingsRules,
) {
    const { fields, labelPrefix } = context;
    const isBenchmarkSet = isSet(fields.benchmarkId.value);

    const benchmarkRules = policySettingsRules.benchmark;

    return (
        <>
            {renderPolicyBenchmarkSelectionFormField({
                formField: fields.benchmarkId,
                policySettingsRules,
            })}
            {isBenchmarkSet && (
                <>
                    <BenchmarkSlider
                        formField={fields.benchmarkPositionFraction}
                        initialValue={initialValues.benchmarkPositionFraction}
                        settingRule={benchmarkRules.positionFractionRange}
                        wrapper={{
                            label: 'fields.benchmark_position_fraction.label',
                            tooltip: 'fields.benchmark_position_fraction.tooltip',
                        }}
                    />
                    <ExtendedInputNumber
                        formField={fields.relativeIvarWeight}
                        type="percentage"
                        shouldAllowNumbersBelow0={false}
                        nrOfDecimals={DEFAULT_NR_OF_DECIMALS_FOR_PERCENTAGE_VALUE}
                        readOnly={isCannotDifferRule(benchmarkRules.relativeIvarWeight.rule)}
                        wrapper={{
                            label: 'fields.relative_ivar_weight.label',
                            tooltip: {
                                msg: 'fields.relative_ivar_weight.tooltip',
                            },
                            minHeight: SIZES.INPUT.HEIGHT.S,
                        }}
                    />
                    <ContentTitle
                        label={`${labelPrefix}.fields.benchmark_allocation_boundaries.label`}
                        variant="regular"
                        className="titleSpacing"
                    />
                    {renderBenchmarkAllocationCategories()}
                </>
            )}
        </>
    );

    function renderBenchmarkAllocationCategories() {
        return (
            <>
                <InputWrapper>
                    <InputSelectField
                        value={openBenchmarkAllocationCategory}
                        items={allocationCategoriesList}
                        onChange={onChangeOpenBenchmarkAllocationCategory}
                        itemLabelsAreTranslationKeys
                    />
                </InputWrapper>

                {renderOpenAllocationCategoryContent()}
            </>
        );

        function renderOpenAllocationCategoryContent() {
            switch (openBenchmarkAllocationCategory) {
                case PolicyAllocationCategory.assetClasses:
                    return renderPolicyBenchmarkAllocationAssetClassesFormContent();
                case PolicyAllocationCategory.regionBonds:
                    return renderPolicyBenchmarkAllocationRegionBondsFormContent();
                case PolicyAllocationCategory.regionStocks:
                    return renderPolicyBenchmarkAllocationRegionStocksFormContent();
                case PolicyAllocationCategory.bondTypes:
                    return renderPolicyBenchmarkAllocationBondTypesFormContent();
                case PolicyAllocationCategory.sectors:
                    return renderPolicyBenchmarkAllocationSectorsFormContent();
                default: return null;
            }
        }

        function onChangeOpenBenchmarkAllocationCategory({ value }: IOnChangeTextInputProps<PolicyAllocationCategory>) {
            setOpenBenchmarkAllocationCategory(value);
        }
    }

    function renderPolicyBenchmarkAllocationSectorsFormContent() {
        return (
            <>
                <BenchmarkSlider
                    formField={fields.benchmarkSectorBasicMaterials}
                    initialValue={initialValues.benchmarkSectorBasicMaterials}
                    settingRule={benchmarkRules.sectorBasicMaterialsRange}
                    wrapper={{
                        label: 'fields.sectors.basic_materials',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkSectorCommunicationServices}
                    initialValue={initialValues.benchmarkSectorCommunicationServices}
                    settingRule={benchmarkRules.sectorCommunicationServicesRange}
                    wrapper={{
                        label: 'fields.sectors.communication_services',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkSectorConsumerCyclical}
                    initialValue={initialValues.benchmarkSectorConsumerCyclical}
                    settingRule={benchmarkRules.sectorConsumerCyclicalRange}
                    wrapper={{
                        label: 'fields.sectors.consumer_cyclical',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkSectorConsumerDefensive}
                    initialValue={initialValues.benchmarkSectorConsumerDefensive}
                    settingRule={benchmarkRules.sectorConsumerDefensiveRange}
                    wrapper={{
                        label: 'fields.sectors.consumer_defensive',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkSectorEnergy}
                    initialValue={initialValues.benchmarkSectorEnergy}
                    settingRule={benchmarkRules.sectorEnergyRange}
                    wrapper={{
                        label: 'fields.sectors.energy',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkSectorFinancialServices}
                    initialValue={initialValues.benchmarkSectorFinancialServices}
                    settingRule={benchmarkRules.sectorFinancialServicesRange}
                    wrapper={{
                        label: 'fields.sectors.financial_services',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkSectorHealthcare}
                    initialValue={initialValues.benchmarkSectorHealthcare}
                    settingRule={benchmarkRules.sectorHealthcareRange}
                    wrapper={{
                        label: 'fields.sectors.healthcare',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkSectorIndustrials}
                    initialValue={initialValues.benchmarkSectorIndustrials}
                    settingRule={benchmarkRules.sectorIndustrialsRange}
                    wrapper={{
                        label: 'fields.sectors.industrials',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkSectorRealEstate}
                    initialValue={initialValues.benchmarkSectorRealEstate}
                    settingRule={benchmarkRules.sectorRealEstateRange}
                    wrapper={{
                        label: 'fields.sectors.real_estate',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkSectorTechnology}
                    initialValue={initialValues.benchmarkSectorTechnology}
                    settingRule={benchmarkRules.sectorTechnologyRange}
                    wrapper={{
                        label: 'fields.sectors.technology',
                    }}
                />
            </>
        );
    }

    function renderPolicyBenchmarkAllocationBondTypesFormContent() {
        return (
            <>
                <BenchmarkSlider
                    formField={fields.benchmarkBondTypeConvertible}
                    initialValue={initialValues.benchmarkBondTypeConvertible}
                    settingRule={benchmarkRules.bondTypeConvertibleRange}
                    wrapper={{
                        label: 'fields.bond_types.convertible',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkBondTypeCorporate}
                    initialValue={initialValues.benchmarkBondTypeCorporate}
                    settingRule={benchmarkRules.bondTypeCorporateRange}
                    wrapper={{
                        label: 'fields.bond_types.corporate',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkBondTypeGovernment}
                    initialValue={initialValues.benchmarkBondTypeGovernment}
                    settingRule={benchmarkRules.bondTypeGovernmentRange}
                    wrapper={{
                        label: 'fields.bond_types.government',
                    }}
                />
            </>
        );
    }

    function renderPolicyBenchmarkAllocationRegionBondsFormContent() {
        return (
            <>
                <BenchmarkSlider
                    formField={fields.benchmarkRegionBondsAsia}
                    initialValue={initialValues.benchmarkRegionBondsAsia}
                    settingRule={benchmarkRules.regionBondsAsiaRange}
                    wrapper={{
                        label: 'fields.regions.asia_pacific_developed',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkRegionBondsEmerging}
                    initialValue={initialValues.benchmarkRegionBondsEmerging}
                    settingRule={benchmarkRules.regionBondsEmergingRange}
                    wrapper={{
                        label: 'fields.regions.emerging',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkRegionBondsEurope}
                    initialValue={initialValues.benchmarkRegionBondsEurope}
                    settingRule={benchmarkRules.regionBondsEuropeRange}
                    wrapper={{
                        label: 'fields.regions.europe_developed',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkRegionBondsAmerica}
                    initialValue={initialValues.benchmarkRegionBondsAmerica}
                    settingRule={benchmarkRules.regionBondsAmericaRange}
                    wrapper={{
                        label: 'fields.regions.north_america',
                    }}
                />
            </>
        );
    }

    function renderPolicyBenchmarkAllocationRegionStocksFormContent() {
        return (
            <>
                <BenchmarkSlider
                    formField={fields.benchmarkRegionStocksAsia}
                    initialValue={initialValues.benchmarkRegionStocksAsia}
                    settingRule={benchmarkRules.regionStocksAsiaRange}
                    wrapper={{
                        label: 'fields.regions.asia_pacific_developed',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkRegionStocksEmerging}
                    initialValue={initialValues.benchmarkRegionStocksEmerging}
                    settingRule={benchmarkRules.regionStocksEmergingRange}
                    wrapper={{
                        label: 'fields.regions.emerging',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkRegionStocksEurope}
                    initialValue={initialValues.benchmarkRegionStocksEurope}
                    settingRule={benchmarkRules.regionStocksEuropeRange}
                    wrapper={{
                        label: 'fields.regions.europe_developed',
                    }}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkRegionStocksAmerica}
                    initialValue={initialValues.benchmarkRegionStocksAmerica}
                    settingRule={benchmarkRules.regionStocksAmericaRange}
                    wrapper={{
                        label: 'fields.regions.north_america',
                    }}
                />
            </>
        );
    }

    function renderPolicyBenchmarkAllocationAssetClassesFormContent() {
        return (
            <>
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassAlternatives}
                    initialValue={initialValues.benchmarkAssetClassAlternatives}
                    settingRule={benchmarkRules.assetClassAlternativesRange}
                    wrapper={{
                        label: 'fields.asset_classes.alternatives.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('alternatives')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassBonds}
                    initialValue={initialValues.benchmarkAssetClassBonds}
                    settingRule={benchmarkRules.assetClassBondsRange}
                    wrapper={{
                        label: 'fields.asset_classes.bonds.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('bonds')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassCash}
                    initialValue={initialValues.benchmarkAssetClassCash}
                    settingRule={benchmarkRules.assetClassCashRange}
                    wrapper={{
                        label: 'fields.asset_classes.cash.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('cash')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassCommodities}
                    initialValue={initialValues.benchmarkAssetClassCommodities}
                    settingRule={benchmarkRules.assetClassCommoditiesRange}
                    wrapper={{
                        label: 'fields.asset_classes.commodities.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('commodities')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassCorporate}
                    initialValue={initialValues.benchmarkAssetClassCorporate}
                    settingRule={benchmarkRules.assetClassCorporateRange}
                    wrapper={{
                        label: 'fields.asset_classes.corporate.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('corporate')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassEquity}
                    initialValue={initialValues.benchmarkAssetClassEquity}
                    settingRule={benchmarkRules.assetClassEquityRange}
                    wrapper={{
                        label: 'fields.asset_classes.equity.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('equity')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassEurobond}
                    initialValue={initialValues.benchmarkAssetClassEurobond}
                    settingRule={benchmarkRules.assetClassEurobondRange}
                    wrapper={{
                        label: 'fields.asset_classes.eurobond.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('eurobond')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassFixedIncome}
                    initialValue={initialValues.benchmarkAssetClassFixedIncome}
                    settingRule={benchmarkRules.assetClassFixedIncomeRange}
                    wrapper={{
                        label: 'fields.asset_classes.fixed_income.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('fixed_income')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassGovernment}
                    initialValue={initialValues.benchmarkAssetClassGovernment}
                    settingRule={benchmarkRules.assetClassGovernmentRange}
                    wrapper={{
                        label: 'fields.asset_classes.government.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('government')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassMoneyMarket}
                    initialValue={initialValues.benchmarkAssetClassMoneyMarket}
                    settingRule={benchmarkRules.assetClassMoneyMarketRange}
                    wrapper={{
                        label: 'fields.asset_classes.money_market.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('money_market')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassRealEstate}
                    initialValue={initialValues.benchmarkAssetClassRealEstate}
                    settingRule={benchmarkRules.assetClassRealEstateRange}
                    wrapper={{
                        label: 'fields.asset_classes.real_estate.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('real_estate')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassStocks}
                    initialValue={initialValues.benchmarkAssetClassStocks}
                    settingRule={benchmarkRules.assetClassStocksRange}
                    wrapper={{
                        label: 'fields.asset_classes.stocks.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('stocks')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassSukuk}
                    initialValue={initialValues.benchmarkAssetClassSukuk}
                    settingRule={benchmarkRules.assetClassSukukRange}
                    wrapper={{
                        label: 'fields.asset_classes.sukuk.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('sukuk')}
                />
                <BenchmarkSlider
                    formField={fields.benchmarkAssetClassOther}
                    initialValue={initialValues.benchmarkAssetClassOther}
                    settingRule={benchmarkRules.assetClassOtherRange}
                    wrapper={{
                        label: 'fields.asset_classes.other.label',
                    }}
                    isHideable
                    isEnabledForTenant={isPolicyAssetClassEnabled('other')}
                />
            </>
        );
    }
}

function BenchmarkSlider({
    settingRule,
    isHideable = false,
    isEnabledForTenant = true,
    ...otherProps
}: Pick<IExtendedInputSliderProps, 'formField' | 'initialValue' | 'wrapper'> & {
    settingRule: IPolicySettingRuleBoundedNumberRangeField;
    isHideable?: boolean; /* default false */
    isEnabledForTenant?: boolean; /* default true */
}) {
    if (!isEnabledForTenant && !isSet(otherProps.formField.value)) {
        return null;
    }
    return (
        <ExtendedInputSlider
            {...otherProps}
            size="S"
            readOnly={isCannotDifferRule(settingRule.rule)}
            range={settingRule.range}
            absoluteRange={RANGE_FROM_MIN1_TO_1}
            inputHideable={(!isHideable || settingRule.isParentValueSet()) ? false : {
                formFieldValueWhenHidden: null,
            }}
        />
    );
}

export function renderPolicyBenchmarkSelectionFormField({
    formField,
    policySettingsRules,
    usedDuringCreate = false,
}: {
    formField: IExtendedFormField;
    policySettingsRules: IPolicySettingsRules;
    usedDuringCreate?: boolean;
}) {
    const benchmarks: IInputSelectItem<string>[] = getPoliciesBenchmarksConfig().map(
        (benchmark) => ({
            value: benchmark.isin,
            label: benchmark.label,
        }),
    );

    return (
        <ExtendedInputSelect<string>
            formField={formField}
            wrapper={{
                label: usedDuringCreate ? 'fields.benchmark_id.add_label' : 'fields.benchmark_id.label',
                tooltip: {
                    msg: 'fields.benchmark_id.tooltip',
                },
                minHeight: usedDuringCreate ? SIZES.INPUT.HEIGHT.M : SIZES.INPUT.HEIGHT.S,
            }}
            readOnly={isCannotDifferRule(policySettingsRules.benchmark.id.rule)}
            items={benchmarks}
            noDataLabel={{
                msg: 'fields.benchmark_id.no_data',
            }}
            addNoDataSelectItem="fields.benchmark_id.no_data"
            itemLabelsAreTranslationKeys={false}
        />
    );
}
