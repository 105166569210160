import { TEntityUlid, TEntity, TEntityCreate, TEntityPatch } from '../../../../entities/dist/common/entity.models';
import {
    IApiEntityListResponseWithPageNr,
} from '../consoleApiQuery.models';
import { IMultiTranslationsLabel } from '../common/i18n.models';

export type TGoalsData = IApiEntityListResponseWithPageNr<IGoalEntityData>;
export type TGoal = TEntity<IGoalEntityData>;
export type TTGoalPatch = TEntityPatch<IGoalEntityData>;
export type TTGoalCreate = TEntityCreate<IGoalEntityData>;

export interface IGoalEntityData {
    name: IMultiTranslationsLabel;
    description: IMultiTranslationsLabel;
    min_horizon_years: number;
    type: GoalType;
    image_url?: string;
    external_id?: string;
}

export enum GoalType {
    FINANCIAL_SECURITY = 'FINANCIAL_SECURITY',
    NON_SPECIFIC = 'NON_SPECIFIC',
    OLD_AGE = 'OLD_AGE',
    OTHER = 'OTHER',
    SPECIFIC = 'SPECIFIC',
}

export interface ISingleGoalApiInput {
    goalId: TEntityUlid;
}
