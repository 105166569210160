import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { SchemaErrorType } from '@console/common/utils/schema';
import { roundFloat4 } from '@console/common/utils/float/roundFloat';
import { PolicyAllocationCategory } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import {
    IPolicySettingRuleBase,
    IPolicySettingRuleMultiValueField,
    PolicySettingRuleType,
    IPolicySettingRuleSingleValueField,
    IPolicySettingRuleBoundedSingleNumberField,
    IPolicySettingRuleBoundedNumberRangeField, PolicySettingFieldType,
} from 'models/ui/policySettings.ui.models';
import { areThereErrorsInFormFields } from 'utils/input/extendedFormUtils';
import {
    IExtendedInputFormContext,
    IOverallValidationError,
    TExtendedFormFields,
} from 'views/common/inputs/extended/ExtendedInputForm';
import {
    IPolicyAllocationBoundariesFormValues,
    IPolicyBenchmarkFormValues,
    IPolicyDetailsFormValues,
    IPolicyExecutionConstraintsFormValues,
    IPolicySettingsFormValues,
    IPolicyPortfolioConstraintsFormValues,
    IPolicyPortfolioUpdateConstraintsFormValues,
    IPolicyIvarSpecificFormValues,
    IPolicyModelPortfolioSpecificFormValues,
    IPolicyMetaPortfolioSpecificFormValues,
} from 'views/portfolioMgmt/Policies/PolicyDetail/policyFormContents/types';

const ALLOCATION_BOUNDARIES_OVERALL_SCHEMA_ERROR_TYPES = [
    SchemaErrorType.PolicyAllocationInfeasibleLowerBoundaries,
    SchemaErrorType.PolicyAllocationInfeasibleUpperBoundaries,
];

export function isNoneRule(rule: PolicySettingRuleType) {
    return rule === PolicySettingRuleType.NONE;
}

export function isMoreItemsAllowedRule(rule: PolicySettingRuleType) {
    return rule === PolicySettingRuleType.MORE_ITEMS_ALLOWED;
}

export function isLessItemsAllowedRule(rule: PolicySettingRuleType) {
    return rule === PolicySettingRuleType.LESS_ITEMS_ALLOWED;
}

export function isCannotDifferRule(rule: PolicySettingRuleType) {
    return rule === PolicySettingRuleType.CANNOT_DIFFER;
}

export function isWithinBoundariesRule(rule: PolicySettingRuleType) {
    return rule === PolicySettingRuleType.WITHIN_BOUNDARIES;
}

export function isPolicySettingRuleMultiValueFieldTypeGuard<Value>(
    input: IPolicySettingRuleBase<unknown>,
): input is IPolicySettingRuleMultiValueField<Value> {
    return input.fieldType === PolicySettingFieldType.MULTI_VALUE;
}

export function isPolicySettingRuleSingleValueFieldTypeGuard<Value>(
    input: IPolicySettingRuleBase<unknown>,
): input is IPolicySettingRuleSingleValueField<Value> {
    return input.fieldType === PolicySettingFieldType.SINGLE_VALUE;
}

export function isPolicySettingRuleBoundedNumberRangeFieldTypeGuard(
    input: IPolicySettingRuleBase<unknown>,
): input is IPolicySettingRuleBoundedNumberRangeField {
    return input.fieldType === PolicySettingFieldType.BOUNDED_NUMBER_RANGE;
}

export function isPolicySettingRuleBoundedSingleNumberFieldTypeGuard(
    input: IPolicySettingRuleBase<unknown>,
): input is IPolicySettingRuleBoundedSingleNumberField {
    return input.fieldType === PolicySettingFieldType.BOUNDED_SINGLE_NUMBER;
}

export function areThereErrorsInPolicySettingsDetailSection({
    fields,
}: IExtendedInputFormContext<IPolicySettingsFormValues>) {
    const fieldsToCheck: TExtendedFormFields<IPolicyDetailsFormValues> = {
        name: fields.name,
        tags: fields.tags,
        externalId: fields.externalId,
        riskProfileId: fields.riskProfileId,
    };

    return areThereErrorsInFormFields(fieldsToCheck);
}

export function areThereErrorsInPolicyCompositionDetailSection({
    fields,
}: IExtendedInputFormContext<IPolicySettingsFormValues>) {
    const fieldsToCheck: TExtendedFormFields<IPolicyIvarSpecificFormValues
    & IPolicyModelPortfolioSpecificFormValues & IPolicyMetaPortfolioSpecificFormValues> = {
        investmentUniverse: fields.investmentUniverse,
        excludedBuyInstrumentsMap: fields.excludedBuyInstrumentsMap,
        modelPortfolioComposition: fields.modelPortfolioComposition,
        metaPortfolioComposition: fields.metaPortfolioComposition,
    };

    return areThereErrorsInFormFields(fieldsToCheck);
}

export function areThereErrorsInPolicySettingsExecutionConstraintsSection({
    fields,
}: IExtendedInputFormContext<IPolicySettingsFormValues>) {
    const fieldsToCheck: TExtendedFormFields<IPolicyExecutionConstraintsFormValues> = {
        baseCurrency: fields.baseCurrency,
        minimumTransactionAmount: fields.minimumTransactionAmount,
        maximumTransactionAmount: fields.maximumTransactionAmount,
        fractionalShares: fields.fractionalShares,
        transactionCostAmount: fields.transactionCostAmount,
        transactionCostFraction: fields.transactionCostFraction,
        combineTransactionCostMode: fields.combineTransactionCostMode,
    };

    return areThereErrorsInFormFields(fieldsToCheck);
}

export function areThereErrorsInPolicySettingsPortfolioConstraintsSection({
    fields,
}: IExtendedInputFormContext<IPolicySettingsFormValues>) {
    const fieldsToCheck: TExtendedFormFields<IPolicyPortfolioConstraintsFormValues> = {
        minMomentum: fields.minMomentum,
        maxMomentum: fields.maxMomentum,
        minCashAmount: fields.minCashAmount,
        maxCashAmount: fields.maxCashAmount,
        minPositionAmount: fields.minPositionAmount,
        maxPositionAmount: fields.maxPositionAmount,
        cashFraction: fields.cashFraction,
        positionFraction: fields.positionFraction,
    };

    return areThereErrorsInFormFields(fieldsToCheck);
}

export function areThereErrorsInPolicySettingsPortfolioUpdateConstraintsSection({
    fields,
}: IExtendedInputFormContext<IPolicySettingsFormValues>) {
    const fieldsToCheck: TExtendedFormFields<IPolicyPortfolioUpdateConstraintsFormValues> = {
        maxPortfolioConstraintsViolation: fields.maxPortfolioConstraintsViolation,
        minPortfolioValue: fields.minPortfolioValue,
        minMaxCashPercentage: fields.minMaxCashPercentage,
        minPortfolioOptimalityImprovement: fields.minPortfolioOptimalityImprovement,
    };

    return areThereErrorsInFormFields(fieldsToCheck);
}

export function areThereErrorsInPolicySettingsBenchmarkSection({
    fields,
}: IExtendedInputFormContext<IPolicySettingsFormValues>) {
    const fieldsToCheck: TExtendedFormFields<IPolicyBenchmarkFormValues> = {
        benchmarkId: fields.benchmarkId,
        benchmarkPositionFraction: fields.benchmarkPositionFraction,

        benchmarkAssetClassAlternatives: fields.benchmarkAssetClassAlternatives,
        benchmarkAssetClassBonds: fields.benchmarkAssetClassBonds,
        benchmarkAssetClassCash: fields.benchmarkAssetClassCash,
        benchmarkAssetClassCommodities: fields.benchmarkAssetClassCommodities,
        benchmarkAssetClassStocks: fields.benchmarkAssetClassStocks,
        benchmarkAssetClassCorporate: fields.benchmarkAssetClassCorporate,
        benchmarkAssetClassEquity: fields.benchmarkAssetClassEquity,
        benchmarkAssetClassEurobond: fields.benchmarkAssetClassEurobond,
        benchmarkAssetClassFixedIncome: fields.benchmarkAssetClassFixedIncome,
        benchmarkAssetClassGovernment: fields.benchmarkAssetClassGovernment,
        benchmarkAssetClassMoneyMarket: fields.benchmarkAssetClassMoneyMarket,
        benchmarkAssetClassRealEstate: fields.benchmarkAssetClassRealEstate,
        benchmarkAssetClassSukuk: fields.benchmarkAssetClassSukuk,
        benchmarkAssetClassOther: fields.benchmarkAssetClassOther,

        benchmarkRegionBondsAsia: fields.benchmarkRegionBondsAsia,
        benchmarkRegionBondsEmerging: fields.benchmarkRegionBondsEmerging,
        benchmarkRegionBondsEurope: fields.benchmarkRegionBondsEurope,
        benchmarkRegionBondsAmerica: fields.benchmarkRegionBondsAmerica,

        benchmarkRegionStocksAsia: fields.benchmarkRegionStocksAsia,
        benchmarkRegionStocksEmerging: fields.benchmarkRegionStocksEmerging,
        benchmarkRegionStocksEurope: fields.benchmarkRegionStocksEurope,
        benchmarkRegionStocksAmerica: fields.benchmarkRegionStocksAmerica,

        benchmarkBondTypeConvertible: fields.benchmarkBondTypeConvertible,
        benchmarkBondTypeCorporate: fields.benchmarkBondTypeCorporate,
        benchmarkBondTypeGovernment: fields.benchmarkBondTypeGovernment,

        benchmarkSectorBasicMaterials: fields.benchmarkSectorBasicMaterials,
        benchmarkSectorCommunicationServices: fields.benchmarkSectorCommunicationServices,
        benchmarkSectorConsumerCyclical: fields.benchmarkSectorConsumerCyclical,
        benchmarkSectorConsumerDefensive: fields.benchmarkSectorConsumerDefensive,
        benchmarkSectorEnergy: fields.benchmarkSectorEnergy,
        benchmarkSectorFinancialServices: fields.benchmarkSectorFinancialServices,
        benchmarkSectorHealthcare: fields.benchmarkSectorHealthcare,
        benchmarkSectorIndustrials: fields.benchmarkSectorIndustrials,
        benchmarkSectorRealEstate: fields.benchmarkSectorRealEstate,
        benchmarkSectorTechnology: fields.benchmarkSectorTechnology,
        benchmarkSectorUtilities: fields.benchmarkSectorUtilities,

        relativeIvarWeight: fields.relativeIvarWeight,
    };

    return areThereErrorsInFormFields(fieldsToCheck);
}

export function areThereErrorsInPolicySettingsAllocationBoundariesSection({
    fields,
    overallValidationError,
}: IExtendedInputFormContext<IPolicySettingsFormValues>) {
    const fieldsToCheck: TExtendedFormFields<IPolicyAllocationBoundariesFormValues> = {
        assetClassAlternatives: fields.assetClassAlternatives,
        assetClassBonds: fields.assetClassBonds,
        assetClassCash: fields.assetClassCash,
        assetClassCommodities: fields.assetClassCommodities,
        assetClassStocks: fields.assetClassStocks,
        assetClassCorporate: fields.assetClassCorporate,
        assetClassEquity: fields.assetClassEquity,
        assetClassEurobond: fields.assetClassEurobond,
        assetClassFixedIncome: fields.assetClassFixedIncome,
        assetClassGovernment: fields.assetClassGovernment,
        assetClassMoneyMarket: fields.assetClassMoneyMarket,
        assetClassRealEstate: fields.assetClassRealEstate,
        assetClassSukuk: fields.assetClassSukuk,
        assetClassOther: fields.assetClassOther,

        regionBondsAsia: fields.regionBondsAsia,
        regionBondsEmerging: fields.regionBondsEmerging,
        regionBondsEurope: fields.regionBondsEurope,
        regionBondsAmerica: fields.regionBondsAmerica,

        regionStocksAsia: fields.regionStocksAsia,
        regionStocksEmerging: fields.regionStocksEmerging,
        regionStocksEurope: fields.regionStocksEurope,
        regionStocksAmerica: fields.regionStocksAmerica,

        bondTypeConvertible: fields.bondTypeConvertible,
        bondTypeCorporate: fields.bondTypeCorporate,
        bondTypeGovernment: fields.bondTypeGovernment,

        sectorBasicMaterials: fields.sectorBasicMaterials,
        sectorCommunicationServices: fields.sectorCommunicationServices,
        sectorConsumerCyclical: fields.sectorConsumerCyclical,
        sectorConsumerDefensive: fields.sectorConsumerDefensive,
        sectorEnergy: fields.sectorEnergy,
        sectorFinancialServices: fields.sectorFinancialServices,
        sectorHealthcare: fields.sectorHealthcare,
        sectorIndustrials: fields.sectorIndustrials,
        sectorRealEstate: fields.sectorRealEstate,
        sectorTechnology: fields.sectorTechnology,
        sectorUtilities: fields.sectorUtilities,
    };

    return areThereErrorsInFormFields(fieldsToCheck)
        || isOverallValidationErrorForAllocationBoundariesSection(overallValidationError);
}

export function isOverallValidationErrorForAllocationBoundariesSection(
    overallValidationError: IOverallValidationError,
): boolean {
    return overallValidationError
        && ALLOCATION_BOUNDARIES_OVERALL_SCHEMA_ERROR_TYPES.includes(overallValidationError.errorType);
}

interface IInfeasiblePolicyAllocationCategoryBoundaries {
    areThereInfeasibleBoundaries: boolean;
    categoriesWhereLowerBoundariesAddUpToMoreThan100Percent: PolicyAllocationCategory[];
    categoriesWhereUpperBoundariesAddUpToLessThan100Percent: PolicyAllocationCategory[];
}

export function getInfeasiblePolicyAllocationCategoriesIfAny(
    values: IPolicyAllocationBoundariesFormValues,
): IInfeasiblePolicyAllocationCategoryBoundaries {
    const result: IInfeasiblePolicyAllocationCategoryBoundaries = {
        areThereInfeasibleBoundaries: false,
        categoriesWhereLowerBoundariesAddUpToMoreThan100Percent: [],
        categoriesWhereUpperBoundariesAddUpToLessThan100Percent: [],
    };

    if (!values) {
        return result;
    }

    const allocationCategoryToAllocationBoundariesMap = mapAllocationFormValuesToBoundariesMap(values);

    Object.entries(allocationCategoryToAllocationBoundariesMap)
        .forEach(([allocationCategoryKey, allocationCategoryBoundaries]) => {
            const {
                doLowerBoundariesAddUpToMoreThan100Percent,
                doUpperBoundariesAddUpToLessThan100Percent,
            } = determineFeasibilityOfAllocationCategoryBoundaries(allocationCategoryBoundaries);

            if (doLowerBoundariesAddUpToMoreThan100Percent) {
                result.categoriesWhereLowerBoundariesAddUpToMoreThan100Percent
                    .push(allocationCategoryKey as PolicyAllocationCategory);
            }
            if (doUpperBoundariesAddUpToLessThan100Percent) {
                result.categoriesWhereUpperBoundariesAddUpToLessThan100Percent
                    .push(allocationCategoryKey as PolicyAllocationCategory);
            }
        });

    if (isArrayWithValues(result.categoriesWhereLowerBoundariesAddUpToMoreThan100Percent)
        || isArrayWithValues(result.categoriesWhereUpperBoundariesAddUpToLessThan100Percent)) {
        result.areThereInfeasibleBoundaries = true;
    }

    return result;
}

function mapAllocationFormValuesToBoundariesMap(
    values: IPolicyAllocationBoundariesFormValues,
): { [key in PolicyAllocationCategory]: Partial<IPolicyAllocationBoundariesFormValues> } {
    return {
        [PolicyAllocationCategory.assetClasses]: {
            assetClassAlternatives: values.assetClassAlternatives,
            assetClassBonds: values.assetClassBonds,
            assetClassCash: values.assetClassCash,
            assetClassCommodities: values.assetClassCommodities,
            assetClassStocks: values.assetClassStocks,
            assetClassCorporate: values.assetClassCorporate,
            assetClassEquity: values.assetClassEquity,
            assetClassEurobond: values.assetClassEurobond,
            assetClassFixedIncome: values.assetClassFixedIncome,
            assetClassGovernment: values.assetClassGovernment,
            assetClassMoneyMarket: values.assetClassMoneyMarket,
            assetClassRealEstate: values.assetClassRealEstate,
            assetClassSukuk: values.assetClassSukuk,
            assetClassOther: values.assetClassOther,
        },
        [PolicyAllocationCategory.bondTypes]: {
            bondTypeConvertible: values.bondTypeConvertible,
            bondTypeCorporate: values.bondTypeCorporate,
            bondTypeGovernment: values.bondTypeGovernment,
        },
        [PolicyAllocationCategory.regionBonds]: {
            regionBondsAsia: values.regionBondsAsia,
            regionBondsEmerging: values.regionBondsEmerging,
            regionBondsEurope: values.regionBondsEurope,
            regionBondsAmerica: values.regionBondsAmerica,
        },
        [PolicyAllocationCategory.regionStocks]: {
            regionStocksAsia: values.regionStocksAsia,
            regionStocksEmerging: values.regionStocksEmerging,
            regionStocksEurope: values.regionStocksEurope,
            regionStocksAmerica: values.regionStocksAmerica,
        },
        [PolicyAllocationCategory.sectors]: {
            sectorBasicMaterials: values.sectorBasicMaterials,
            sectorCommunicationServices: values.sectorCommunicationServices,
            sectorConsumerCyclical: values.sectorConsumerCyclical,
            sectorConsumerDefensive: values.sectorConsumerDefensive,
            sectorEnergy: values.sectorEnergy,
            sectorFinancialServices: values.sectorFinancialServices,
            sectorHealthcare: values.sectorHealthcare,
            sectorIndustrials: values.sectorIndustrials,
            sectorRealEstate: values.sectorRealEstate,
            sectorTechnology: values.sectorTechnology,
            sectorUtilities: values.sectorUtilities,
        },
    };
}

function determineFeasibilityOfAllocationCategoryBoundaries(allocationCategoryBoundaries: TObjectWithProps): {
    doLowerBoundariesAddUpToMoreThan100Percent: boolean;
    doUpperBoundariesAddUpToLessThan100Percent: boolean;
} {
    if (Object.values(allocationCategoryBoundaries).every((boundary) => !boundary)) {
        /* Needed because during the create-policy-flow all these boundaries are not set
         * which would cause issues in the code below */
        return {
            doLowerBoundariesAddUpToMoreThan100Percent: false,
            doUpperBoundariesAddUpToLessThan100Percent: false,
        };
    }

    const { lowerBoundariesSum, upperBoundariesSum } = Object.values(allocationCategoryBoundaries).reduce(
        (accumulator, numberRange) => {
            if (numberRange) {
                accumulator.lowerBoundariesSum += numberRange[0];
                accumulator.upperBoundariesSum += numberRange[1];
            }

            return accumulator;
        },
        {
            lowerBoundariesSum: 0,
            upperBoundariesSum: 0,
        },
    );

    return {
        doLowerBoundariesAddUpToMoreThan100Percent: roundFloat4(lowerBoundariesSum) > 1,
        doUpperBoundariesAddUpToLessThan100Percent: roundFloat4(upperBoundariesSum) < 1,
    };
}
