import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { IUnderlyingRequestApiInput } from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import { SortOrder } from '../../typsy/entities/dist/common/entityQuery.models';
import {
    IFetchPortfolioValuationsApiInput,
    TPortfolioValuation,
    TPortfolioValuationsData,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/portfolioValuation.entity.models';
import { TEntityUlid } from '../../typsy/entities/dist/common/entity.models';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import { MAX_PAGE_ITEM_LIMIT } from '../../typsy/console-api-client/dist/config/consoleApi.config';
import { get } from '../coreApiRequestWrapper';
import fetchApiEntityUrlParamBuilder from '../../typsy/console-api-client/dist/utils/fetch/fetchApiEntityUrlParamBuilder';

export function fetchPortfolioValuations({
    portfolioIds,
    offset,
    limit = MAX_PAGE_ITEM_LIMIT,
    orderBy,
    underlyingApiRequestConfig,
}: IFetchPortfolioValuationsApiInput) {
    return get<TPortfolioValuationsData>({
        url: ConsoleApiPath.PORTFOLIO_VALUATIONS,
        queryParams: {
            offset,
            limit,
            ...fetchApiEntityUrlParamBuilder()
                .fieldIn({
                    field: 'portfolio_id',
                    value: portfolioIds,
                })
                .orderBy(orderBy)
                .build(),
        },
        ...underlyingApiRequestConfig,
    });
}

export async function fetchLatestValuationOfSinglePortfolio({
    portfolioId,
    underlyingApiRequestConfig,
}: IUnderlyingRequestApiInput & {
    portfolioId: TEntityUlid;
}): Promise<TPortfolioValuation> {
    const valuations = await fetchPortfolioValuations({
        portfolioIds: [portfolioId],
        limit: 1,
        orderBy: {
            field: 'valuation_date',
            order: SortOrder.Descending,
        },
        underlyingApiRequestConfig,
    });

    if (valuations && isArrayWithValues(valuations.results)) {
        return valuations.results[0];
    }

    return null;
}
