import { Locale } from '@console/common/config/i18n.config';
import { bankAccountTypeSchema } from '@console/bff/server/utils/schemas/portfolio.schemas';
import { BankAccountType, BankIdType } from '@console/core-api/typsy/console-api-client/dist/models/common/banking.models';
import { bankAccountNumber } from '@console/core-api/typsy/console-api-client/dist/utils/schema/bankAccountNumber';
import { bankId } from '@console/core-api/typsy/console-api-client/dist/utils/schema/bankId';
import {
    object, string, phoneNumber, email, array, boolean,
} from '@console/common/utils/schema';
import { UserFeatureFlag } from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/user.entity.models';
import { getUserConfig } from 'state/appConfig/selectors';

const userFeatureFlag = string().required().oneOf(
    Object.values(UserFeatureFlag),
);
const featureFlags = array().of(userFeatureFlag);
const bankIdType = string().oneOf(Object.values(BankIdType));
const language = string().oneOf(Object.values(Locale));

export function getUserCounterAccountSchema() {
    return object({
        bankId: bankId().when('bankIdType', ([type]: BankIdType[]) => bankId(type)),
        bankIdType,
        bankAccountNumber: bankAccountNumber().when(
            'bankAccountNumberType',
            ([type]: BankAccountType[]) => bankAccountNumber(type).required(),
        ),
        bankAccountNumberType: bankAccountTypeSchema.required(),
        paymentReference: string(),
    });
}

export function getUserFeatureFlagsSchema() {
    return object({
        featureFlags,
    }).required();
}

export function getUserDetailsSchema({
    /**
     * - when creating a user --> the email is mandatory when the 'allowUsersWithoutIdpLink' config is false
     *                            OR when the console user opts to create a user linked to the IDP (identity provider).
     * - when updating a user --> the email can not be updated!
     */
    isCreateMode,
}: {
    isCreateMode: boolean;
}) {
    const { allowUsersWithoutIdpLink, allowPatchingIdpSpecificFields } = getUserConfig();

    /**
     * When the allowPatchingIdpSpecificFields flag is set to false we do not allow patching any of the fields
     * that are stored in the idp.
     * Also when flag is set as false we don't allow creating users,
     * so we don't have to check the isCreateMode or allowUserWithoutIdpLink flags here
     * */
    const idpSpecificFieldsSchema = allowPatchingIdpSpecificFields
        ? {
            phone: phoneNumber().required(),
            externalId: string(),
            firstName: string().required(),
            middleName: string(),
            lastName: string().required(),
        }
        : {};

    const conditionalCreatePart = isCreateMode
        ? allowUsersWithoutIdpLink
            ? {
                shouldUserBeLinkedToIdp: boolean().required(),
                email: email().when('shouldUserBeLinkedToIdp', {
                    is: true,
                    then: (schema) => schema.required(),
                }),
            }
            : {
                email: email().required(),
            }
        : {}; /* no email when in update mode */

    return object({
        ...conditionalCreatePart,
        ...idpSpecificFieldsSchema,
        brokerageId: string(),
        language,
        featureFlags,
    }).required();
}
