import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { PolicyManagerTag } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import {
    IEnhancedPolicyAlgorithmSettings,
    PolicyAlgorithmType,
    PolicyMeasureOfRisk,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';
import {
    mapPolicySettingsFormValuesToAlgorithmSettings,
} from 'utils/entities/portfolioMgmt/policyFormMapper';
import { getStore } from 'state';
import { getTranslator } from 'state/i18n/selectors';
import {
    ExtendedInputFormName,
    getManagedExtendedInputForm,
} from 'views/common/inputs/extended/extendedInputFormManager';
import { IPolicySettingsFormValues } from 'views/portfolioMgmt/Policies/PolicyDetail/policyFormContents/types';
import { IAskConfirmationConfig } from 'views/common/buttons/types';

export function getPolicyManagerTagLabel(managerTag: PolicyManagerTag): string {
    return `portfolio_mgmt.portfolios.data.manager_tag.${managerTag.toLowerCase()}`;
}

export function convertPolicyManagerTagsForDisplay({
    tags,
    translator,
}: {
    tags: PolicyManagerTag[];
    translator: TTranslator;
}): string {
    if (!isArrayWithValues(tags)) {
        return '';
    }

    return tags
        .map((tag) => translator(getPolicyManagerTagLabel(tag)))
        .join(', ');
}

export function getPolicySettingsChangeConfirmationModalConfig(): IAskConfirmationConfig {
    return {
        titleLabel: 'portfolio_mgmt.policies.detail.configuration.settings.confirmation_modal.title',
        messageLabel: 'portfolio_mgmt.policies.detail.configuration.settings.confirmation_modal.message',
        confirmLabel: 'portfolio_mgmt.policies.detail.configuration.settings.confirmation_modal.confirm',
    };
}

export function getUnsavedPolicySettingsIfAny(): {
    areThereUnsavedSettings: boolean;
    settings?: IEnhancedPolicyAlgorithmSettings;
    isFormValid?: boolean;
    // eslint-disable-next-line indent
} {
    const managedPolicySettingsForm = getManagedExtendedInputForm<IPolicySettingsFormValues>(
        ExtendedInputFormName.policySettings,
    );

    if (!managedPolicySettingsForm) {
        return {
            areThereUnsavedSettings: false,
        };
    }

    const { isDirty, isValid, isDiff, values } = managedPolicySettingsForm.formState;

    if (!isDirty || !isDiff) {
        return {
            areThereUnsavedSettings: false,
        };
    }

    return {
        areThereUnsavedSettings: true,
        settings: mapPolicySettingsFormValuesToAlgorithmSettings(values),
        isFormValid: isValid,
    };
}

export function mapPolicyTagsForDisplay(tags: PolicyManagerTag[]) {
    if (!tags) {
        return '-';
    }

    const translator = getTranslator(getStore().getState());

    return tags
        .map(getPolicyManagerTagLabel)
        .map(translator)
        .join(', ');
}

export function getPolicyAlgorithmLabel(algorithm: PolicyAlgorithmType): string {
    return `portfolio_mgmt.policies.data.algorithm.${algorithm.toLowerCase()}`;
}

export function getPolicyMeasureOfRiskLabel(measureOfRisk: PolicyMeasureOfRisk): string {
    return `portfolio_mgmt.policies.data.measure_of_risk.${measureOfRisk.toLowerCase()}`;
}
