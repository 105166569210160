import {
    IMultiTranslationsLabel,
} from '../common/i18n.models';
import { TEntityUlid, TEntity, TEntityCreate } from '../../../../entities/dist/common/entity.models';
import { IQueryEntityEmbedRequest } from '../../../../entities/dist/common/entityQuery.models';
import {
    IApiEntityListRequest, IApiEntityListResponseWithPageNr,
} from '../consoleApiQuery.models';

export type TPortfolioReportsData = IApiEntityListResponseWithPageNr<IPortfolioReportEntityData>;
export type TPortfolioReport = TEntity<IPortfolioReportEntityData>;
export type TPortfolioReportCreate = TEntityCreate<IPortfolioReportAddEntityData>;
export type TPortfolioReportUpdate = IPortfolioReportUpdateEntityData;

export interface IPortfolioReportEntityData {
    name: IMultiTranslationsLabel;
    user_id: TEntityUlid;
    portfolio_id: TEntityUlid; // can be the id of a portfolio or portfolio group
    language: string; // to do - enum?
    start_date: string;
    end_date: string;
    status: PortfolioReportStatus;
    report_uri: string;
}

export enum PortfolioReportStatus {
    PENDING = 'PENDING',
    OK = 'OK',
    FAILED = 'FAILED',
}

export type TPortfolioReportEmbeddableFields = 'user_id' | 'portfolio_id';

export interface IFetchPortfolioGroupReportPortfoliosApiInput {
    ids: TEntityUlid[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPortfolioReportAddEntityData
    extends Omit<IPortfolioReportEntityData, 'status' | 'report_uri'> {
}

export interface IPortfolioReportUpdateEntityData
    extends Pick<IPortfolioReportEntityData, 'name'> {
    portfolioReportId: string;
}

export interface IFetchPortfolioReportsApiInput
    extends IApiEntityListRequest, IQueryEntityEmbedRequest<TPortfolioReportEmbeddableFields> {
    portfolioId?: string;
    name?: string;
    userId?: string;
}

export type TTriggerPortfolioReportFileUrlInput = Pick<IFetchPortfolioReportFileUrlApiInput, 'portfolioReportId'>;

export interface IFetchPortfolioReportFileUrlApiInput {
    portfolioReportId: string;
    format: PortfolioReportFileUrlFormat;
}

export enum PortfolioReportFileUrlFormat {
    JSON = 'json',
    PDF = 'pdf',
    WEB = 'webpage',
}
