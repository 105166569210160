import { TEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    IUserEntityData, UserFeatureFlag, UserStatus,
} from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/user.entity.models';
import { joinParts } from '@console/core-api/typsy/core/dist/string/joinParts';

export function getUserFullName(user: IUserEntityData) {
    if (!user) {
        return '';
    }

    return joinParts([
        user.first_name,
        user.last_name,
    ], { joinSeparator: ' ' });
}

export function isUserFeatureFlagPresent(
    user: TEntity<IUserEntityData>,
    featureFlag: UserFeatureFlag,
): boolean {
    return user.feature_flags?.includes(featureFlag);
}

export function getUserFeatureFlagLabel(featureFlag: UserFeatureFlag): string {
    return `user_mgmt.users.detail.fields.feature_flags.${featureFlag.toLowerCase()}`;
}

export function getUserStatusLabel(userStatus: UserStatus): string {
    if (!userStatus) {
        return '-';
    }
    return `user_mgmt.users.detail.fields.status.values.${userStatus.toLowerCase()}`;
}
