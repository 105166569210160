import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TPolicy } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { TEnhancedPolicy } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { IPolicySettingsRules } from 'models/ui/policySettings.ui.models';
import { APP_COLORS } from 'config/styling/colors';
import { DEFAULT_POLICY_TAB_KEY } from 'config/tabs.config';
import { policyDetailsEntity, triggerPatchPolicyDetails } from 'state/entities/portfolioMgmt/policyDetails';
import {
    canUserCreateBasePolicy,
} from 'state/auth/apiEntityAuthorization.selectors';
import { getPolicySettingsChangeConfirmationModalConfig } from 'utils/entities/portfolioMgmt/policyUtils';
import IconButton from 'views/common/buttons/IconButton';
import { ArrowRightIcon } from 'views/common/icons';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { IExtendedInputFormContext, IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import SelectOneViaModal, { IRenderSelectedItemProps } from 'views/common/inputs/base/SelectOneViaModal';
import ContentTitle from 'views/common/layout/ContentTitle';
import { makeStyles, mixins } from 'views/styling';
import { IParentPolicyFormValues } from '../policyFormContents/types';
import { getParentPolicySchema } from '../policyDetailsSchema';
import { SelectParentPolicyFormContent } from '../policyFormContents/policyDetailSectionFormContent';

const LABEL_PREFIX = 'portfolio_mgmt.policies.detail';

interface IPublicProps {
    parentPolicy: TPolicy | TEnhancedPolicy | null;
    isReadOnly: boolean;
    isSettingsEditActive: boolean;
    policySettingsRules: IPolicySettingsRules;
}

const useStyles = makeStyles((theme) => ({
    ParentPolicySection: {
        ...mixins.widthMax(),
        ...mixins.borderBottom({ width: 1, style: 'solid', color: APP_COLORS.GREY['100'] }),
        paddingBottom: theme.spacing(3),
    },
    NoParentPolicy: {
        ...mixins.typo({ color: APP_COLORS.GREY['400'] }),
        paddingTop: theme.spacing(1),
    },
    ParentPolicyName: {
        paddingTop: theme.spacing(1),
        ...mixins.widthMax(),
        ...mixins.flexRow({ alignCross: 'center', alignMain: 'space-between' }),
    },
}));

export default function ParentPolicySection({
    parentPolicy,
    isReadOnly,
    isSettingsEditActive,
    policySettingsRules,
}: IPublicProps) {
    const classes = useStyles();

    const policyDetailsData = policyDetailsEntity.select().data;
    const parentPolicyId = policyDetailsData?.parent_policy_id;
    const isParentPolicySet = isSet(parentPolicyId);
    const parentPolicyFormValues: IParentPolicyFormValues = {
        parentPolicyId,
    };
    const doesUserHaveCreateBasePolicyPermission = canUserCreateBasePolicy();
    const policySettingsChangeConfirmationModalConfig = getPolicySettingsChangeConfirmationModalConfig();
    const isParentPolicyRequired = policySettingsRules.parent.isRequired;

    return (
        <div className={classes.ParentPolicySection}>
            <ContentTitle
                label={`${LABEL_PREFIX}.fields.parent_policy_id.label`}
                infoTooltip={{
                    name: 'parent_policy_info',
                    simpleContent: {
                        info: `${LABEL_PREFIX}.fields.parent_policy_id.tooltip`,
                    },
                    showUntilDismissed: true,
                }}
                variant="section"
            />
            <SelectOneViaModal
                currentSelectedItem={parentPolicyFormValues}
                renderSelectedItem={renderSelectedPolicy}
                name="change_parent_policy"
                edit={{
                    modalTitle: `${LABEL_PREFIX}.fields.parent_policy_id.select`,
                    modalMaxWidth: 'md',
                    label: 'common.action.change',
                    initialValues: parentPolicyFormValues,
                    schema: getParentPolicySchema({ isParentPolicyRequired }),
                    disabled: isSettingsEditActive || isReadOnly,
                    renderFormFields: renderSelectParentPolicyFormContent,
                    submit: {
                        onSubmit: selectParentPolicy,
                        actionLabel: 'common.action.update',
                        confirmationModal: policySettingsChangeConfirmationModalConfig,
                    },
                }}
                remove={doesUserHaveCreateBasePolicyPermission && isParentPolicySet && {
                    label: 'common.action.unlink',
                    onRemoveItem: unlinkParentPolicy,
                    confirmationModal: policySettingsChangeConfirmationModalConfig,
                    disabled: !isParentPolicySet || isSettingsEditActive,
                }}
                renderActionsBelowItem
            />
        </div>
    );

    function unlinkParentPolicy() {
        triggerPatchPolicyDetails((currentPolicy) => {
            // eslint-disable-next-line no-param-reassign
            currentPolicy.parent_policy_id = null;
        }, { isChangingParentPolicy: true });
    }

    function renderSelectParentPolicyFormContent(
        context: IExtendedInputFormContext<IParentPolicyFormValues>,
    ) {
        return (
            <SelectParentPolicyFormContent
                {...context}
                policyAlgorithmType={policyDetailsData.config.algorithm}
                childPolicyId={policyDetailsData?.id}
                currency={policyDetailsData.coerced_config.algorithm_settings.execution_settings.base_currency}
            />
        );
    }

    function selectParentPolicy({ values }: IOnSubmitProps<IParentPolicyFormValues>) {
        return triggerPatchPolicyDetails((currentPolicy) => {
            // eslint-disable-next-line no-param-reassign
            currentPolicy.parent_policy_id = values.parentPolicyId;
        }, { isChangingParentPolicy: true });
    }

    function renderSelectedPolicy({ item }: IRenderSelectedItemProps<IParentPolicyFormValues>) {
        if (!isSet(item.parentPolicyId)) {
            return (
                <div className={classes.NoParentPolicy}>
                    <Translate msg={`${LABEL_PREFIX}.fields.parent_policy_id.no_data`} />
                </div>
            );
        }

        return (
            <div className={classes.ParentPolicyName}>
                <div>
                    {parentPolicy
                        ? parentPolicy.name
                        : item.parentPolicyId
                    }
                </div>
                <IconButton
                    id="navigate_to_parent_policy_detail"
                    icon={<ArrowRightIcon />}
                    tooltip="common.action.navigate_to"
                    variant="bare"
                    color="text"
                    size="S"
                    onClick={navigateToPolicyDetail}
                />
            </div>
        );

        function navigateToPolicyDetail() {
            redirectTo({
                routeKey: ROUTE_KEY.R_POLICY_DETAIL,
                params: {
                    policyId: item.parentPolicyId,
                    policyTab: DEFAULT_POLICY_TAB_KEY,
                },
            });
        }
    }
}
