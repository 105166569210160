import isSet from '@snipsonian/core/cjs/is/isSet';
import { TNumberRange } from '@console/core-api/typsy/console-api-client/dist/models/common/numeric.models';

export function createRange(from: number, to: number, step: number) {
    return [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step);
}

export function isNumberInRange(number: number, range: [number, number]) {
    const [minValue, maxValue] = range;

    if (isSet(number) && number < minValue) {
        return false;
    }

    if (!isSet(maxValue)) {
        /* null = infinity */
        return true;
    }

    if (!isSet(number)) {
        return false;
    }

    return number <= maxValue;
}

export function ensureMinimumRange(
    minMaxAmount: TNumberRange,
    minAmountRange: number,
): TNumberRange {
    let [minAmount, maxAmount] = minMaxAmount;
    const amountDiff = maxAmount - minAmount;

    if (amountDiff > minAmountRange) {
        return minMaxAmount;
    }

    if (minAmount < 0) {
        /* negative minAmount --> extend range only 'upwards' */
        maxAmount = minAmount + minAmountRange;
    } else {
        /* positive minAmount --> extend range in both directions, but don't go below 0 */
        const lowerBound = ((minAmount + maxAmount) / 2) - (minAmountRange / 2);

        if (lowerBound < 0) {
            minAmount = 0;
            maxAmount = minAmountRange;
        } else {
            minAmount = lowerBound;
            maxAmount = lowerBound + minAmountRange;
        }
    }

    return [minAmount, maxAmount];
}
