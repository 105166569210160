import React, { useState } from 'react';
import isDate from '@snipsonian/core/cjs/is/isDate';
import { toStartOfDay, toEndOfDay, parseInputDate } from '@console/common/utils/date/dateUtils';
import { IPeriodFilter, PeriodType, IDatePeriod, IPeriodBoundaries } from '@console/common/models/periodFilter.models';
import { object, date, DateSchema } from '@console/common/utils/schema';
import { formatDate } from '@console/common/utils/date/formatDate';
import { getDatesForPeriodType } from '@console/common/utils/date/getDatesForPeriodType';
import { DEFAULT_SELECTABLE_PERIOD_TYPES } from 'config/ui.config';
import { TI18nLabelOrString } from 'models/general.models';
import { makeStyles, mixins } from 'views/styling';
import Modal from 'views/common/layout/Modal';
import InputSelectField, { IInputSelectItem } from './InputSelectField';
import { IOnChangeTextInputProps } from './InputTextField';
import { IFormValues } from '../extended/types';
import ExtendedInputForm, { IExtendedInputFormContext } from '../extended/ExtendedInputForm';
import ExtendedInputDate from '../extended/ExtendedInputDate';
import InputGroup from './InputGroup';

const LABEL_PREFIX = 'common.period_input';

export interface IInputPeriodFieldProps<DateType = Date> extends IPeriodBoundaries {
    currentPeriod: IPeriodFilter<DateType>;
    onChange: (currentPeriod: IPeriodFilter<DateType>) => void;
    /*
        By default you can select the Standard period types, however if you choose so you can override them.

        Depending on which subset of periods from the PeriodType enum you would like to use, you must
        provide an array with the selectable periods and extend.

        When you want to use the SPECIFIC_PERIOD period selector:
            - make sure to pass both CUSTOM and SPECIFIC_PERIOD enum values to the component
                (see more explanation with PeriodType definition)
    */
    selectablePeriods?: IPeriodFilter<DateType>[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICustomPeriodFormValues extends IFormValues, IDatePeriod {}

const useStyles = makeStyles(() => ({
    InputPeriodField: {
        ...mixins.widthMax(),

        '& .__customPeriod': {
            ...mixins.flexRow(),
        },
    },
}));

export default function InputPeriodField<DateType = Date>({
    minDate: minDateInput,
    maxDate: maxDateInput,
    selectablePeriods: selectablePeriodsInput,
    currentPeriod,
    onChange,
}: IInputPeriodFieldProps<DateType>) {
    const classes = useStyles();
    const { minDate, maxDate } = getMinMaxDateRange();
    const selectablePeriods: IPeriodFilter<DateType>[] = selectablePeriodsInput ||
        DEFAULT_SELECTABLE_PERIOD_TYPES.map((periodType) => ({
            type: periodType,
            ...getDatesForPeriodType({
                minDate,
                maxDate,
                periodType,
            }) as unknown as IDatePeriod<DateType>,
        }));
    const periodInputSelectItems = getPeriodInputSelectItems();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <div className={classes.InputPeriodField}>
            <Modal
                id="select_custom_period_modal"
                open={isModalOpen}
                title={`${LABEL_PREFIX}.custom.modal_title`}
                onClose={goBackToPeriodTypeSelection}
            >
                <CustomPeriod />
            </Modal>

            <InputSelectField<PeriodType>
                onChange={onChangePeriodInput}
                value={currentPeriod.type}
                items={periodInputSelectItems}
                itemLabelsAreTranslationKeys
            />
        </div>
    );

    function onChangePeriodInput({ value: periodType }: IOnChangeTextInputProps<PeriodType>) {
        if (periodType === PeriodType.SPECIFIC_PERIOD) {
            setIsModalOpen(true);
        } else {
            const { startDate, endDate } = selectablePeriods.find(({ type }) => type === periodType);
            onChange({
                type: periodType,
                startDate,
                endDate,
            });
        }
    }

    function getPeriodInputSelectItems(): IInputSelectItem<PeriodType>[] {
        return Object.values(selectablePeriods)
            .filter(({ type }) => type !== PeriodType.CUSTOM || currentPeriod.type === PeriodType.CUSTOM)
            .map(({ type }) => ({
                value: type,
                label: getPeriodInputItemLabel<DateType>({
                    type,
                    startDate: currentPeriod.startDate,
                    endDate: currentPeriod.endDate,
                }),
            }));
    }

    function goBackToPeriodTypeSelection() {
        setIsModalOpen(false);
    }

    function confirmCustomPeriod({ values }: { values: ICustomPeriodFormValues }) {
        setIsModalOpen(false);

        onChange({
            type: PeriodType.CUSTOM,
            ...(values as unknown as IDatePeriod<DateType>),
        });

        return Promise.resolve();
    }

    // TODO
    // eslint-disable-next-line react/no-unstable-nested-components
    function CustomPeriod() {
        const initialValues: ICustomPeriodFormValues = {
            startDate: isDate(currentPeriod.startDate)
                ? currentPeriod.startDate as Date
                : parseInputDate(currentPeriod.startDate as unknown as string).toDate(),
            endDate: isDate(currentPeriod.endDate)
                ? currentPeriod.endDate as Date
                : parseInputDate(currentPeriod.endDate as unknown as string).toDate(),
        };

        return (
            <div className="__customPeriod">
                <ExtendedInputForm<ICustomPeriodFormValues>
                    name="custom-period-filter"
                    initialValues={initialValues}
                    schema={getCustomPeriodFilterSchema({
                        minDate,
                        maxDate,
                    })}
                    renderFormFields={renderFormFields}
                    submit={{
                        onSubmit: confirmCustomPeriod,
                        actionLabel: 'common.action.confirm',
                    }}
                    cancel={{
                        onCancel: goBackToPeriodTypeSelection,
                    }}
                    labelPrefix={LABEL_PREFIX}
                />
            </div>
        );

        function renderFormFields(context: IExtendedInputFormContext<ICustomPeriodFormValues>) {
            return (
                <InputGroup>
                    <ExtendedInputDate
                        formField={context.fields.startDate}
                        wrapper={{
                            label: 'custom.from',
                        }}
                        clearable={false}
                        minDate={minDate}
                        maxDate={maxDate}
                    />

                    <ExtendedInputDate
                        formField={context.fields.endDate}
                        wrapper={{
                            label: 'custom.to',
                        }}
                        clearable={false}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </InputGroup>
            );
        }
    }

    function getMinMaxDateRange(): { minDate: Date, maxDate: Date } {
        return {
            minDate: minDateInput
                ? toStartOfDay(minDateInput).toDate()
                : undefined,
            maxDate: maxDateInput
                ? toEndOfDay(maxDateInput).toDate()
                : undefined,
        };
    }
}

function getPeriodInputItemLabel<DateType>(currentPeriod: IPeriodFilter<DateType>): TI18nLabelOrString {
    if (currentPeriod.type === PeriodType.CUSTOM) {
        return {
            msg: `${LABEL_PREFIX}.custom.item_label`,
            placeholders: {
                fromDate: formatDate({ date: currentPeriod.startDate as unknown as string }),
                toDate: formatDate({ date: currentPeriod.endDate as unknown as string }),
            },
        };
    }

    return getPeriodTypeLabel(currentPeriod.type);
}

function getPeriodTypeLabel(periodType: PeriodType) {
    return `${LABEL_PREFIX}.type.${periodType.toLowerCase()}`;
}

function getCustomPeriodFilterSchema({
    minDate,
    maxDate,
}: {
    minDate?: Date;
    maxDate?: Date;
}) {
    return object({
        startDate: date()
            .minDate(minDate)
            .maxDate(maxDate),
        endDate: date()
            .minDate(minDate)
            .maxDate(maxDate)
            .when('startDate', ([startDate]: Date[], schema: DateSchema) => {
                if (isDate(startDate)) {
                    return schema.minDate(toStartOfDay(startDate).toDate());
                }
                return schema;
            }),
    });
}
