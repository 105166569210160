import React from 'react';
import { SortOrder } from '@console/core-api/typsy/entities/dist/common/entityQuery.models';
import { IColValues, IDataItem, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { TGoal } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/goal.entity.models';
import { UiPageKey } from 'models/state/ui.models';
import { DEFAULT_GOAL_TAB_KEY } from 'config/tabs.config';
import { goalsEntity, triggerFetchGoals } from 'state/entities/portfolioMgmt/goals';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import DataTable from 'views/common/list/DataTable';
import ListPageForApiEntity, {
    ISortConfigFunctions,
    TSetStateOnPageNrChange,
} from 'views/common/list/ListPageForApiEntity';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';

const COL_TRANSLATION_PREFIX = 'portfolio_mgmt.goals.list.columns';

interface IGoalCols extends IColValues {
    name: string;
    minHorizonYears: number;
    description: string;
    updated: string;
}

const COLS: TDataColumns<IGoalCols> = {
    name: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.name`,
        },
        data: {
            className: UtilityClass.table.cellBold,
        },
        percentWidth: 20,
    },
    description: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.description`,
        },
        percentWidth: 40,
    },
    minHorizonYears: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.min_horizon_years`,
        },
        align: 'center',
        percentWidth: 20,
    },
    updated: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.updated`,
        },
        percentWidth: 20,
        sort: {
            initialOrder: SortOrder.Descending,
            serverSide: {
                field: 'version_datetime',
            },
        },
    },
};

export default function GoalsList() {
    const setStateOnPageNrChange: TSetStateOnPageNrChange = (pageNr) => ({
        toState: (draftState) => {
            // eslint-disable-next-line no-param-reassign
            draftState.entities.goals.data.pageNr = pageNr;
        },
        notificationsToTrigger: [StateChangeNotification.GOALS_DATA],
    });

    let sortConfigFunctions: ISortConfigFunctions;

    return (
        <ListPageForApiEntity
            notifications={[StateChangeNotification.GOALS_DATA, StateChangeNotification.GOALS_UI_VARS]}
            asyncListEntity={goalsEntity}
            asyncListEntityFetchTrigger={triggerFetchGoals}
            setStateOnPageNrChange={setStateOnPageNrChange}

            uiPageKey={UiPageKey.goalsList}
            notificationToTriggerOnUiVarChanges={StateChangeNotification.GOALS_UI_VARS}
            box={{
                title: 'portfolio_mgmt.goals.title',
            }}
            list={{
                renderData: renderGoalsList,
            }}
            sort={{
                getSortConfigFunctionsCallback: (newSortConfigFunctions) => {
                    sortConfigFunctions = newSortConfigFunctions;
                },
            }}
            create={{
                toRoute: {
                    routeKey: ROUTE_KEY.R_GOAL_ADD,
                },
                tooltip: 'portfolio_mgmt.goals.list.actions.create_goal',
            }}
        />
    );

    function renderGoalsList({ data }: { data: TGoal[] }) {
        if (!data) {
            return null;
        }

        const goalItems: IDataItem<IGoalCols>[] = data.map((goal) => ({
            id: goal.id,
            colValues: {
                name: getDefaultTranslationFromApiLabel(goal.name),
                description: getDefaultTranslationFromApiLabel(goal.description),
                minHorizonYears: goal.min_horizon_years,
                updated: formatDateRelativeToNow({ date: goal.version_datetime }),
            },
        }));

        return (
            <DataTable
                cols={COLS}
                items={goalItems}
                onItemRowClicked={openGoalDetail}
                serverSideSorting={{
                    activeSortColumn: sortConfigFunctions.getActiveSortColumn,
                    onSelectSortColumn: (selectedSortCol) => {
                        sortConfigFunctions.setStateOnSortColumnChange(selectedSortCol);
                    },
                }}
            />
        );
    }

    function openGoalDetail({
        id,
    }: IDataItem<IGoalCols>) {
        redirectTo({
            routeKey: ROUTE_KEY.R_GOAL_DETAIL,
            params: {
                goalId: id,
                goalTab: DEFAULT_GOAL_TAB_KEY,
            },
        });
    }
}
