import {
    DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR, LIST_RESPONSE_NO_COUNT,
} from '@console/core-api/typsy/console-api-client/dist/config/consoleApi.config';
import { IUntranslatableLabel } from 'models/general.models';

export const TOTAL_NR_OF_ITEMS_UNKNOWN = LIST_RESPONSE_NO_COUNT; // -1
export const PAGE_LABEL_UNSURE_IF_DATA = '...';
export const PAGE_LABEL_SKIPPED = '...';

export const EMPTY_COL_TITLE: IUntranslatableLabel = {
    text: '',
    shouldTranslate: false,
};

export function extractDataItemsOfSelectedPage<Data>({
    allDataItems,
    itemsPerPage,
    pageNr,
}: {
    allDataItems: Data[];
    itemsPerPage?: number;
    pageNr?: number;
}) {
    if (!allDataItems) {
        return [];
    }

    const { from, to } = getFromToItemIndexOfCurrentPage({
        nrOfItems: allDataItems.length,
        itemsPerPage,
        pageNr,
    });

    if (from > allDataItems.length) {
        return [];
    }

    /**
     * slice(start, end)
     * - start: first index = 0
     * - end: exclusive, so the element with that index will not be returned
     */
    return allDataItems.slice(from - 1, to);
}

/**
 * 'from' starts from 1 and the 'to' is inclusive !!!
 * So for e.g. the first 10 items, it would return:
 *   { from: 1, to: 10 }
 */
export function getFromToItemIndexOfCurrentPage({
    nrOfItems,
    itemsPerPage = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
}: {
    nrOfItems: number;
    itemsPerPage?: number;
    pageNr?: number;
}): { from: number; to: number } {
    const from = (itemsPerPage * (pageNr - 1)) + 1;
    const to = Math.min(from + itemsPerPage - 1, nrOfItems);

    return {
        from,
        to,
    };
}

export interface IPage {
    nr: number;
    label: string;
    areItemsAvailable: boolean;
    disabled?: boolean; // default false
}

export function getPages({
    nrOfItems,
    itemsPerPage = DEFAULT_PAGE_ITEM_LIMIT,
    totalNrOfItems = TOTAL_NR_OF_ITEMS_UNKNOWN,
}: {
    nrOfItems: number;
    itemsPerPage?: number;
    totalNrOfItems?: number;
}): IPage[] {
    if (nrOfItems === 0) {
        return [{ nr: 1, label: '1', areItemsAvailable: true }];
    }

    const pages: IPage[] = [];

    const nrOfFullPages = Math.floor(nrOfItems / itemsPerPage);
    for (let pageNr = 1; pageNr <= nrOfFullPages; pageNr++) {
        pages.push({ nr: pageNr, label: pageNr.toString(), areItemsAvailable: true });
    }

    if (totalNrOfItems > nrOfItems) {
        const totalNrOfPages = Math.ceil(totalNrOfItems / itemsPerPage);

        for (let pageNr = nrOfFullPages + 1; pageNr <= totalNrOfPages; pageNr++) {
            pages.push({ nr: pageNr, label: pageNr.toString(), areItemsAvailable: false });
        }
    } else {
        const remainingItems = nrOfItems % itemsPerPage;

        if (remainingItems > 0) {
            pages.push({ nr: nrOfFullPages + 1, label: (nrOfFullPages + 1).toString(), areItemsAvailable: true });
        } else if (totalNrOfItems === TOTAL_NR_OF_ITEMS_UNKNOWN) {
            /* we don't know if there is extra data or not */
            pages.push({ nr: nrOfFullPages + 1, label: PAGE_LABEL_UNSURE_IF_DATA, areItemsAvailable: false });
        }
    }

    return pages;
}

/**
 * Always return:
 * - page 1
 * - ... (disabled, if applicable)
 * - page before current page (if applicable)
 * - current page
 * - page after current page (if applicable)
 * - ... (disabled, if applicable)
 * - latest page (if applicable)
 *
 * Will max return 7 pages!
 * Will max return 1 page beyond the current page where areItemsAvailable is false
 *   (because we want the users to first fetch the 1st page, then the 2nd, and so on, so that we can concatenate those
 *   items and we don't have to re-fetch when going back a page)
 */
export function limitPagesToShow({
    pages,
    pageNr,
}: {
    pages: IPage[];
    pageNr: number;
}): IPage[] {
    const firstPageIndexWithoutAvailableItems = pages.findIndex((page) => !page.areItemsAvailable);
    const allowedPages = pages.reduce(
        (accumulator, page, pageIndex) => {
            if (pageIndex === 0) {
                accumulator.push(page);
            } else if (firstPageIndexWithoutAvailableItems === -1) {
                accumulator.push(page);
            } else if (pageIndex <= firstPageIndexWithoutAvailableItems) {
                accumulator.push(page);
            } else if (pageIndex === firstPageIndexWithoutAvailableItems + 1) {
                accumulator.push({
                    ...page,
                    label: PAGE_LABEL_SKIPPED,
                    disabled: true,
                });
            }

            return accumulator;
        },
        [],
    );

    if (allowedPages.length <= 7) {
        return allowedPages;
    }

    return allowedPages.reduce(
        (accumulator, page, pageIndex) => {
            if (page.nr === FIRST_PAGE_NR && pageNr > FIRST_PAGE_NR + 1) {
                accumulator.push(page);
            } else if (page.nr === (FIRST_PAGE_NR + 1) && pageNr > FIRST_PAGE_NR + 2) {
                accumulator.push({
                    ...page,
                    label: PAGE_LABEL_SKIPPED,
                    disabled: true,
                });
            } else if ([pageNr - 1, pageNr, pageNr + 1].includes(page.nr)) {
                accumulator.push(page);
            } else if (page.nr === (pageNr + 2) && pageIndex < (allowedPages.length - 1)) {
                accumulator.push({
                    ...page,
                    label: PAGE_LABEL_SKIPPED,
                    disabled: true,
                });
            } else if (pageIndex === (allowedPages.length - 1)) {
                // final page
                accumulator.push(page);
            }

            return accumulator;
        },
        [],
    );
}
