import { IBaseIdentifiedEntity } from '../../typsy/entities/dist/common/entity.models';
import { IApiEntityListRequest, IApiEntityListResponse } from '../../typsy/console-api-client/dist/models/consoleApiQuery.models';
import {
    IHorizonEntityData,
    ISingleHorizonApiInput,
    THorizon,
    THorizonsData,
    TTHorizonCreate,
    TTHorizonPatch,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/horizon.entity.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../typsy/console-api-client/dist/config/consoleApi.config';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../typsy/console-api-client/dist/utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../typsy/console-api-client/dist/utils/fetch/entityFetchUtils';
import { fetchAllPagesOfApiEntity } from '../../typsy/console-api-client/dist/utils/fetch/fetchAllPagesOfApiEntity';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchAllHorizons() {
    return fetchAllPagesOfApiEntity<IFetchHorizonsApiInput, IHorizonEntityData>({
        apiInput: {
            count: false,
        },
        api: fetchHorizons,
    });
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFetchHorizonsApiInput extends IApiEntityListRequest {}

export function fetchHorizons({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    orderBy,
    count,
}: IFetchHorizonsApiInput = {}) {
    return get<THorizonsData, IApiEntityListResponse<IHorizonEntityData>>({
        url: ConsoleApiPath.HORIZONS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .orderBy(orderBy)
                .build(),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function fetchHorizonDetails({ horizonId }: ISingleHorizonApiInput) {
    return get<THorizon>({
        url: ConsoleApiPath.HORIZON_DETAILS,
        pathParams: {
            horizonId,
        },
    });
}

export function patchHorizon({
    id,
    ...patchFields
}: TTHorizonPatch) {
    return patch<THorizon>({
        url: ConsoleApiPath.HORIZON_DETAILS,
        pathParams: {
            horizonId: id,
        },
        body: patchFields,
    });
}

export function createHorizon(horizonToCreate: TTHorizonCreate) {
    return post<THorizon>({
        url: ConsoleApiPath.HORIZONS,
        body: horizonToCreate,
    });
}

export function deleteHorizon({ id }: IBaseIdentifiedEntity) {
    return remove<unknown>({
        url: ConsoleApiPath.HORIZON_DETAILS,
        pathParams: {
            horizonId: id,
        },
    });
}
