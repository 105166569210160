import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import { TUserGroup } from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/userGroup.entity.models';
import { canUserModifyUserGroup } from 'state/auth/apiEntityAuthorization.selectors';

export function isUserGroupReadOnly(userGroup: TUserGroup) {
    return isEveryoneGroup(userGroup) || !canUserModifyUserGroup(userGroup);
}

export function isEveryoneGroup(userGroup: TUserGroup): boolean {
    return (userGroup.id === 'GAAAAAAAAAAAAAAAAAAAAAAAAAA');
}

export function getOperationPermissionDisplayName(
    operationPermission: OperationPermissionKey,
    translator: TTranslator,
): string {
    const translation = translator({
        msg: `auth.operation_permissions.${operationPermission.toLowerCase()}.name`,
    });

    return (translation.indexOf('auth.') === 0)
        ? operationPermission.toLowerCase() /* no translation found, so just showing the key itself */
        : translation;
}

export function getOperationPermissionDescription(
    operationPermission: OperationPermissionKey,
    translator: TTranslator,
): string {
    const translation = translator({
        msg: `auth.operation_permissions.${operationPermission.toLowerCase()}.description`,
    });

    return (translation.indexOf('auth.') === 0)
        ? '' /* no translation found, so just showing an empty string */
        : translation;
}
