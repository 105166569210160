import React from 'react';
import { TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { PolicyAlgorithmType, TEnhancedPolicy } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { IColValues, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { triggerFetchPoliciesForSearch } from 'state/entities/portfolioMgmt/policies';
import GenericPoliciesList, {
    DEFAULT_POLICY_LIST_COL_TRANSLATION_PREFIX,
    getDefaultPoliciesCols,
} from 'views/portfolioMgmt/Policies/GenericPoliciesList';
import InputRadioField from 'views/common/inputs/base/InputRadioField';

interface IPublicProps {
    selectedPolicyId: TEntityUlid;
    initialSelectedPolicyId?: TEntityUlid;
    onPolicySelected: (onPolicySelectedProps: IOnPolicySelectedProps) => void;
    preFilledPolicyAlgorithmTypeFilter?: PolicyAlgorithmType;
    isPolicySelectable?: (policyId: TEntityUlid) => boolean;
}

export interface IOnPolicySelectedProps {
    policy: TEnhancedPolicy;
}

interface ISearchedPoliciesCols extends IColValues {
    selected: boolean;
    name: string;
    baseCurrency: string;
    tags: string;
}

interface ISearchedPolicyExtraData {
    policyData: TEnhancedPolicy;
    selectable: boolean;
    changeSelected: (policy: TEnhancedPolicy) => void;
}

const DEFAULT_POLICY_COLS = getDefaultPoliciesCols({
    desiredCols: [
        { colName: 'name', percentWidth: 52 },
        { colName: 'baseCurrency', percentWidth: 20 },
        { colName: 'tags', percentWidth: 20 },
    ],
});

const COLS: TDataColumns<ISearchedPoliciesCols, ISearchedPolicyExtraData> = {
    selected: {
        label: {
            msg: `${DEFAULT_POLICY_LIST_COL_TRANSLATION_PREFIX}.selected`,
        },
        data: {
            render: ({ cellValue, item }) => (
                <InputRadioField
                    name="select-single-policy"
                    value={item.id}
                    selected={cellValue as boolean}
                    onSelected={() => item.extra.changeSelected(item.extra.policyData)}
                    disabled={!item.extra.selectable}
                />
            ),
        },
        percentWidth: 8,
    },
    name: DEFAULT_POLICY_COLS.name,
    baseCurrency: DEFAULT_POLICY_COLS.baseCurrency,
    tags: DEFAULT_POLICY_COLS.tags,
};

export default function GenericPolicySelect({
    selectedPolicyId,
    initialSelectedPolicyId,
    onPolicySelected,
    isPolicySelectable = () => true,
    preFilledPolicyAlgorithmTypeFilter,
}: IPublicProps) {
    return (
        <GenericPoliciesList<ISearchedPoliciesCols, ISearchedPolicyExtraData>
            overrideEntity={{
                asyncListEntityFetchTrigger: triggerFetchPoliciesForSearch,
            }}
            overrideUiVars={{
                uiVarsNotification: StateChangeNotification.POLICY_SEARCH_UI_VARS,
                uiPageKey: UiPageKey.policySearchList,
            }}
            overrideCols={{
                cols: COLS,
                toPolicyListItem: ({ policy, defaultPolicyCols }) => ({
                    id: policy.id,
                    colValues: {
                        selected: policy.id === selectedPolicyId,
                        name: defaultPolicyCols.name,
                        baseCurrency: defaultPolicyCols.baseCurrency,
                        tags: defaultPolicyCols.tags,
                    },
                    extra: {
                        policyData: policy,
                        selectable: canPolicyBeSelected(policy.id),
                        changeSelected: changeSelectedPolicy,
                    },
                }),
            }}
            onPolicyRowClicked={({ extra }) => changeSelectedPolicy(extra.policyData)}
            isPolicyRowClickable={({ id }) => canPolicyBeSelected(id)}
            preFilledPolicyAlgorithmTypeFilter={preFilledPolicyAlgorithmTypeFilter}
        />
    );

    function changeSelectedPolicy(policy: TEnhancedPolicy) {
        onPolicySelected({ policy });
    }

    function canPolicyBeSelected(policyId: TEntityUlid) {
        return policyId !== initialSelectedPolicyId && isPolicySelectable(policyId);
    }
}
