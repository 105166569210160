import React from 'react';
import clsx from 'clsx';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    IEnhancedApiEntityVersionsComparisonField,
} from '@console/core-api/models/apiEntityVersions.models';
import { APP_COLORS } from 'config/styling/colors';
import { api } from 'api';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import { makeStyles } from 'views/styling';
import Spinner from 'views/common/loading/Spinner';
import { mixins } from 'views/assets/cssInJs/mixins';
import ApiEntityVersionsComparisonFields from './ApiEntityVersionsComparisonFields';

const TRANSLATION_PREFIX = 'common.generic_api_entity.versions.list.comparison';

interface IApiEntityVersionsComparisonProps {
    fromVersion: number;
    toVersion: number;
    entityType: EntityType;
    entityId: string;
    highlightChanges?: boolean;
}

const useStyles = makeStyles((theme) => ({
    ApiEntityVersionsComparison: {
        '& .__changeRow': {
            ...mixins.flexRowCenterLeft(),
            paddingTop: theme.spacing(1),
        },
        '& .__nestedChangeRow': {
            ...mixins.flexRow({ alignMain: 'flex-start', alignCross: 'flex-start' }),
            paddingBottom: theme.spacing(0.5),
        },
        '& .__fieldName': {
            ...mixins.typoBold(),
            ...mixins.flexRowCenterLeft(),
            paddingRight: theme.spacing(1),
        },
        '& .__fieldName svg': {
            marginRight: theme.spacing(1),
        },
        '& .__fieldContent': {
            ...mixins.flexColTopLeft(),
        },
        '& .__fieldChanges': {
            ...mixins.flexColTopLeft(),

            '& > div': {
                ...mixins.flexRow({ alignMain: 'flex-start', alignCross: 'flex-start' }),
            },

            '& .__value': {
                whiteSpace: 'pre-wrap',
            },
        },
        '&.highlightChanges .__newValue': {
            ...mixins.typo({ weight: 'bold', color: APP_COLORS.PRIMARY['500'] }),
        },
        '& .__changeAction': {
            ...mixins.typoBold(),
        },
    },
}));

export default function ApiEntityVersionsComparison({
    fromVersion,
    toVersion,
    entityType,
    entityId,
    highlightChanges,
}: IApiEntityVersionsComparisonProps) {
    const [asyncRootComparisonField] = useAsyncFetchOnMount<IEnhancedApiEntityVersionsComparisonField>({
        fetcher: () =>
            api.apiEntityVersions.fetchApiEntityVersionsComparison({
                fromVersion,
                toVersion,
                id: entityId,
                type: entityType,
            }),
    });

    const classes = useStyles();

    if (asyncRootComparisonField.status === AsyncStatus.Busy) {
        return <Spinner />;
    }

    if (asyncRootComparisonField.status === AsyncStatus.Error) {
        return <Translate msg={`${TRANSLATION_PREFIX}.error`} />;
    }

    if (!asyncRootComparisonField.data) {
        return null;
    }

    return (
        <div className={clsx(classes.ApiEntityVersionsComparison, highlightChanges && 'highlightChanges')}>
            <ApiEntityVersionsComparisonFields
                rootComparisonField={asyncRootComparisonField.data}
                entityType={entityType}
            />
        </div>
    );
}
