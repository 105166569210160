import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import { enhanceApiOptimizationMissingError } from '../../typsy/console-api-client/dist/utils/error/clientSideConsoleApiErrorUtils';
import { ISinglePolicyApiInput } from '../../typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import {
    IPolicyOptimizationEntityData,
    IPolicyOptimization,
    ITriggerAdHocOptimizationResponse,
    TFetchPolicyOptimizationApiInput,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/policySimulation.entity.models';
import { TEntity } from '../../typsy/entities/dist/common/entity.models';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import { get, post } from '../coreApiRequestWrapper';

/**
 * To fetch the "cached" policy optimization.
 */
export function fetchCachedPolicyOptimization({
    policyId,
    underlyingApiRequestConfig,
}: ISinglePolicyApiInput & IUnderlyingRequestApiInput) {
    return get<IPolicyOptimization, TEntity<IPolicyOptimizationEntityData>>({
        url: ConsoleApiPath.POLICY_OPTIMIZATION,
        pathParams: {
            policyId,
        },
        mapResponse: ({ data, status }) => ({
            apiStatus: status,
            result: data,
        }),
        enhanceError: enhanceApiOptimizationMissingError,
        ...underlyingApiRequestConfig,
    });
}

/**
 * First step to fetch the "ad hoc" policy optimization (for when user wants to simulate with unsaved changes).
 * >> create uniq id to optimize
 */
export function triggerAdHocOptimization({
    policyId,
    shouldUseRelaxedProcessing = true,
    underlyingApiRequestConfig,
    ...other
}: TFetchPolicyOptimizationApiInput & IUnderlyingRequestApiInput) {
    const baseCurrencyKey = `$${other?.algorithmSettings?.execution_settings?.base_currency}`;
    return post<ITriggerAdHocOptimizationResponse>({
        url: ConsoleApiPath.ALGORITHMS_IVAR_OPTIMIZE,
        pathParams: {
            policyId,
        },
        body: {
            algorithm_settings: other.algorithmSettings,
            portfolio: {
                [baseCurrencyKey]: other.investmentAmount,
            },
            relaxed: shouldUseRelaxedProcessing,
        },
        ...underlyingApiRequestConfig,
    });
}

/**
 * Second step to fetch the "ad hoc" policy optimization.
 * >> returns result of optimization when ready (status 200), otherwise status 202
 */
export function fetchAdHocOptimization({
    location,
    underlyingApiRequestConfig,
}: ITriggerAdHocOptimizationResponse & IUnderlyingRequestApiInput) {
    return get<IPolicyOptimization, TEntity<IPolicyOptimizationEntityData>>({
        url: location,
        mapResponse: ({ data, status }) => ({
            apiStatus: status,
            result: data,
        }),
        ...underlyingApiRequestConfig,
    });
}
