import React from 'react';
import { IColValues, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    AccessibleByPermissionType,
    IAccessibleByState,
    IChangeAccessProps,
} from 'models/ui/apiEntityAccessibleBy.ui.models';
import { UiPageKey } from 'models/state/ui.models';
import { IFetchUsersApiInput } from '@console/core-api/client/userMgmt/users.api';
import { IFetchUserGroupsApiInput } from '@console/core-api/client/userMgmt/userGroups.api';
import GenericUserGroupsList from 'views/userMgmt/UserGroups/GenericUserGroupsList';
import GenericUsersList, { getDefaultUsersCols } from 'views/userMgmt/shared/GenericUsersList';
import InputCheckboxField from 'views/common/inputs/base/InputCheckboxField';

const COL_LABEL_PREFIX = 'common.generic_api_entity.accessible_by.cols';

interface IAccessibleByCols extends IColValues {
    name: string;
    id: string;
    read: boolean;
    write: boolean;
}

interface IAccessibleByExtraData {
    changeAccess: (props: IChangeAccessProps) => void;
    isReadOnly: boolean;
}

export interface IAccessibleByEntityListProps {
    isReadOnly: boolean;
    readableByState: IAccessibleByState;
    modifiableByState: IAccessibleByState;
    onChangeAccess: (changeProps: IChangeAccessProps) => void;
    asyncListEntityFetchTrigger: (apiInput?: IFetchUsersApiInput | IFetchUserGroupsApiInput) => unknown;
}

const COLS: TDataColumns<IAccessibleByCols, IAccessibleByExtraData> = {
    name: getDefaultUsersCols({
        desiredCols: [{ colName: 'name', percentWidth: 30 }],
    }).name,
    id: {
        label: {
            msg: `${COL_LABEL_PREFIX}.id`,
        },
        percentWidth: 40,
    },
    read: {
        label: {
            msg: `${COL_LABEL_PREFIX}.read`,
        },
        data: {
            render: ({ cellValue, item }) => (
                <InputCheckboxField
                    name={item.id}
                    checked={cellValue as boolean}
                    onChange={({ checked, name }) => item.extra.changeAccess({
                        isEnabled: checked,
                        entityId: name,
                        permissionType: AccessibleByPermissionType.Read,
                    })}
                    disabled={item.extra.isReadOnly}
                />
            ),
        },
        percentWidth: 15,
    },
    write: {
        label: {
            msg: `${COL_LABEL_PREFIX}.write`,
        },
        data: {
            render: ({ cellValue, item }) => (
                <InputCheckboxField
                    name={item.id}
                    checked={cellValue as boolean}
                    onChange={({ checked, name }) => item.extra.changeAccess({
                        isEnabled: checked,
                        entityId: name,
                        permissionType: AccessibleByPermissionType.Modify,
                    })}
                    disabled={item.extra.isReadOnly}
                />
            ),
        },
        percentWidth: 15,
    },
};

export function AccessibleByUsersList({
    isReadOnly,
    onChangeAccess,
    readableByState,
    modifiableByState,
    asyncListEntityFetchTrigger,
}: IAccessibleByEntityListProps) {
    return (
        <GenericUsersList<IAccessibleByCols, IAccessibleByExtraData>
            overrideEntity={{
                asyncListEntityFetchTrigger,
            }}
            overrideUiVars={{
                uiPageKey: UiPageKey.accessibleByUsersList,
                uiVarsNotification: StateChangeNotification.ACCESSIBLE_BY_USERS_UI_VARS,
            }}
            overrideCols={{
                cols: COLS,
                toUserListItem: ({ user, defaultUserCols }) => ({
                    id: user.id,
                    colValues: {
                        id: user.id,
                        name: defaultUserCols.name,
                        read: readableByState[user.id],
                        write: modifiableByState[user.id],
                    },
                    extra: {
                        changeAccess: onChangeAccess,
                        isReadOnly,
                    },
                }),
            }}
            includeRefreshButton={false}
        />
    );
}

export function AccessibleByUserGroupsList({
    isReadOnly,
    onChangeAccess,
    readableByState,
    modifiableByState,
    asyncListEntityFetchTrigger,
}: IAccessibleByEntityListProps) {
    return (
        <GenericUserGroupsList<IAccessibleByCols, IAccessibleByExtraData>
            overrideEntity={{
                asyncListEntityFetchTrigger,
            }}
            overrideUiVars={{
                uiPageKey: UiPageKey.accessibleByUserGroupsList,
                uiVarsNotification: StateChangeNotification.ACCESSIBLE_BY_USERGROUPS_UI_VARS,
            }}
            overrideCols={{
                cols: COLS,
                toUserGroupListItem: ({ userGroup, defaultUserGroupCols }) => ({
                    id: userGroup.id,
                    colValues: {
                        id: userGroup.id,
                        name: defaultUserGroupCols.name,
                        read: readableByState[userGroup.id],
                        write: modifiableByState[userGroup.id],
                    },
                    extra: {
                        changeAccess: onChangeAccess,
                        isReadOnly,
                    },
                }),
            }}
            includeRefreshButton={false}
        />
    );
}
