import assert from '@snipsonian/core/cjs/assert';
import convertArrayToListString from '@snipsonian/core/cjs/array/listString/convertArrayToListString';
import { convertOrderByObjectToString } from '@console/core-api/typsy/console-api-client/dist/utils/fetch/entityFetchUtils';
import { TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { formatDateAsISOString } from '@console/core-api/typsy/date/dist/formatting/formatDate';
import { ConsoleBffPath } from '../../server/consoleBffUrls';
import { post, get, patch } from '../consoleBffRequestWrapper';
import {
    TEnhancedPortfolioTransactionsData,
    TFetchEnhancedPortfolioTransactionsApiInput,
} from '../../models/portfolios/enhancedPortfolioTransactions.models';
import {
    IEnhancedPortfolioPerformance,
    TFetchEnhancedPortfolioPerformanceApiInput,
} from '../../models/portfolios/enhancedPortfolioPerformance.models';
import {
    IEnhancedPortfolioAllocation,
    TFetchEnhancedPortfolioAllocationApiInput,
} from '../../models/portfolios/portfolioAllocation.models';
import {
    IEnhancedPortfolioHoldings,
    TEnhancePortfolioHoldingsApiInput,
} from '../../models/portfolios/portfolioHoldings.models';
import {
    IEnhancedPortfolioOptimization, LATEST_OPTIMIZATION_ID,
    TFetchEnhancedPortfolioOptimizationApiInput,
} from '../../models/portfolios/enhancedPortfolioOptimization.models';
import {
    IPortfolioOptimizationComparison,
    TFetchPortfolioOptimizationComparisonApiInput,
} from '../../models/portfolios/portfolioOptimizationComparison.models';
import {
    TEnhancedPortfolioOptimizations,
    TFetchEnhancedPortfolioOptimizationsApiClientInput,
} from '../../models/portfolios/enhancedPortfolioOptimizations.models';
import {
    IDepositOrWithdrawPortfolioCashApiInput,
    TCreatePortfolioApiInput,
    TEnhancedPortfolio,
    TFetchEnhancedPortfolioDetailsApiInput,
    TPatchPortfolioApiInput,
} from '../../models/portfolios/enhancedPortfolioDetails.models';
import {
    TEnhancedPortfoliosData,
    TFetchEnhancedPortfoliosClientApiInput,
} from '../../models/portfolios/enhancedPortfolios.models';

export function createPortfolio(portfolioToCreate: TCreatePortfolioApiInput) {
    return post<TEnhancedPortfolio>({
        url: ConsoleBffPath.PORTFOLIOS,
        body: portfolioToCreate,
    });
}

export function patchPortfolio({
    portfolioId,
    shouldCreateTransactions,
    ...patchFields
}: TPatchPortfolioApiInput) {
    return patch<TEnhancedPortfolio>({
        url: ConsoleBffPath.PORTFOLIO_DETAILS,
        pathParams: {
            portfolioId,
        },
        queryParams: {
            shouldCreateTransactions,
        },
        body: patchFields,
    });
}

export function depositPortfolioCash(input: IDepositOrWithdrawPortfolioCashApiInput) {
    assert(input.amount, (val) => val > 0, 'The amount to deposit should be larger than 0.');

    return depositOrWithdrawPortfolioCash(input);
}

export function depositOrWithdrawPortfolioCash({
    portfolioId,
    amount,
}: IDepositOrWithdrawPortfolioCashApiInput) {
    return post<TEnhancedPortfolio>({
        url: ConsoleBffPath.PORTFOLIO_CASH,
        pathParams: {
            portfolioId,
        },
        body: {
            amount,
        },
    });
}

export function fetchEnhancedPortfolioDetails({
    portfolioId,
}: TFetchEnhancedPortfolioDetailsApiInput) {
    return get<TEnhancedPortfolio>({
        url: ConsoleBffPath.PORTFOLIO_DETAILS,
        pathParams: {
            portfolioId,
        },
    });
}

export function fetchEnhancedPortfolios({
    ids,
    orderBy,
    ...queryParams
}: TFetchEnhancedPortfoliosClientApiInput = {}) {
    return get<TEnhancedPortfoliosData>({
        url: ConsoleBffPath.PORTFOLIOS,
        queryParams: {
            ...queryParams,
            ids: convertArrayToListString(ids),
            orderBy: convertOrderByObjectToString(orderBy),
        },
    });
}

export function enhancePortfolioHoldings({
    portfolioId,
    ...body
}: TEnhancePortfolioHoldingsApiInput) {
    return post<IEnhancedPortfolioHoldings>({
        url: ConsoleBffPath.ENHANCE_PORTFOLIO_HOLDINGS,
        pathParams: {
            portfolioId,
        },
        body,
    });
}

export function fetchEnhancedPortfolioPerformance({
    portfolioId,
    ...body
}: TFetchEnhancedPortfolioPerformanceApiInput) {
    return post<IEnhancedPortfolioPerformance>({
        url: ConsoleBffPath.PORTFOLIO_PERFORMANCE,
        pathParams: {
            portfolioId,
        },
        body,
    });
}

export function fetchEnhancedPortfolioAllocation({
    portfolioId,
    currency,
    managerType,
}: TFetchEnhancedPortfolioAllocationApiInput) {
    return get<IEnhancedPortfolioAllocation>({
        url: ConsoleBffPath.PORTFOLIO_ALLOCATION,
        pathParams: {
            portfolioId,
        },
        queryParams: {
            currency,
            managerType,
        },
    });
}

export function fetchEnhancedPortfolioOptimizations({
    portfolioId,
    orderBy,
    ...queryParams
}: TFetchEnhancedPortfolioOptimizationsApiClientInput) {
    return get<TEnhancedPortfolioOptimizations>({
        url: ConsoleBffPath.PORTFOLIO_OPTIMIZATIONS,
        pathParams: {
            portfolioId,
        },
        queryParams: {
            ...queryParams,
            startDate: formatDateAsISOString(queryParams.startDate),
            endDate: formatDateAsISOString(queryParams.endDate),
            orderBy: convertOrderByObjectToString(orderBy),
        },
    });
}

export function fetchEnhancedPortfolioOptimization({
    portfolioId,
    optimizationId = LATEST_OPTIMIZATION_ID,
}: Omit<TFetchEnhancedPortfolioOptimizationApiInput, 'optimizationId'> & { optimizationId?: TEntityUlid }) {
    return get<IEnhancedPortfolioOptimization>({
        url: ConsoleBffPath.PORTFOLIO_OPTIMIZATION,
        pathParams: {
            portfolioId,
            optimizationId,
        },
    });
}

export function fetchPortfolioOptimizationComparison({
    portfolioId,
    optimizationId,
    ...body
}: TFetchPortfolioOptimizationComparisonApiInput) {
    return post<IPortfolioOptimizationComparison>({
        url: ConsoleBffPath.PORTFOLIO_OPTIMIZATION_COMPARISON,
        pathParams: {
            portfolioId,
            optimizationId,
        },
        body,
    });
}

export function fetchEnhancedPortfolioTransactions({
    portfolioId,
    ...queryParams
}: TFetchEnhancedPortfolioTransactionsApiInput) {
    return get<TEnhancedPortfolioTransactionsData>({
        url: ConsoleBffPath.PORTFOLIO_TRANSACTIONS,
        pathParams: {
            portfolioId,
        },
        queryParams: {
            ...queryParams,
        },
    });
}
