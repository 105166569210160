import {
    IInstrumentPerformancePast,
    ITimeseriesPastData,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/instrumentPerformancePast.entity.models';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import { IFetchSpecificInstrumentsApiInput } from './instruments.api';
import { getInstrumentsFromCache } from './instrumentsCache';
import { post } from '../coreApiRequestWrapper';
import { SampleFrequencyPerformancePast } from '../../typsy/console-api-client/dist/models/common/performance.models';

export interface IFetchInstrumentPerformancePastApiInput
    extends Pick<IFetchSpecificInstrumentsApiInput, 'universe_type'> {
    isin: string;
    currency: string;
    startDate: string;
    sampleFrequency: SampleFrequencyPerformancePast;
    instrumentAmount: number;
}

export async function fetchInstrumentPerformancePast({
    isin,
    currency,
    universe_type,
    startDate,
    sampleFrequency,
    instrumentAmount,
}: IFetchInstrumentPerformancePastApiInput) {
    const mapOfIsin = [isin];

    const instrumentInfo = await getInstrumentsFromCache({
        instrumentIds: mapOfIsin,
        currency,
        universe_type,
    });

    return post<IInstrumentPerformancePast, ITimeseriesPastData>({
        url: ConsoleApiPath.TIMESERIES_PAST,
        body: {
            method: 'BUY_AND_HOLD',
            method_settings: {
                base_currency: currency,
                start_date: startDate,
                sample_frequency: sampleFrequency,
            },
            portfolio: {
                [isin]: instrumentAmount,
            },
        },
        mapResponse: ({ data }) => ({
            isin,
            instrumentInfo,
            ...data,
        }),
    });
}
