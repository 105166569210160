import { IBaseIdentifiedEntity } from '../../typsy/entities/dist/common/entity.models';
import { IApiEntityListRequest, IApiEntityListResponse } from '../../typsy/console-api-client/dist/models/consoleApiQuery.models';
import {
    IPortfolioGroupEntityData,
    ISinglePortfolioGroupApiInput,
    TPortfolioGroup,
    TPortfolioGroupCreate,
    TPortfolioGroupPatch,
    TPortfolioGroupsData,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/portfolioGroup.entity.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../typsy/console-api-client/dist/config/consoleApi.config';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../typsy/console-api-client/dist/utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../typsy/console-api-client/dist/utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFetchPortfolioGroupsApiInput
    extends IApiEntityListRequest, Partial<IAdvancedPortfolioGroupsFilters> {}

export interface IAdvancedPortfolioGroupsFilters {
    externalId: string;
    portfolioGroupName: string;
}

export function fetchPortfolioGroups({
    orderBy,
    portfolioGroupName,
    externalId,
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    count,
}: IFetchPortfolioGroupsApiInput = {}) {
    return get<TPortfolioGroupsData, IApiEntityListResponse<IPortfolioGroupEntityData>>({
        url: ConsoleApiPath.PORTFOLIO_GROUPS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .contains({ field: 'name', value: portfolioGroupName })
                .exactMatch({ field: 'external_id', value: externalId })
                .orderBy(orderBy)
                .build(),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function fetchPortfolioGroupDetails({
    portfolioGroupId,
}: ISinglePortfolioGroupApiInput) {
    return get<TPortfolioGroup>({
        url: ConsoleApiPath.PORTFOLIO_GROUP_DETAILS,
        pathParams: {
            portfolioGroupId,
        },
    });
}

export function createPortfolioGroup(portfolioGroupToCreate: TPortfolioGroupCreate) {
    return post<TPortfolioGroup>({
        url: ConsoleApiPath.PORTFOLIO_GROUPS,
        body: portfolioGroupToCreate,
    });
}

export function deletePortfolioGroup({ id }: IBaseIdentifiedEntity) {
    return remove<unknown>({
        url: ConsoleApiPath.PORTFOLIO_GROUP_DETAILS,
        pathParams: {
            portfolioGroupId: id,
        },
    });
}

export function patchPortfolioGroup({
    id,
    ...patchFields
}: TPortfolioGroupPatch) {
    return patch<TPortfolioGroup>({
        url: ConsoleApiPath.PORTFOLIO_GROUP_DETAILS,
        pathParams: {
            portfolioGroupId: id,
        },
        body: patchFields,
    });
}
