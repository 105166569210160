import React from 'react';
import { useParams } from 'react-router-dom';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { IPeriodFilter } from '@console/common/models/periodFilter.models';
import {
    PortfolioTransactionMovementStatus,
    PortfolioTransactionMovementType,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioTransaction.entity.models';
import { IEnhancedPortfolioTransaction } from '@console/bff/models/portfolios/enhancedPortfolioTransactions.models';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { IColValues, IDataItem, IRenderPreRowProps, IToDynamicCellClassProps, TDataColumns } from 'models/list.models';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { IAdvancedPortfolioTransactionsFilters } from 'models/ui/portfolioTransactions.ui.models';
import { APP_COLORS } from 'config/styling/colors';
import {
    portfolioTransactionsEntity,
    triggerFetchPortfolioTransactions,
} from 'state/entities/portfolioMgmt/portfolioTransactions';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';
import { DATE_FORMAT, formatDate, TIME_FORMAT } from '@console/common/utils/date/formatDate';
import { isSameYear, parseInputDate, toEndOfDay, toStartOfDay } from '@console/common/utils/date/dateUtils';
import {
    getMovementStatusLabel,
    getMovementTypeLabel,
    getTagVariantForMovementStatus,
} from 'utils/entities/portfolioMgmt/transactionUtils';
import { getInitialPeriodData } from 'utils/date/periodUtils';
import { getEndOfTodayAsDate } from '@console/common/utils/date/getSpecificDate';
import { makeStyles, mixins, UtilityClass } from 'views/styling';
import DataTable from 'views/common/list/DataTable';
import ListPageForApiEntity, { TSetStateOnPageNrChange } from 'views/common/list/ListPageForApiEntity';
import Tag from 'views/common/widget/Tag';
import { IRenderAdvancedFiltersProps } from 'views/common/list/DataSearch';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import InputGroup from 'views/common/inputs/base/InputGroup';
import InputPeriodField from 'views/common/inputs/base/InputPeriodField';
import {
    IInputSearchableSelectItem,
} from 'views/common/inputs/base/InputSearchableSelectField';
import InputSearchableMultiSelectField, {
    IOnChangeSearchableMultiSelectProps,
} from 'views/common/inputs/base/InputSearchableMultiSelectField';
import EntityFiche from 'views/common/genericApiEntity/EntityFiche';

const LABEL_PREFIX = 'portfolio_mgmt.portfolios.detail.transactions';
const COL_LABEL_PREFIX = `${LABEL_PREFIX}.columns`;

const MOVEMENT_TYPE_SELECT_ITEMS: IInputSearchableSelectItem[] = Object.values(PortfolioTransactionMovementType)
    .map((movementType) => ({
        label: getMovementTypeLabel(movementType),
        value: movementType,
    }));

const MOVEMENT_STATUS_SELECT_ITEMS: IInputSearchableSelectItem[] = Object.values(PortfolioTransactionMovementStatus)
    .map((movementStatus) => ({
        label: getMovementStatusLabel(movementStatus),
        value: movementStatus,
    }));

interface ITransactionMovementCols extends IColValues {
    primaryMovementDate: string;
    type: string;
    instrument: {
        isin: string;
        name: string;
    };
    quantity: string;
    amount: string;
    status: PortfolioTransactionMovementStatus;
    time: string;
}

interface ITransactionMovementExtra {
    transactionId: string;
    currency: string;
    /* the movement date, which can be after the primary movement date (e.g. when the movement
       is 'settled' and the primary movement is 'placed') */
    date: string;
    datetime: string;
}

function getCols({
    currency,
}: {
    currency: string;
}): TDataColumns<ITransactionMovementCols, ITransactionMovementExtra> {
    return {
        primaryMovementDate: {
            label: {
                msg: `${COL_LABEL_PREFIX}.primary_movement_date`,
            },
            data: {
                className: UtilityClass.table.cellBold,
                render: ({ cellValue, item, prevItem }) => {
                    if (areMovementsForSameTransaction({
                        dataItem: item,
                        prevDataItem: prevItem,
                    })) {
                        return null;
                    }

                    return cellValue as React.ReactNode;
                },
            },
            percentWidth: 10,
        },
        type: {
            label: {
                msg: `${COL_LABEL_PREFIX}.type`,
            },
            percentWidth: 13,
        },
        instrument: {
            label: {
                msg: `${COL_LABEL_PREFIX}.instrument_or_currency`,
            },
            data: {
                render: ({ item }) => (
                    <InstrumentOrCurrencyCell dataItem={item} />
                ),
            },
            percentWidth: 35,
        },
        quantity: {
            label: {
                msg: `${COL_LABEL_PREFIX}.quantity`,
            },
            align: 'right',
            percentWidth: 8,
        },
        amount: {
            label: {
                msg: `${COL_LABEL_PREFIX}.amount`,
                placeholders: {
                    currency,
                },
            },
            align: 'right',
            percentWidth: 13,
        },
        status: {
            label: {
                msg: `${COL_LABEL_PREFIX}.status`,
            },
            data: {
                render: ({ cellValue, item }) => {
                    const status = cellValue as PortfolioTransactionMovementStatus;
                    const statusLabel = getMovementStatusLabel(status);
                    const differsMovementDateFromPrimaryMovement =
                        item.extra.date !== item.colValues.primaryMovementDate;

                    return (
                        <div className="__statusCell">
                            <Tag
                                label={statusLabel}
                                addMargin={false}
                                variant={getTagVariantForMovementStatus(status)}
                            />
                            {differsMovementDateFromPrimaryMovement && (
                                <div className="__date">
                                    {item.extra.date}
                                </div>
                            )}
                        </div>
                    );
                },
            },
            percentWidth: 13,
        },
        time: {
            label: {
                msg: `${COL_LABEL_PREFIX}.time`,
            },
            percentWidth: 8,
        },
    };
}

const useStyles = makeStyles((theme) => ({
    PortfolioTransactions: {
        '& .__tableBody .__tableCell.__yearRow': {
            ...mixins.typo({ weight: 'bold', size: 21, color: APP_COLORS.PRIMARY['500'] }),
        },

        '& .__statusCell': {
            ...mixins.flexColCenterLeft(),

            '& .__date': {
                ...mixins.typo({ size: 14, color: APP_COLORS.TEXT['400'] }),
                paddingTop: theme.spacing(1),
            },
        },
    },
}));

export default function PortfolioTransactions() {
    const classes = useStyles();
    const { portfolioId } = useParams<IPossiblePathParams>();
    const portfolioDetailsData = portfolioDetailsEntity.select().data;
    const { minDate, maxDate } = getMinMaxDateRange();
    const initialAdvancedTransactionFilters: IAdvancedPortfolioTransactionsFilters = {
        movementTypes: [],
        movementStatuses: [],
        period: getInitialPeriodData({ minDate, maxDate }),
    };

    const setStateOnPageNrChange: TSetStateOnPageNrChange = (pageNr) => ({
        toState: (draftState) => {
            // eslint-disable-next-line no-param-reassign
            draftState.entities.portfolioTransactions.data.pageNr = pageNr;
        },
        notificationsToTrigger: [StateChangeNotification.PORTFOLIO_TRANSACTIONS_DATA],
    });

    const customApiInput = {
        portfolioId,
    };

    return (
        <ListPageForApiEntity
            className={classes.PortfolioTransactions}
            notifications={[
                StateChangeNotification.PORTFOLIO_TRANSACTIONS_DATA,
                StateChangeNotification.PORTFOLIO_TRANSACTIONS_UI_VARS,
            ]}
            asyncListEntity={portfolioTransactionsEntity}
            asyncListEntityFetchTrigger={triggerFetchPortfolioTransactions}
            customApiInput={customApiInput}
            setStateOnPageNrChange={setStateOnPageNrChange}

            uiPageKey={UiPageKey.portfolioTransactionsList}
            notificationToTriggerOnUiVarChanges={StateChangeNotification.PORTFOLIO_TRANSACTIONS_UI_VARS}
            list={{
                renderData: renderPortfolioTransactions,
            }}
            search={{
                advanced: {
                    initialValues: initialAdvancedTransactionFilters,
                    renderFilters: renderAdvancedPortfolioTransactionFilters,
                },
            }}
        />
    );

    function renderAdvancedPortfolioTransactionFilters({
        filterValues,
        onChangeFilterValue,
    }: IRenderAdvancedFiltersProps<IAdvancedPortfolioTransactionsFilters>) {
        return (
            <InputGroup>
                <InputWrapper
                    label="portfolio_mgmt.portfolios.detail.transactions.filter.advanced.period.label"
                >
                    <InputPeriodField<Date>
                        onChange={onChangePeriodFilter}
                        currentPeriod={{
                            type: filterValues.period.type,
                            startDate: filterValues.period.startDate,
                            endDate: filterValues.period.endDate,
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </InputWrapper>
                <InputWrapper
                    label="portfolio_mgmt.portfolios.detail.transactions.filter.advanced.type.label"
                >
                    <InputSearchableMultiSelectField
                        items={MOVEMENT_TYPE_SELECT_ITEMS}
                        itemLabelsAreTranslationKeys
                        values={filterValues.movementTypes}
                        onChange={changeInputSelectMultiHandler('movementTypes')}
                        placeholder="portfolio_mgmt.portfolios.detail.transactions.filter.advanced.type.placeholder"
                        noOptionsLabel="portfolio_mgmt.portfolios.detail.transactions.filter.advanced.type.no_options"
                    />
                </InputWrapper>
                <InputWrapper
                    label="portfolio_mgmt.portfolios.detail.transactions.filter.advanced.status.label"
                >
                    <InputSearchableMultiSelectField
                        items={MOVEMENT_STATUS_SELECT_ITEMS}
                        itemLabelsAreTranslationKeys
                        values={filterValues.movementStatuses}
                        onChange={changeInputSelectMultiHandler('movementStatuses')}
                        placeholder="portfolio_mgmt.portfolios.detail.transactions.filter.advanced.status.placeholder"
                        noOptionsLabel="portfolio_mgmt.portfolios.detail.transactions.filter.advanced.status.no_options"
                    />
                </InputWrapper>
            </InputGroup>
        );

        function changeInputSelectMultiHandler(fieldName: string) {
            return ({ values }: IOnChangeSearchableMultiSelectProps) => onChangeFilterValue({
                [fieldName]: values,
            });
        }

        function onChangePeriodFilter({ type, startDate, endDate }: IPeriodFilter) {
            onChangeFilterValue({ period: {
                type,
                startDate: toStartOfDay(startDate).toDate(),
                endDate: toEndOfDay(endDate).toDate(),
            } });
        }
    }

    function getMinMaxDateRange(): { minDate: Date, maxDate: Date } {
        return {
            minDate: new Date(portfolioDetailsData.creation_datetime),
            maxDate: getEndOfTodayAsDate(),
        };
    }

    function renderPortfolioTransactions({
        data: enhancedTransactions,
        translator,
    }: {
        data: IEnhancedPortfolioTransaction[];
        translator: TTranslator;
    }) {
        const currency = portfolioDetailsEntity.select().data.base_currency;
        const { instrumentMap } = portfolioTransactionsEntity.select().data;

        const movementItems = enhancedTransactions
            .reduce(
                (accumulator, enhancedTransaction) => {
                    accumulator.push(
                        ...enhancedTransaction.movements.map((enhancedMovement, index) => {
                            const { instrument } = enhancedMovement;

                            return {
                                id: `${enhancedTransaction.id}_${index}`,
                                colValues: {
                                    primaryMovementDate: formatDate({
                                        date: enhancedTransaction.datetime,
                                        format: DATE_FORMAT.MONTH_LONG_DAY,
                                    }),
                                    type: translator(getMovementTypeLabel(enhancedMovement.type)),
                                    instrument: instrument
                                        ? {
                                            isin: instrument.isin,
                                            name: instrumentMap[instrument.isin],
                                        }
                                        : null,
                                    quantity: instrument
                                        ? formatFloat(instrument.quantity)
                                        : null,
                                    amount: enhancedMovement.amount
                                        ? formatFloat(enhancedMovement.amount)
                                        : null,
                                    status: enhancedMovement.status,
                                    time: formatDate({
                                        date: enhancedMovement.datetime,
                                        format: TIME_FORMAT.LONG_HR_WITH_MINS,
                                    }),
                                },
                                extra: {
                                    transactionId: enhancedTransaction.id,
                                    currency: enhancedMovement.currency,
                                    date: formatDate({
                                        date: enhancedMovement.datetime,
                                        format: DATE_FORMAT.MONTH_LONG_DAY,
                                    }),
                                    datetime: enhancedMovement.datetime,
                                },
                            };
                        }),
                    );

                    return accumulator;
                },
                [] as IDataItem<ITransactionMovementCols, ITransactionMovementExtra>[],
            );

        return (
            <DataTable
                cols={getCols({ currency })}
                items={movementItems}
                renderPreRow={renderYearRowWhenFirstDateWithinYear}
                toDynamicCellClass={getSpecificCellClass}
            />
        );

        function renderYearRowWhenFirstDateWithinYear({
            dataItem,
            prevDataItem,
            RowComponent,
            CellComponent,
            nrOfCols,
        }: IRenderPreRowProps<ITransactionMovementCols, ITransactionMovementExtra>) {
            const itemDate = parseInputDate(dataItem.extra.datetime);

            if (!prevDataItem || !isSameYear(
                itemDate.toDate(),
                parseInputDate(prevDataItem.extra.datetime).toDate(),
            )) {
                return (
                    <RowComponent>
                        <CellComponent colSpan={nrOfCols} className="__yearRow">
                            {itemDate.get('year')}
                        </CellComponent>
                    </RowComponent>
                );
            }

            return null;
        }

        function getSpecificCellClass({
            colKey,
            dataItem,
            prevDataItem,
        }: IToDynamicCellClassProps<ITransactionMovementCols, ITransactionMovementExtra>) {
            if ((colKey === 'date') && areMovementsForSameTransaction({ dataItem, prevDataItem })) {
                return [UtilityClass.table.cellNoBorder];
            }
            return null;
        }
    }
}

function InstrumentOrCurrencyCell({
    dataItem,
}: {
    dataItem: IDataItem<ITransactionMovementCols, ITransactionMovementExtra>;
}) {
    if (!dataItem.colValues.instrument) {
        return <span>{dataItem.extra.currency}</span>;
    }

    const { name, isin } = dataItem.colValues.instrument;

    return (
        <EntityFiche
            id={isin}
            name={name}
            variant="instrument"
        />
    );
}

function areMovementsForSameTransaction({
    dataItem,
    prevDataItem,
}: {
    dataItem: IDataItem<ITransactionMovementCols, ITransactionMovementExtra>;
    prevDataItem: IDataItem<ITransactionMovementCols, ITransactionMovementExtra>;
}) {
    return prevDataItem && (dataItem.extra.transactionId === prevDataItem.extra.transactionId);
}
