import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import { ISinglePortfolioApiInput } from '../../typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';
import { IPortfolioAllocation } from '../../typsy/console-api-client/dist/models/portfolioMgmt/portfolioAllocation.entity.models';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import { get } from '../coreApiRequestWrapper';

export function fetchPortfolioAllocation({
    portfolioId,
    underlyingApiRequestConfig,
}: ISinglePortfolioApiInput & IUnderlyingRequestApiInput) {
    return get<IPortfolioAllocation, Omit<IPortfolioAllocation, 'portfolioId'>>({
        url: ConsoleApiPath.PORTFOLIO_LOOK_THROUGH,
        pathParams: {
            portfolioId,
        },
        mapResponse: ({ data }) => ({
            portfolioId,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}
