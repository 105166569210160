import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IUserGroupEntityData } from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/userGroup.entity.models';
import { triggerCreateUserGroup, userGroupDetailsEntity } from 'state/entities/userMgmt/userGroupDetails';
import UserGroupPropertiesForm, {
    IUserGroupFormValues,
    mapUserGroupFormValuesToApiEntity,
} from 'views/userMgmt/UserGroups/UserGroupDetail/UserGroupPropertiesForm';
import AddPage from 'views/common/add/AddPage';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';

export default function UserGroupAdd() {
    const initialValues: IUserGroupFormValues = {
        name: '',
        externalId: '',
    };

    return (
        <AddPage
            titleLabel="user_mgmt.user_groups.add.title"
            entity={{
                notifications: [StateChangeNotification.USERGROUP_DETAILS_DATA],
                asyncEntitySelector: userGroupDetailsEntity.select,
            }}
            cancel={{
                onCancel: redirectToList,
            }}
        >
            <UserGroupPropertiesForm
                mode="add"
                initialValues={initialValues}
                submit={{
                    onSubmit: onSubmitAdd,
                }}
            />
        </AddPage>
    );

    function onSubmitAdd({ values }: IOnSubmitProps<IUserGroupFormValues>) {
        return triggerCreateUserGroup(
            enrichApiEntityDataForCreate<IUserGroupEntityData>({
                permissions: [],
                user_ids: [],
                ...mapUserGroupFormValuesToApiEntity(values),
            }),
        );
    }

    function redirectToList() {
        redirectTo({
            routeKey: ROUTE_KEY.R_USER_GROUPS_LIST,
        });
    }
}
