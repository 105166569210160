import { IOptimizationReason } from '../../../typsy/console-api-client/dist/models/portfolioMgmt/optimization.entity.models';

export enum OptimizationReasonType {
    MIN_OPTIMALITY_IMPROVEMENT = 'min_optimality_improvement',
    MAX_CONSTRAINT_VIOLATIONS = 'max_constraint_violations',
    CASH_HOLDINGS_VIOLATION = 'cash_holdings_violation',
    INSTRUMENT_NOT_IN_UNIVERSE = 'instrument_not_in_universe',
}

export function getOptimizationReasonType(reason: IOptimizationReason): OptimizationReasonType {
    switch (reason.portfolio_update_constraint_id) {
        case 'min_portfolio_update_optimality_improvement_fraction':
            return OptimizationReasonType.MIN_OPTIMALITY_IMPROVEMENT;
        case 'max_portfolio_constraint_violation_pctpoints':
            return OptimizationReasonType.MAX_CONSTRAINT_VIOLATIONS;
        case 'holdings.cash_fraction':
            return OptimizationReasonType.CASH_HOLDINGS_VIOLATION;
        default: {
            if (reason.portfolio_update_constraint_id.startsWith('instrument_universe')) {
                return OptimizationReasonType.INSTRUMENT_NOT_IN_UNIVERSE;
            }
        }
    }

    return null;
}

export function extractInstrumentIsinFromOptimizationReason(reason: IOptimizationReason): string {
    if (getOptimizationReasonType(reason) === OptimizationReasonType.INSTRUMENT_NOT_IN_UNIVERSE) {
        /* has the format "instrument_universe.<instrument_isin>", so the second part contains the ISIN */
        return reason.portfolio_update_constraint_id.split('.')[1];
    }

    return null;
}
