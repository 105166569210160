import isSet from '@snipsonian/core/cjs/is/isSet';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { IFetchPortfoliosApiInput } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';
import {
    TEnhancedPortfoliosData,
    TFetchEnhancedPortfoliosClientApiInput,
} from '@console/bff/models/portfolios/enhancedPortfolios.models';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { getCurrentRouteLocation } from 'state/ui/selectors';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';

export const userPortfoliosEntity = getEntitiesManager().registerEntity<TEnhancedPortfoliosData>({
    asyncEntityKey: AsyncEntityKeys.userPortfolios,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.USER_PORTFOLIOS_DATA],
    includeUpdaters: true,
});

export const triggerResetUserPortfoliosFetch = () => userPortfoliosEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchUserPortfolios(apiInput: IFetchPortfoliosApiInput = {}) {
    return userPortfoliosEntity.async.fetch<TFetchEnhancedPortfoliosClientApiInput>({
        api: api.bff.portfolios.fetchEnhancedPortfolios,
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars({
            pageKey: UiPageKey.userPortfoliosList,
            state,
            apiInput: addUserFromRouteIfNotProvidedYet(state, apiInput),
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: userPortfoliosEntity.select().data,
        }),
        refreshMode: 'always',
        resetDataOnTriggerMode: () => {
            if (apiInput && isSet(apiInput.pageNr)) {
                return false;
            }
            return true;
        },
    });
}

function addUserFromRouteIfNotProvidedYet(
    state: IState,
    apiInput: IFetchPortfoliosApiInput = {},
): IFetchPortfoliosApiInput {
    const { params } = getCurrentRouteLocation(state);

    if (params && params.userId) {
        return {
            ownerId: params.userId as string,
            ...apiInput,
        };
    }

    return apiInput;
}
