import { IPeriodFilter } from '@console/common/models/periodFilter.models';
import { TEntityUlid, TEntity } from '../../../../entities/dist/common/entity.models';
import {
    IApiEntityListRequest, IApiEntityListResponseWithPageNr,
} from '../consoleApiQuery.models';
import { IPolicyEntityData } from './policy.entity.models';
import {
    IBaseOptimization, IOptimizationPortfolioUpdate,
} from './optimization.entity.models';

export interface IFetchPortfolioOptimizationsApiParams {
    portfolioId: TEntityUlid;
}

export interface IFetchPortfolioOptimizationsApiQuery
    extends IApiEntityListRequest, IFetchPortfolioOptimizationsFiltersApiQuery {}

export type TFetchPortfolioOptimizationsApiInput =
    IFetchPortfolioOptimizationsApiParams & IFetchPortfolioOptimizationsApiQuery;

export interface IAdvancedPortfolioOptimizationsFilters {
    period: IPeriodFilter;
    ownerChoice: OptimizationOwnerChoiceFilter; /* only 1 at a time (otherwise we can't filter on "No choice") */
    type: OptimizationTypeFilter; /* only 1 at a time */
}

export interface IFetchPortfolioOptimizationsFiltersApiQuery<DateType = string>
    extends Partial<Pick<IPeriodFilter<DateType>, 'startDate' | 'endDate'>>,
    Partial<Pick<IAdvancedPortfolioOptimizationsFilters, 'ownerChoice' | 'type'>> {}

export type TPortfolioOptimizationsData = IApiEntityListResponseWithPageNr<IPortfolioOptimizationEntityData>;

export interface IPortfolioOptimization {
    apiStatus: number;
    optimization: TEntity<IPortfolioOptimizationEntityData> | IPortfolioOptimizationResponse202;
}

export interface IPortfolioOptimizationResponse202 {
    details: string;
    message: string;
}

/* in case of 200 */
export interface IPortfolioOptimizationEntityData extends IBaseOptimization {
    policy_snapshot: TEntity<IPolicyEntityData>;
    portfolio_id: TEntityUlid;
    portfolio_update: IOptimizationPortfolioUpdate;
    portfolio_version: number;
    owner_choice?: OptimizationOwnerChoice;
}

export enum OptimizationOwnerChoice {
    ACCEPT = 'ACCEPT',
    IGNORE = 'IGNORE',
    REJECT = 'REJECT',
    REOPTIMIZE = 'REOPTIMIZE',
}

export enum OptimizationOwnerChoiceFilter {
    ACCEPT = 'ACCEPT',
    IGNORE = 'IGNORE',
    REJECT = 'REJECT',
    REOPTIMIZE = 'REOPTIMIZE',
    NO_CHOICE = 'NO_CHOICE',
}

export enum OptimizationTypeFilter {
    RECOMMENDED = 'RECOMMENDED',
    NOT_RECOMMENDED = 'NOT_RECOMMENDED',
}
