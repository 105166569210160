import React from 'react';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IPortfolioPerformanceFuture,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioPerformance.entity.models';
import { IPortfolioFuturePerformanceMarkedDate } from 'models/ui/portfolioPerformance.ui.models';
import { IChartDimensions } from 'models/chart.models';
import { CHART, STANDARD_CHART_DIMENTIONS } from 'config/styling/chart';
import { getPortfolioPerformancePageVars } from 'state/ui/uiPages.selectors';
import { formatDate } from '@console/common/utils/date/formatDate';
import GenericPerformanceGraph, { IGenericPerformanceGraphProps } from 'views/common/charts/GenericPerformanceGraph';
import { IMarkedDateData, IMarkedDateValueItem } from 'views/common/charts/MarkedDateBox';
import {
    getPortfolioFuturePerformanceChartConfig,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/PortfolioPerformance/portfolioPerformanceGraphsConfig';

interface IPublicProps
    // eslint-disable-next-line max-len
    extends Pick<IGenericPerformanceGraphProps<IPortfolioFuturePerformanceMarkedDate>, 'baseCurrency' | 'onChangedMarkedDate'> {
    futurePerformanceData: IPortfolioPerformanceFuture;
}

export default function PortfolioPerformanceGraphFuture({
    futurePerformanceData,
    baseCurrency,
    onChangedMarkedDate,
}: IPublicProps) {
    return (
        <GenericPerformanceGraph
            baseCurrency={baseCurrency}
            chartDimensions={STANDARD_CHART_DIMENTIONS}
            getChartConfig={(dimensions: IChartDimensions) => getPortfolioFuturePerformanceChartConfig({
                chartDimensions: dimensions,
                futurePerformanceData,
            })}
            markedDateBoxNotifications={[StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE_FUTURE_MARKED_DATE]}
            markedDateDataSelector={markedDateDataSelector}
            onChangedMarkedDate={onChangedMarkedDate}
        />
    );

    function markedDateDataSelector(state: IState): IMarkedDateData {
        const { markedDate } = getPortfolioPerformancePageVars(state).future;

        if (!markedDate) {
            return null;
        }

        const valueItems: IMarkedDateValueItem[] = [{
            label: 'portfolio_mgmt.portfolios.detail.performance.future.marked_date.optimistic',
            labelColor: CHART.FUTURE.OPTIMISTIC_COLOR,
            amount: markedDate.values.optimistic,
        }, {
            label: 'portfolio_mgmt.portfolios.detail.performance.future.marked_date.realistic',
            labelColor: CHART.FUTURE.REALISTIC_COLOR,
            amount: markedDate.values.realistic,
            // percentage: convertPercentageRelative1ToRelative100(markedDate.values.twr),
        }, {
            label: 'portfolio_mgmt.portfolios.detail.performance.future.marked_date.pessimistic',
            labelColor: CHART.FUTURE.PESSIMISTIC_COLOR,
            amount: markedDate.values.pessimistic,
        }];

        return {
            formattedDate: formatDate({ date: markedDate.date }),
            valueItems,
        };
    }
}
