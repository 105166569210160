import { IBaseIdentifiedEntity } from '../../typsy/entities/dist/common/entity.models';
import { IApiEntityListRequest, IApiEntityListResponse } from '../../typsy/console-api-client/dist/models/consoleApiQuery.models';
import {
    TGoalsData,
    IGoalEntityData,
    ISingleGoalApiInput,
    TGoal,
    TTGoalPatch,
    TTGoalCreate,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/goal.entity.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../typsy/console-api-client/dist/config/consoleApi.config';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import { fetchAllPagesOfApiEntity } from '../../typsy/console-api-client/dist/utils/fetch/fetchAllPagesOfApiEntity';
import fetchApiEntityUrlParamBuilder from '../../typsy/console-api-client/dist/utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../typsy/console-api-client/dist/utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchAllGoals() {
    return fetchAllPagesOfApiEntity<IFetchGoalsApiInput, IGoalEntityData>({
        apiInput: {
            count: false,
        },
        api: fetchGoals,
    });
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFetchGoalsApiInput extends IApiEntityListRequest {}

export function fetchGoals({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    orderBy,
    count,
}: IFetchGoalsApiInput = {}) {
    return get<TGoalsData, IApiEntityListResponse<IGoalEntityData>>({
        url: ConsoleApiPath.GOALS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .orderBy(orderBy)
                .build(),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function fetchGoalDetails({ goalId }: ISingleGoalApiInput) {
    return get<TGoal>({
        url: ConsoleApiPath.GOAL_DETAILS,
        pathParams: {
            goalId,
        },
    });
}

export function patchGoal({
    id,
    ...patchFields
}: TTGoalPatch) {
    return patch<TGoal>({
        url: ConsoleApiPath.GOAL_DETAILS,
        pathParams: {
            goalId: id,
        },
        body: patchFields,
    });
}

export function createGoal(goalToCreate: TTGoalCreate) {
    return post<TGoal>({
        url: ConsoleApiPath.GOALS,
        body: goalToCreate,
    });
}

export function deleteGoal({ id }: IBaseIdentifiedEntity) {
    return remove<unknown>({
        url: ConsoleApiPath.GOAL_DETAILS,
        pathParams: {
            goalId: id,
        },
    });
}
