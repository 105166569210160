import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { addOrRemoveItemFromArray } from '@console/common/utils/array/addOrRemoveItemFromArray';
import { IColValues, TDataColumns } from 'models/list.models';
import { TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { UiPageKey } from 'models/state/ui.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IFetchUsersApiInput } from '@console/core-api/client/userMgmt/users.api';
import { triggerPatchUserGroupDetails, userGroupDetailsEntity } from 'state/entities/userMgmt/userGroupDetails';
import { triggerFetchUserGroupMembers } from 'state/entities/userMgmt/users';
import { getUserGroupMembersPageVars } from 'state/ui/uiPages.selectors';
import { updateUserGroupMembersPageVars } from 'state/ui/uiPages.actions';
import { isEveryoneGroup, isUserGroupReadOnly } from 'utils/entities/userMgmt/userGroupUtils';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import { makeStyles, mixins } from 'views/styling';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IFormValues,
    IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import InputToggleField from 'views/common/inputs/base/InputToggleField';
import GenericUsersList, { getDefaultUsersCols } from 'views/userMgmt/shared/GenericUsersList';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import { IOnChangeCheckboxProps } from 'views/common/inputs/base/InputCheckboxField';
import { observe, IObserveProps } from 'views/observe';
import { userGroupMembersSchema } from './userGroupDetailsSchema';

const LABEL_PREFIX = 'user_mgmt.user_groups.detail.members';
const COL_LABEL_PREFIX = `${LABEL_PREFIX}.columns`;

interface IUserGroupMembersFormValues extends IFormValues {
    user_ids: TEntityUlid[];
}

interface IMembersState {
    [userId: string]: boolean;
}

interface IMembersCols extends IColValues {
    enabled: boolean;
    name: string;
    email: string;
}

interface IMemberExtraData {
    changeEnabled: (props: IChangeEnabledProps) => void;
    isReadOnly: boolean;
}

interface IChangeEnabledProps {
    isEnabled: boolean;
    userId: TEntityUlid;
}

const COLS: TDataColumns<IMembersCols, IMemberExtraData> = {
    enabled: {
        label: {
            msg: `${COL_LABEL_PREFIX}.enabled`,
        },
        data: {
            render: ({ cellValue, item }) => (
                <InputToggleField
                    name={item.id}
                    checked={cellValue as boolean}
                    onChange={({ checked, name }) => item.extra.changeEnabled({
                        isEnabled: checked,
                        userId: name,
                    })}
                    disabled={item.extra.isReadOnly}
                />
            ),
        },
        percentWidth: 10,
    },
    name: getDefaultUsersCols({
        desiredCols: [{ colName: 'name', percentWidth: 40 }],
    }).name,
    email: getDefaultUsersCols({
        desiredCols: [{ colName: 'email', percentWidth: 50 }],
    }).email,
};

const useStyles = makeStyles((theme) => ({
    UserGroupMembers: {
        ...mixins.flexColTopLeft(),

        '& .__listPage': {
            ...mixins.widthMax(),
        },
        '& .DataSearch': {
            padding: theme.spacing(2, 0, 3, 0),
        },

        '& .showOnlyActiveMembersFilter': {
            ...mixins.flexRow({ alignMain: 'right', alignCross: 'center' }),
            padding: theme.spacing(1, 4, 1, 0),

            '& .showOnlyActiveMembersToggle': {
                margin: theme.spacing(0, 2, 0, 0),
            },
        },
    },
}));

function UserGroupMembers({ dispatch, state }: IObserveProps) {
    const classes = useStyles();
    const userGroupData = userGroupDetailsEntity.select().data;
    const userGroupMemberIds = userGroupData.user_ids || [];
    const isReadOnly = isUserGroupReadOnly(userGroupData);

    useAsyncFetchOnMount({
        fetcher: () => triggerFetchMembersByUsingFilters({
            forceRefresh: false,
        }),
    });

    if (isEveryoneGroup(userGroupData)) {
        return (
            <div className={classes.UserGroupMembers}>
                <Translate msg={`${LABEL_PREFIX}.all_users_belong_to_group`} />
            </div>
        );
    }

    const initialFormValues: IUserGroupMembersFormValues = {
        user_ids: userGroupMemberIds,
    };

    return (
        <div className={classes.UserGroupMembers}>
            <ExtendedInputForm<IUserGroupMembersFormValues>
                name={ExtendedInputFormName.userGroupMembers}
                className="membersForm"
                labelPrefix={LABEL_PREFIX}
                initialValues={initialFormValues}
                renderFormFields={renderUsersList}
                submit={{
                    onSubmit: onSubmitPatch,
                }}
                schema={userGroupMembersSchema}
                reset={{}}
                readOnly={isReadOnly}
                placeFormActionsInFixedFooter
            />
        </div>
    );

    function triggerFetchMembersByUsingFilters({
        apiInput = {},
        showOnlyActiveMembers = getUserGroupMembersPageVars(state).showOnlyActiveMembers,
        forceRefresh = true,
        currentUserGroupMemberIds = userGroupMemberIds,
    }: {
        apiInput?: IFetchUsersApiInput;
        showOnlyActiveMembers?: boolean;
        forceRefresh?: boolean;
        currentUserGroupMemberIds?: TEntityUlid[];
    }) {
        return triggerFetchUserGroupMembers({
            ...apiInput,
            userIds: showOnlyActiveMembers ? currentUserGroupMemberIds : null,
            forceRefresh,
        });
    }

    function renderUsersList({
        fields,
        setFieldValue,
    }: IExtendedInputFormContext<IUserGroupMembersFormValues>) {
        const currentUserGroupMemberIds = (fields.user_ids.value || []) as TEntityUlid[];

        const membersState = currentUserGroupMemberIds.reduce(
            (accumulator, userId) => {
                /* as we (normally) don't know all users yet, we only populate the state with the enabled ones */
                accumulator[userId] = true;

                return accumulator;
            },
            {} as IMembersState,
        );

        const { showOnlyActiveMembers } = getUserGroupMembersPageVars(state);

        return (
            <>
                <UserGroupMembersFilter
                    isShowOnlyActiveMembersEnabled={showOnlyActiveMembers}
                    onChangeShowOnlyActiveMembers={onChangeShowOnlyActiveMembers}
                />
                <UserGroupMembersUsersList
                    membersState={membersState}
                    isReadOnly={isReadOnly}
                    onChangeMember={onChangeMember}
                    asyncListEntityFetchTrigger={(apiInput) => triggerFetchMembersByUsingFilters({
                        apiInput,
                        currentUserGroupMemberIds,
                    })}
                />
            </>
        );

        function onChangeShowOnlyActiveMembers({ checked }: IOnChangeCheckboxProps) {
            dispatch(updateUserGroupMembersPageVars({
                showOnlyActiveMembers: checked,
            }));

            triggerFetchMembersByUsingFilters({
                showOnlyActiveMembers: checked,
                currentUserGroupMemberIds,
            });
        }

        function onChangeMember(
            { isEnabled, userId }: IChangeEnabledProps,
        ) {
            /* update the form field, so that the form is validated on every change
             + knows that there has been a change or not) */

            const newUserIds = addOrRemoveItemFromArray<TEntityUlid>(
                isEnabled,
                currentUserGroupMemberIds,
                userId,
                { resultInNewArray: true },
            );

            setFieldValue({
                fieldName: fields.user_ids.fieldName,
                value: newUserIds,
            });
        }
    }

    function onSubmitPatch({ values }: IOnSubmitProps<IUserGroupMembersFormValues>) {
        return triggerPatchUserGroupDetails({
            id: userGroupData.id,
            user_ids: values.user_ids,
        });
    }
}

export default observe(
    [StateChangeNotification.UI_PAGE_USERGROUP_MEMBERS_FILTER],
    UserGroupMembers,
);

function UserGroupMembersFilter({
    isShowOnlyActiveMembersEnabled,
    onChangeShowOnlyActiveMembers,
}: {
    isShowOnlyActiveMembersEnabled: boolean;
    onChangeShowOnlyActiveMembers: (props: IOnChangeCheckboxProps) => void;
}) {
    return (
        <div className="showOnlyActiveMembersFilter">
            <InputToggleField
                className="showOnlyActiveMembersToggle"
                name="show_only_active_members_filter"
                checked={isShowOnlyActiveMembersEnabled}
                onChange={onChangeShowOnlyActiveMembers}
            />
            <Translate msg={`${LABEL_PREFIX}.filter.show_only_active_members`} />
        </div>
    );
}

function UserGroupMembersUsersList({
    membersState,
    isReadOnly,
    onChangeMember,
    asyncListEntityFetchTrigger,
}: {
    membersState: IMembersState;
    isReadOnly: boolean;
    onChangeMember: (changeProps: IChangeEnabledProps) => void;
    asyncListEntityFetchTrigger: (apiInput?: IFetchUsersApiInput) => unknown;
}) {
    return (
        <GenericUsersList<IMembersCols, IMemberExtraData>
            className="__listPage"
            overrideEntity={{
                asyncListEntityFetchTrigger,
            }}
            overrideUiVars={{
                uiVarsNotification: StateChangeNotification.USERGROUP_MEMBERS_UI_VARS,
                uiPageKey: UiPageKey.userGroupMembersList,
            }}
            overrideCols={{
                cols: COLS,
                toUserListItem: ({ user, defaultUserCols }) => ({
                    id: user.id,
                    colValues: {
                        enabled: membersState[user.id],
                        name: defaultUserCols.name,
                        email: defaultUserCols.email,
                    },
                    extra: {
                        changeEnabled: onChangeMember,
                        isReadOnly,
                    },
                }),
            }}
        />
    );
}
