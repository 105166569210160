import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    IPortfolioOptimizationComparison,
    TFetchPortfolioOptimizationComparisonApiInput,
} from '@console/bff/models/portfolios/portfolioOptimizationComparison.models';
import { LATEST_OPTIMIZATION_ID } from '@console/bff/models/portfolios/enhancedPortfolioOptimization.models';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { portfolioOptimizationEntity } from 'state/entities/portfolioMgmt/portfolioOptimization';
import { getStore } from 'state';
import { getCurrentRouteParam } from 'state/ui/selectors';
import { portfolioDetailsEntity, triggerFetchPortfolioDetails } from 'state/entities/portfolioMgmt/portfolioDetails';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { api } from 'api';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';

// eslint-disable-next-line max-len
export const portfolioOptimizationComparisonEntity = getEntitiesManager().registerEntity<IPortfolioOptimizationComparison>({
    asyncEntityKey: AsyncEntityKeys.portfolioOptimizationComparison,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_OPTIMIZATION_COMPARISON],
});

export function triggerFetchPortfolioOptimizationComparison() {
    const portfolioId = getCurrentRouteParam(getStore().getState(), 'portfolioId');
    const optimizationId = getCurrentRouteParam(getStore().getState(), 'optimizationId');

    if (!validateEntityIdBeforeFetch({
        entityId: portfolioId,
        entityType: EntityType.portfolio,
    })) {
        return null;
    }

    if (optimizationId !== LATEST_OPTIMIZATION_ID && !validateEntityIdBeforeFetch({
        entityId: optimizationId,
        entityType: EntityType.optimization,
    })) {
        return null;
    }

    if (portfolioDetailsEntity.select().data?.id !== portfolioId) {
        /* 'triggerFetchPortfolioDetails' will also trigger 'triggerFetchPortfolioOptimizationLatest' on success,
         * but that input will be too late I guess */
        return triggerFetchPortfolioDetails()
            .then(() => {
                doFetchPortfolioOptimizationComparison({ portfolioId, optimizationId });
            });
    }

    /* the portfolio detail (and possibly the portfolio optimization) is available */
    return doFetchPortfolioOptimizationComparison({ portfolioId, optimizationId });
}

function doFetchPortfolioOptimizationComparison({
    portfolioId,
    optimizationId,
}: Pick<TFetchPortfolioOptimizationComparisonApiInput, 'portfolioId' | 'optimizationId'>) {
    if (!portfolioDetailsEntity.select().data) {
        throw new Error('The portfolio detail is not loaded!');
    }

    const {
        base_currency: currency,
    } = portfolioDetailsEntity.select().data;

    return portfolioOptimizationComparisonEntity.async.fetch<TFetchPortfolioOptimizationComparisonApiInput>({
        api: api.bff.portfolios.fetchPortfolioOptimizationComparison,
        apiInputSelector: () => ({
            portfolioId,
            optimizationId,
            currency,
            optimization: isKnownPortfolioOptimizationOfTheRequestedIds({ portfolioId, optimizationId })
                ? portfolioOptimizationEntity.select().data
                : undefined,
        }),
        refreshMode: () =>
            portfolioOptimizationComparisonEntity.select().data.portfolioId !== portfolioId
            || portfolioOptimizationComparisonEntity.select().data.optimizationId !== optimizationId
            /* in case of 'latest' we will always fetch, otherwise it can be that you keep seeing the previous one */
            || optimizationId === LATEST_OPTIMIZATION_ID,
        resetDataOnTriggerMode: 'always',
    });
}

function isKnownPortfolioOptimizationOfTheRequestedIds({
    portfolioId,
    optimizationId,
}: Pick<TFetchPortfolioOptimizationComparisonApiInput, 'portfolioId' | 'optimizationId'>) {
    if (portfolioOptimizationEntity.select().data) {
        return portfolioOptimizationEntity.select().data.portfolioId === portfolioId
            && portfolioOptimizationEntity.select().data.optimizationId === optimizationId;
    }

    return false;
}
