import React, { useState } from 'react';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import {
    IEnhancedPortfolioHoldings,
} from '@console/bff/models/portfolios/portfolioHoldings.models';
import { TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    calculateEnhancedPortfolioHoldingsPercentages,
} from '@console/bff/utils/portfolios/enhancedPortfolioHoldingsUtils';
import { CASH_CELL_NAME, UPLOAD_HOLDINGS_EXCEL_HEADER_NAMES } from 'config/portfolioMgmt/portfolioHoldings.config';
import { readPortfolioHoldingsFromFile } from 'state/entities/portfolioMgmt/portfolioHoldings';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import TextButton from 'views/common/buttons/TextButton';
import { AddIcon, CopyIcon } from 'views/common/icons';
import Modal from 'views/common/layout/Modal';
import IconButtonEdit from 'views/common/buttons/IconButtonEdit';
import InfoIconTooltip from 'views/common/widget/InfoIconTooltip';
import SpinnerOverlay from 'views/common/loading/SpinnerOverlay';
import PortfolioHoldingsList from './PortfolioHoldingsList';
import AddPortfolioHoldings from './AddPortfolioHoldings';
import { IPortfolioHoldingsFormValues } from '../portfolioFormContents/types';

const FILE_IMPORT_INPUT_ID = 'import_holdings_input';
const LABEL_PREFIX = 'portfolio_mgmt.portfolios.detail.holdings';
const FILE_IMPORT_INPUT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

interface IPortfolioHoldingsWrapperProps
    extends Pick<IExtendedInputFormContext<IPortfolioHoldingsFormValues>, 'fields' | 'setFieldValue'> {
    currentHoldings: IEnhancedPortfolioHoldings;
    initialHoldings: IEnhancedPortfolioHoldings;
    translator: TTranslator;
    setIsEditActive: (value: React.SetStateAction<boolean>) => void;
    isEditActive: boolean;
    isReadOnly: boolean;
    portfolioId: TEntityUlid;
}

export default function PortfolioHoldingsWrapper({
    currentHoldings,
    initialHoldings,
    setFieldValue,
    fields,
    isEditActive,
    setIsEditActive,
    isReadOnly,
    translator,
    portfolioId,
}: IPortfolioHoldingsWrapperProps) {
    const [isAddActive, setIsAddActive] = useState<boolean>(false);
    const [isImportingFile, setIsImportingFile] = useState<boolean>(false);

    return (
        <>
            <SpinnerOverlay open={isImportingFile} position="fixed" />

            {renderAddPortfolioHoldings()}

            {renderHoldingsButtons()}

            <PortfolioHoldingsList
                initialHoldings={initialHoldings}
                currentHoldings={currentHoldings}
                setFieldValue={setFieldValue}
                isEditActive={isEditActive}
                fields={fields}
                translator={translator}
            />
        </>
    );

    function renderHoldingsButtons() {
        return (
            <div className="ButtonRow">
                {isEditActive && (
                    <>
                        <div className="SingleButton">
                            <TextButton
                                id="add-instruments_button"
                                label={{
                                    msg: `${LABEL_PREFIX}.buttons.add_instruments`,
                                }}
                                variant="bare"
                                size="M"
                                onClick={openAddModal}
                                icon={<AddIcon />}
                                svgSize={25}
                                iconPlacement="end"
                            />
                        </div>
                        <div className="SingleButton">
                            <TextButton
                                id="import_holdings_button"
                                label={{
                                    msg: `${LABEL_PREFIX}.buttons.import_holdings`,
                                }}
                                variant="bare"
                                size="M"
                                svgSize={23}
                                iconPlacement="end"
                                icon={<CopyIcon />}
                                onClick={triggerInputImportHoldings}
                            />
                            <InfoIconTooltip
                                name="import_holdings"
                                simpleContent={{
                                    title: `${LABEL_PREFIX}.buttons.import_holdings`,
                                    info: {
                                        msg: `${LABEL_PREFIX}.info.import_holdings`,
                                        placeholders: {
                                            requiredColumns: UPLOAD_HOLDINGS_EXCEL_HEADER_NAMES.join(', '),
                                            cashCell: CASH_CELL_NAME,
                                        },
                                        raw: true,
                                    },
                                }}
                            />
                        </div>
                        <input
                            type="file"
                            accept={FILE_IMPORT_INPUT_TYPE}
                            hidden
                            id={FILE_IMPORT_INPUT_ID}
                            onChange={processHoldingsFile}
                        />
                    </>
                )}
                <div className="SingleButton">
                    <IconButtonEdit
                        id="edit-holdings-button"
                        disabled={isReadOnly}
                        tooltip={(isReadOnly) ? 'common.action.edit_disabled' :
                            (!isEditActive) ? 'common.action.edit' : 'common.action.cancel'}
                        onClick={changeViewMode}
                        isCancel={isEditActive}
                    />
                </div>
            </div>
        );
    }

    async function processHoldingsFile(reactEvent: React.ChangeEvent<HTMLInputElement>) {
        setIsImportingFile(true);
        try {
            const enhancedPortfolioHoldings = await readPortfolioHoldingsFromFile({
                file: reactEvent?.target?.files[0],
                expectedFileFormat: FILE_IMPORT_INPUT_TYPE,
                portfolioId,
            });
            if (enhancedPortfolioHoldings) {
                changeCurrentHoldings(enhancedPortfolioHoldings, true);
            }
        } finally {
            setIsImportingFile(false);
        }
    }

    function triggerInputImportHoldings() {
        document.getElementById(FILE_IMPORT_INPUT_ID).click();
    }

    function renderAddPortfolioHoldings() {
        return (
            <Modal
                id="add_instruments_modal"
                title={{
                    msg: `${LABEL_PREFIX}.buttons.add_instruments`,
                }}
                onClose={closeAddModal}
                open={isAddActive}
                maxWidth="lg"
            >
                <AddPortfolioHoldings
                    changeCurrentHoldings={changeCurrentHoldings}
                    currentInstruments={currentHoldings.instruments}
                    closeAddModal={closeAddModal}
                />
            </Modal>
        );
    }

    function openAddModal() {
        setIsAddActive(true);
    }

    function closeAddModal() {
        setIsAddActive(false);
    }

    function changeViewMode() {
        if (isEditActive) {
            setIsEditActive(false);
        } else {
            setIsEditActive(true);
        }
    }

    function changeCurrentHoldings(
        holdings: Partial<IEnhancedPortfolioHoldings>,
        shouldOverrideInstruments = true,
    ) {
        /* eslint-disable no-param-reassign */
        if (shouldOverrideInstruments) {
            currentHoldings = calculateEnhancedPortfolioHoldingsPercentages({
                ...currentHoldings,
                ...holdings,
            });
        } else {
            const { instruments, ...other } = holdings;
            currentHoldings = calculateEnhancedPortfolioHoldingsPercentages({
                ...currentHoldings,
                ...other,
                instruments: {
                    ...currentHoldings.instruments,
                    ...holdings?.instruments,
                },
            });
        }
        /* eslint-enable no-param-reassign */
        setFieldValue({
            fieldName: fields.instrumentsTotalValue.fieldName,
            value: currentHoldings.instrumentsTotalValue,
        }, {
            fieldName: fields.instrumentsPercentageWithinHoldings.fieldName,
            value: currentHoldings.instrumentsPercentageWithinHoldings,
        }, {
            fieldName: fields.cash.fieldName,
            value: currentHoldings.cash,
        }, {
            fieldName: fields.instruments.fieldName,
            value: currentHoldings.instruments,
        });
    }
}
