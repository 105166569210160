import React from 'react';
import { IMultiTranslationsLabel } from '@console/core-api/typsy/console-api-client/dist/models/common/i18n.models';
import { GoalType, IGoalEntityData } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/goal.entity.models';
import { SIZES } from 'config/styling/sizes';
import InputGroup from 'views/common/inputs/base/InputGroup';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import ExtendedInputForm,
{
    IExtendedInputFormContext,
    IExtendedInputFormProps,
    IFormValues,
} from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputNumber from 'views/common/inputs/extended/ExtendedInputNumber';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ContentTitle from 'views/common/layout/ContentTitle';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import ExtendedMultiTranslationsInput from 'views/common/inputs/extended/ExtendedMultiTranslationsInput';
import { TPropertiesFormDisplayMode } from 'models/state/ui.models';
import { getSubmitActionLabelBasedOnMode } from 'utils/entities/entityDetailUtils';
import { getGoalDetailsSchema } from './goalDetailsSchema';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPublicProps
    extends Pick<IExtendedInputFormProps<IGoalFormValues>, 'initialValues' | 'submit' | 'readOnly'> {
    mode: TPropertiesFormDisplayMode;
}

export interface IGoalFormValues extends IFormValues {
    name: IMultiTranslationsLabel;
    description: IMultiTranslationsLabel;
    minHorizonYears: number;
    type: GoalType;
    imgUrl: string;
    externalId: string;
}

const LABEL_PREFIX = 'portfolio_mgmt.goals.detail';

const GOAL_TYPES: IInputSelectItem<string>[] = Object.values(GoalType).map((value) => ({
    value,
    label: `portfolio_mgmt.goals.goal_types.${value.toLowerCase()}`,
}));

export default function GoalPropertiesForm({
    submit,
    mode,
    ...formProps
}: IPublicProps) {
    return (
        <ExtendedInputForm<IGoalFormValues>
            name={ExtendedInputFormName.goalProperties}
            labelPrefix={LABEL_PREFIX}
            submit={{
                actionLabel: getSubmitActionLabelBasedOnMode(mode),
                ...submit,
            }}
            {...formProps}
            renderFormFields={renderFormFields}
            schema={getGoalDetailsSchema()}
            maxWidthPixels={SIZES.DETAIL_FORM.MAX_WIDTH}
            reset={{}}
        />
    );

    function renderFormFields(context: IExtendedInputFormContext<IGoalFormValues>) {
        return (
            <>
                <ContentTitle
                    label={`${LABEL_PREFIX}.sub_title.main`}
                    variant="section"
                />

                {renderGoalPropertiesFormFields(context)}
            </>
        );
    }
}

export function renderGoalPropertiesFormFields({
    fields,
}: IExtendedInputFormContext<IGoalFormValues>) {
    return (
        <>
            <ExtendedMultiTranslationsInput
                formField={fields.name}
                wrapper={{
                    label: 'fields.name.label',
                }}
            />

            <ExtendedMultiTranslationsInput
                formField={fields.description}
                multilineRows
                wrapper={{
                    label: 'fields.description.label',
                }}
            />

            <ExtendedInputText<string>
                formField={fields.externalId}
                wrapper={{
                    label: 'fields.external_id.label',
                }}
            />

            <InputGroup>
                <ExtendedInputNumber
                    formField={fields.minHorizonYears}
                    wrapper={{
                        label: 'fields.min_horizon_years.label',
                    }}
                />
                <ExtendedInputSelect
                    formField={fields.type}
                    wrapper={{
                        label: 'fields.type.label',
                    }}
                    itemLabelsAreTranslationKeys
                    shouldPrefixItemLabels={false}
                    items={GOAL_TYPES}
                />
            </InputGroup>

            <ExtendedInputText<string>
                formField={fields.imgUrl}
                wrapper={{
                    label: 'fields.image_url.label',
                }}
            />
        </>
    );
}

export function mapGoalFormValuesToApiEntity(
    formValues: IGoalFormValues,
): IGoalEntityData {
    return {
        name: formValues.name,
        description: formValues.description,
        min_horizon_years: formValues.minHorizonYears,
        type: formValues.type,
        image_url: formValues.imgUrl,
        external_id: formValues.externalId,
    };
}
