import React from 'react';
import { useParams } from 'react-router-dom';
import { EntityType, IBaseEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { DEFAULT_GOAL_TAB_KEY, GOAL_TAB_KEY } from 'config/tabs.config';
import { getGoalTitle, goalDetailsEntity, triggerDeleteGoal } from 'state/entities/portfolioMgmt/goalDetails';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import DetailPage, {
    redirectToCurrentRouteButOverrideParams,
    TDetailPageTitleConfig,
} from 'views/common/detail/DetailPage';
import { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import { getApiEntityDeleteAction } from 'views/common/genericApiEntity/apiEntityHelper';
import GoalPropertiesTab from './GoalPropertiesTab';
import GoalAccessibleBy from './GoalAccessibleBy';

const GOAL_TABS: ITabItem[] = [{
    key: GOAL_TAB_KEY.PROPERTIES,
    label: 'portfolio_mgmt.goals.detail.tabs.properties.title',
    component: GoalPropertiesTab,
    wrapWithContentBox: false,
}, {
    key: GOAL_TAB_KEY.ACCESSIBLE_BY,
    label: 'portfolio_mgmt.goals.detail.tabs.accessible_by.title',
    component: GoalAccessibleBy,
}];

const GOAL_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: getGoalTitle,
    notifications: [StateChangeNotification.GOAL_DETAILS_DATA],
};

export default function GoalDetail() {
    const { goalTab = DEFAULT_GOAL_TAB_KEY } = useParams<IPossiblePathParams>();

    return (
        <DetailPage
            title={GOAL_TITLE_CONFIG}
            tabsWithEntityWrapper={{
                items: GOAL_TABS,
                activeTabKey: goalTab,
                onTabChange: tabChange,
                entity: {
                    notifications: [StateChangeNotification.GOAL_DETAILS_DATA],
                    asyncEntitySelector: goalDetailsEntity.select,
                    loaderPosition: 'fixed',
                },
            }}
            actions={getGoalDetailActions}
            footer={{
                apiEntityVersion: {
                    apiEntitySelector: () => goalDetailsEntity.select().data,
                },
            }}
            entityId={{
                idSelector: ({ urlParams }) => urlParams.goalId as string,
            }}
        />
    );

    function tabChange({ tabKey }: IOnTabChangeProps) {
        redirectToCurrentRouteButOverrideParams({
            newParams: {
                goalTab: tabKey,
            },
        });
    }

    function getGoalDetailActions(apiEntity: IBaseEntity): IActionItem[] {
        return [getApiEntityDeleteAction({
            apiEntity,
            apiEntityType: EntityType.goal,
            deleteTrigger: triggerDeleteGoal,
        })];
    }
}
