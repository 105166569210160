import React, { useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { DATE_FORMAT, formatDate, TIME_FORMAT } from '@console/common/utils/date/formatDate';
import { isSameYear, parseInputDate } from '@console/common/utils/date/dateUtils';
import { EntityType, TEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    IApiEntityVersions,
    IFetchEntityVersionsApiInput,
} from '@console/core-api/models/apiEntityVersions.models';
import { IUserEntityData, TUser } from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/user.entity.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import {
    IColValues,
    IDataItem,
    IRenderDataItemSubRowProps,
    IRenderPreRowProps,
    TDataColumns,
} from 'models/list.models';
import { IRoute } from 'models/route.models';
import { APP_COLORS, hexToRgba, OPACITY } from 'config/styling/colors';
import {
    apiEntityVersionsEntity,
    triggerFetchApiEntityVersions,
} from 'state/entities/genericApiEntity/apiEntityVersions';
import { extractEmbeddedEntity } from 'utils/entities/entityEmbedUtils';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import { makeStyles, UtilityClass } from 'views/styling';
import ListPageForApiEntity, { TSetStateOnPageNrChange } from 'views/common/list/ListPageForApiEntity';
import DataTable from 'views/common/list/DataTable';
import Tag from 'views/common/widget/Tag';
import { ROUTE_KEY } from 'views/routeKeys';
import { mixins } from 'views/assets/cssInJs/mixins';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import Modal from 'views/common/layout/Modal';
import { IRenderDataProps } from 'views/common/widget/EntityWrapper';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';
import ApiEntityVersionsComparison from './ApiEntityVersionsComparison';

const VERSIONS_TRANSLATION_PREFIX = 'common.generic_api_entity.versions';
const LIST_TRANSLATION_PREFIX = `${VERSIONS_TRANSLATION_PREFIX}.list`;
const COL_TRANSLATION_PREFIX = `${LIST_TRANSLATION_PREFIX}.columns`;

interface IApiEntityVersionsCols extends IColValues {
    version: number;
    updatedTime: string;
    updatedDate: string;
    updatedByLabel: string;
    updatedBy: string;
    currentVersion: boolean;
}

interface IVersionsExtraItemData {
    datetime: string;
}

const COLS: TDataColumns<IApiEntityVersionsCols, IVersionsExtraItemData> = {
    version: {
        label: EMPTY_COL_TITLE,
        data: {
            className: UtilityClass.table.cellBold,
            render: ({ cellValue }) => (
                <Translate
                    msg={`${VERSIONS_TRANSLATION_PREFIX}.active.version`}
                    placeholders={{
                        versionNumber: cellValue as number,
                    }}
                />
            ),
        },
        percentWidth: 10,
        minPxWidth: 96,
    },
    updatedDate: {
        label: EMPTY_COL_TITLE,
        percentWidth: 10,
        minPxWidth: 96,
    },
    updatedTime: {
        label: EMPTY_COL_TITLE,
        percentWidth: 10,
        minPxWidth: 96,
    },
    updatedByLabel: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.updated_by`,
        },
        data: {
            render: () => <Translate msg={`${COL_TRANSLATION_PREFIX}.updated_by`} />,
        },
        percentWidth: 10,
        minPxWidth: 128,
    },
    updatedBy: {
        label: EMPTY_COL_TITLE,
        data: {
            className: UtilityClass.table.cellBold,
        },
        percentWidth: 60,
    },
    currentVersion: {
        label: EMPTY_COL_TITLE,
        data: {
            render: ({ cellValue }) => {
                if (cellValue) {
                    return (
                        <Tag label={`${LIST_TRANSLATION_PREFIX}.current_tag`} />
                    );
                }

                return null;
            },
        },
        percentWidth: 10,
        minPxWidth: 72,
    },
};

interface IApiEntityVersionsProps {
    entityType: EntityType;
    entityIdRouteParamName: string;
}

const useStyles = makeStyles((/* theme */) => ({
    ApiEntityVersions: {
        '& .__tableBody .__tableCell.__yearRow': {
            borderTop: 0,
            ...mixins.typo({ weight: 'bold', size: 21, color: APP_COLORS.PRIMARY['500'] }),
        },

        '& .__tableBody .__versionCompareSubRow': {
            '&.even': {
                background: `${hexToRgba(APP_COLORS.PRIMARY['100'], OPACITY.HIGH)}`,
            },
            '& .__tableCell': {
                borderTop: 0,
            },
        },
    },
}));

function ApiEntityVersions({ entityType, entityIdRouteParamName }: IApiEntityVersionsProps) {
    const [versionToCompareToLatest, setVersionToCompareToLatest] = useState<number>();
    const classes = useStyles();
    const params = useParams<TObjectWithProps>();

    const setStateOnPageNrChange: TSetStateOnPageNrChange = (pageNr) => ({
        toState: (draftState) => {
            // eslint-disable-next-line no-param-reassign
            draftState.entities.apiEntityVersions.data.pageNr = pageNr;
        },
        notificationsToTrigger: [StateChangeNotification.API_ENTITY_VERSIONS_DATA],
    });

    return (
        <ListPageForApiEntity<IFetchEntityVersionsApiInput>
            className={classes.ApiEntityVersions}
            notifications={[
                StateChangeNotification.API_ENTITY_VERSIONS_DATA,
                StateChangeNotification.API_ENTITY_VERSIONS_UI_VARS,
            ]}
            asyncListEntity={apiEntityVersionsEntity}
            asyncListEntityFetchTrigger={triggerFetchApiEntityVersions}
            customApiInput={{
                type: entityType,
                id: params[entityIdRouteParamName],
            }}
            setStateOnPageNrChange={setStateOnPageNrChange}

            uiPageKey={UiPageKey.apiEntityVersionsList}
            notificationToTriggerOnUiVarChanges={StateChangeNotification.API_ENTITY_VERSIONS_UI_VARS}
            box={{
                title: `${LIST_TRANSLATION_PREFIX}.title`,
            }}
            list={{
                renderData: renderUsersList,
            }}
        />
    );

    function renderUsersList({
        data,
        /**
         * The entire entity data (not just the result items of the current page) is used for:
         * - to know which version is the current one
         * - to have access to the _embedded field
         */
        entityData,
    }: IRenderDataProps<TEntity<unknown>[], TEntity<unknown>[], IApiEntityVersions>) {
        if (!data || !isArrayWithValues(entityData.results)) {
            return null;
        }

        const currentVersionNumber = entityData.results[0].version;

        const versionItems: IDataItem<IApiEntityVersionsCols, IVersionsExtraItemData>[] = data.map((apiEntity) => ({
            id: apiEntity.version.toString(),
            colValues: {
                version: apiEntity.version,
                updatedDate: formatDate({
                    date: apiEntity.version_datetime,
                    format: DATE_FORMAT.MONTH_LONG_DAY,
                }),
                updatedTime: formatDate({
                    date: apiEntity.version_datetime,
                    format: TIME_FORMAT.LONG_HR_WITH_MINS,
                }),
                updatedByLabel: '',
                updatedBy: getUserFullName(
                    extractEmbeddedEntity<IUserEntityData>({
                        data: entityData,
                        id: apiEntity.version_authored_by_user_id,
                    }),
                ),
                currentVersion: apiEntity.version === currentVersionNumber,
            },
            extra: {
                datetime: apiEntity.version_datetime,
            },
        }));

        return (
            <>
                <DataTable
                    cols={COLS}
                    items={versionItems}
                    renderPreRow={renderPreRowWhenNewYear}
                    subRow={{
                        render: renderVersionCompareSubRow,
                        isCollapsible: true,
                        isRowCollapsible: dataItemHasPreviousVersion,
                        shouldAddExtraColumnForCollapsibleIcon: true,
                    }}
                    getActions={getRowActions}
                    noHeader
                    alternatingRowBackgrounds
                />
                <Modal
                    id="compare-version-to-latest"
                    open={!!versionToCompareToLatest}
                    onClose={closeCompareToLatestModal}
                    title={{
                        msg: `${LIST_TRANSLATION_PREFIX}.compare_to_current_modal.title`,
                        placeholders: {
                            fromVersionNumber: versionToCompareToLatest,
                            currentVersionNumber,
                        },
                    }}
                >
                    <ApiEntityVersionsComparison
                        fromVersion={versionToCompareToLatest}
                        toVersion={currentVersionNumber}
                        entityId={params[entityIdRouteParamName]}
                        entityType={entityType}
                        highlightChanges
                    />
                </Modal>
            </>
        );

        function openCompareToLatestModal(version: number) {
            setVersionToCompareToLatest(version);
        }

        function closeCompareToLatestModal() {
            setVersionToCompareToLatest(null);
        }

        function getRowActions(dataItem: IDataItem<IApiEntityVersionsCols, IVersionsExtraItemData>): IActionItem[] {
            if (dataItem.colValues.currentVersion) {
                return [];
            }

            return [{
                id: `compare-version-${dataItem.id}`,
                label: {
                    msg: `${LIST_TRANSLATION_PREFIX}.actions.compare_to_current`,
                },
                onExecute: () => openCompareToLatestModal(dataItem.colValues.version),
                variant: 'extra',
            }];
        }

        function renderPreRowWhenNewYear({
            dataItem,
            prevDataItem,
            RowComponent,
            CellComponent,
            nrOfCols,
        }: IRenderPreRowProps<IApiEntityVersionsCols, IVersionsExtraItemData>) {
            const itemDate = parseInputDate(dataItem.extra.datetime);

            if (!prevDataItem || !isSameYear(
                itemDate.toDate(),
                parseInputDate(prevDataItem.extra.datetime).toDate(),
            )) {
                return (
                    <RowComponent>
                        <CellComponent colSpan={nrOfCols} className="__yearRow">
                            {itemDate.get('year')}
                        </CellComponent>
                    </RowComponent>
                );
            }

            return null;
        }

        function renderVersionCompareSubRow({
            RowComponent,
            CellComponent,
            nrOfCols,
            isCollapsed,
            dataItem,
            itemIndex,
        }: IRenderDataItemSubRowProps<IApiEntityVersionsCols, IVersionsExtraItemData, TUser>) {
            if (!isCollapsed) {
                return (
                    <RowComponent
                        className={clsx('__versionCompareSubRow', itemIndex % 2 === 0 && 'even')}
                        size="small"
                    >
                        <CellComponent colSpan={1} />
                        <CellComponent colSpan={nrOfCols - 1}>
                            <ApiEntityVersionsComparison
                                fromVersion={dataItem.colValues.version - 1}
                                toVersion={dataItem.colValues.version}
                                entityId={params[entityIdRouteParamName]}
                                entityType={entityType}
                            />
                        </CellComponent>
                    </RowComponent>
                );
            }
            return null;
        }

        function dataItemHasPreviousVersion(dataItem: IDataItem<IApiEntityVersionsCols, IVersionsExtraItemData>) {
            return dataItem.colValues.version !== 1;
        }
    }
}

export default function getApiEntityVersionsChildRoute({
    entityType,
    entityIdRouteParamName,
    routeKey,
}: IApiEntityVersionsProps & { routeKey: ROUTE_KEY }): IRoute<ROUTE_KEY> {
    const component = () => (
        <ApiEntityVersions entityType={entityType} entityIdRouteParamName={entityIdRouteParamName} />
    );

    return {
        routeKey,
        path: '/versions',
        breadcrumb: { translationKey: `${LIST_TRANSLATION_PREFIX}.breadcrumb` },
        component,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        executeOnRoute: [{
            executeInputSelector: ({ routeLocation }) => ({
                type: entityType,
                id: routeLocation.params[entityIdRouteParamName],
            }),
            execute: triggerFetchApiEntityVersions,
        }],
    };
}
