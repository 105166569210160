import {
    AsyncOperation,
    TRefreshMode,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import {
    TInstrumentsData,
    IAdvancedInstrumentFilters,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/instrument.entity.models';
import { IFetchInstrumentsApiInput } from '@console/core-api/client/portfolioMgmt/instruments.api';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { getEntitiesManager } from '../entitiesManager';

export const instrumentsEntity = getEntitiesManager().registerEntity<TInstrumentsData>({
    asyncEntityKey: AsyncEntityKeys.instruments,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.INSTRUMENTS_DATA],
    includeUpdaters: true,
});

export function triggerFetchInstrumentsForUniverse({
    forceRefresh = false,
    ...apiInput
}: IFetchInstrumentsApiInput & IForceStateRefreshFilter) {
    return triggerFetchInstrumentsGeneric({
        pageKey: UiPageKey.universeInstrumentsList,
        apiInput,
        refreshMode: () => forceRefresh || isObjectWithProps(apiInput),
    });
}

export function triggerFetchInstruments({
    forceRefresh = false,
    ...apiInput
}: IFetchInstrumentsApiInput & IForceStateRefreshFilter) {
    return triggerFetchInstrumentsGeneric({
        pageKey: UiPageKey.instrumentsList,
        apiInput,
        refreshMode: () => forceRefresh || isObjectWithProps(apiInput),
    });
}

export function triggerFetchInstrumentsGeneric({
    pageKey,
    apiInput,
    refreshMode,
}: {
    apiInput: IFetchInstrumentsApiInput;
    pageKey: UiPageKey;
    refreshMode: TRefreshMode<IState>;
}) {
    return instrumentsEntity.async.fetch<IFetchInstrumentsApiInput>({
        api: api.instruments.fetchInstruments,
        apiInputSelector: ({ state }) =>
            enrichApiInputWithListPageVars<IFetchInstrumentsApiInput, IAdvancedInstrumentFilters>({
                pageKey,
                state,
                apiInput,
                mapSimpleFilterValue: (simpleFilterValue) => ({
                    partialInstrumentId: simpleFilterValue,
                }),
                mapAdvancedFilterValues: (advancedFilterValues) => ({
                    name: advancedFilterValues.name,
                    partialInstrumentId: advancedFilterValues.partialInstrumentId,
                }),
            }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: instrumentsEntity.select().data,
        }),
        refreshMode,
        resetDataOnTriggerMode: 'never',
    });
}
