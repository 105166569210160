import React from 'react';
import { Locale } from '@console/common/config/i18n.config';
import { EntityType, IBaseEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { canUserModifyApiEntity } from 'state/auth/apiEntityAuthorization.selectors';
import { translationsManager } from 'views/translations';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import { TrashIcon } from 'views/common/icons';
import { resetExtendedInputForms } from 'views/common/inputs/extended/extendedInputFormManager';
import {
    CORE_API_ENTITY_TYPE_TO_ENTITY_NAME_PREFIX,
    CORE_API_ENTITY_TYPE_TO_FIELD_TRANSLATION_PREFIX_MAP,
} from 'config/coreEntities.ui.config';
import {
    ENTITY_TYPE_TO_MODIFY_PERMISSION,
} from '@console/core-api/typsy/entities/dist/common/entityPermissions.config';

const GENERIC_API_ENTITY_FIELD_TRANSLATION_PREFIX = 'common.generic_api_entity.detail.fields';

interface IApiEntityDeteleActionProps {
    apiEntity: IBaseEntity;
    apiEntityType: EntityType;
    deleteTrigger: () => void;
}

export function getApiEntityDeleteAction({
    apiEntityType,
    apiEntity,
    deleteTrigger,
}: IApiEntityDeteleActionProps): IActionItem {
    if (apiEntity?.deleted) {
        return null;
    }

    const canUserModifyEntity =
        canUserModifyApiEntity(apiEntity, ENTITY_TYPE_TO_MODIFY_PERMISSION[apiEntityType]);

    return {
        id: `delete_${CORE_API_ENTITY_TYPE_TO_ENTITY_NAME_PREFIX[apiEntityType]}`,
        label: 'common.action.delete',
        variant: 'extra',
        icon: <TrashIcon />,
        disabled: !canUserModifyEntity,
        tooltip: !canUserModifyEntity && 'error.auth.not_allowed',
        tooltipPlacement: 'top',
        onExecute: () => {
            /* to avoid route-leaving-guard issues */
            resetExtendedInputForms(`${CORE_API_ENTITY_TYPE_TO_ENTITY_NAME_PREFIX[apiEntityType]}_`);

            deleteTrigger();
        },
        askConfirmation: true,
    };
}

/**
 * This will try to find a translation for the given fieldName.
 * - It will first try to find a translation label specific for the entity
 * - If not found, it will try to find a generic translation label
 * - If not found at all, it will return undefined.
 *
 * Usually we would do this with translator from state,
 * but that translator returns the translation key if no translation is found.
 * In this case we want to do something else if no translation is found.
 *
 * @param fieldName How it is returned by the back-end!
 */
export function getFieldNameTranslationIfAny({
    fieldName,
    entityType,
    locale,
}: {
    fieldName: string;
    entityType: EntityType;
    locale: Locale;
}): string {
    let translatedFieldName = translationsManager.getTranslationsOfLocale({
        locale,
    })[`${CORE_API_ENTITY_TYPE_TO_FIELD_TRANSLATION_PREFIX_MAP[entityType]}.${fieldName}.label`];

    if (!translatedFieldName) {
        translatedFieldName = translationsManager.getTranslationsOfLocale({
            locale,
        })[`${GENERIC_API_ENTITY_FIELD_TRANSLATION_PREFIX}.${fieldName}.label`];
    }

    return translatedFieldName;
}
