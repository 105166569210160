import { nowAsDayJs, parseInputDate } from '@console/common/utils/date/dateUtils';
import { DataPointFrequency, IAumDistributionPart, ISelfAumReportTimeSeriesItem } from '@console/bff/export';
import { TLabel } from 'models/general.models';
import { MGMT_REPORTING_LINE_CHART_CONFIG } from 'config/mgmtReporting/mgmtReportingCharts.config';
import { REPORT_EXPIRY_IN_MINUTES } from 'config/mgmtReporting/mgmtReporting.config';
import { TMgmtReportingDonutItem } from 'views/mgmtReporting/graphs/MgmtReportingDonutChart';
import { ILineChartData, TValueLineItemVariant } from 'views/common/charts/types';
import { PeriodType } from '@console/common/models/periodFilter.models';

const AVERAGE_YTD_LABEL_PREFIX = 'mgmt_reporting.generic.average_ytd';
export const INCOMPLETE_PERIOD_TYPES = [PeriodType.MONTH_TO_DATE, PeriodType.QUARTER_TO_DATE, PeriodType.YEAR_TO_DATE];
export const YEAR_PERIOD_TYPES = [PeriodType.LAST_YEAR, PeriodType.YEAR_TO_DATE];

export type TTrendDirection = 'up' | 'down' | 'remainedSame';

export function determineTrendDirection(prevNumber: number, nextNumber: number) {
    if (nextNumber === prevNumber) {
        return 'remainedSame';
    }

    if (nextNumber > prevNumber) {
        return 'up';
    }

    return 'down';
}

export function calculateTotalAum({
    aumDistributionParts,
}: {
    aumDistributionParts: IAumDistributionPart[];
}): number {
    return aumDistributionParts.reduce(
        (accumulator, { amount }) => accumulator + amount,
        0,
    );
}

export function mapAumDistributionPartsToDonutChartData({
    aumDistributionParts,
}: {
    aumDistributionParts: IAumDistributionPart[];
}): TMgmtReportingDonutItem[] {
    const total = calculateTotalAum({ aumDistributionParts });

    return aumDistributionParts.map((aumPart) => ({
        amount: aumPart.amount,
        value: aumPart.amount / total, /* percentage */
        label: {
            text: aumPart.name,
            shouldTranslate: false,
        },
    }));
}

export function mapAumTimeSeriesToLineChartData({
    aumTimeSeriesItems,
}: {
    aumTimeSeriesItems: ISelfAumReportTimeSeriesItem[];
}): ILineChartData<Date, number>[] {
    const uniqueOccurringDistributionNames = aumTimeSeriesItems.reduce(
        (accumulator, { items }) => {
            items.forEach(({ name }) => {
                if (!accumulator[name]) {
                    accumulator[name] = true;
                }
            });

            return accumulator;
        },
        {} as { [key: string]: boolean },
    );

    return Object.keys(uniqueOccurringDistributionNames).map((distributionName) => ({
        key: distributionName,
        label: {
            text: distributionName,
            shouldTranslate: false,
        },
        lineColor: MGMT_REPORTING_LINE_CHART_CONFIG.lineColor,
        dataPoints: aumTimeSeriesItems.map(({ datetime, items }) => ({
            x: new Date(datetime),
            y: items.find(({ name }) => distributionName === name)?.amount || 0,
        })),
    }));
}

export type TEnrichedReportResponse<ReportResponse> = ReportResponse & {
    fetchDate: Date;
};

export function enrichReportResponseWithFetchDate<ReportResponse>(
    reportResponse: ReportResponse,
): TEnrichedReportResponse<ReportResponse> {
    return {
        ...reportResponse,
        fetchDate: new Date(),
    };
}

export function isEnrichedReportOutdated(enrichedReport: TEnrichedReportResponse<unknown>): boolean {
    return parseInputDate(enrichedReport.fetchDate).isBefore(nowAsDayJs().subtract(REPORT_EXPIRY_IN_MINUTES, 'minute'));
}

export function getAverageYtdLabel({
    dataPointFrequency,
}: {
    dataPointFrequency: DataPointFrequency;
}): TLabel {
    switch (dataPointFrequency) {
        case DataPointFrequency.Day:
            return getLabel('day');
        case DataPointFrequency.Week:
            return getLabel('week');
        case DataPointFrequency.Month:
            return getLabel('month');
        case DataPointFrequency.Quarter:
            return getLabel('quarter');
        default:
            return {
                text: '-',
                shouldTranslate: false,
            };
    }

    function getLabel(labelSuffix: string) {
        return `${AVERAGE_YTD_LABEL_PREFIX}.${labelSuffix}`;
    }
}

export function determineValueLineItemVariant({
    periodType,
    barGroupIndex,
    nrOfBarGroups,
}: {
    periodType: PeriodType;
    barGroupIndex: number;
    nrOfBarGroups: number;
}): TValueLineItemVariant {
    if (barGroupIndex === nrOfBarGroups - 1) {
        if (INCOMPLETE_PERIOD_TYPES.includes(periodType)) {
            return 'incomplete';
        }
    }

    return 'default';
}
