import React from 'react';
import { IRiskProfileEntityData } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/riskProfiles.entity.models';
import { riskProfileDetailsEntity, triggerCreateRiskProfile } from 'state/entities/portfolioMgmt/riskProfileDetails';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import { getScoreRangeMinMax } from 'views/portfolioMgmt/RiskProfiles/RiskProfileDetail/riskProfileDetailsSchema';
import RiskProfilePropertiesForm, {
    IRiskProfileFormValues,
    mapRiskProfileFormValuesToApiEntity,
} from 'views/portfolioMgmt/RiskProfiles/RiskProfileDetail/RiskProfilePropertiesForm';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import AddPage from 'views/common/add/AddPage';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getMultiTranslationsLabelInitialValue } from 'state/appConfig/selectors';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';

export default function RiskProfileAdd() {
    const SCORE_RANGE_EXTREMES = getScoreRangeMinMax();

    const initialValues: IRiskProfileFormValues = {
        name: getMultiTranslationsLabelInitialValue(),
        description: getMultiTranslationsLabelInitialValue(),
        scoreRangeMin: SCORE_RANGE_EXTREMES.MIN,
        scoreRangeMax: SCORE_RANGE_EXTREMES.MAX,
        expectedRiskFraction: undefined,
        expectedReturnFraction: undefined,
        expectedAssetClasses: [],
        externalId: '',
        imageUrl: '',
    };

    return (
        <AddPage
            titleLabel="portfolio_mgmt.risk_profiles.add.title"
            entity={{
                notifications: [StateChangeNotification.RISK_PROFILE_DETAILS_DATA],
                asyncEntitySelector: riskProfileDetailsEntity.select,
            }}
            cancel={{
                onCancel: redirectToList,
            }}
        >
            <RiskProfilePropertiesForm
                initialValues={initialValues}
                mode="add"
                submit={{
                    onSubmit: onSubmitAdd,
                }}
            />
        </AddPage>
    );

    function onSubmitAdd({ values }: IOnSubmitProps<IRiskProfileFormValues>) {
        return triggerCreateRiskProfile(
            enrichApiEntityDataForCreate<IRiskProfileEntityData>(mapRiskProfileFormValuesToApiEntity(values)),
        );
    }

    function redirectToList() {
        redirectTo({
            routeKey: ROUTE_KEY.R_RISK_PROFILES_LIST,
        });
    }
}
