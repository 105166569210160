import React from 'react';
import { useParams } from 'react-router-dom';
import { EntityType, IBaseEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { USERGROUP_TAB_KEY, DEFAULT_USERGROUP_TAB_KEY } from 'config/tabs.config';
import {
    userGroupDetailsEntity,
    getUserGroupTitle,
    triggerDeleteUserGroup,
} from 'state/entities/userMgmt/userGroupDetails';
import { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import DetailPage, {
    redirectToCurrentRouteButOverrideParams,
    TDetailPageTitleConfig,
} from 'views/common/detail/DetailPage';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import { getApiEntityDeleteAction } from 'views/common/genericApiEntity/apiEntityHelper';
import UserGroupOperationPermissions from './UserGroupOperationPermissions';
import UserGroupMembers from './UserGroupMembers';
import UserGroupProperties from './UserGroupProperties';
import UserGroupAccessibleBy from './UserGroupAccessibleBy';

const USERGROUP_TABS: ITabItem[] = [{
    key: USERGROUP_TAB_KEY.OPERATION_PERMISSIONS,
    label: 'user_mgmt.user_groups.detail.tabs.operation_permissions.title',
    component: UserGroupOperationPermissions,
}, {
    key: USERGROUP_TAB_KEY.MEMBERS,
    label: 'user_mgmt.user_groups.detail.tabs.members.title',
    component: UserGroupMembers,
}, {
    key: USERGROUP_TAB_KEY.PROPERTIES,
    label: 'user_mgmt.user_groups.detail.tabs.properties.title',
    component: UserGroupProperties,
    wrapWithContentBox: false,
}, {
    key: USERGROUP_TAB_KEY.ACCESSIBLE_BY,
    label: 'user_mgmt.user_groups.detail.tabs.accessible_by.title',
    component: UserGroupAccessibleBy,
}];

const USERGROUP_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: getUserGroupTitle,
    notifications: [StateChangeNotification.USERGROUP_DETAILS_DATA],
};

export default function UserGroupDetail() {
    const {
        userGroupTab = DEFAULT_USERGROUP_TAB_KEY,
    } = useParams<IPossiblePathParams>();

    return (
        <DetailPage
            title={USERGROUP_TITLE_CONFIG}
            tabsWithEntityWrapper={{
                items: USERGROUP_TABS,
                activeTabKey: userGroupTab,
                onTabChange: tabChanged,
                entity: {
                    notifications: [StateChangeNotification.USERGROUP_DETAILS_DATA],
                    asyncEntitySelector: userGroupDetailsEntity.select,
                    loaderPosition: 'fixed',
                },
            }}
            actions={getUserGroupDetailActions}
            footer={{
                apiEntityVersion: {
                    apiEntitySelector: () => userGroupDetailsEntity.select().data,
                },
            }}
            entityId={{
                idSelector: ({ urlParams }) => urlParams.groupId as string,
            }}
        />
    );

    function tabChanged({ tabKey }: IOnTabChangeProps) {
        redirectToCurrentRouteButOverrideParams({
            newParams: {
                userGroupTab: tabKey,
            },
        });
    }

    function getUserGroupDetailActions(apiEntity: IBaseEntity): IActionItem[] {
        return [getApiEntityDeleteAction({
            apiEntity,
            apiEntityType: EntityType.userGroup,
            deleteTrigger: triggerDeleteUserGroup,
        })];
    }
}
