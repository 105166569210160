import produce from 'immer';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { hasFetchSucceeded } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getOnlyChangedProperties } from '@console/common/utils/object/getOnlyChangedProperties';
import {
    IPortfolioCreateTransactionsFlag,
    ISinglePortfolioApiInput,
    PortfolioMoneyType,
    PortfolioManagerType,
    PortfolioStatus,
    TPortfolioCreate,
    TPortfolioPatch,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';
import { EntityType, TEntityUlid, IBaseIdentifiedEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    TCreatePortfolioApiInput,
    TEnhancedPortfolio,
    TPatchPortfolioApiInput,
} from '@console/bff/models/portfolios/enhancedPortfolioDetails.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { PORTFOLIO_TAB_KEY, USER_TAB_KEY } from 'config/tabs.config';
import { api } from 'api';
import { getTranslator } from 'state/i18n/selectors';
import { getStore } from 'state';
import { getCurrentRouteLocation, getCurrentRouteParam } from 'state/ui/selectors';
import { getSelectedUserName, triggerFetchUserDetails } from 'state/entities/userMgmt/userDetails';
import { resetPortfolioPerformancePageVars } from 'state/ui/uiPages.actions';
import {
    flashErrorApiEntityCreate,
    flashErrorApiEntityPatch,
    flashSuccessApiEntityCreate,
    flashSuccessApiEntityPatch,
} from 'state/entities/entitiesFlashDispatcher';
import { triggerResetPortfoliosFetch } from 'state/entities/portfolioMgmt/portfolios';
import { triggerResetPortfolioTransactionsFetch } from 'state/entities/portfolioMgmt/portfolioTransactions';
import { shouldCheckIfPortfolioCanBeOptimized } from 'utils/entities/portfolioMgmt/portfolioOptimizationUtils';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { TTitleLabelSelector } from 'views/common/layout/PageTitleBasedOnState';
import { getEntitiesManager } from '../entitiesManager';
import { resetPortfolioOptimization, triggerFetchPortfolioOptimizationLatest } from './portfolioOptimization';
import {
    apiEntityAsyncDeleteAction,
    apiDetailsEntityAsyncFetchAction,
} from '../genericApiEntity/apiEntityAsyncActions';

export const portfolioDetailsEntity = getEntitiesManager().registerEntity<TEnhancedPortfolio>({
    asyncEntityKey: AsyncEntityKeys.portfolioDetails,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_DETAILS_DATA],
    includeUpdaters: true,
});

export const getPortfolioTitle = ({
    includeUserName = false,
}: {
    includeUserName?: boolean;
} = {}): string => {
    const portfolioDetails = portfolioDetailsEntity.select();
    const hasPortfolioName = hasFetchSucceeded(portfolioDetails) && isSetString(portfolioDetails.data.name);

    const portfolioName = hasPortfolioName
        ? portfolioDetails.data.name
        : getDefaultPortfolioName();
    const titleParts = includeUserName && hasPortfolioName
        ? [`'${portfolioName}'`]
        : [portfolioName];

    if (includeUserName) {
        const userName = getSelectedUserName();
        if (userName) {
            titleParts.push(userName);
        }
    }

    return titleParts.join(' - ');
};

function getDefaultPortfolioName() {
    return getTranslator(getStore().getState())('portfolio_mgmt.portfolios.detail.title');
}

export const getPortfolioTitleIncludingUserName: TTitleLabelSelector = () => ({
    text: getPortfolioTitle({ includeUserName: true }),
    shouldTranslate: false,
});

export function triggerFetchPortfolioDetails() {
    const portfolioId = getCurrentRouteParam(getStore().getState(), 'portfolioId');

    if (!validateEntityIdBeforeFetch({
        entityId: portfolioId,
        entityType: EntityType.portfolio,
    })) {
        return null;
    }

    return apiDetailsEntityAsyncFetchAction<TEnhancedPortfolio, ISinglePortfolioApiInput>({
        detailsEntity: portfolioDetailsEntity,
        entityType: EntityType.portfolio,
        api: api.bff.portfolios.fetchEnhancedPortfolioDetails,
        apiInputSelector: () => ({
            portfolioId,
        }),
        refreshMode: () =>
            portfolioDetailsEntity.select().data.id !== portfolioId,
        resetDataOnTriggerMode: 'always',
        onTrigger: ({ dispatch }) => {
            dispatch(resetPortfolioPerformancePageVars());
            resetPortfolioOptimization();
        },
        onPreSuccess: ({ apiResult }) => {
            triggerFetchUserDetails({ userId: apiResult.owned_by_user_id });

            triggerPortfolioOptimizationFetchIfCanBeOptimized({
                portfolioId,
                portfolioManagerType: apiResult.config.manager,
                portfolioStatus: apiResult.enhancedStatus.portfolio,
                forceRefresh: false,
            });
        },
    });
}

export function triggerPortfolioOptimizationFetchIfCanBeOptimized({
    portfolioId,
    portfolioManagerType,
    portfolioStatus,
    forceRefresh = true,
}: {
    portfolioId: TEntityUlid;
    portfolioManagerType: PortfolioManagerType;
    portfolioStatus: PortfolioStatus;
} & IForceStateRefreshFilter) {
    if (shouldCheckIfPortfolioCanBeOptimized({
        portfolioManagerType,
        portfolioStatus,
    })) {
        triggerFetchPortfolioOptimizationLatest({
            portfolioId,
            forceRefresh,
        });
    } else {
        resetPortfolioOptimization();
    }
}

export function triggerClearPortfolioBrokerageAccount() {
    return triggerPatchPortfolioDetails((currentPortfolio) => {
        // eslint-disable-next-line no-param-reassign
        currentPortfolio.brokerage_account = null;
    });
}

export function triggerPatchPortfolioDetails(portfolioUpdater: (currentPortfolio: TPortfolioPatch) => void, {
    onPreSuccess,
    notificationsToTrigger,
    shouldCreateTransactions = false,
}: {
    onPreSuccess?: (props: { state: IState }) => void;
    notificationsToTrigger?: StateChangeNotification[];
} & IPortfolioCreateTransactionsFlag = {}) {
    return portfolioDetailsEntity.async.update<TPatchPortfolioApiInput, TEnhancedPortfolio>({
        api: api.bff.portfolios.patchPortfolio,
        apiInputSelector: () => ({
            ...getOnlyChangedProperties(
                portfolioDetailsEntity.select().data,
                produce(portfolioDetailsEntity.select().data, portfolioUpdater),
            ),
            portfolioId: portfolioDetailsEntity.select().data.id,
            shouldCreateTransactions,
        }),
        updateDataOnSuccess: true,
        onPreSuccess: ({ state, apiResult }) => {
            triggerResetPortfoliosFetch();

            if (shouldCreateTransactions) {
                triggerResetPortfolioTransactionsFetch();
            }

            triggerPortfolioOptimizationFetchIfCanBeOptimized({
                portfolioId: apiResult.id,
                portfolioManagerType: apiResult.config.manager,
                portfolioStatus: apiResult.enhancedStatus.portfolio,
                forceRefresh: true,
            });

            if (onPreSuccess) {
                onPreSuccess({ state });
            }
        },
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
        notificationsToTrigger,
    });
}

export function triggerUnarchivePortfolio(portfolioIdentifier?: IBaseIdentifiedEntity) {
    return portfolioDetailsEntity.async.update<TPatchPortfolioApiInput, TEnhancedPortfolio>({
        api: api.bff.portfolios.patchPortfolio,
        apiInputSelector: () => ({
            portfolioId: portfolioIdentifier ? portfolioIdentifier.id : portfolioDetailsEntity.select().data.id,
            archived: false,
        }),
        updateDataOnSuccess: true,
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
    });
}

export function triggerCreatePortfolio(portfolio: TPortfolioCreate) {
    return portfolioDetailsEntity.async.create<TCreatePortfolioApiInput, TEnhancedPortfolio>({
        api: api.bff.portfolios.createPortfolio,
        apiInputSelector: () => portfolio,
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetPortfoliosFetch();

            resetPortfolioOptimization();
        },
        onSuccess: ({ apiResult, dispatch }) => {
            triggerFetchUserDetails({ userId: apiResult.owned_by_user_id });

            /**
             * In case of a virtual portfolio (paper money) no success-flash is shown
             * and there is no immediate redirection to the detail page, as the user will first
             * be presented with a modal to optionally add cash to the portfolio (virtually).
             */
            if (!shouldShowDepositCashModalAfterPortfolioCreation(apiResult)) {
                triggerPortfolioOptimizationFetchIfCanBeOptimized({
                    portfolioId: apiResult.id,
                    portfolioManagerType: apiResult.config.manager,
                    portfolioStatus: apiResult.enhancedStatus.portfolio,
                    forceRefresh: false,
                });

                flashSuccessApiEntityCreate({ dispatch });

                redirectToPortfolioDetailAfterPortfolioCreation({ portfolioId: apiResult.id });
            }
        },
        onError: flashErrorApiEntityCreate,
    });
}

export function shouldShowDepositCashModalAfterPortfolioCreation(portfolio: TEnhancedPortfolio) {
    return portfolio.money_type === PortfolioMoneyType.PAPER_MONEY
        && portfolio?.config?.manager_settings?.start_amount > 0;
}

export function redirectToPortfolioDetailAfterPortfolioCreation({ portfolioId }: { portfolioId: TEntityUlid }) {
    redirectTo({
        routeKey: ROUTE_KEY.R_PORTFOLIO_DETAIL,
        params: {
            portfolioId,
            portfolioTab: PORTFOLIO_TAB_KEY.PROPERTIES,
        },
    });
}

export function triggerDeletePortfolio(portfolioIdentifier?: IBaseIdentifiedEntity) {
    return apiEntityAsyncDeleteAction({
        detailsEntity: portfolioDetailsEntity,
        api: api.portfolios.deletePortfolio,
        apiInputSelector: () => portfolioIdentifier || {
            id: portfolioDetailsEntity.select().data.id,
        },
        onPreSuccess: ({ state }) => {
            triggerResetPortfoliosFetch();

            const { params, routeKey } = getCurrentRouteLocation(state);
            if (routeKey === ROUTE_KEY.R_USER_DETAIL_PORTFOLIO_DETAIL) {
                redirectTo({
                    routeKey: ROUTE_KEY.R_USER_DETAIL_PORTFOLIOS,
                    params: { userId: params.userId, userTab: USER_TAB_KEY.PORTFOLIOS },
                });
            } else {
                redirectTo({ routeKey: ROUTE_KEY.R_PORTFOLIOS_LIST });
            }
        },
    });
}
