import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { EntityType, TEntityUlid, IBaseEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { ENTITY_ID_REGEX, ENTITY_ULID_PREFIX_TO_TYPE } from '@console/core-api/typsy/entities/dist/common/entityUlid.config';
import { ENTITY_TYPE_TO_MODIFY_PERMISSION } from '@console/core-api/typsy/entities/dist/common/entityPermissions.config';
import { CORE_API_ENTITY_TYPE_TO_SINGULAR_ENTITY_NAME_TRANSLATION_MAP } from 'config/coreEntities.ui.config';
import { getTranslator } from 'state/i18n/selectors';
import { getStore } from 'state';
import { flashErrorInvalidEntityId } from 'state/entities/entitiesFlashDispatcher';
import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';

export function getEntityTypeBasedOnId(entityId: TEntityUlid): EntityType {
    if (!isSetString(entityId)) {
        return null;
    }

    return ENTITY_ULID_PREFIX_TO_TYPE[entityId.substr(0, 1)];
}

export function getEntityTypeSingularNameBasedOnId(entityId: TEntityUlid): string {
    if (!isSetString(entityId)) {
        return null;
    }
    const translator = getTranslator(getStore().getState());

    return translator(CORE_API_ENTITY_TYPE_TO_SINGULAR_ENTITY_NAME_TRANSLATION_MAP[getEntityTypeBasedOnId(entityId)]);
}

export function isUserEntityId(entityId: TEntityUlid): boolean {
    return getEntityTypeBasedOnId(entityId) === EntityType.user;
}

export function isEntityIdValid(entityId: string): boolean {
    return entityId.search(ENTITY_ID_REGEX) > -1;
}

export function isEntityIdValidForEntityType({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: EntityType;
}): boolean {
    if (!isEntityIdValid(entityId)) {
        return false;
    }

    return getEntityTypeBasedOnId(entityId) === entityType;
}

export function validateEntityIdBeforeFetch({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: EntityType;
}): boolean {
    const isIdValid = isEntityIdValidForEntityType({ entityId, entityType });

    if (!isIdValid) {
        flashErrorInvalidEntityId(entityId);
    }

    return isIdValid;
}

export function getDefaultModifyPermissionOfEntity(
    apiEntity: IBaseEntity,
): OperationPermissionKey {
    if (apiEntity) {
        const entityType = getEntityTypeBasedOnId(apiEntity.id);

        return ENTITY_TYPE_TO_MODIFY_PERMISSION[entityType];
    }

    return null;
}
