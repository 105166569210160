import { SchemaErrorType, stringLike, string, array } from '@console/common/utils/schema';
import { EntityType } from '../../typsy/entities/dist/common/entity.models';
import { ENTITY_TYPE_TO_ULID_PREFIX, ENTITY_TYPE_TO_ULID_REGEX } from '../../typsy/entities/dist/common/entityUlid.config';

export const entityId = (entityType: EntityType) =>
    stringLike()
        .format(ENTITY_TYPE_TO_ULID_REGEX[entityType], `${ENTITY_TYPE_TO_ULID_PREFIX[entityType]}...`);

export const entityIdArray = ({
    entityType,
    entityName,
}: {
    entityType: EntityType;
    entityName: string;
}) => {
    const entityIdRegex = ENTITY_TYPE_TO_ULID_REGEX[entityType];

    return array()
        .of(string())
        .test({
            name: SchemaErrorType.ArrayOfEntityIds,
            test: (values) => {
                if (values) {
                    return values.every((value) => value.search(entityIdRegex) > -1);
                }
                return true;
            },
            params: {
                entityType: entityName,
            },
            // eslint-disable-next-line no-template-curly-in-string,prefer-template
            message: '${path} should contain a list of ' + entityName + ' identifiers.',
        });
};
