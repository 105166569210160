import React from 'react';
import { useParams } from 'react-router-dom';
import { TPortfolio } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { isRoboPortfolioManagerType } from '@console/core-api/utils/entities/portfolios/portfolioTypeUtils';
import { TEnhancedPortfolio } from '@console/bff/models/portfolios/enhancedPortfolioDetails.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { PORTFOLIO_TAB_KEY, DEFAULT_PORTFOLIO_TAB_KEY } from 'config/tabs.config';
import {
    getPortfolioTitleIncludingUserName,
    portfolioDetailsEntity,
    triggerDeletePortfolio, triggerUnarchivePortfolio,
} from 'state/entities/portfolioMgmt/portfolioDetails';
import { canUserUnarchivePortfolios } from 'state/auth/apiEntityAuthorization.selectors';
import { isStorytellerEnabled } from 'state/appConfig/selectors';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import { IOnTabChangeProps } from 'views/common/layout/Tabs';
import DetailPage, {
    redirectToCurrentRouteButOverrideParams,
    TDetailPageTitleConfig,
} from 'views/common/detail/DetailPage';
import { IConditionalTabItem } from 'views/common/layout/TabsWithEntityWrapper';
import { getApiEntityDeleteAction } from 'views/common/genericApiEntity/apiEntityHelper';
import { PortfolioTypeIndicator } from 'views/common/icons/indicators';
import PortfolioOptimizationBanner from './PortfolioOptimizations/PortfolioOptimizationBanner';
import PortfolioPerformance from './PortfolioPerformance';
import PortfolioAllocation from './PortfolioAllocation';
import PortfolioHoldings from './PortfolioHoldings/PortfolioHoldings';
import PortfolioOptimizations from './PortfolioOptimizations/PortfolioOptimizationsList';
import { PortfolioProperties } from './PortfolioProperties';
import PortfolioTransactions from './PortfolioTransactions';
import PortfolioAccessibleBy from './PortfolioAccessibleBy';
import PortfolioReports from './PortfolioReports';
import PortfolioStatusOverview from './PortfolioStatusOverview';

const PF_DETAIL_LABEL_PREFIX = 'portfolio_mgmt.portfolios.detail';
const TAB_LABEL_PREFIX = `${PF_DETAIL_LABEL_PREFIX}.tabs`;

function getPortfolioTabs(shouldDisplayStoryteller: boolean): IConditionalTabItem<TPortfolio>[] {
    return [{
        key: PORTFOLIO_TAB_KEY.PERFORMANCE,
        label: `${TAB_LABEL_PREFIX}.performance.title`,
        component: PortfolioPerformance,
        // noHorizontalPadding: true,
    }, {
        key: PORTFOLIO_TAB_KEY.ALLOCATION,
        label: `${TAB_LABEL_PREFIX}.allocation.title`,
        component: PortfolioAllocation,
        wrapWithContentBox: false,
    }, {
        key: PORTFOLIO_TAB_KEY.HOLDINGS,
        label: `${TAB_LABEL_PREFIX}.holdings.title`,
        component: PortfolioHoldings,
        wrapWithContentBox: false,
    }, {
        key: PORTFOLIO_TAB_KEY.OPTIMIZATIONS,
        label: `${TAB_LABEL_PREFIX}.optimizations.title`,
        component: PortfolioOptimizations,
        showBasedOnEntity: ({ entityData }) => isRoboPortfolioManagerType(entityData.config.manager)
            && entityData.config.manager_settings.active,
    }, {
        key: PORTFOLIO_TAB_KEY.PROPERTIES,
        label: `${TAB_LABEL_PREFIX}.properties.title`,
        component: PortfolioProperties,
        wrapWithContentBox: false,
    }, {
        key: PORTFOLIO_TAB_KEY.TRANSACTIONS,
        label: `${TAB_LABEL_PREFIX}.transactions.title`,
        component: PortfolioTransactions,
    }, {
        key: PORTFOLIO_TAB_KEY.ACCESSIBLE_BY,
        label: `${TAB_LABEL_PREFIX}.accessible_by.title`,
        component: PortfolioAccessibleBy,
    }, {
        key: PORTFOLIO_TAB_KEY.REPORTS,
        label: `${TAB_LABEL_PREFIX}.reports.title`,
        component: PortfolioReports,
        wrapWithContentBox: false,
        hidden: !shouldDisplayStoryteller,
    }];
}

const PORTFOLIO_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: getPortfolioTitleIncludingUserName,
    startIconSelector: () => {
        const portfolioDetails = portfolioDetailsEntity.select();
        if (portfolioDetails.data) {
            return (
                <PortfolioTypeIndicator
                    managerType={portfolioDetails.data.config.manager}
                    moneyType={portfolioDetails.data.money_type}
                />
            );
        }
        return null;
    },
    endIconSelector: () => (
        <PortfolioStatusOverview
            portfolio={portfolioDetailsEntity.select().data}
        />
    ),
    notifications: [
        StateChangeNotification.PORTFOLIO_DETAILS_DATA,
        StateChangeNotification.USER_DETAILS_DATA,
        StateChangeNotification.PORTFOLIO_OPTIMIZATION,
    ],
};

export default function PortfolioDetail() {
    const {
        portfolioTab = DEFAULT_PORTFOLIO_TAB_KEY,
    } = useParams<IPossiblePathParams>();

    return (
        <>
            <PortfolioOptimizationBanner />

            <DetailPage
                title={PORTFOLIO_TITLE_CONFIG}
                tabsWithEntityWrapper={{
                    items: getPortfolioTabs(isStorytellerEnabled()),
                    activeTabKey: portfolioTab,
                    onTabChange: tabChanged,
                    entity: {
                        notifications: [StateChangeNotification.PORTFOLIO_DETAILS_DATA],
                        asyncEntitySelector: portfolioDetailsEntity.select,
                        loaderPosition: 'fixed',
                    },
                }}
                footer={{
                    apiEntityVersion: {
                        apiEntitySelector: () => portfolioDetailsEntity.select().data,
                    },
                }}
                actions={getPortfolioDetailActions}
                entityId={{
                    idSelector: ({ urlParams }) => urlParams.portfolioId as string,
                }}
            />
        </>
    );

    function tabChanged({ tabKey }: IOnTabChangeProps) {
        redirectToCurrentRouteButOverrideParams({
            newParams: {
                portfolioTab: tabKey,
            },
        });
    }

    function getPortfolioDetailActions(apiEntity: TEnhancedPortfolio): IActionItem[] {
        const detailActions = [getApiEntityDeleteAction({
            apiEntity,
            apiEntityType: EntityType.portfolio,
            deleteTrigger: triggerDeletePortfolio,
        })];

        if (apiEntity.archived) {
            const canUserUnarchive = canUserUnarchivePortfolios();

            detailActions.push({
                id: 'un-archive_portfolio',
                label: `${PF_DETAIL_LABEL_PREFIX}.actions.unarchive`,
                variant: 'extra',
                disabled: !canUserUnarchive,
                onExecute: triggerUnarchivePortfolio,
                askConfirmation: true,
            });
        }

        return detailActions;
    }
}
