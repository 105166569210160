import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import { EntityType } from './entity.models';

export const ENTITY_TYPE_TO_MODIFY_PERMISSION: { [key in EntityType]: OperationPermissionKey } = {
    [EntityType.goal]: OperationPermissionKey.GOALS_MODIFY,
    [EntityType.horizon]: OperationPermissionKey.HORIZONS_MODIFY,
    [EntityType.riskProfile]: OperationPermissionKey.RISKPROFILES_MODIFY,
    [EntityType.policy]: OperationPermissionKey.POLICIES_MODIFY,
    [EntityType.portfolio]: OperationPermissionKey.PORTFOLIOS_MODIFY,
    [EntityType.portfolioGroup]: OperationPermissionKey.PORTFOLIO_GROUPS_MODIFY,
    [EntityType.optimization]: null,
    [EntityType.userGroup]: OperationPermissionKey.USERGROUPS_MODIFY,
    [EntityType.user]: OperationPermissionKey.USERS_MODIFY,
};
