import {
    IPortfolioTransactionsApiInput,
    TPortfolioTransactionsData,
    IPortfolioTransactionEntityData,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/portfolioTransaction.entity.models';
import { IApiEntityListResponse } from '../../typsy/console-api-client/dist/models/consoleApiQuery.models';
import { FIRST_PAGE_NR, MAX_PAGE_ITEM_LIMIT } from '../../typsy/console-api-client/dist/config/consoleApi.config';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import { conditionallyAskCount } from '../../typsy/console-api-client/dist/utils/fetch/entityFetchUtils';
import { fetchAllPagesOfApiEntity } from '../../typsy/console-api-client/dist/utils/fetch/fetchAllPagesOfApiEntity';
import { get } from '../coreApiRequestWrapper';
import fetchApiEntityUrlParamBuilder from '../../typsy/console-api-client/dist/utils/fetch/fetchApiEntityUrlParamBuilder';

export function fetchAllPortfolioTransactions(
    apiInput: Omit<IPortfolioTransactionsApiInput, 'offset' | 'limit' | 'pageNr'>,
) {
    return fetchAllPagesOfApiEntity<IPortfolioTransactionsApiInput, IPortfolioTransactionEntityData>({
        apiInput: {
            count: false,
            ...apiInput,
        },
        api: fetchPortfolioTransactions,
    });
}

export function fetchPortfolioTransactions({
    portfolioId,
    movementTypes,
    movementStatuses,
    transactionTypes,
    transactionStatuses,
    startDate,
    endDate,
    offset,
    limit = MAX_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    count,
    underlyingApiRequestConfig,
}: IPortfolioTransactionsApiInput) {
    return get<TPortfolioTransactionsData, IApiEntityListResponse<IPortfolioTransactionEntityData>>({
        url: ConsoleApiPath.PORTFOLIO_TRANSACTIONS,
        pathParams: {
            portfolioId,
        },
        queryParams: {
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
            ...fetchApiEntityUrlParamBuilder()
                .fieldIn({
                    field: 'status',
                    value: transactionStatuses,
                })
                .fieldIn({
                    field: 'type',
                    value: transactionTypes,
                })
                .fieldIn({
                    field: 'movements.type',
                    value: movementTypes,
                })
                .fieldIn({
                    field: 'movements.status',
                    value: movementStatuses,
                })
                .dateInRange({
                    field: 'primary_movement.datetime',
                    startDate,
                    endDate,
                })
                .build(),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}
