import flipObjectKeyVals from '@snipsonian/core/cjs/object/keyVals/flipObjectKeyVals';
import { EntityType } from './entity.models';

export const ENTITY_TYPE_TO_ULID_PREFIX: { [key in EntityType]: string; } = {
    [EntityType.goal]: 'L',
    [EntityType.horizon]: 'H',
    [EntityType.riskProfile]: 'K',
    [EntityType.policy]: 'Y',
    [EntityType.portfolio]: 'P',
    [EntityType.portfolioGroup]: 'F',
    [EntityType.optimization]: 'O',
    [EntityType.userGroup]: 'G',
    [EntityType.user]: 'U',
};

type TEntityType2EntityUlidRegexMap = { [type in EntityType]: RegExp };

/* generic one, for type-specific ones see ENTITY_TYPE_TO_ULID_REGEX */
export const ENTITY_ID_REGEX = /^[A-Z][0-9A-Z]+$/;

export const ENTITY_TYPE_TO_ULID_REGEX: TEntityType2EntityUlidRegexMap = Object.values(EntityType)
    .reduce(
        (accumulator, type) => {
            const typePrefix = ENTITY_TYPE_TO_ULID_PREFIX[type];

            accumulator[type] = new RegExp(`^${typePrefix}[0-9A-Z]{26}$`);

            return accumulator;
        },
        {} as TEntityType2EntityUlidRegexMap,
    );

export const ENTITY_ULID_PREFIX_TO_TYPE: { [key: string]: EntityType } =
    flipObjectKeyVals(ENTITY_TYPE_TO_ULID_PREFIX);
