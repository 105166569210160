import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { TEntityUlid, TEntity } from '../../../../entities/dist/common/entity.models';
import {
    IApiEntityListResponseWithPageNr,
} from '../consoleApiQuery.models';

export type TInstrument = TEntity<IInstrumentEntityData>;
export type TInstrumentsData = IApiEntityListResponseWithPageNr<IInstrumentEntityData>;

export interface IInstrumentEntityData {
    id: TEntityUlid;
    class: string; // e.g. 'stocks'
    name: string; // e.g. 'iShares MSCI Qatar ETF'
    price: number; // e.g, 15.8637
    type: string; // e.g. 'ETF'
    look_through: TAnyObject; // TODO proper typing
}

export enum InstrumentType {
    etfs = 'etfs',
    stocks = 'stocks',
    tokens = 'tokens',
}

export const ALL_INSTRUMENT_TYPES: InstrumentType[] = Object.values(InstrumentType);

export enum UniverseType {
    Robo = 'ROBO',
    Self = 'SELF',
}

export interface IAdvancedInstrumentFilters {
    name: string;
    partialInstrumentId: string;
}

export interface IInstrumentsMap {
    [instrumentId: string]: IInstrumentEntityData;
}

export interface IInstrumentIdToNameMap {
    [instrumentId: string]: string;
}

export interface IEnhancedPortfolioInstrument {
    name: string;
    pricePer: number;
    units: number; // how many of this instrument
    amount: number; // the total price of this instrument
    percentageWithinHoldings: number; // the percentage of the amount vs. the amount of the whole portfolio
    class: string; // e.g. 'stocks'
    type: string;
    deleted?: boolean; // Will be used only in the Holdings screen
    edited?: boolean;
    added?: boolean;
    missingData?: boolean; /* when no instrument data, like the name, could be fetched (from FDA) */
    missingPrice?: boolean; /* when the price (and so also the total amount) of the instrument is not known */
}
