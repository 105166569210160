import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IPortfolioCreateTransactionsFlag, TPortfolioPatch,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';
import { TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    TEnhancePortfolioHoldingsApiInput,
    IEnhancedPortfolioHoldings,
} from '@console/bff/models/portfolios/portfolioHoldings.models';
import { CASH_CELL_NAME, UPLOAD_HOLDINGS_EXCEL_HEADER_NAMES } from 'config/portfolioMgmt/portfolioHoldings.config';
import { api } from 'api';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { portfolioDetailsEntity, triggerPatchPortfolioDetails } from 'state/entities/portfolioMgmt/portfolioDetails';
import {
    flashErrorFileImportMissingColumns,
    flashErrorFileImportMissingData,
    flashErrorFileImportWrongFormat,
    infoFlashDispatcher,
} from 'state/entities/entitiesFlashDispatcher';
import {
    extractColumnDataOutOfExcelFile,
    IExtractColumnDataOutOfExcelFileProps,
} from 'utils/file/excelReader';

export const portfolioHoldingsEntity = getEntitiesManager().registerEntity<IEnhancedPortfolioHoldings>({
    asyncEntityKey: AsyncEntityKeys.portfolioHoldings,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_HOLDINGS],
});

export function triggerPatchPortfolioHoldings({
    portfolioUpdater,
    shouldCreateTransactions,
}: {
    portfolioUpdater: (currentPortfolio: TPortfolioPatch) => void;
} & IPortfolioCreateTransactionsFlag) {
    return triggerPatchPortfolioDetails(
        portfolioUpdater,
        /* We do not want the default PORTFOLIO_DETAILS_DATA notification to be triggered,
        because the user would loose all changes if a patch call fails */
        { notificationsToTrigger: [], shouldCreateTransactions },
    );
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFetchHoldingsFilter
    extends Pick<TEnhancePortfolioHoldingsApiInput, 'portfolioId'>, IForceStateRefreshFilter {}

export function triggerFetchPortfolioHoldings({
    forceRefresh = false,
    ...apiInput
}: IFetchHoldingsFilter) {
    return portfolioHoldingsEntity.async.fetch<TEnhancePortfolioHoldingsApiInput>({
        api: api.bff.portfolios.enhancePortfolioHoldings,
        apiInputSelector: () => {
            const portfolioDetail = portfolioDetailsEntity.select().data;

            if (!portfolioDetail || (portfolioDetail.id !== apiInput.portfolioId)) {
                throw new Error('Current portfolio detail does not match the input portfolioId!');
            }

            return {
                ...apiInput,
                currency: portfolioDetail.base_currency,
                managerType: portfolioDetail.config.manager,
                portfolioHoldings: portfolioDetail.portfolio,
            };
        },
        refreshMode: () => forceRefresh
            || portfolioHoldingsEntity.select().data.portfolioId !== apiInput.portfolioId,
        resetDataOnTriggerMode: 'always',
    });
}

interface IImportedInstrumentData {
    instrumentId: string; // isin or CASH_CELL_NAME
    units: number;
}

export async function readPortfolioHoldingsFromFile({
    portfolioId,
    ...fileProps
}: {
    portfolioId: TEntityUlid;
} & Pick<IExtractColumnDataOutOfExcelFileProps, 'file' | 'expectedFileFormat'>): Promise<IEnhancedPortfolioHoldings> {
    const {
        isColumnDataMissing, isFileMissing, isFileWrongFormat, columnData,
    } = await extractColumnDataOutOfExcelFile({
        desiredColumnHeaderNames: UPLOAD_HOLDINGS_EXCEL_HEADER_NAMES,
        ...fileProps,
    });

    if (isFileMissing) {
        return null;
    }

    if (isFileWrongFormat) {
        flashErrorFileImportWrongFormat();
        return null;
    }

    if (isColumnDataMissing) {
        flashErrorFileImportMissingColumns(UPLOAD_HOLDINGS_EXCEL_HEADER_NAMES);
        return null;
    }

    const instruments: IImportedInstrumentData[] = columnData
        .filter((row) => isSetString(row[0]))
        .map((row) => ({
            instrumentId: row[0] as string,
            units: row[1] as number,
        }));

    if (instruments.length === 0) {
        flashErrorFileImportMissingData({ error: null });
        return null;
    }

    const newCash: IImportedInstrumentData = instruments.find((instrument) =>
        instrument.instrumentId.toUpperCase() === CASH_CELL_NAME);

    const { currency, cash } = portfolioHoldingsEntity.select().data;

    const enhancedHoldings = await api.bff.portfolios.enhancePortfolioHoldings({
        portfolioId,
        portfolioHoldings: instruments.reduce(
            (accumulator, instrument) => {
                if (instrument.instrumentId.toUpperCase() !== CASH_CELL_NAME) {
                    accumulator[instrument.instrumentId] = instrument.units;
                }
                return accumulator;
            },
            {
                // Decide whether to use current or new cash
                [`$${currency}`]: newCash ? newCash.units : cash.amount,
            },
        ),
        currency,
        managerType: portfolioDetailsEntity.select().data.config.manager,
    }).then(
        /** Mark all instruments as added */
        (holdings) => {
            const markedInstruments = Object.fromEntries(
                Object.entries(holdings.instruments).map(
                    ([isin, instrumentData]) => [isin, { ...instrumentData, added: true }],
                ),
            );

            return {
                ...holdings,
                instruments: markedInstruments,
            };
        },
    );

    /* If the cash value is in the updated holdings, mark it as edited */
    enhancedHoldings.cash.edited = !!newCash;

    infoFlashDispatcher('portfolio_mgmt.portfolios.detail.holdings.import.success')();

    return enhancedHoldings;
}
