import { isRoboPortfolioManagerType } from '@console/core-api/utils/entities/portfolios/portfolioTypeUtils';
import {
    PortfolioManagerType,
    PortfolioStatus,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';
import {
    OptimizationOwnerChoice,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioOptimization.entity.models';

const OPTIMIZATIONS_DATA_LABEL_PREFIX = 'portfolio_mgmt.portfolios.detail.optimizations.data';

export function shouldCheckIfPortfolioCanBeOptimized({
    portfolioManagerType,
    portfolioStatus,
}: {
    portfolioManagerType: PortfolioManagerType;
    portfolioStatus: PortfolioStatus;
}) {
    return isRoboPortfolioManagerType(portfolioManagerType)
        && portfolioStatus === PortfolioStatus.ACTIVE;
}

export function getOptimizationOwnerChoiceLabel(ownerChoice: OptimizationOwnerChoice): string {
    if (!ownerChoice) {
        return `${OPTIMIZATIONS_DATA_LABEL_PREFIX}.owner_choice.no_choice`;
    }
    return `${OPTIMIZATIONS_DATA_LABEL_PREFIX}.owner_choice.${ownerChoice.toLowerCase()}`;
}

export function isPortfolioOptimizationOwnerChoiceApplicable({
    portfolioManagerType,
}: {
    portfolioManagerType: PortfolioManagerType;
}) {
    return portfolioManagerType === PortfolioManagerType.ROBO_ADVISOR_ADVISORY;
}
