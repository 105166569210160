import { ITraceableApiErrorBase } from '@snipsonian/core/cjs/typings/apiErrors';
import { IRequestWrapperConfig } from '@snipsonian/axios/cjs/request/getRequestWrapper';
import { IBodyRequestConfig, IGetRequestConfig } from '@snipsonian/axios/cjs/request/types';
import {
    IOnApiWarningProps,
    IRequestRequiresAuthenticationConfig,
} from '@typsy/rest-client/dist/models/restClient.models';
import {
    IOrigConsoleApiErrorBody,
    TConsoleApiErrorClientSide,
} from '@typsy/console-api-client/dist/models/consoleApiError.models';
import {
    initConsoleApiRequestWrapper,
    getConsoleApiRequestWrapper,
} from '@typsy/console-api-client/dist/client/consoleApiRequestWrapper';
import {
    combineApiErrorEnhancers,
    enhanceApiEntityDeleteError,
    enhanceApiEntityNotFoundError,
    enhanceApiEntityUniqueExternalIdError,
    enhanceApiResourcesNotReadyError,
} from '../typsy/console-api-client/dist/utils/error/clientSideConsoleApiErrorUtils';
import { DEFAULT_TIMEOUT_IN_MILLIS } from '../typsy/console-api-client/dist/config/consoleApi.config';

// TODO use TInitConsoleApiRequestWrapperProps
interface ICoreApiRequestWrapperConfig
    // eslint-disable-next-line max-len
    extends Pick<IRequestWrapperConfig<IRequestRequiresAuthenticationConfig, TConsoleApiErrorClientSide, ITraceableApiErrorBase<IOrigConsoleApiErrorBody>>,
    'apiLogger' | 'defaultBaseUrl' | 'onError' | 'requestCustomTransformer'> {
    onApiWarning: TOnApiWarning;
}

type TOnApiWarning = (props: IOnApiWarningProps) => void;

let onApiWarning: TOnApiWarning = null;

export function initCoreApiRequestWrapper({
    apiLogger,
    defaultBaseUrl,
    onError,
    requestCustomTransformer,
    onApiWarning: newOnApiWarning,
}: ICoreApiRequestWrapperConfig) {
    onApiWarning = newOnApiWarning;

    initConsoleApiRequestWrapper({
        consoleApiBaseUrl: defaultBaseUrl,
        onApiWarning: newOnApiWarning,
        apiLogger,
        defaultTimeoutInMillis: DEFAULT_TIMEOUT_IN_MILLIS,
        onError,
        requestCustomTransformer,
    });
}

export function notifyApiWarning(warningCode: string) {
    onApiWarning({ warningCode });
}

export function get<Result, ResponseData = Result>(
    config: IGetRequestConfig<Result, ResponseData, TConsoleApiErrorClientSide> & IRequestRequiresAuthenticationConfig,
): Promise<Result> {
    return getConsoleApiRequestWrapper().get({
        ...config,
        enhanceError: combineApiErrorEnhancers(
            config.enhanceError,
            enhanceApiEntityNotFoundError,
            enhanceApiResourcesNotReadyError,
        ),
    });
}

export function post<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, TConsoleApiErrorClientSide> & IRequestRequiresAuthenticationConfig,
): Promise<Result> {
    return getConsoleApiRequestWrapper().post({
        ...config,
        enhanceError: combineApiErrorEnhancers(
            enhanceApiResourcesNotReadyError,
            enhanceApiEntityUniqueExternalIdError,
            config.enhanceError,
        ),
    });
}

export function put<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, TConsoleApiErrorClientSide> & IRequestRequiresAuthenticationConfig,
): Promise<Result> {
    return getConsoleApiRequestWrapper().put(config);
}

export function patch<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, TConsoleApiErrorClientSide> & IRequestRequiresAuthenticationConfig,
): Promise<Result> {
    return getConsoleApiRequestWrapper().patch({
        ...config,
        enhanceError: combineApiErrorEnhancers(
            enhanceApiResourcesNotReadyError,
            enhanceApiEntityUniqueExternalIdError,
            config.enhanceError,
        ),
    });
}

export function remove<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, TConsoleApiErrorClientSide> & IRequestRequiresAuthenticationConfig,
): Promise<Result> {
    return getConsoleApiRequestWrapper().remove({
        ...config,
        enhanceError: combineApiErrorEnhancers(
            enhanceApiResourcesNotReadyError,
            enhanceApiEntityDeleteError,
            config.enhanceError,
        ),
    });
}
