import React from 'react';
import { RANGE_FROM_0_TO_1 } from '@console/core-api/typsy/console-api-client/dist/config/range.config';
import {
    IPolicySettingRuleBoundedNumberRangeField,
    IPolicySettingsRules,
} from 'models/ui/policySettings.ui.models';
import { SIZES } from 'config/styling/sizes';
import { NO_DATA_CHARACTER_DO_NOT_TRANSLATE } from 'config/styling/typography';
import { isCannotDifferRule } from 'utils/entities/portfolioMgmt/policySettingsUtils';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputSlider, { IExtendedInputSliderProps } from 'views/common/inputs/extended/ExtendedInputSlider';
import ExtendedInputNumber from 'views/common/inputs/extended/ExtendedInputNumber';
import InputGroup from 'views/common/inputs/base/InputGroup';
import ContentTitle from 'views/common/layout/ContentTitle';
import { IPolicySettingsFormValues } from './types';

export function renderPolicyCashAmountFormContent(
    {
        fields,
        // eslint-disable-next-line max-len
    }: IExtendedInputFormContext<Pick<IPolicySettingsFormValues, 'minCashAmount' | 'maxCashAmount' | 'baseCurrency'>>,
    {
        smallerSize,
        policySettingsRules,
        shouldShowLabelInBold = true,
    }: {
        smallerSize: boolean;
        policySettingsRules: IPolicySettingsRules;
        shouldShowLabelInBold?: boolean;
    },
) {
    const isFieldReadOnly = isCannotDifferRule(
        policySettingsRules.portfolioConstraints.cashAmountRange.rule,
    );

    return (
        <InputGroup>
            <ExtendedInputNumber
                formField={fields.minCashAmount}
                inputProps={{
                    adornment: fields.baseCurrency.value as string,
                }}
                wrapper={{
                    label: {
                        msg: 'fields.min_cash_amount.label',
                    },
                    tooltip: 'fields.min_cash_amount.tooltip',
                    shouldShowLabelInBold,
                    minHeight: smallerSize ? SIZES.INPUT.HEIGHT.S : SIZES.INPUT.HEIGHT.M,
                }}
                shouldAllowNumbersBelow0={false}
                readOnly={isFieldReadOnly}
            />
            <ExtendedInputNumber
                formField={fields.maxCashAmount}
                inputProps={{
                    adornment: fields.baseCurrency.value as string,
                }}
                wrapper={{
                    label: {
                        msg: 'fields.max_cash_amount.label',
                    },
                    minHeight: smallerSize ? SIZES.INPUT.HEIGHT.S : SIZES.INPUT.HEIGHT.M,
                    shouldShowLabelInBold,
                    tooltip: 'fields.max_cash_amount.tooltip',
                }}
                shouldAllowNumbersBelow0={false}
                readOnly={isFieldReadOnly}
            />
        </InputGroup>
    );
}

export function renderPolicyPortfolioConstraintsFormContent(
    context: IExtendedInputFormContext<IPolicySettingsFormValues>,
    initialValues: IPolicySettingsFormValues,
    policySettingsRules: IPolicySettingsRules,
) {
    const { fields } = context;
    const portfolioConstraintsRules = policySettingsRules.portfolioConstraints;

    return (
        <>
            <ContentTitle
                label="portfolio_mgmt.policies.detail.fields.momentum.label"
                variant="field-group"
                infoTooltip={{
                    name: 'momentum_tooltip',
                    simpleContent: {
                        info: `${context.labelPrefix}.fields.momentum.tooltip`,
                    },
                }}
            />
            <InputGroup>
                <ExtendedInputNumber
                    formField={fields.minMomentum}
                    type="percentage"
                    readOnly={isCannotDifferRule(portfolioConstraintsRules.momentum.rule)}
                    noDataLabel={NO_DATA_CHARACTER_DO_NOT_TRANSLATE}
                    shouldPrefixNoDataLabel={false}
                    nrOfDecimals={2}
                    wrapper={{
                        label: 'fields.momentum.min.label',
                        shouldShowLabelInBold: false,
                        minHeight: SIZES.INPUT.HEIGHT.S,
                    }}
                    shouldAllowNumbersBelow0={false}
                    shouldAllowNumbersAbove1={false}
                />
                <ExtendedInputNumber
                    formField={fields.maxMomentum}
                    type="percentage"
                    readOnly={isCannotDifferRule(portfolioConstraintsRules.momentum.rule)}
                    noDataLabel={NO_DATA_CHARACTER_DO_NOT_TRANSLATE}
                    shouldPrefixNoDataLabel={false}
                    nrOfDecimals={2}
                    wrapper={{
                        label: 'fields.momentum.max.label',
                        shouldShowLabelInBold: false,
                        minHeight: SIZES.INPUT.HEIGHT.S,
                    }}
                    shouldAllowNumbersBelow0={false}
                    shouldAllowNumbersAbove1={false}
                />
            </InputGroup>
            <ContentTitle
                label="portfolio_mgmt.policies.detail.fields.position_boundaries.label"
                variant="field-group"
            />
            <InputGroup>
                <ExtendedInputNumber
                    formField={fields.minPositionAmount}
                    inputProps={{
                        adornment: fields.baseCurrency.value as string,
                    }}
                    readOnly={isCannotDifferRule(portfolioConstraintsRules.positionAmountRange.rule)}
                    wrapper={{
                        label: 'fields.position_amount.min.label',
                        tooltip: 'fields.position_amount.min.tooltip',
                        shouldShowLabelInBold: false,
                        minHeight: SIZES.INPUT.HEIGHT.S,
                    }}
                    shouldAllowNumbersBelow0={false}
                />
                <ExtendedInputNumber
                    formField={fields.maxPositionAmount}
                    inputProps={{
                        adornment: fields.baseCurrency.value as string,
                    }}
                    readOnly={isCannotDifferRule(portfolioConstraintsRules.positionAmountRange.rule)}
                    wrapper={{
                        label: 'fields.position_amount.max.label',
                        tooltip: 'fields.position_amount.max.tooltip',
                        shouldShowLabelInBold: false,
                        minHeight: SIZES.INPUT.HEIGHT.S,
                    }}
                    shouldAllowNumbersBelow0={false}
                />
            </InputGroup>
            <PortfolioConstraintSlider
                formField={fields.positionFraction}
                initialValue={initialValues.positionFraction}
                settingRule={portfolioConstraintsRules.positionFractionRange}
                wrapper={{
                    label: 'fields.position_fraction.label',
                    tooltip: 'fields.position_fraction.tooltip',
                    shouldShowLabelInBold: false,
                }}
            />
            <ContentTitle
                label="portfolio_mgmt.policies.detail.fields.cash_boundaries.label"
                variant="field-group"
            />
            {renderPolicyCashAmountFormContent(context, {
                smallerSize: true,
                policySettingsRules,
                shouldShowLabelInBold: false,
            })}
            <PortfolioConstraintSlider
                formField={fields.cashFraction}
                initialValue={initialValues.cashFraction}
                settingRule={portfolioConstraintsRules.cashFractionRange}
                wrapper={{
                    label: 'fields.cash_fraction.label',
                    tooltip: 'fields.cash_fraction.tooltip',
                    shouldShowLabelInBold: false,
                }}
            />
        </>
    );
}

function PortfolioConstraintSlider({
    settingRule,
    ...otherProps
}: Pick<IExtendedInputSliderProps, 'formField' | 'initialValue' | 'wrapper'> & {
    settingRule: IPolicySettingRuleBoundedNumberRangeField;
}) {
    return (
        <ExtendedInputSlider
            {...otherProps}
            size="S"
            readOnly={isCannotDifferRule(settingRule.rule)}
            range={settingRule.range}
            absoluteRange={RANGE_FROM_0_TO_1}
        />
    );
}
