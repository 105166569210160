import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import { get } from '../coreApiRequestWrapper';
import {
    IPortfolioOptimization,
    IPortfolioOptimizationEntityData,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/portfolioOptimization.entity.models';
import { TEntity, TEntityUlid } from '../../typsy/entities/dist/common/entity.models';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';

export interface IFetchOptimizationApiParams {
    optimizationId: TEntityUlid;
}

export function fetchOptimizationDetails({
    optimizationId,
    underlyingApiRequestConfig,
}: IFetchOptimizationApiParams & IUnderlyingRequestApiInput) {
    return get<IPortfolioOptimization, TEntity<IPortfolioOptimizationEntityData>>({
        url: ConsoleApiPath.OPTIMIZATION_DETAILS,
        pathParams: {
            optimizationId,
        },
        mapResponse: ({ data, status }) => ({
            apiStatus: status,
            optimization: data,
        }),
        ...underlyingApiRequestConfig,
    });
}
