import React, { useState } from 'react';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { object } from '@console/common/utils/schema';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { UniverseType } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/instrument.entity.models';
import {
    PolicyAlgorithmType,
    TEnhancedPolicyComposition,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { isPolicyOfTypeMetaPortfolio, isPolicyOfTypeModelPortfolio } from '@console/bff/utils/policies/policyTypeUtils';
import { getPoliciesConfig } from 'state/appConfig/selectors';
import { DEFAULT_POLICY_META_COMPOSITION_TAB_KEY, POLICY_META_COMPOSITION_TAB_KEY } from 'config/tabs.config';
import { triggerFetchPoliciesForSearch } from 'state/entities/portfolioMgmt/policies';
import { triggerFetchPortfoliosForSelect } from 'state/entities/portfolioMgmt/portfolios';
import { triggerFetchInstruments } from 'state/entities/portfolioMgmt/instruments';
import TextButton from 'views/common/buttons/TextButton';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import Modal from 'views/common/layout/Modal';
import AddPolicyMetaPortfolioComposition from './AddMetaPortfolioCompositionElement';
import AddPolicyModelPortfolioComposition from './AddModelPortfolioCompositionElement';
import {
    IAddCompositionElementFormValues,
    IAddCompositionElementProps,
    ISelectedCompositionElementState,
} from './types';

interface IAddPolicyCompositionProps {
    policyAlgorithmType: PolicyAlgorithmType;
    currency: SupportedCurrency;
    policyComposition: TEnhancedPolicyComposition;
    onCompositionElementAdded: (props: IAddCompositionElementProps) => void;
    compositionLabelPrefix: string;
}

const INITIAL_ADD_COMPOSITION_ELEMENT_VALUES: IAddCompositionElementFormValues = {
    elementState: {},
};

const ADD_COMPOSITION_ELEMENT_SCHEMA = object({
    elementState: object(),
});

export default function AddPolicyCompositionElement({
    policyAlgorithmType,
    currency,
    onCompositionElementAdded,
    compositionLabelPrefix,
    policyComposition,
}: IAddPolicyCompositionProps) {
    const [isAddCompositionElementsModalOpen, setIsAddCompositionElementsModalOpen] = useState(false);
    const { allowPortfoliosInMetaPolicyComposition } = getPoliciesConfig();

    return (
        <>
            <Modal
                id="add_composition_elements_modal"
                title={`${compositionLabelPrefix}.${policyAlgorithmType.toLowerCase()}.modal_title`}
                maxWidth="lg"
                open={isAddCompositionElementsModalOpen}
                onClose={closeAddCompositionElementsModal}
            >
                <ExtendedInputForm<IAddCompositionElementFormValues>
                    name={ExtendedInputFormName.policyAddComposition}
                    initialValues={INITIAL_ADD_COMPOSITION_ELEMENT_VALUES}
                    schema={ADD_COMPOSITION_ELEMENT_SCHEMA}
                    submit={{
                        actionLabel: 'common.action.add',
                        onSubmit: onSubmitAddCompositionElement,
                    }}
                    cancel={{
                        onCancel: closeAddCompositionElementsModal,
                    }}
                    renderFormFields={renderPolicyAddCompositionElementFormContents}
                />
            </Modal>
            <TextButton
                id="add_composition_element_button"
                label={getAddCompositionElementButtonLabel()}
                variant="bare"
                fontWeight="initial"
                fontSize="MD"
                onClick={openAddCompositionElementsModal}
            />
        </>
    );

    function renderPolicyAddCompositionElementFormContents({
        fields,
        setFieldValue,
    }: IExtendedInputFormContext<IAddCompositionElementFormValues>) {
        const selectedElementState = fields.elementState.value as ISelectedCompositionElementState;

        if (isPolicyOfTypeModelPortfolio(policyAlgorithmType)) {
            return (
                <AddPolicyModelPortfolioComposition
                    currency={currency}
                    selectedElementState={selectedElementState}
                    setSelectedElementState={setSelectedElementState}
                    currentComposition={policyComposition}
                />
            );
        }

        return (
            <AddPolicyMetaPortfolioComposition
                selectedElementState={selectedElementState}
                setSelectedElementState={setSelectedElementState}
                currentComposition={policyComposition}
                triggerListEntityFetchBasedOnTab={triggerListEntityFetchBasedOnTab}
            />
        );

        function setSelectedElementState(elementState: TObjectWithProps) {
            setFieldValue({
                fieldName: fields.elementState.fieldName,
                value: elementState,
            });
        }
    }

    function triggerListEntityFetchBasedOnTab(tabKey: string) {
        switch (tabKey) {
            case POLICY_META_COMPOSITION_TAB_KEY.RISK_BASED_POLICIES: {
                triggerFetchPoliciesForSearch({ algorithmType: PolicyAlgorithmType.RISK_BASED });
                break;
            }
            case POLICY_META_COMPOSITION_TAB_KEY.MODEL_PORTFOLIO_POLICIES: {
                triggerFetchPoliciesForSearch({ algorithmType: PolicyAlgorithmType.MODEL_PORTFOLIO });
                break;
            }
            case POLICY_META_COMPOSITION_TAB_KEY.PORTFOLIOS: {
                triggerFetchPortfoliosForSelect();
                break;
            }
            default: throw new Error(`Unknown policy meta composition tab key: ${tabKey}`);
        }

        return Promise.resolve();
    }

    function onSubmitAddCompositionElement({
        values,
    }: IOnSubmitProps<IAddCompositionElementFormValues>) {
        const newCompositionItem = Object.entries(values.elementState)[0];
        onCompositionElementAdded({
            compositionElementId: newCompositionItem[0],
            compositionElementName: newCompositionItem[1],
        });
        closeAddCompositionElementsModal();
        return Promise.resolve();
    }

    function getAddCompositionElementButtonLabel() {
        if (isPolicyOfTypeModelPortfolio(policyAlgorithmType)) {
            return `${compositionLabelPrefix}.${policyAlgorithmType.toLowerCase()}.button_text`;
        }

        return `${compositionLabelPrefix}.${policyAlgorithmType.toLowerCase()}.button_text.${
            allowPortfoliosInMetaPolicyComposition ? 'policies_and_portfolios' : 'policies'
        }`;
    }

    function openAddCompositionElementsModal() {
        if (isPolicyOfTypeMetaPortfolio(policyAlgorithmType)) {
            triggerListEntityFetchBasedOnTab(DEFAULT_POLICY_META_COMPOSITION_TAB_KEY);
        }
        if (isPolicyOfTypeModelPortfolio(policyAlgorithmType)) {
            /* Policies are only used for ROBO */
            triggerFetchInstruments({ currency, universe_type: UniverseType.Robo });
        }
        setIsAddCompositionElementsModalOpen(true);
    }

    function closeAddCompositionElementsModal() {
        setIsAddCompositionElementsModalOpen(false);
    }
}
