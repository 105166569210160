import { IS_BROWSER } from '@snipsonian/core/cjs/is/isBrowser';
import { getCurrentTimestamp } from '@snipsonian/core/cjs/date/currentDate';
import { ONE_MINUTE_IN_MILLIS, ONE_HOUR_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';
import { getLogger } from '@typsy/log/dist/logger/loggerManager';
import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import { TEntityUlid } from '../../typsy/entities/dist/common/entity.models';
import {
    IInstrumentsMap,
    IInstrumentIdToNameMap,
    IInstrumentEntityData,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/instrument.entity.models';
import { fetchAllSpecificInstruments, IFetchSpecificInstrumentsApiInput } from './instruments.api';
import { notifyApiWarning } from '../coreApiRequestWrapper';

type TGetInstrumentsFromCacheFilter =
    Pick<IFetchSpecificInstrumentsApiInput, 'instrumentIds' | 'currency' | 'universe_type'>
    & IUnderlyingRequestApiInput;

interface IInstrumentsPerCurrencyAndUniverseTypeMap {
    [currency: string]: {
        [universeType: string]: IExpirableInstrumentsMap;
    };
}

interface IExpirableInstrumentsMap {
    [instrumentId: string]: TCachedInstrument;
}

type TCachedInstrument = IInstrumentEntityData & {
    cacheTimestamp: number;
};

const INSTRUMENT_EXPIRY_IN_MINS = 10;

const instrumentsCache: IInstrumentsPerCurrencyAndUniverseTypeMap = {};

if (process.env.NODE_ENV !== 'test') {
    setInterval(removeExpiredInstrumentsFromCache, 8 * ONE_HOUR_IN_MILLIS);
}

export async function getInstrumentNamesFromCache({
    instrumentIds,
    underlyingApiRequestConfig,
    universe_type,
    currency,
}: TGetInstrumentsFromCacheFilter): Promise<IInstrumentIdToNameMap> {
    const wholeInstrumentMap = await getInstrumentsFromCache({
        instrumentIds,
        currency,
        universe_type,
        underlyingApiRequestConfig,
    });

    return Object.values(wholeInstrumentMap).reduce(
        (accumulator, instrument) => {
            accumulator[instrument.id] = instrument.name;
            return accumulator;
        },
        {} as IInstrumentIdToNameMap,
    );
}

export async function getInstrumentsFromCache({
    instrumentIds,
    currency,
    universe_type,
    underlyingApiRequestConfig,
}: TGetInstrumentsFromCacheFilter): Promise<IInstrumentsMap> {
    const alreadyCachedCheck = checkAlreadyCachedInstruments({ instrumentIds, currency, universe_type });

    if (alreadyCachedCheck.uncachedInstrumentIds.length === 0) {
        return alreadyCachedCheck.cached;
    }

    const newlyFetchedList = await fetchAllSpecificInstruments({
        instrumentIds: alreadyCachedCheck.uncachedInstrumentIds,
        currency,
        universe_type,
        failOnApiError: false,
        underlyingApiRequestConfig,
    });

    if (newlyFetchedList.results.length < alreadyCachedCheck.uncachedInstrumentIds.length) {
        notifyApiWarning('error.instruments.some_could_not_be_fetched');
    }

    if (!instrumentsCache[currency]) {
        instrumentsCache[currency] = {};
    }
    if (!instrumentsCache[currency][universe_type]) {
        instrumentsCache[currency][universe_type] = {};
    }

    const newlyFetchedMap = newlyFetchedList.results.reduce(
        (accumulator, instrument) => {
            instrumentsCache[currency][universe_type][instrument.id] = {
                ...instrument,
                cacheTimestamp: getCurrentTimestamp(),
            };

            accumulator[instrument.id] = instrument;

            return accumulator;
        },
        {} as IInstrumentsMap,
    );

    return {
        ...alreadyCachedCheck.cached,
        ...newlyFetchedMap,
    };
}

function checkAlreadyCachedInstruments({
    instrumentIds,
    currency,
    universe_type,
}: TGetInstrumentsFromCacheFilter): { cached: IInstrumentsMap; uncachedInstrumentIds: TEntityUlid[] } {
    if (!instrumentsCache[currency] || !instrumentsCache[currency][universe_type]) {
        return {
            cached: {},
            uncachedInstrumentIds: instrumentIds,
        };
    }

    const expiryTimestamp = getExpiryTimestamp();
    const uncachedInstrumentIds: TEntityUlid[] = [];

    const cached = instrumentIds.reduce(
        (accumulator, instrumentId) => {
            const cachedInstrument = instrumentsCache[currency][universe_type][instrumentId];

            if (cachedInstrument && wasInstrumentCachedStillWithinExpiryPeriod({
                cachedInstrument,
                expiryTimestamp,
            })) {
                accumulator[instrumentId] = cachedInstrument;
            } else {
                uncachedInstrumentIds.push(instrumentId);
            }
            return accumulator;
        },
        {} as IInstrumentsMap,
    );

    return {
        cached,
        uncachedInstrumentIds,
    };
}

function getExpiryTimestamp() {
    return getCurrentTimestamp() - (INSTRUMENT_EXPIRY_IN_MINS * ONE_MINUTE_IN_MILLIS);
}

function wasInstrumentCachedStillWithinExpiryPeriod({
    cachedInstrument,
    expiryTimestamp,
}: {
    cachedInstrument: TCachedInstrument;
    expiryTimestamp: number;
}): boolean {
    if (!cachedInstrument || !cachedInstrument.cacheTimestamp) {
        return false;
    }

    if (cachedInstrument.cacheTimestamp < expiryTimestamp) {
        return false;
    }

    return true;
}

/**
 * To prevent the cache growing too large over time.
 * Important: this clearing of the cache (within an interval) can both happen within the browser
 * as from a nodejs context!
 */
function removeExpiredInstrumentsFromCache() {
    const runningOnServer = !IS_BROWSER;

    try {
        const expiryTimestamp = getExpiryTimestamp();

        const removedCount = Object.values(instrumentsCache).reduce(
            (accumulator, perCurrencyCache) => {
                Object.values(perCurrencyCache).forEach((expirableInstrumentsMap) => {
                    Object.entries(expirableInstrumentsMap).forEach(([instrumentId, cachedInstrument]) => {
                        if (!wasInstrumentCachedStillWithinExpiryPeriod({
                            cachedInstrument,
                            expiryTimestamp,
                        })) {
                            // eslint-disable-next-line no-param-reassign
                            delete expirableInstrumentsMap[instrumentId];

                            // eslint-disable-next-line no-param-reassign
                            accumulator += 1;
                        }
                    });
                });
                return accumulator;
            },
            0,
        );

        if (runningOnServer) {
            getLogger().info(`+++++ Cleanup instrumentsCache: removed ${removedCount} instruments +++++`);
        }
    } catch (error) {
        console.log('+++++ Cleanup instrumentsCache: ERROR +++++', error);
    }
}
