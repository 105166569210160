import { api } from 'api';
import produce from 'immer';
import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { hasFetchSucceeded } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getOnlyChangedProperties } from '@console/common/utils/object/getOnlyChangedProperties';
import { EntityType, IBaseIdentifiedEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    ISinglePortfolioGroupApiInput,
    TPortfolioGroup,
    TPortfolioGroupCreate,
    TPortfolioGroupPatch,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioGroup.entity.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { getCurrentRouteParam } from 'state/ui/selectors';
import { PORTFOLIO_GROUP_DETAILS_TAB_KEY } from 'config/tabs.config';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { getStore } from 'state';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import {
    flashErrorApiEntityCreate,
    flashErrorApiEntityPatch,
    flashSuccessApiEntityPatch,
} from '../entitiesFlashDispatcher';
import { getEntitiesManager } from '../entitiesManager';
import { triggerResetPortfolioGroupFetch } from './portfolioGroups';
import { triggerFetchPortfolioGroupPortfolios } from './portfolioGroupPortfolios';
import {
    apiDetailsEntityAsyncFetchAction,
    apiEntityAsyncDeleteAction,
} from '../genericApiEntity/apiEntityAsyncActions';

export const portfolioGroupDetailsEntity = getEntitiesManager().registerEntity<TPortfolioGroup>({
    asyncEntityKey: AsyncEntityKeys.portfolioGroupDetails,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_GROUP_DETAILS_DATA],
    includeUpdaters: true,
});

export function triggerFetchPortfolioGroupDetails() {
    if (!validateEntityIdBeforeFetch({
        entityId: getCurrentRouteParam(getStore().getState(), 'portfolioGroupId'),
        entityType: EntityType.portfolioGroup,
    })) {
        return null;
    }

    return apiDetailsEntityAsyncFetchAction<TPortfolioGroup, ISinglePortfolioGroupApiInput>({
        detailsEntity: portfolioGroupDetailsEntity,
        entityType: EntityType.portfolioGroup,
        api: api.portfolioGroups.fetchPortfolioGroupDetails,
        apiInputSelector: ({ state }) => ({
            portfolioGroupId: getCurrentRouteParam(state, 'portfolioGroupId'),
        }),
        refreshMode: ({ state }) =>
            portfolioGroupDetailsEntity.select().data.id !== getCurrentRouteParam(state, 'portfolioGroupId'),
        resetDataOnTriggerMode: 'always',
        onPreSuccess: ({ apiResult }) => {
            triggerFetchPortfolioGroupPortfolios({ ids: apiResult.portfolio_ids });
        },
    });
}

export const getPortfolioGroupTitle = () => {
    const portfolioGroupDetails = portfolioGroupDetailsEntity.select();
    if (hasFetchSucceeded(portfolioGroupDetails)) {
        return portfolioGroupDetails.data.name;
    }

    return 'portfolio_mgmt.portfolio_groups.detail.title';
};

export function triggerCreatePortfolioGroup(portfolioGroup: TPortfolioGroupCreate) {
    return portfolioGroupDetailsEntity.async.create<TPortfolioGroupCreate, TPortfolioGroup>({
        api: api.portfolioGroups.createPortfolioGroup,
        apiInputSelector: () => portfolioGroup,
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetPortfolioGroupFetch();
        },
        onSuccess: ({ apiResult }) => {
            redirectTo({
                routeKey: ROUTE_KEY.R_PORTFOLIO_GROUP_DETAIL,
                params: {
                    portfolioGroupId: apiResult.id,
                    portfolioGroupTab: PORTFOLIO_GROUP_DETAILS_TAB_KEY.PORTFOLIOS,
                },
            });
        },
        onError: flashErrorApiEntityCreate,
    });
}

export function triggerDeletePortfolioGroup(portfolioGroupIdentifier?: IBaseIdentifiedEntity) {
    return apiEntityAsyncDeleteAction<TPortfolioGroup>({
        detailsEntity: portfolioGroupDetailsEntity,
        api: api.portfolioGroups.deletePortfolioGroup,
        apiInputSelector: () => portfolioGroupIdentifier || {
            id: portfolioGroupDetailsEntity.select().data.id,
        },
        onPreSuccess: () => {
            triggerResetPortfolioGroupFetch();
            redirectTo({ routeKey: ROUTE_KEY.R_PORTFOLIO_GROUPS_LIST });
        },
    });
}

export function triggerPatchPortfolioGroupDetails(
    portfolioGroupUpdater: (currentPortfolioGroup: TPortfolioGroupPatch) => void, {
        onPreSuccess,
        notificationsToTrigger,
    }: {
        onPreSuccess?: (props: { state: IState }) => void;
        notificationsToTrigger?: StateChangeNotification[];
    } = {},
) {
    return portfolioGroupDetailsEntity.async.update<TPortfolioGroupPatch, TPortfolioGroup>({
        api: api.portfolioGroups.patchPortfolioGroup,
        apiInputSelector: () => ({
            ...getOnlyChangedProperties(
                portfolioGroupDetailsEntity.select().data,
                produce(portfolioGroupDetailsEntity.select().data, portfolioGroupUpdater),
            ),
            id: portfolioGroupDetailsEntity.select().data.id,
        }),
        updateDataOnSuccess: true,
        onPreSuccess: ({ state, apiResult }) => {
            triggerResetPortfolioGroupFetch();
            triggerFetchPortfolioGroupPortfolios({ ids: apiResult.portfolio_ids });

            if (onPreSuccess) {
                onPreSuccess({ state });
            }
        },
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
        notificationsToTrigger,
    });
}
