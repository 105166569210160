export interface IQueryEntityBaseResponse<Entity> {
    results: Entity[];
}

export interface IQueryEntityPaginationRequest {
    offset?: number;
    limit?: number;
    addCount?: boolean; /* default false */
}

export interface IQueryEntityPaginationResponse<Entity> extends IQueryEntityBaseResponse<Entity> {
    count?: number;
    offset: number | null; /* = the 'next' offset to get the 'next' page - will be null when there is no 'next page' */
}

export type TQueryEntityPaginationResponseNoCount<Entity> = Omit<IQueryEntityPaginationResponse<Entity>, 'count'>;

export interface IQueryEntityOrderByRequest {
    orderBy?: IOrderByField;
}

export interface IOrderByField {
    field: string;
    order?: SortOrder; // default 'ascending'
}

export enum SortOrder {
    Ascending = 'ascending',
    Descending = 'descending',
}

/**
 * This can be added to e.g. api input extending from IApiEntityListRequest
 * to be able to dynamically alter for which fields you want embedding.
 * Another option is to just always add the embed param in the api proxy function like in "fetchPortfolios".
 */
export interface IQueryEntityEmbedRequest<EmbeddableField extends string = string> {
    /**
     * An optional list of field names of the response model. For each field, the related entity will be embedded
     * in the response.
     *
     * e.g. "embed: [owned_by_user_id]" when fetching a portfolio (or portfolio list), will result that the related
     *      user entity (of that user id) will be added in the '_embedded' field at the root level
     *      of the response object.
     *
     * Some remarks:
     * - multiple embed values will be comma-separated automatically
     * - nested response fields can also be specified by using "." as field separator
     */
    embed?: EmbeddableField | EmbeddableField[];
}

/**
 * This can be added both to an IBaseEntity (when a single entity detail is fetched)
 * or to a IApiEntityListResponse/IApiEntityPaginatedListResponse (when a list of entities is fetched)
 */
export interface IQueryEntityEmbedResponse<Embedded = never> {
    _embedded?: IEmbeddedMap<Embedded>;
}

export interface IEmbeddedMap<Embedded = never> {
    [embeddedId: string]: Embedded;
}
