import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IBaseEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { TUser } from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/user.entity.models';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { findSpecificUser } from 'state/entities/userMgmt/users';
import { getCurrentRouteLocation } from 'state/ui/selectors';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import replacePathPlaceholders from 'utils/routing/replacePathPlaceholders';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import { makeStyles, mixins } from 'views/styling';
import { IObserveProps, observe } from 'views/observe';
import TextButton from 'views/common/buttons/TextButton';
import { redirectToPath } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';

const TRANSLATION_PREFIX = 'common.generic_api_entity.versions.active';

export interface IActiveApiEntityVersionProps {
    apiEntitySelector: TApiEntityVersionSelector;
}

type TApiEntityVersionSelector = (state: IState) => IBaseEntity;

const useStyles = makeStyles((theme) => ({
    ActiveApiEntityVersion: {
        ...mixins.flexRowCenterLeft(),
        ...mixins.typo({ size: 14 }),

        '& .__separator': {
            padding: theme.spacing(0, 1),
        },
        '& .__toAllVersions': {
            paddingLeft: theme.spacing(2),
        },
    },
}));

function ActiveApiEntityVersion({
    apiEntitySelector,
    state,
}: IActiveApiEntityVersionProps & IObserveProps) {
    const classes = useStyles();
    const currentRoute = getCurrentRouteLocation(state);

    const baseApiEntity = apiEntitySelector(state);
    const userId = baseApiEntity.version_authored_by_user_id;

    const [userDetails] = useAsyncFetchOnMount<TUser>({
        fetcher: () => {
            /* see if we have the user already in the user list */
            const userOfList = findSpecificUser({ id: userId });

            if (userOfList) {
                return Promise.resolve(userOfList);
            }

            /* fetch the details of the user that made this version */
            return apiCacheManager.fetchUser({ userId });
        },
    });

    const Separator = <span className="__separator">-</span>;

    const byLabel = baseApiEntity.version === 1
        ? `${TRANSLATION_PREFIX}.created_by`
        : `${TRANSLATION_PREFIX}.updated_by`;

    return (
        <div className={classes.ActiveApiEntityVersion}>
            <div className="__version">
                <Translate
                    msg={`${TRANSLATION_PREFIX}.version`}
                    placeholders={{
                        versionNumber: baseApiEntity.version,
                    }}
                />
            </div>

            {Separator}

            <div className="__when">
                <Translate
                    msg={`${TRANSLATION_PREFIX}.when`}
                    placeholders={{
                        date: formatDateRelativeToNow({
                            date: baseApiEntity.version_datetime,
                            options: {
                                alwaysIncludeTime: true,
                            },
                        }),
                    }}
                />
            </div>

            {Separator}

            <div className="__by">
                <Translate
                    msg={byLabel}
                    placeholders={{
                        user: (userDetails && userDetails.data)
                            ? getUserFullName(userDetails.data)
                            : userId,
                    }}
                />
            </div>

            {(baseApiEntity.version !== 1) && showAllVersionsLinkOnRoute() && (
                <TextButton
                    id="view-all-api-entity-versions"
                    className="__toAllVersions"
                    label={`${TRANSLATION_PREFIX}.view_all_versions`}
                    onClick={goToAllVersions}
                    variant="bare"
                    size="M"
                />
            )}
        </div>
    );

    function showAllVersionsLinkOnRoute() {
        if (currentRoute.routeKey === ROUTE_KEY.R_PORTFOLIO_DETAIL) {
            if (currentRoute.params.portfolioTab === 'optimizations') {
                /**
                 * In the optimizations tab of a portfolio, the child route uses the path
                 * "/:portfolioId/optimizations/:optimizationId"
                 * so we can't go to "/:portfolioId/optimizations/versions" */
                return false;
            }
        }

        return true;
    }

    function goToAllVersions() {
        redirectToPath({
            path: replacePathPlaceholders({
                path: `${currentRoute.path}/versions`,
                placeholders: currentRoute.params,
            }),
        });
    }
}

export function initActiveApiEntityVersion({
    notifications,
}: { notifications: StateChangeNotification[] }) {
    return observe<IActiveApiEntityVersionProps>(
        notifications,
        ActiveApiEntityVersion,
    );
}
