import React from 'react';
import clsx from 'clsx';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import isArray from '@snipsonian/core/cjs/is/isArray';
import { IMultiTranslationsLabel } from '@console/core-api/typsy/console-api-client/dist/models/common/i18n.models';
import { getUserLocalesSortedDefaultFirstMemoized } from 'state/appConfig/selectors';
import { formatLocaleForDisplay } from 'utils/entities/multiTranslationsLabelUtils';
import { getLocaleTranslationLabel } from 'utils/i18n/localeUtils';
import { makeStyles, mixins } from 'views/styling';
import Tag from 'views/common/widget/Tag';
import Tooltip from 'views/common/widget/Tooltip';

interface IPublicProps {
    /**
     * This label determines the color of the locale tags:
     * - green/success if there is a translation for the locale
     * - grey/regular if there is no translation for the locale
     *
     * In case it's an array, it will only be green/success if
     * ALL the labels have a translation for the locale.
     */
    label: IMultiTranslationsLabel | IMultiTranslationsLabel[];
    addRightPaddingIfMultipleLocales?: boolean; // default false
}

const useStyles = makeStyles((theme) => ({
    MultiTranslationsLabelLocaleTags: {
        ...mixins.flexRow(),

        '&.__rightPadding': {
            paddingRight: theme.spacing(2),
        },

        '& .Tag': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function MultiTranslationsLabelLocaleTags({
    label,
    addRightPaddingIfMultipleLocales = false,
}: IPublicProps) {
    const classes = useStyles();

    const localesDefaultFirst = getUserLocalesSortedDefaultFirstMemoized();
    const addRightPadding = addRightPaddingIfMultipleLocales && localesDefaultFirst.length > 1;

    return (
        <div
            className={clsx(
                classes.MultiTranslationsLabelLocaleTags,
                'MultiTranslationsLabelLocaleTags',
                addRightPadding && '__rightPadding',
            )}
        >
            {localesDefaultFirst.map((locale) => {
                const hasTranslationForLocale = isArray<IMultiTranslationsLabel>(label)
                    ? label.every((singleLabel) => isSetString(singleLabel[locale]))
                    : isSetString(label[locale]);

                return (
                    <Tooltip
                        key={`MultiTranslationsLabelLocale_${locale}`}
                        label={getLocaleTranslationLabel(locale)}
                    >
                        <Tag
                            label={{
                                text: formatLocaleForDisplay(locale),
                                shouldTranslate: false,
                            }}
                            variant={hasTranslationForLocale ? 'success' : 'regular'}
                        />
                    </Tooltip>
                );
            })}
        </div>
    );
}
