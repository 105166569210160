import { DEFAULT_USERGROUP_TAB_KEY } from 'config/tabs.config';
import { TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import React from 'react';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import GenericUserGroupsList from './GenericUserGroupsList';

export default function UserGroupsList() {
    return (
        <GenericUserGroupsList
            box={{
                title: 'user_mgmt.user_groups.title',
            }}
            onUserGroupRowClicked={openUserGroupDetails}
            create={{
                toRoute: {
                    routeKey: ROUTE_KEY.R_USER_GROUP_ADD,
                },
                tooltip: 'user_mgmt.user_groups.list.actions.create_user_group',
            }}
        />
    );

    function openUserGroupDetails(groupId: TEntityUlid) {
        redirectTo({
            routeKey: ROUTE_KEY.R_USER_GROUP_DETAIL,
            params: {
                groupId,
                userGroupTab: DEFAULT_USERGROUP_TAB_KEY,
            },
        });
    }
}
