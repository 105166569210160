import { SupportedCurrency } from '@console/common/config/currencies.config';
import { TNumberRange } from '../common/numeric.models';
import {
    TEntityUlid, TEntityDetail, TEntityCreate, TEntityPatch,
} from '../../../../entities/dist/common/entity.models';
import { IApiEntityListResponseWithPageNr } from '../consoleApiQuery.models';
import { IPortfolioHoldings } from './portfolioHoldings.entity.models';

export type TPoliciesData = IApiEntityListResponseWithPageNr<IPolicyEntityData>;
export type TPolicy = TEntityDetail<IPolicyEntityData>;
export type TPolicyPatch = TEntityPatch<IPolicyEntityData>;
export type TPolicyCreate = TEntityCreate<IPolicyAddEntityData>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPolicyAddEntityData
    extends Omit<IPolicyEntityData, 'coerced_config' | 'parent_coerced_config'> {
}

/**
 * Difference between config, coerced_config and parent_coerced_config:
 * - config :
 *     > the actual config of this policy
 * - coerced_config :
 *     > the combo of this policy config with the config of the parent(s)
 *     > If no parent policy, this is the same as the config.
 * - parent_coerced_config :
 *     > the coerced_config of the parent policy (which is a combo of the config of that parent-policy
 *     and the coerced config of its own parent).
 *     > If no parent policy, this is the same as the config.
 */
export interface IPolicyEntityData {
    coerced_config: IPolicyConfig;
    config: IPolicyConfig;
    external_id: string;
    name: string;
    parent_coerced_config: IPolicyConfig;
    parent_policy_id: TEntityUlid;
    risk_profile_id: TEntityUlid;
    tags: PolicyManagerTag[];
}

export interface IPolicyConfig {
    algorithm: CoreApiPolicyAlgorithmType;
    algorithm_settings: IPolicyAlgorithmSettings;
    algorithm_version: number; // e.g. 1
}

export enum CoreApiPolicyAlgorithmType {
    RISK_BASED = 'RISK_BASED',
    MODEL_PORTFOLIO = 'MODEL_PORTFOLIO',
    META_PORTFOLIO = 'META_PORTFOLIO',
    IVAR = 'IVAR',
}

export interface IPolicyAlgorithmSettings {
    execution_settings: IPolicyExecutionSettings;
    portfolio_update_constraints: IPolicyPortfolioUpdateConstraints;
    portfolio_constraints: IPolicyPortfolioConstraints;
    benchmark_constraints: IPolicyBenchmarkConstraints;
    model_portfolio_settings?: IPolicyModelPortfolioSettings;
    meta_portfolio_settings?: IPolicyMetaPortfolioSettings;
}

export interface IPolicyExecutionSettings {
    base_currency: SupportedCurrency; // e.g. 'USD'
    fractional_shares: boolean;
    cost_per_transaction_amount: number; // e.g. 7.5
    cost_per_transaction_fraction: number; // e.g. 0.006999999999999999
    combine_transaction_cost_components: CombineTransactionCostMode; // e.g. 'MAX'
    transaction_amount: TNumberRange; // e.g. [0, null]
    benchmark_id: string;
    // simulate_transaction_cost?: boolean; // default false // TODO
}

export interface IPolicyPortfolioUpdateConstraints {
    min_portfolio_value: number; // e.g. 10000
    min_portfolio_update_optimality_improvement_fraction: number; // e.g. 0.1
    max_portfolio_constraint_violation_pctpoints: number; // e.g. 0.05
    holdings: Pick<IPolicyHoldingsConstraints, 'cash_fraction'>;
}

export interface IPolicyPortfolioConstraints {
    asset_classes: IPolicyAssetClasses;
    regions: IPolicyRegionsConstraints;
    bond_types: IPolicyBondTypes;
    sectors: IPolicySectorConstraints;
    investment_universe: string[]; /* = the "hold" universe e.g. ['US46137V5892', 'IE00BYXYYK40'] */
    /**
     * optional, to limit the instruments that can be bought (vs. the hold universe)
     * In the back-end, the buy universe is
     * - either null (~ means that the buy universe contains the same instruments as the hold universe)
     * - either an empty array (~ means that no instrument can be bought)
     * - either an array containing a (subset) of instruments of the hold universe (~ only those instruments can be
     *   bought) (instruments in the buy universe BUT NOT in the hold universe do not make any sense)
     */
    buy_universe?: string[];
    holdings: IPolicyHoldingsConstraints;
    momentum: TNumberRange;
}

export interface IPolicyBenchmarkConstraints {
    asset_classes: IPolicyAssetClasses;
    regions: IPolicyRegionsConstraints;
    bond_types: IPolicyBondTypes;
    sectors: IPolicySectorConstraints;
    holdings: Pick<IPolicyHoldingsConstraints, 'position_fraction'>;
    relative_ivar_weight?: number;
}

export interface IPolicyModelPortfolioSettings {
    /** E.g. { "US3160928574": 0.5, "US78464A4748": 0.5 } */
    model_portfolio: IModelPortfolioComposition;
    excluded_instrument_ids?: string[];
    /** E.g. { "US78464A4748": "US46138E7435" } */
    model_portfolio_alternatives?: {
        [instrumentId: string]: string;
    };
}

export interface IPolicyMetaPortfolioSettings {
    /** E.g. { "Y01CRE000000000000000000000": 0.5, "P01MEGATREND000000000000000": 0.5 } */
    meta_portfolio: IMetaPortfolioComposition;
    excluded_instrument_ids?: string[];
}

export interface IModelPortfolioComposition {
    [instrumentId: string]: number;
}

export interface IMetaPortfolioComposition {
    [policyOrPortfolioId: string]: number;
}

export type TPolicyComposition = IPortfolioHoldings | IMetaPortfolioComposition;

export interface IPolicyAssetClasses {
    /* these number ranges are e.g. [0, 1] */
    alternatives: TNumberRange;
    bonds: TNumberRange;
    cash: TNumberRange;
    commodities: TNumberRange;
    stocks: TNumberRange;
    corporate?: TNumberRange;
    equity?: TNumberRange;
    eurobond?: TNumberRange;
    fixed_income?: TNumberRange; /* in FDA, but not supported yet in console-api - is mapped to "bonds" in events */
    government?: TNumberRange;
    money_market?: TNumberRange;
    real_estate?: TNumberRange;
    sukuk?: TNumberRange;
    other?: TNumberRange; /* in FDA, but not supported yet in console-api - is mapped to "alternatives" in events */
}

export type TAssetClassKey = keyof IPolicyAssetClasses;
export const ALL_ASSET_CLASS_KEYS: TAssetClassKey[] = [
    'alternatives', 'bonds', 'cash', 'commodities', 'stocks',
    'corporate', 'equity', 'eurobond',
    // 'fixed_income',
    'government', 'money_market', 'real_estate', 'sukuk',
    // 'other',
];

export interface IPolicyRegionsConstraints {
    bonds: IPolicyRegionRanges;
    stocks: IPolicyRegionRanges;
}

export interface IPolicyRegionRanges {
    asia_pacific_developed: TNumberRange; // e.g. [0, 1]
    emerging: TNumberRange; // e.g. [0, 1]
    europe_developed: TNumberRange; // e.g. [0, 1]
    north_america: TNumberRange; // e.g. [0, 1]
}

export interface IPolicyBondTypes {
    convertible: TNumberRange; // e.g. [0, 1]
    corporate: TNumberRange; // e.g. [0, 1]
    government: TNumberRange; // e.g. [0, 1]
}

export interface IPolicySectorConstraints {
    basic_materials: TNumberRange; // e.g. [0, 1]
    communication_services: TNumberRange; // e.g. [0, 1]
    consumer_cyclical: TNumberRange; // e.g. [0, 1]
    consumer_defensive: TNumberRange; // e.g. [0, 1]
    energy: TNumberRange; // e.g. [0, 1]
    financial_services: TNumberRange; // e.g. [0, 1]
    healthcare: TNumberRange; // e.g. [0, 1]
    industrials: TNumberRange; // e.g. [0, 1]
    real_estate: TNumberRange; // e.g. [0, 1]
    technology: TNumberRange; // e.g. [0, 1]
    utilities: TNumberRange; // e.g. [0, 1]
}

export interface IPolicyHoldingsConstraints {
    cash_amount: TNumberRange; // e.g. [100, 10000]
    cash_fraction: TNumberRange; // e.g. [0.01, 0.03]
    position_amount: TNumberRange; // e.g. [0, 100000]
    position_fraction: TNumberRange; // e.g. [0, 0.25]
}

export enum CombineTransactionCostMode {
    MAX = 'MAX',
    SUM = 'SUM',
}

export enum PolicyManagerTag {
    SHARIA = 'sharia',
    ESG = 'esg',
}

export enum PolicyHierarchyLevel {
    Base = 'Base',
    Child = 'Child',
}

export enum PolicyAllocationCategory {
    assetClasses = 'assetClasses',
    regionBonds = 'regionBonds',
    regionStocks = 'regionStocks',
    bondTypes = 'bondTypes',
    sectors = 'sectors',
}

export interface IPolicyPerformanceFuture {
    policyId: TEntityUlid;
    date: string[]; // e.g. ["2020-11-23"]
    return_amount: number[][]; // an array of numbers per requested quantile
    value_amount: number[][]; // an array of numbers per requested quantile
    invested_amount: number[];
}

export interface ISinglePolicyApiInput {
    policyId: TEntityUlid;
}
