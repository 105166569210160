import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { EntityType, TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    PolicyAlgorithmType,
    TEnhancedPolicyComposition,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';
import GenericPortfolioSelect from 'views/portfolioMgmt/Portfolios/GenericPortfolioSelect';
import GenericPolicySelect from '../GenericPolicySelect';
import { IAddCompositionElementProps } from './types';

interface IAddMetaPortfolioCompositionTabProps {
    listEntityType: EntityType.policy | EntityType.portfolio;
    policyAlgorithmType?: PolicyAlgorithmType;
    setSelectedCompositionElement: (props: IAddCompositionElementProps) => void;
    selectedCompositionElementId: TEntityUlid;
    currentComposition: TEnhancedPolicyComposition;
}

export default function AddMetaPortfolioCompositionTab({
    listEntityType,
    policyAlgorithmType,
    selectedCompositionElementId,
    currentComposition,
    setSelectedCompositionElement,
}: IAddMetaPortfolioCompositionTabProps) {
    if (listEntityType === EntityType.portfolio) {
        return (
            <GenericPortfolioSelect
                selectedPortfolioId={selectedCompositionElementId}
                onPortfolioSelected={({ portfolio }) => setSelectedCompositionElement({
                    compositionElementId: portfolio.id,
                    compositionElementName: portfolio.name,
                })}
                isPortfolioSelectable={(portfolioId) => !isSet(currentComposition[portfolioId])}
            />
        );
    }

    return (
        <GenericPolicySelect
            selectedPolicyId={selectedCompositionElementId}
            onPolicySelected={({ policy }) => setSelectedCompositionElement({
                compositionElementId: policy.id,
                compositionElementName: policy.name,
            })}
            isPolicySelectable={(policyId) => !isSet(currentComposition[policyId])}
            preFilledPolicyAlgorithmTypeFilter={policyAlgorithmType}
        />
    );
}
