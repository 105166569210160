import { SupportedCurrency } from '@console/common/config/currencies.config';
import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import { IBaseIdentifiedEntity, TEntityUlid } from '../../typsy/entities/dist/common/entity.models';
import { IApiEntityListRequest, IApiEntityListResponse } from '../../typsy/console-api-client/dist/models/consoleApiQuery.models';
import {
    IPolicyEntityData,
    TPoliciesData,
    TPolicy,
    ISinglePolicyApiInput,
    TPolicyPatch,
    IPolicyPerformanceFuture,
    TPolicyCreate, PolicyManagerTag, CoreApiPolicyAlgorithmType,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import {
    DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR,
    DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS,
} from '../../typsy/console-api-client/dist/config/consoleApi.config';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../typsy/console-api-client/dist/utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../typsy/console-api-client/dist/utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';
import {
    RecurringDepositFrequency,
    SampleFrequencyPerformanceFuture,
} from '../../typsy/console-api-client/dist/models/common/performance.models';

export interface IFetchPoliciesApiInput extends IApiEntityListRequest {
    externalId?: string;
    policyName?: string;
    riskProfileId?: string;
    currencies?: SupportedCurrency[];
    tags?: PolicyManagerTag[];
    ids?: string[];
    algorithmTypes?: CoreApiPolicyAlgorithmType[];
}

export function fetchPolicies({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    externalId,
    policyName,
    ids,
    riskProfileId,
    algorithmTypes,
    currencies,
    tags,
    orderBy,
    count,
    underlyingApiRequestConfig,
}: IFetchPoliciesApiInput & IUnderlyingRequestApiInput = {}) {
    return get<TPoliciesData, IApiEntityListResponse<IPolicyEntityData>>({
        url: ConsoleApiPath.POLICIES,
        queryParams: {
            offset,
            limit,
            ...fetchApiEntityUrlParamBuilder()
                .exactMatch({ field: 'external_id', value: externalId })
                .contains({ field: 'name', value: policyName })
                .exactMatch({ field: 'risk_profile_id', value: riskProfileId })
                .arrayContains({ field: 'tags', value: tags })
                .fieldIn({ field: 'id', value: ids })
                .fieldIn({ field: 'config.algorithm_settings.execution_settings.base_currency', value: currencies })
                .fieldIn({ field: 'config.algorithm', value: algorithmTypes })
                .orderBy(orderBy)
                .build(),
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}

export function fetchPolicyDetails({
    policyId,
    underlyingApiRequestConfig,
}: ISinglePolicyApiInput & IUnderlyingRequestApiInput) {
    return get<TPolicy>({
        url: ConsoleApiPath.POLICY_DETAILS,
        pathParams: {
            policyId,
        },
        ...underlyingApiRequestConfig,
    });
}

export function deletePolicy({ id }: IBaseIdentifiedEntity) {
    return remove<unknown>({
        url: ConsoleApiPath.POLICY_DETAILS,
        pathParams: {
            policyId: id,
        },
    });
}

export function createPolicy({
    underlyingApiRequestConfig,
    ...policyToCreate
}: TPolicyCreate & IUnderlyingRequestApiInput) {
    return post<TPolicy>({
        url: ConsoleApiPath.POLICIES,
        body: policyToCreate,
        ...underlyingApiRequestConfig,
    });
}

export function patchPolicy({
    id,
    underlyingApiRequestConfig,
    ...patchFields
}: TPolicyPatch & IUnderlyingRequestApiInput) {
    return patch<TPolicy>({
        url: ConsoleApiPath.POLICY_DETAILS,
        pathParams: {
            policyId: id,
        },
        body: patchFields,
        ...underlyingApiRequestConfig,
    });
}

export interface IFetchPolicyPerformanceFutureApiInput extends IFetchPolicyPerformanceBaseApiInput {
    startAmount?: number; // default 1000
    recurringDepositAmount?: number; // default 0
    recurringDepositFrequency?: RecurringDepositFrequency; // default 'M'
    quantiles?: number[]; // default [ 0.025, 0.5, 0.975 ]
    sampleFrequency?: SampleFrequencyPerformanceFuture; // default 'M'
    useExpectedReturns?: boolean; // default false
}

export interface IFetchPolicyPerformanceBaseApiInput {
    policyId: TEntityUlid;
    forceRefresh?: boolean; // default false - only used in FE, not by BE
    startDate?: string; // e.g. "2010-05-15"
    endDate?: string; // e.g. "2010-05-15"
}

export function fetchPolicyPerformanceFuture({
    policyId,
    useExpectedReturns = DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS,
    ...other
}: IFetchPolicyPerformanceFutureApiInput) {
    return post<IPolicyPerformanceFuture, Omit<IPolicyPerformanceFuture, 'policyId'>>({
        url: ConsoleApiPath.POLICY_PERFORMANCE_FUTURE,
        pathParams: {
            policyId,
        },
        body: {
            method: 'PORTFOLIO_FUTURE_PERFORMANCE',
            method_settings: {
                start_date: other.startDate,
                end_date: other.endDate,
                start_amount: other.startAmount,
                recurring_deposit_amount: other.recurringDepositAmount,
                recurring_deposit_frequency: other.recurringDepositFrequency,
                quantiles: other.quantiles,
                sample_frequency: other.sampleFrequency,
                use_expected_returns: useExpectedReturns,
            },
        },
        mapResponse: ({ data }) => ({
            policyId,
            ...data,
        }),
    });
}
