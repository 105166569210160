import React from 'react';
import { useParams } from 'react-router-dom';
import { EntityType, IBaseEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { DEFAULT_RISK_PROFILE_TAB_KEY, RISK_PROFILE_TAB_KEY } from 'config/tabs.config';
import {
    getRiskProfileTitle,
    riskProfileDetailsEntity,
    triggerDeleteRiskProfile,
} from 'state/entities/portfolioMgmt/riskProfileDetails';
import DetailPage, {
    redirectToCurrentRouteButOverrideParams,
    TDetailPageTitleConfig,
} from 'views/common/detail/DetailPage';
import { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import { getApiEntityDeleteAction } from 'views/common/genericApiEntity/apiEntityHelper';
import RiskProfilePropertiesTab from './RiskProfilePropertiesTab';
import RiskProfileAccessibleByTab from './RiskProfileAccessibleByTab';

const RISK_PROFILE_TABS: ITabItem[] = [{
    key: RISK_PROFILE_TAB_KEY.PROPERTIES,
    label: 'portfolio_mgmt.risk_profiles.detail.tabs.properties.title',
    component: RiskProfilePropertiesTab,
    wrapWithContentBox: false,
}, {
    key: RISK_PROFILE_TAB_KEY.ACCESSIBLE_BY,
    label: 'portfolio_mgmt.risk_profiles.detail.tabs.accessible_by.title',
    component: RiskProfileAccessibleByTab,
}];

const RISK_PROFILE_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: getRiskProfileTitle,
    notifications: [StateChangeNotification.RISK_PROFILE_DETAILS_DATA],
};

export default function RiskProfileDetail() {
    const { riskProfileTab = DEFAULT_RISK_PROFILE_TAB_KEY } = useParams<IPossiblePathParams>();

    return (
        <DetailPage
            title={RISK_PROFILE_TITLE_CONFIG}
            tabsWithEntityWrapper={{
                items: RISK_PROFILE_TABS,
                activeTabKey: riskProfileTab,
                onTabChange: tabChanged,
                entity: {
                    notifications: [StateChangeNotification.RISK_PROFILE_DETAILS_DATA],
                    asyncEntitySelector: riskProfileDetailsEntity.select,
                    loaderPosition: 'fixed',
                },
            }}
            actions={getRiskProfileDetailActions}
            footer={{
                apiEntityVersion: {
                    apiEntitySelector: () => riskProfileDetailsEntity.select().data,
                },
            }}
            entityId={{
                idSelector: ({ urlParams }) => urlParams.riskProfileId as string,
            }}
        />
    );

    function tabChanged({ tabKey }: IOnTabChangeProps) {
        redirectToCurrentRouteButOverrideParams({
            newParams: {
                riskProfileTab: tabKey,
            },
        });
    }

    function getRiskProfileDetailActions(apiEntity: IBaseEntity): IActionItem[] {
        return [getApiEntityDeleteAction({
            apiEntity,
            apiEntityType: EntityType.riskProfile,
            deleteTrigger: triggerDeleteRiskProfile,
        })];
    }
}
