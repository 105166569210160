import { TFastifyRouteGenericInterface } from '@typsy/rest-api/dist/server/endpoint/endpointValidation.models';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { IInstrumentsMap } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/instrument.entity.models';
import {
    IOptimizationPortfolioUpdate,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/optimization.entity.models';
import {
    OptimizationOwnerChoice,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioOptimization.entity.models';
import { IPortfolioHoldings } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioHoldings.entity.models';
import { EnhancedOptimizationStatus } from '../enhancedOptimization.models';

export const LATEST_OPTIMIZATION_ID = 'latest';

export interface IFetchEnhancedPortfolioOptimizationApiContract extends TFastifyRouteGenericInterface {
    Params: IFetchEnhancedPortfolioOptimizationApiParams;
    Reply: IEnhancedPortfolioOptimization;
}

export interface IFetchEnhancedPortfolioOptimizationApiParams {
    portfolioId: TEntityUlid;
    optimizationId: 'latest' | TEntityUlid;
}

export type TFetchEnhancedPortfolioOptimizationApiInput = IFetchEnhancedPortfolioOptimizationApiParams;

export interface IEnhancedPortfolioOptimization {
    portfolioId: TEntityUlid;
    status: EnhancedOptimizationStatus;
    optimizationId?: TEntityUlid;
    currency?: SupportedCurrency;
    ownerChoice?: OptimizationOwnerChoice;
    portfolio_update?: IEnhancedOptimizationPortfolioUpdate;
    holdingsAtTimeOfOptim?: IPortfolioHoldings; /* the portfolio holdings at the time of the optimization */
    estimatedCashAfterOptim?: number;
    instrumentMap?: IInstrumentsMap;
    creation_datetime?: string;
    version_datetime?: string;
}

export interface IEnhancedOptimizationPortfolioUpdate extends IOptimizationPortfolioUpdate {
    nrOfBuys: number;
    nrOfSells: number;
}
