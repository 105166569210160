import { IQueryEntityEmbedResponse } from './entityQuery.models';

export enum EntityType {
    goal = 'goal',
    horizon = 'horizon',
    riskProfile = 'riskProfile',
    policy = 'policy',
    portfolio = 'portfolio',
    portfolioGroup = 'portfolioGroup',
    optimization = 'optimization',
    userGroup = 'userGroup',
    user = 'user',
}

/** ONE capital letter (unique per entity type, e.g. "U" for users) followed by a ULID of 26 characters */
export type TEntityUlid = string;

export type TEntity<EntityData> = IBaseEntity & EntityData;
export type TEntityDetail<EntityData> = IBaseEntity & EntityData & IQueryEntityEmbedResponse;
export type TEntityPatch<EntityData> = TEntityPatchBase & Partial<EntityData>;
export type TEntityPatchBase = IBaseIdentifiedEntity & TBaseEntityAccessPatch;
export type TEntityCreate<EntityData> = TEntityCreateBase & EntityData;
export type TEntityCreateBase = IBaseEntityAccess;

export interface IBaseEntity extends IBaseIdentifiedEntity, IBaseEntityAccess {
    creation_datetime: string;
    deleted: boolean;
    version: number;
    version_authored_by_user_id: TEntityUlid;
    version_datetime: string;
}

export interface IBaseIdentifiedEntity {
    id: TEntityUlid;
}

export interface IBaseEntityAccess {
    readable_by: TEntityUlid[]; /* array of user- and/or group-id's */
    modifiable_by: TEntityUlid[]; /* array of user- and/or group-id's */
}

export type TBaseEntityAccessPatch = Partial<IBaseEntityAccess>;
