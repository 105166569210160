import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { IFetchGoalsApiInput } from '@console/core-api/client/portfolioMgmt/goals.api';
import { TGoalsData } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/goal.entity.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { isRouteLocation } from 'state/ui/selectors';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { getEntitiesManager } from '../entitiesManager';

export const goalsEntity = getEntitiesManager().registerEntity<TGoalsData>({
    asyncEntityKey: AsyncEntityKeys.goals,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.GOALS_DATA],
    includeUpdaters: true,
});

export const triggerResetGoalsFetch = () => goalsEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchGoals({
    forceRefresh = false,
    ...apiInput
}: IFetchGoalsApiInput & IForceStateRefreshFilter = {}) {
    return goalsEntity.async.fetch<IFetchGoalsApiInput>({
        api: api.goals.fetchGoals,
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars<IFetchGoalsApiInput>({
            pageKey: UiPageKey.goalsList,
            state,
            apiInput,
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: goalsEntity.select().data,
        }),
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_GOAL_DETAIL,
        }),
        resetDataOnTriggerMode: 'never',
    });
}
