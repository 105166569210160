import { PeriodType } from '@console/common/models/periodFilter.models';
import { AggregateRoboAumBy } from '@console/bff/export';

/**
 * Without period dates (only the type) so that they are NOT stored in browser storage!
 * Otherwise the start- and endDates of the periods would not automatically shift to a new value when a new day starts.
 */
export interface IMgmtReportingUiVars {
    robo: IRoboMgmtReportingUiVars;
    self: ISelfMgmtReportingUiVars;
}

export interface IRoboMgmtReportingUiVars {
    periodType: PeriodType;
    aumAggregateBy: AggregateRoboAumBy;
}
export interface ISelfMgmtReportingUiVars {
    periodType: PeriodType;
}

export enum AggregateSelfAumBy {
    ASSET_TYPE = 'ASSET_TYPE',
}
