import { TTranslator, TTranslatorInput } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { getTranslator as getStoredTranslator }
    from '@snipsonian/react/cjs/components/i18n/translator/translatorManager';
import { Locale, DEFAULT_LOCALE } from '@console/common/config/i18n.config';
import { IMultiTranslationsLabel } from '@console/core-api/typsy/console-api-client/dist/models/common/i18n.models';
import { IUntranslatableLabel, TTranslatorIfNeeded } from 'models/general.models';
import { IState } from 'models/state.models';
import { getDefaultUserLocaleConfig } from 'state/appConfig/selectors';
import { getLabelTranslation } from 'utils/entities/multiTranslationsLabelUtils';
import { isUntranslatableLabelTypeGuard } from 'utils/i18n/i18nUtils';

export const getLocale = (state: IState) => state.i18n.locale || DEFAULT_LOCALE;

export const areTranslationsRefreshed = (state: IState) => state.i18n.areTranslationsRefreshed;

export const shouldShowTranslationKeys = (state: IState) => state.i18n.showTranslationKeys;

export const getTranslator = (state: IState, localeOverride?: Locale): TTranslator => {
    const locale = localeOverride || getLocale(state);

    return getStoredTranslator({
        locale,
        showTranslationKeys: shouldShowTranslationKeys(state),
        areTranslationsRefreshed: areTranslationsRefreshed(state),
    });
};

export const getTranslatorIfNeeded = (state: IState, localeOverride?: Locale): TTranslatorIfNeeded => (
    input: TTranslatorInput | IUntranslatableLabel,
) => {
    if (isUntranslatableLabelTypeGuard(input)) {
        return input.text;
    }

    return getTranslator(state, localeOverride)(input);
};

/**
 * If the desired locale is not specified (null), this function will by default return the translation for the
 * default locale of the active tenant.
 */
export const getSpecificTranslationFromApiLabel = (
    { label, locale }: { label: IMultiTranslationsLabel; locale: Locale },
    state?: IState,
) => {
    const defaultLocale = getDefaultUserLocaleConfig(state);

    return getLabelTranslation({
        label,
        locale: locale || defaultLocale,
        defaultLocale,
    });
};

export const getDefaultTranslationFromApiLabel = (
    label: IMultiTranslationsLabel,
    state?: IState,
) => getSpecificTranslationFromApiLabel(
    {
        label,
        locale: null,
    },
    state,
);
