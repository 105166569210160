import React from 'react';
import { IMultiTranslationsLabel } from '@console/core-api/typsy/console-api-client/dist/models/common/i18n.models';
import { getUserLocalesSortedDefaultFirstMemoized } from 'state/appConfig/selectors';
import { makeStyles, mixins } from 'views/styling';
import {
    ExtendedInputFormContext,
    IExtendedFormField,
} from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputWrapper, { IExtendedInputWrapperProps } from 'views/common/inputs/extended/ExtendedInputWrapper';
import MultiTranslationsLabelLocaleTags
    from 'views/common/detail/multiTranslationsLabel/MultiTranslationsLabelLocaleTags';
import InputTextField, { IInputTextFieldProps, IOnChangeTextInputProps } from 'views/common/inputs/base/InputTextField';
import MultiTranslationsLabelLocaleTitle
    from 'views/common/detail/multiTranslationsLabel/MultiTranslationsLabelLocaleTitle';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import IconButtonCollapse from 'views/common/buttons/IconButtonCollapse';
import clsx from 'clsx';

interface IExtendedMultiTranslationsInputBaseProps extends Pick<IInputTextFieldProps, 'multilineRows' | 'className'> {
    readOnly?: boolean; // default false
    formField: IExtendedFormField;
    wrapper: Pick<IExtendedInputWrapperProps, 'label' | 'shouldPrefixLabel'>;
}

const useStyles = makeStyles((theme) => ({
    ExtendedMultiTranslationsInput: {
        ...mixins.flexColTopLeft(),

        '& .__header': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            ...mixins.widthMax(),
            marginBottom: theme.spacing(-1),

            '& .__headerRight': {
                ...mixins.flexRowCenterRight(),
            },

            '& .__collapseButton': {
                marginLeft: theme.spacing(1),
            },
        },

        '& .__localeTranslation': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'flex-start' }),
            ...mixins.widthMax(),
            marginBottom: theme.spacing(1),

            '& .MultiTranslationsLabelLocaleTitle': {
                paddingTop: 18,
                paddingRight: theme.spacing(2),
            },
        },
    },
}));

export default function ExtendedMultiTranslationsInput({
    readOnly = false,
    formField,
    wrapper,
    multilineRows = false,
    className,
}: IExtendedMultiTranslationsInputBaseProps) {
    const classes = useStyles();
    const [isCollapsed, setIsCollapsed] = React.useState(true);

    return (
        <ExtendedInputFormContext.Consumer>
            {({ readOnlyForm, setFieldValue, fields }) => {
                const isFieldReadOnly = readOnly || readOnlyForm;

                if (isFieldReadOnly) {
                    // assumption that this component is only used from an editable form
                    console.log('A readonly ExtendedMultiTranslationsInput is not implemented yet!');
                    return null;
                }

                const localesDefaultFirst = getUserLocalesSortedDefaultFirstMemoized();
                const localesToShow = isCollapsed
                    ? [localesDefaultFirst[0]]
                    : localesDefaultFirst;

                const multiTranslationsValue = formField.value as IMultiTranslationsLabel;

                return (
                    <div className={clsx(classes.ExtendedMultiTranslationsInput, className)}>
                        <div className="__header">
                            <ExtendedInputWrapper
                                {...wrapper}
                            >
                                {null}
                            </ExtendedInputWrapper>

                            <div className="__headerRight">
                                <MultiTranslationsLabelLocaleTags
                                    label={multiTranslationsValue}
                                />

                                {localesDefaultFirst.length > 1 && (
                                    <IconButtonCollapse
                                        id={`multi-translation-collapse_${formField.fieldName}`}
                                        isCollapsed={isCollapsed}
                                        onCollapseChange={setIsCollapsed}
                                        className="__collapseButton"
                                    />
                                )}
                            </div>
                        </div>

                        {localesToShow.map((locale) => {
                            const localeSpecificFieldName = `${formField.fieldName}.${locale}`;
                            const localeSpecificField =
                                (fields as Record<string, IExtendedFormField>)[localeSpecificFieldName];

                            return (
                                <div
                                    key={`localeTranslation_${locale}`}
                                    className="__localeTranslation"
                                >
                                    <MultiTranslationsLabelLocaleTitle
                                        locale={locale}
                                        shouldMarkDefaultLocale={false}
                                    />

                                    <InputWrapper>
                                        <InputTextField
                                            id={localeSpecificField.fieldId}
                                            name={localeSpecificField.fieldName}
                                            value={multiTranslationsValue[locale]}
                                            onChange={onChangeLocaleTranslation}
                                            error={localeSpecificField.error}
                                            emphasizeError={formField.emphasizeError}
                                            // placeholder={adjustedPlaceholder}
                                            crudStylingType={localeSpecificField.isDiff ? 'edited' : null}
                                            multilineRows={multilineRows}
                                        />
                                    </InputWrapper>
                                </div>
                            );

                            function onChangeLocaleTranslation({ value }: IOnChangeTextInputProps) {
                                const newMultiTranslationsValue = {
                                    ...multiTranslationsValue,
                                    [locale]: value,
                                };

                                setFieldValue({
                                    fieldName: formField.fieldName,
                                    value: newMultiTranslationsValue,
                                }, {
                                    fieldName: localeSpecificFieldName,
                                    value,
                                });
                            }
                        })}
                    </div>
                );
            }}
        </ExtendedInputFormContext.Consumer>
    );
}
