import React from 'react';
import { triggerFetchUsersForSearch } from 'state/entities/userMgmt/users';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import GenericUserSelect, { IOnUserSelectedProps } from 'views/userMgmt/shared/GenericUserSelect';
import ExtendedInputNumber from 'views/common/inputs/extended/ExtendedInputNumber';
import { TUser } from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/user.entity.models';
import { IPortfolioFormValues, TPortfolioOwnerFormValues } from './types';

export function PortfolioStartAmountFormContent({
    fields,
}: IExtendedInputFormContext<Pick<IPortfolioFormValues, 'startAmount'>>) {
    return (
        <ExtendedInputNumber
            formField={fields.startAmount}
            wrapper={{
                label: 'fields.start_amount.label',
                tooltip: 'fields.start_amount.tip',
            }}
        />
    );
}

export function PortfolioOwnerFormContent({
    fields,
    setFieldValue,
    initialValues,
}: IExtendedInputFormContext<TPortfolioOwnerFormValues>) {
    useExecuteOnMount({
        execute: () => triggerFetchUsersForSearch(),
    });

    const ownerField = fields.owner;

    return (
        <GenericUserSelect
            selectedUserId={(ownerField.value as TUser)?.id}
            onUserSelected={updateFormWithSelectedUser}
            initialSelectedUserId={(initialValues.owner as TUser)?.id}
        />
    );

    function updateFormWithSelectedUser({ user }: IOnUserSelectedProps) {
        setFieldValue({
            fieldName: ownerField.fieldName,
            value: user,
        });
    }
}
