import { UserFeatureFlag } from '../models/userMgmt/user.entity.models';

/**
 * A 'user' will be considered a "client" if he/she at least has one of these features.
 */
export const CLIENT_FEATURE_FLAGS = [
    UserFeatureFlag.RoboAdvisor,
    UserFeatureFlag.SelfInvestor,
    UserFeatureFlag.Storyteller,
];
