import React from 'react';
import clsx from 'clsx';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { ICustomAsyncEntity } from 'models/state/entities.models';
import { IListPageVars, ISimpleFilterToggles } from 'models/state/ui.models';
import { IState } from 'models/state.models';
import { FIRST_PAGE_NR } from '@console/core-api/typsy/console-api-client/dist/config/consoleApi.config';
import { getListPageVars } from 'state/ui/uiPages.selectors';
import { makeStyles } from 'views/styling';
import {
    IListConfig,
    IListPageProps,
    initListPage,
    IOnChangePageNrWithOffsetProps,
    IPaginationInfo,
    TListPageSimpleFilterProps,
    TToListItems,
} from 'views/common/list/ListPage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,max-len
interface IPublicProps<ListItem, AdvancedFilters extends TAnyObject = any>
    extends Omit<IListPageProps<AdvancedFilters, null, ListItem>, 'list' | 'pagination' | 'search'> {
    list: Pick<IListConfig<ListItem, ListItem>, 'className' | 'renderData' | 'setMinHeight'> & {
        items: ListItem[];
    };
    search?: {
        simple?: Pick<TListPageSimpleFilterProps, 'tipTranslationKey' | 'extraFilters'>;
        // advanced TODO once needed
        isListItemToBeIncluded: (props: IIsListItemToBeIncludedProps<ListItem, AdvancedFilters>) => boolean;
    };
    disablePagination?: boolean;
}

export interface IIsListItemToBeIncludedProps<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ListItem, AdvancedFilters = any, ExtraSimpleFilterToggles extends ISimpleFilterToggles = any> {
    listItem: ListItem;
    listPageVars: IListPageVars<AdvancedFilters, ExtraSimpleFilterToggles>;
}

const useStyles = makeStyles((theme) => ({
    ListPageForFixedItems: {
        '& .DataSearch': {
            padding: theme.spacing(2, 0, 3, 0),
        },
    },
}));

export default function ListPageForFixedItems
<ListItem, AdvancedFilters extends TAnyObject = TAnyObject>({
    list,
    uiPageKey,
    search: searchInput,
    className,
    disablePagination,
    ...other
}: IPublicProps<ListItem, AdvancedFilters>) {
    const classes = useStyles();
    const [pageNr, setPageNr] = React.useState<number>(FIRST_PAGE_NR);

    const ListPage = initListPage({ notifications: [other.notificationToTriggerOnUiVarChanges] });

    const dummyAsyncEntitySelectorThatReturnsTheFixedItemsArray: () => ICustomAsyncEntity<ListItem[]> = () => ({
        data: list.items,
        fetch: {
            status: AsyncStatus.Success,
            error: null,
        },
    });

    const listPageVarsSelector = (state: IState) => getListPageVars(state, uiPageKey);

    const search = searchInput
        ? {
            isListItemToBeIncluded: searchInput.isListItemToBeIncluded,
            simple: searchInput.simple
                ? {
                    ...searchInput.simple,
                    isClientSideSearch: true,
                    onSearch: () => setPageNr(FIRST_PAGE_NR),
                }
                : undefined,
        }
        : undefined;

    const onlyKeepListItemsThatMatchSearchInput: TToListItems<ListItem[], ListItem, IListPageVars<AdvancedFilters>> =
        (search && searchInput.simple)
            ? ({ entityData, extraData: listPageVars }) => entityData
                .filter((listItem) => search.isListItemToBeIncluded({ listItem, listPageVars }))
            : undefined;

    return (
        <ListPage
            className={clsx(classes.ListPageForFixedItems, className)}
            uiPageKey={uiPageKey}
            list={{
                ...list,
                asyncEntitySelector: dummyAsyncEntitySelectorThatReturnsTheFixedItemsArray,
                extraDataSelector: listPageVarsSelector,
                toListItems: onlyKeepListItemsThatMatchSearchInput,
            }}
            search={search}
            pagination={disablePagination ? null : {
                onChangeItemsPerPage,
                onChangePageNr,
                getPaginationInfoSelector,
            }}
            {...other}
        />
    );

    function onChangeItemsPerPage() {
        setPageNr(FIRST_PAGE_NR);
    }

    function onChangePageNr({ pageNr: newPageNr }: IOnChangePageNrWithOffsetProps) {
        setPageNr(newPageNr);
    }

    function getPaginationInfoSelector(state: unknown, listItems: ListItem[]): IPaginationInfo {
        return {
            pageNr,
            nrOfItems: listItems.length,
            totalNrOfItems: listItems.length,
        };
    }
}
