// import { TDateToFormat } from '../date.models';
// import { parseDate, TParseDateOptions } from '../parsing/parseDate';
//
// export type TFormatDateOptions = TParseDateOptions & {
//     format?: string;
// };
//
// export const DATE_FORMAT = {
//     /**
//      * E.g. 2022-05-12T14:54:10.987590+00:00
//      *
//      * Some explanation:
//      *   .SSS[000]
//      *     In the console api the datetimes have the microseconds after the dot, which is a 6-digit number,
//      *     but as dayjs only supports 3-digit-milliseconds (SSS) and not microseconds, we just append 3 zero's
//      *   Z
//      *     The timezone = offset from UTC. e.g. +02:00
//      */
//     BACK_END_DATETIME: 'YYYY-MM-DD[T]HH:mm:ss.SSS[000]Z',
// };
//
// export function formatDate(
//     date: TDateToFormat,
//     {
//         format = DATE_FORMAT.BACK_END_DATETIME,
//         ...parseOptions
//     }: TFormatDateOptions = {},
// ) {
//     return parseDate(date, parseOptions)
//         .format(format);
// }

/**
 * Returns a date in the format of YYYY-MM-DDTHH:mm:ss.sssZ
 * (where the timezone is always zero UTC offset)
 */
export function formatDateAsISOString(date: Date) {
    if (!date) {
        return null;
    }

    return new Date(date).toISOString();
}
