import {
    AsyncOperation,
    TRefreshMode,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { ISinglePolicyApiInput } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { TEnhancedPolicy } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import {
    TEnhancedPoliciesData,
    TFetchEnhancedPoliciesClientApiInput,
} from '@console/bff/models/policies/enhancedPolicies.models';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { isRouteLocation } from 'state/ui/selectors';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { IAdvancedPoliciesFilters } from 'models/ui/policyList.ui.models';

export const policiesEntity = getEntitiesManager().registerEntity<TEnhancedPoliciesData>({
    asyncEntityKey: AsyncEntityKeys.policies,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.POLICIES_DATA],
    includeUpdaters: true,
});

export const triggerResetPoliciesFetch = () => policiesEntity.updaters.fetch.resetWithoutDataReset();

/**
 * For the regular policy list page
 */
export function triggerFetchPolicies({
    forceRefresh = false,
    ...apiInput
}: TFetchEnhancedPoliciesClientApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchPoliciesGeneric({
        apiInput,
        pageKey: UiPageKey.policiesList,
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_POLICY_DETAIL,
        }),
    });
}

/**
 * For the modal where a policy is selected from a list
 */
export function triggerFetchPoliciesForSearch({
    forceRefresh = false,
    ...apiInput
}: TFetchEnhancedPoliciesClientApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchPoliciesGeneric({
        apiInput,
        pageKey: UiPageKey.policySearchList,
        refreshMode: () => forceRefresh || isObjectWithProps(apiInput),
    });
}

function triggerFetchPoliciesGeneric({
    apiInput,
    pageKey,
    refreshMode,
}: {
    apiInput?: TFetchEnhancedPoliciesClientApiInput;
    pageKey: UiPageKey;
    refreshMode: TRefreshMode<IState>;
}) {
    return policiesEntity.async.fetch<TFetchEnhancedPoliciesClientApiInput>({
        api: api.bff.policies.fetchEnhancedPolicies,
        apiInputSelector: ({ state }) =>
            enrichApiInputWithListPageVars<TFetchEnhancedPoliciesClientApiInput, IAdvancedPoliciesFilters>({
                pageKey,
                state,
                apiInput,
                mapSimpleFilterValue: (simpleFilterValue) => ({
                    policyName: simpleFilterValue,
                }),
                mapAdvancedFilterValues: (advancedFilterValues) => ({
                    policyName: advancedFilterValues.policyName,
                    externalId: advancedFilterValues.externalId,
                    currencies: advancedFilterValues.currencies,
                    riskProfileId: advancedFilterValues.riskProfileId,
                    algorithmType: advancedFilterValues.algorithmType,
                    tags: advancedFilterValues.tags,
                    ids: advancedFilterValues.ids,
                }),
            }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: policiesEntity.select().data,
        }),
        refreshMode,
        resetDataOnTriggerMode: 'never',
    });
}

export function findSpecificPolicy({ policyId }: ISinglePolicyApiInput) {
    const policiesData = policiesEntity.select().data;

    if (!policiesData || !isArrayWithValues(policiesData.results)) {
        return null;
    }

    return policiesData.results.find((singlePolicy) => singlePolicy.id === policyId);
}

export function findOrFetchSpecificPolicy({ policyId }: ISinglePolicyApiInput): Promise<TEnhancedPolicy> {
    const policyOfList = findSpecificPolicy({ policyId });

    if (policyOfList) {
        return Promise.resolve(policyOfList);
    }

    /* fetch the possibly-already-cached details of the policy */
    return apiCacheManager.fetchPolicy({ policyId });
}
