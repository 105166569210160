import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import {
    IInstrumentPerformancePast,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/instrumentPerformancePast.entity.models';
import {
    IFetchInstrumentPerformancePastApiInput,
} from '@console/core-api/client/portfolioMgmt/instrumentPerformancePast.api';
import { api } from 'api';
import { getEntitiesManager } from '../entitiesManager';

export const instrumentPerformancePastEntity = getEntitiesManager().registerEntity<IInstrumentPerformancePast>({
    asyncEntityKey: AsyncEntityKeys.instrumentPerformancePast,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.INSTRUMENT_PERFORMANCE_PAST],
});

export function triggerFetchInstrumentPerformancePast(apiInput: IFetchInstrumentPerformancePastApiInput) {
    return instrumentPerformancePastEntity.async.fetch<IFetchInstrumentPerformancePastApiInput>({
        api: api.instrumentPerformancePast.fetchInstrumentPerformancePast,
        apiInputSelector: () => apiInput,
    });
}
