import { object } from '@console/common/utils/schema';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { ENTITY_TYPE_TO_ULID_REGEX } from '@console/core-api/typsy/entities/dist/common/entityUlid.config';
import { entityIdArray } from '@console/core-api/utils/schema/entityIdSchema';
import { advancedPortfolioFiltersServerSideSchema } from '@console/bff/server/utils/schemas/portfolio.schemas';

export const PORTFOLIO_ID_REGEX = ENTITY_TYPE_TO_ULID_REGEX[EntityType.portfolio];

export const advancedPortfolioFiltersClientSideSchema = advancedPortfolioFiltersServerSideSchema
    .omit(['ids'])
    .concat(object({
        ids: entityIdArray({
            entityType: EntityType.portfolio,
            entityName: 'portfolio',
        }),
    }));
