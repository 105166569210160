import { SupportedCurrency } from '@console/common/config/currencies.config';
import { BankAccountType } from '../common/banking.models';
import { IMultiTranslationsLabel } from '../common/i18n.models';
import { TEntityUlid, TEntityDetail, TEntityCreate, TEntityPatch } from '../../../../entities/dist/common/entity.models';
import { IQueryEntityEmbedRequest } from '../../../../entities/dist/common/entityQuery.models';
import {
    IApiEntityListRequest, IApiEntityListResponseWithPageNr,
} from '../consoleApiQuery.models';
import { PolicyManagerTag } from './policy.entity.models';

export type TPortfoliosData = IApiEntityListResponseWithPageNr<IPortfolioEntityData>;
export type TPortfolio = TEntityDetail<IPortfolioEntityData>;
export type TPortfolioPatch = TEntityPatch<IPortfolioPatchEntityData>;
export type TPortfolioCreate = TEntityCreate<IPortfolioAddEntityData>;

export interface IPortfolioEntityData {
    archived: boolean;
    base_currency: SupportedCurrency;
    brokerage_account?: IPortfolioBrokerageAccount;
    config: IPortfolioConfig;
    external_id?: string;
    funded_since: string;
    money_type: PortfolioMoneyType;
    name: string;
    owned_by_user_id: TEntityUlid;
    portfolio: IPortfolioHoldings;
    snapshot_datetime: string; // e.g. 2022-02-11T08:52:19.278410+00:00
    /* start_datetime = Date of the oldest transaction of the portfolio ? */
    start_datetime: string; // e.g. 2022-03-03T04:12:14.223571+00:00
    status: PortfolioStatus;
}

export interface IPortfolioBrokerageAccount {
    bank_account_number: string;
    bank_account_type: BankAccountType; // e.g. 'IBAN'
    bank_id: string;
    brokerage_portfolio_id: string; // e.g. 'BrokerAccountKey'
    brokerage_user_id: string; // e.g. 'BrokerAccountId'
    payment_reference?: string;
}

export interface IPortfolioConfig {
    manager: PortfolioManagerType;
    manager_settings: IPortfolioManagerSettings;
    manager_version: number; // e.g. 1
}

export enum PortfolioManagerType {
    /* no robo-, but real-person-advisor that does suggestions but user has to confirm */
    // ADVISOR_ADVISORY = 'ADVISOR_ADVISORY',
    /* no robo-, but real-person-advisor that can act on the client's behalf */
    ADVISOR_DISCRETIONARY = 'ADVISOR_DISCRETIONARY',
    /* robo-advisor suggests re-balancings but user has to confirm */
    ROBO_ADVISOR_ADVISORY = 'ROBO_ADVISOR_ADVISORY',
    /* robo-advisor automatically does re-balancing (has discretion to invest on the client's behalf) */
    ROBO_ADVISOR_DISCRETIONARY = 'ROBO_ADVISOR_DISCRETIONARY',
    /* self investor */
    USER_MANAGED = 'USER_MANAGED',
}

// TODO split this up in a ROBO one and a SELF one?
//   as each only use a portion of the fields
//   (see also portfolio.schemas.ts)
export interface IPortfolioManagerSettings {
    /* === COMMON === */
    /**
     * Active in case of ROBO = Whether the optimizer service is active for this portfolio.
     * - when true: the Robo Advisor will compute optimizations automatically and provide re-balancing recommendations
     *
     * Active in case of SELF = Used to block users from placing orders, and potentially of access to any other features.
     */
    active: boolean;
    broker_provider?: BrokerProviderType;
    background_color: string;
    // eslint-disable-next-line max-len
    background_image: string; // image url e.g. 'https://images.unsplash.com/photo-1503376780353-7e6692767b70?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=82'
    /* Used by the broker to define how much commission will be charged for a portfolio's transactions. */
    commission_profile?: string;

    /* === ROBO-specific === */
    goal_id: TEntityUlid;
    horizon_id: TEntityUlid;
    policy_id: TEntityUlid;
    risk_profile_id: TEntityUlid;
    proposed_risk_profile_id: TEntityUlid;
    esg_profile?: IEsgSuitabilityProfilerSummaryResult[];
    proposed_esg_profile?: IEsgSuitabilityProfilerSummaryResult[];
    divest_amount: number;
    start_amount: number; // e.g. 1000
    recurring_deposit_amount: number;
    end_datetime: string;
    /* The suitability profile linked to this portfolio. */
    suitability_profile_id?: TEntityUlid;
    tags: PolicyManagerTag[];
    risk_profile_score: number; // e.g. 3
    /**
     * The end datetime of the Portfolio's cooldown. A 30-day cooldown period starts when there is a OPPORTUNITY optimization REJECTED.
     * During the cooldown period, users should not get a OPPORTUNITY optimization advised.
     * This cooldown_datetime is only for ROBO ADVISOR ADVISORY portfolio's.
     */
    optimization_cooldown_end?: string;

    /* === SELF-specific === */
    // ?
}

export enum BrokerProviderType {
    NONE = 'NONE',
    SAXO = 'SAXO',
    AVALOQ = 'AVALOQ',
}

export interface IEsgSuitabilityProfilerSummaryResult {
    property_id: string;
    title: IMultiTranslationsLabel;
    description: IMultiTranslationsLabel;
    selected: boolean;
}

export enum PortfolioMoneyType {
    PAPER_MONEY = 'PAPER_MONEY',
    REAL_MONEY = 'REAL_MONEY',
}

/**
 * The portfolio composition can contain both:
 * - cash : has '$<currency>' as key (e.g. $EUR) and contains the cash amount (in that currency) as value.
 * - instruments : has the ISIN as key and contains the quantity (number) of those instruments that are included
 *                 in the portfolio.
 *
 * For example: {
 *   $USD: 158.72674932629272 // cash amount
 *   US46137V3996: 63 // number of instruments
 *   US46429B4086: 4
 *   US46429B6891: 2.461
 * }
 */
export interface IPortfolioHoldings {
    [currencyOrInstrumentId: string]: number;
}

export enum PortfolioStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    WAITING_FOR_FUNDS = 'WAITING_FOR_FUNDS',
    WAITING_FOR_POLICY = 'WAITING_FOR_POLICY',
    WAITING_FOR_ACCOUNT = 'WAITING_FOR_ACCOUNT',
}

export type TPortfolioEmbeddableFields = 'owned_by_user_id';

export interface IFetchPortfoliosApiInput
    extends IApiEntityListRequest, IQueryEntityEmbedRequest<TPortfolioEmbeddableFields> {
    ownerId?: string;
    externalId?: string;
    portfolioName?: string;
    email?: string;
    virtualIban?: string;
    userLastName?: string;
    ids?: string[];
}

export interface IAdvancedPortfoliosFilters {
    externalId: string;
    portfolioName: string;
    email: string;
    virtualIban: string;
    userLastName: string;
    ids: string[];
}

export interface ISinglePortfolioApiInput
    extends IQueryEntityEmbedRequest<TPortfolioEmbeddableFields> {
    portfolioId: TEntityUlid;
}

export interface IPortfolioCreateTransactionsFlag {
    shouldCreateTransactions?: boolean;
}

export interface IPortfolioPatchEntityData extends Pick<IPortfolioEntityData,
'external_id' | 'name' | 'brokerage_account' | 'portfolio' | 'funded_since' | 'owned_by_user_id' | 'archived'> {
    config: {
        manager_settings: Pick<IPortfolioManagerSettings,
        'risk_profile_id' | 'goal_id' | 'horizon_id' | 'policy_id' | 'tags' | 'active'>,
    };
}

export interface IPortfolioAddEntityData
    extends Pick<IPortfolioEntityData, 'base_currency' | 'external_id' | 'money_type' | 'name' | 'owned_by_user_id'> {
    config: Pick<IPortfolioConfig, 'manager' | 'manager_version'> & {
        manager_settings: Pick<IPortfolioManagerSettings,
        'goal_id' | 'horizon_id' | 'policy_id' | 'start_amount' | 'tags'>;
    };
    portfolio: IPortfolioHoldings;
}
