import isSet from '@snipsonian/core/cjs/is/isSet';
import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import {
    IFetchPortfolioReportsApiInput,
    IPortfolioReportEntityData,
    TPortfolioReportCreate,
    TPortfolioReportsData,
    TPortfolioReportUpdate,
    TTriggerPortfolioReportFileUrlInput,
    PortfolioReportFileUrlFormat,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioReport.entity.models';
import {
    IDefaultUserLocale,
} from '@console/core-api/typsy/console-api-client/dist/models/common/i18n.models';
import { EntityType, IBaseIdentifiedEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import {
    infoFlashDispatcher,
    flashErrorApiEntityCreate,
    errorFlashDispatcher,
    flashErrorApiEntityPatch,
    flashSuccessApiEntityDelete,
    flashErrorApiEntityDelete,
} from 'state/entities/entitiesFlashDispatcher';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { getDefaultUserLocaleConfig } from 'state/appConfig/selectors';
import { getEntitiesManager } from '../entitiesManager';

export const portfolioReportsEntity = getEntitiesManager().registerEntity<TPortfolioReportsData>({
    asyncEntityKey: AsyncEntityKeys.portfolioReports,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_REPORTS_DATA],
    includeUpdaters: true,
});

export const triggerResetPortfolioReportsFetch = () => portfolioReportsEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchUserPortfolioReports(apiInput: IFetchPortfolioReportsApiInput & IForceStateRefreshFilter) {
    if (!validateEntityIdBeforeFetch({ entityId: apiInput.userId, entityType: EntityType.user })) {
        return null;
    }

    return triggerFetchReports(apiInput);
}

export function triggerFetchPortfolioGroupPortfolioReports(
    apiInput: IFetchPortfolioReportsApiInput & IForceStateRefreshFilter,
) {
    if (!validateEntityIdBeforeFetch({
        entityId: apiInput.portfolioId,
        entityType: EntityType.portfolioGroup,
    })) {
        return null;
    }

    return triggerFetchReports(apiInput);
}

export function triggerFetchPortfolioReports(apiInput: IFetchPortfolioReportsApiInput & IForceStateRefreshFilter) {
    if (!validateEntityIdBeforeFetch({ entityId: apiInput.portfolioId, entityType: EntityType.portfolio })) {
        return null;
    }

    return triggerFetchReports(apiInput);
}

export function triggerFetchReports({
    forceRefresh = false,
    ...apiInput
}: IFetchPortfolioReportsApiInput & IForceStateRefreshFilter = {}) {
    return portfolioReportsEntity.async.fetch<IFetchPortfolioReportsApiInput & IDefaultUserLocale>({
        api: api.portfolioReports.fetchPortfolioReports,
        // eslint-disable-next-line max-len
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars<IFetchPortfolioReportsApiInput & IDefaultUserLocale>({
            pageKey: UiPageKey.portfolioReportsList,
            state,
            apiInput: {
                ...apiInput,
                defaultUserLocale: getDefaultUserLocaleConfig(state),
            },
            mapSimpleFilterValue: (simpleFilterInput) => ({
                name: simpleFilterInput,
            }),
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: portfolioReportsEntity.select().data,
        }),
        refreshMode: () => forceRefresh || isObjectWithProps(apiInput),
        resetDataOnTriggerMode: () => {
            if (apiInput && isSet(apiInput.pageNr)) {
                return false;
            }
            return true;
        },
    });
}

export function triggerCreatePortfolioReports(payload: TPortfolioReportCreate[]) {
    return portfolioReportsEntity.async.create<TPortfolioReportCreate[], IPortfolioReportEntityData[]>({
        api: api.portfolioReports.createPortfolioReports,
        apiInputSelector: () => payload,
        onPreSuccess: () => {
            triggerResetPortfolioReportsFetch();
        },
        onSuccess: ({ dispatch }) => {
            infoFlashDispatcher('portfolio_mgmt.portfolio_reports.create.flash.succeeded')({ dispatch });
        },
        onError: flashErrorApiEntityCreate,
    });
}

export function triggerUpdatePortfolioReport(payload: TPortfolioReportUpdate) {
    return portfolioReportsEntity.async.update<TPortfolioReportUpdate, IPortfolioReportEntityData>({
        api: api.portfolioReports.updatePortfolioReport,
        apiInputSelector: () => payload,
        onSuccess: () => updatePortfolioReportNameInEntityData(payload),
        onError: flashErrorApiEntityPatch,
    });
}

function updatePortfolioReportNameInEntityData(update: TPortfolioReportUpdate) {
    const portfolioReports = portfolioReportsEntity.select().data.results;

    // eslint-disable-next-line no-param-reassign
    portfolioReports.find((report) => report.id === update.portfolioReportId).name = update.name;
}

export function triggerDeletePortfolioReport(portfolioReportIdentifier: IBaseIdentifiedEntity) {
    return portfolioReportsEntity.async.remove<IBaseIdentifiedEntity, unknown>({
        api: api.portfolioReports.deletePortfolioReport,
        apiInputSelector: () => portfolioReportIdentifier,
        onPreSuccess: () => {
            triggerFetchReports({ forceRefresh: true });
        },
        onSuccess: flashSuccessApiEntityDelete,
        onError: flashErrorApiEntityDelete,
    });
}

export function triggerDownloadPortfolioReports(reports: TTriggerPortfolioReportFileUrlInput[]) {
    return reports.forEach((report) => {
        api.portfolioReports.fetchPortfolioReportFileUrls({
            portfolioReportId: report.portfolioReportId,
            format: PortfolioReportFileUrlFormat.PDF,
        }).then((reportUrl) => window.open(reportUrl, '_blank'))
            .catch(errorFlashDispatcher('error.portfolio_report.failed'));
    });
}

export function triggerOpenPortfolioReport(payload: TTriggerPortfolioReportFileUrlInput) {
    return api.portfolioReports.fetchPortfolioReportFileUrls({
        portfolioReportId: payload.portfolioReportId,
        format: PortfolioReportFileUrlFormat.WEB,
    }).then((reportUrl) => window.open(reportUrl, '_blank'))
        .catch(errorFlashDispatcher('error.portfolio_report.failed'));
}
