import React from 'react';
import { SortOrder } from '@console/core-api/typsy/entities/dist/common/entityQuery.models';
import { IColValues, IDataItem, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { THorizon } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/horizon.entity.models';
import { UiPageKey } from 'models/state/ui.models';
import { DEFAULT_HORIZON_TAB_KEY } from 'config/tabs.config';
import { horizonsEntity, triggerFetchHorizons } from 'state/entities/portfolioMgmt/horizons';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import DataTable from 'views/common/list/DataTable';
import ListPageForApiEntity, {
    ISortConfigFunctions,
    TSetStateOnPageNrChange,
} from 'views/common/list/ListPageForApiEntity';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';

const COL_TRANSLATION_PREFIX = 'portfolio_mgmt.horizons.list.columns';

interface IHorizonCols extends IColValues {
    name: string;
    description: string;
    years: number;
    updated: string;
}

const COLS: TDataColumns<IHorizonCols> = {
    name: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.name`,
        },
        data: {
            className: UtilityClass.table.cellBold,
        },
        percentWidth: 30,
    },
    description: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.description`,
        },
        percentWidth: 40,
    },
    years: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.years`,
        },
        align: 'center',
        percentWidth: 10,
    },
    updated: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.updated`,
        },
        percentWidth: 20,
        sort: {
            initialOrder: SortOrder.Descending,
            serverSide: {
                field: 'version_datetime',
            },
        },
    },
};

export default function HorizonsList() {
    const setStateOnPageNrChange: TSetStateOnPageNrChange = (pageNr) => ({
        toState: (draftState) => {
            // eslint-disable-next-line no-param-reassign
            draftState.entities.horizons.data.pageNr = pageNr;
        },
        notificationsToTrigger: [StateChangeNotification.HORIZONS_DATA],
    });

    let sortConfigFunctions: ISortConfigFunctions;

    return (
        <ListPageForApiEntity
            notifications={[StateChangeNotification.HORIZONS_DATA, StateChangeNotification.HORIZONS_UI_VARS]}
            asyncListEntity={horizonsEntity}
            asyncListEntityFetchTrigger={triggerFetchHorizons}
            setStateOnPageNrChange={setStateOnPageNrChange}

            uiPageKey={UiPageKey.horizonsList}
            notificationToTriggerOnUiVarChanges={StateChangeNotification.HORIZONS_UI_VARS}
            box={{
                title: 'portfolio_mgmt.horizons.title',
            }}
            list={{
                renderData: renderHorizonsList,
            }}
            sort={{
                getSortConfigFunctionsCallback: (newSortConfigFunctions) => {
                    sortConfigFunctions = newSortConfigFunctions;
                },
            }}
            create={{
                toRoute: {
                    routeKey: ROUTE_KEY.R_HORIZON_ADD,
                },
                tooltip: 'portfolio_mgmt.horizons.list.actions.create_horizon',
            }}
        />
    );

    function renderHorizonsList({ data }: { data: THorizon[] }) {
        if (!data) {
            return null;
        }

        const horizonItems: IDataItem<IHorizonCols>[] = data.map((horizon) => ({
            id: horizon.id,
            colValues: {
                name: getDefaultTranslationFromApiLabel(horizon.name),
                description: getDefaultTranslationFromApiLabel(horizon.description),
                years: horizon.years,
                updated: formatDateRelativeToNow({ date: horizon.version_datetime }),
            },
        }));

        return (
            <DataTable
                cols={COLS}
                items={horizonItems}
                onItemRowClicked={openHorizonDetail}
                serverSideSorting={{
                    activeSortColumn: sortConfigFunctions.getActiveSortColumn,
                    onSelectSortColumn: (selectedSortCol) => {
                        sortConfigFunctions.setStateOnSortColumnChange(selectedSortCol);
                    },
                }}
            />
        );
    }

    function openHorizonDetail({ id }: IDataItem<IHorizonCols>) {
        redirectTo({
            routeKey: ROUTE_KEY.R_HORIZON_DETAIL,
            params: {
                horizonId: id,
                horizonTab: DEFAULT_HORIZON_TAB_KEY,
            },
        });
    }
}
