import { BankAccountType, BankIdType } from '../common/banking.models';
import { TEntityUlid, TEntity, TEntityCreate, TEntityPatch } from '../../../../entities/dist/common/entity.models';
import { IApiEntityListResponseWithPageNr } from '../consoleApiQuery.models';

export type TUsersData = IApiEntityListResponseWithPageNr<IUserEntityData>;
export type TUser = TEntity<IUserEntityData>;
export type TTUserPatch = Omit<TEntityPatch<IUserEntityData>, 'email' | 'idp_user_id'>;
export type TTUserCreate = TEntityCreate<IUserEntityData>;

export interface IUserEntityData<Locale extends string = string> {
    brokerage_user_id?: string;
    email?: string;
    external_id?: string;
    feature_flags: UserFeatureFlag[];
    counter_account?: IUserCounterAccount;
    first_name: string;
    idp_user_id?: string;
    language: Locale;
    last_name: string;
    phone: string;
    status?: UserStatus;
    agreements?: IUserAgreements;
}

export enum UserFeatureFlag {
    Console = 'CONSOLE',
    RoboAdvisor = 'ROBO_ADVISOR',
    SelfInvestor = 'SELF_INVESTOR',
    Storyteller = 'STORYTELLER',
}

export interface IUserCounterAccount {
    bank_account_number_type: BankAccountType;
    bank_account_number: string;
    bank_id_type?: BankIdType;
    bank_id?: string;
    payment_reference?: string;
}

export enum UserStatus {
    WAITING_FOR_VERIFICATION = 'WAITING_FOR_VERIFICATION',
    WAITING_FOR_SIGNATURE = 'WAITING_FOR_SIGNATURE',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export interface IUserAgreements {
    [agreementId: string]: IUserAgreement;
}

export interface IUserAgreement {
    status: UserAgreementStatus;
    document_version: number; /* Version of the current agreed document. Minimum 1. */
    datetime: string;
}

export enum UserAgreementStatus {
    AGREED = 'AGREED',
    REJECTED = 'REJECTED',
}

export interface ISingleUserApiInput {
    userId: TEntityUlid;
}

export interface IGroupThatUserBelongsTo {
    id: TEntityUlid;
    name: string;
    permissions: string[];
}
