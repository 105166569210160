import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    AccessibleByEntityType,
} from 'models/ui/apiEntityAccessibleBy.ui.models';
import { IFetchUsersApiInput } from '@console/core-api/client/userMgmt/users.api';
import { IFetchUserGroupsApiInput } from '@console/core-api/client/userMgmt/userGroups.api';
import { getAccessibleByPageVars } from 'state/ui/uiPages.selectors';
import { updateAccessibleByPageVars } from 'state/ui/uiPages.actions';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import { IOnChangeTextInputProps } from 'views/common/inputs/base/InputTextField';
import InputSelectField, { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import InputToggleField from 'views/common/inputs/base/InputToggleField';
import { IOnChangeCheckboxProps } from 'views/common/inputs/base/InputCheckboxField';
import {
    AccessibleByUserGroupsList,
    AccessibleByUsersList,
    IAccessibleByEntityListProps,
} from './AccessibleByEntityLists';

const TRANSLATION_PREFIX = 'common.generic_api_entity.accessible_by';

export interface ITriggerFetchAccessibleByListByUsingFiltersProps {
    apiInput?: IFetchUsersApiInput | IFetchUserGroupsApiInput;
    entityType?: AccessibleByEntityType;
    showOnlyEnabledPermissions?: boolean;
    forceRefresh?: boolean;
}

interface IAccessibleByEntityWrapperProps extends Omit<IAccessibleByEntityListProps, 'asyncListEntityFetchTrigger'> {
    nameForDisplay: string;
    triggerFetchAccessibleByListByUsingFilters: (props: ITriggerFetchAccessibleByListByUsingFiltersProps) => void;
}

const accessibleByEntities: IInputSelectItem<AccessibleByEntityType>[] = [{
    value: AccessibleByEntityType.Users,
    label: `${TRANSLATION_PREFIX}.fields.users`,
}, {
    value: AccessibleByEntityType.UserGroups,
    label: `${TRANSLATION_PREFIX}.fields.user_groups`,
}];

const useStyles = makeStyles((theme) => ({
    AccessibleByFilters: {
        ...mixins.flexRow({ alignMain: 'space-between' }),

        '& .entityChoiceSelect': {
            maxWidth: 150,
            padding: theme.spacing(0, 1),
        },
        '& .entityChoice': {
            ...mixins.flexRowCenterLeft(),
            ...mixins.widthMax(),
            fontWeight: 'bold',
            padding: theme.spacing(3, 0, 5, 3),
        },
        '& .showOnlyEnabledPermissionsFilter': {
            minWidth: 300,
            marginBottom: theme.spacing(3),
            ...mixins.flexRowCenterRight(),

            '& .showOnlyEnabledPermissionsToggle': {
                margin: theme.spacing(0, 2, 0, 0),
            },
        },
    },
}));

function AccessibleByEntityWrapper({
    state,
    dispatch,
    nameForDisplay,
    isReadOnly,
    readableByState,
    modifiableByState,
    onChangeAccess,
    triggerFetchAccessibleByListByUsingFilters,
}: IObserveProps & IAccessibleByEntityWrapperProps) {
    const classes = useStyles();
    const { entityType, showOnlyEnabledPermissions } = getAccessibleByPageVars(state);

    return (
        <>
            <div className={classes.AccessibleByFilters}>
                <div className="entityChoice">
                    <Translate
                        msg={`${TRANSLATION_PREFIX}.text.${isReadOnly ? 'before_select_readonly' : 'before_select'}`}
                    />
                    <InputWrapper noPadding className="entityChoiceSelect">
                        <InputSelectField<AccessibleByEntityType>
                            items={accessibleByEntities}
                            value={entityType}
                            onChange={onEntityChange}
                            itemLabelsAreTranslationKeys
                        />
                    </InputWrapper>
                    <Translate
                        msg={`${TRANSLATION_PREFIX}.text.after_select`}
                    />
                    {` ${nameForDisplay}`}
                </div>
                <div className="showOnlyEnabledPermissionsFilter">
                    <InputToggleField
                        name="show_only_enabled_permissions_filter"
                        checked={showOnlyEnabledPermissions}
                        onChange={onChangeShowOnlyEnabledPermissions}
                        className="showOnlyEnabledPermissionsToggle"
                    />
                    <Translate msg={`${TRANSLATION_PREFIX}.filter.show_only_enabled_permissions.label`} />
                </div>
            </div>

            {entityType === AccessibleByEntityType.Users &&
                (
                    <AccessibleByUsersList
                        isReadOnly={isReadOnly}
                        readableByState={readableByState}
                        modifiableByState={modifiableByState}
                        onChangeAccess={onChangeAccess}
                        asyncListEntityFetchTrigger={(apiInput) => triggerFetchAccessibleByListByUsingFilters({
                            apiInput,
                        })}
                    />
                )
            }
            {entityType === AccessibleByEntityType.UserGroups &&
                (
                    <AccessibleByUserGroupsList
                        isReadOnly={isReadOnly}
                        readableByState={readableByState}
                        modifiableByState={modifiableByState}
                        onChangeAccess={onChangeAccess}
                        asyncListEntityFetchTrigger={(apiInput) => triggerFetchAccessibleByListByUsingFilters({
                            apiInput,
                        })}
                    />
                )
            }
        </>
    );

    function onEntityChange({ value }: IOnChangeTextInputProps<AccessibleByEntityType>) {
        dispatch(updateAccessibleByPageVars({
            entityType: value,
        }));

        triggerFetchAccessibleByListByUsingFilters({
            entityType: value,
        });
    }

    function onChangeShowOnlyEnabledPermissions({ checked }: IOnChangeCheckboxProps) {
        dispatch(updateAccessibleByPageVars({
            showOnlyEnabledPermissions: checked,
        }));

        triggerFetchAccessibleByListByUsingFilters({
            showOnlyEnabledPermissions: checked,
        });
    }
}

export default observe<IAccessibleByEntityWrapperProps>(
    [StateChangeNotification.UI_PAGE_ACCESSIBLE_BY],
    AccessibleByEntityWrapper,
);
