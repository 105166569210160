import isSet from '@snipsonian/core/cjs/is/isSet';
import { decorateSchema } from '@typsy/rest-api/dist/server/swagger/decorateSchema';
import {
    object, number, string, StringSchema, boolean, array,
    ObjectShape, ObjectSchema, Schema,
    email,
    currencySchema, datetimeStringSchema,
    REGEX_COMMA_SEPARATED_ENTITY_IDS,
    getDynamicObjectSchema, IDynamicObjectSchemaOptions,
} from '@console/common/utils/schema';
import { BankAccountType } from '@console/core-api/typsy/console-api-client/dist/models/common/banking.models';
import { bankAccountNumber } from '@console/core-api/typsy/console-api-client/dist/utils/schema/bankAccountNumber';
import { bankId } from '@console/core-api/typsy/console-api-client/dist/utils/schema/bankId';
import { entityId } from '@console/core-api/utils/schema/entityIdSchema';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { ENTITY_TYPE_TO_ULID_REGEX } from '@console/core-api/typsy/entities/dist/common/entityUlid.config';
import {
    IPortfolioHoldings,
    PortfolioManagerType,
    PortfolioMoneyType,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';
import {
    IOptimizationOrders,
    IOptimizationPortfolioUpdate,
    QuantityType,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/optimization.entity.models';
import {
    OptimizationOwnerChoice,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioOptimization.entity.models';
import {
    IEnhancedPortfolioOptimization,
    IEnhancedOptimizationPortfolioUpdate,
    LATEST_OPTIMIZATION_ID,
} from '../../../models/portfolios/enhancedPortfolioOptimization.models';
import {
    ALL_ENHANCED_OPTIMIZATION_STATUSES,
    EnhancedOptimizationStatus,
} from '../../../models/enhancedOptimization.models';
import {
    coreApiCreateEntityBodySchema,
    coreApiPatchEntityBodySchema,
    instrumentMapSchema,
} from './commonBff.schemas';
import { policyManagerTagsSchema } from './policy.schemas';

/* eslint-disable max-len */
export const portfolioMoneyTypeSchema = string().oneOf(Object.values(PortfolioMoneyType)) as StringSchema<PortfolioMoneyType>;
export const portfolioManagerTypeSchema = string().oneOf(Object.values(PortfolioManagerType)) as StringSchema<PortfolioManagerType>;
export const bankAccountTypeSchema = string().oneOf(Object.values(BankAccountType)) as StringSchema<BankAccountType>;
/* eslint-enable max-len */

export const portfolioIdSchema = decorateSchema(
    entityId(EntityType.portfolio),
    { description: 'Portfolio ID' },
);

export const portfolioBrokerageAccountSchema = object({
    bank_account_number: bankAccountNumber()
        .when('bank_account_type', ([bankAccountType]) => bankAccountNumber(bankAccountType).required()),
    bank_account_type: bankAccountTypeSchema.required(),
    bank_id: bankId(),
    brokerage_portfolio_id: string(),
    brokerage_user_id: string(),
    payment_reference: string(),
});

export const esgProfileSchema = object({
    property_id: string().required(),
    /** See IEsgSuitabilityProfilerSummaryResult for the other fields it contains, but as we are not editing these esg profiles
     * from console-web, there is no use for console-web to validate them entirely. */
});

export const portfolioCreateBodySchema = getPortfolioBodySchema({ isCreateMode: true });
export const portfolioPatchBodySchema = getPortfolioBodySchema();

// TODO split this up in a ROBO one and a SELF one?
//   as each only use a portion of the fields
//   (see also portfolio.models.ts)
function getPortfolioManagerSettingsSchema({
    isCreateMode,
}: {
    isCreateMode: boolean;
} = { isCreateMode: false }) {
    const managerSettingsCreateSchema = object({
        goal_id: entityId(EntityType.goal).nullable(),
        horizon_id: entityId(EntityType.horizon).nullable(),
        policy_id: entityId(EntityType.policy).nullable(),
        tags: policyManagerTagsSchema,
        start_amount: number(),
    });

    if (isCreateMode) {
        return managerSettingsCreateSchema;
    }

    return managerSettingsCreateSchema.concat(object({
        active: boolean(),
        risk_profile_id: entityId(EntityType.riskProfile).nullable(),

        /*
         These fields are not used for patching, but since we need to pass the whole object we need to validate them
        */
        background_color: string(),
        background_image: string().url(),
        divest_amount: number().minVal(0),
        end_datetime: datetimeStringSchema,
        proposed_risk_profile_id: entityId(EntityType.riskProfile).nullable(),
        recurring_deposit_amount: number().minVal(0),
        risk_profile_score: number().minVal(0),
        broker_provider: string(),
        suitability_profile_id: string(),
        commission_profile: string(),
        optimization_cooldown_end: datetimeStringSchema,
        esg_profile: array().of(esgProfileSchema),
        proposed_esg_profile: array().of(esgProfileSchema),
    }));
}

function getPortfolioConfigSchema({
    isCreateMode,
}: {
    isCreateMode: boolean;
} = { isCreateMode: false }) {
    const portfolioConfigSchema = object({
        manager: isCreateMode ? portfolioManagerTypeSchema.required() : portfolioManagerTypeSchema,
        manager_settings: getPortfolioManagerSettingsSchema({ isCreateMode }).noUnknown(),
        manager_version: number().minVal(1),
    }).noUnknown();

    return isCreateMode
        ? portfolioConfigSchema.required()
        : portfolioConfigSchema;
}

function getPortfolioBodySchema({
    isCreateMode,
}: {
    isCreateMode: boolean;
} = { isCreateMode: false }) {
    const commonFieldsSchema = object({
        external_id: string(),
        name: isCreateMode ? string().required() : string(),
        portfolio: decorateSchema(
            getPortfolioHoldingsSchema({ isRequired: isCreateMode }),
            {
                title: 'Portfolio Holdings',
                description: 'The **positions** - including cash - currently held in this portfolio.',
                example: {
                    $USD: 1000,
                    IE00B44Z5B48: 765,
                    LU0378818131: 654,
                },
            },
        ),
        config: decorateSchema(
            getPortfolioConfigSchema({ isCreateMode }),
            {
                title: 'Portfolio Configuration',
            },
        ),
        owned_by_user_id: isCreateMode
            ? entityId(EntityType.user).required()
            : entityId(EntityType.user),
    }).concat(isCreateMode ? coreApiCreateEntityBodySchema : coreApiPatchEntityBodySchema);

    if (isCreateMode) {
        return commonFieldsSchema.concat(object({
            money_type: portfolioMoneyTypeSchema.required(),
            base_currency: currencySchema.required(),
        })).noUnknown();
    }

    return commonFieldsSchema.concat(object({
        archived: boolean()
            .test({
                message: 'Archived: should be not provided or false. Can not be used to archive a portfolio.',
                test: (value: boolean) => {
                    if (!isSet(value)) {
                        return true;
                    }

                    if (value) {
                        return false;
                    }

                    return true;
                },
            }),
        brokerage_account: portfolioBrokerageAccountSchema.nullable(),
        funded_since: datetimeStringSchema,
    })).noUnknown();
}

export function getPortfolioHoldingsSchema(options: IDynamicObjectSchemaOptions) {
    return getDynamicObjectSchema<IPortfolioHoldings>({
        ...options,
        mapper: (holdingsInput) => Object.entries(holdingsInput).reduce(
            (accumulator, [key]) => {
                accumulator[key] = number().required();
                return accumulator;
            },
            {} as ObjectShape,
        ),
    });
}

export function getOptimizationOrdersSchema(options: IDynamicObjectSchemaOptions) {
    return getDynamicObjectSchema<IOptimizationOrders>({
        ...options,
        mapper: (ordersInput) => Object.entries(ordersInput).reduce(
            (accumulator, [key]) => {
                accumulator[key] = object({
                    quantity_type: string().oneOf([
                        null,
                        ...Object.values(QuantityType),
                    ]),
                    quantity: number(),
                    shares: number(),
                    expected_share_price: number().required(),
                    expected_transaction_cost: number().required(),
                });
                return accumulator;
            },
            {} as ObjectShape,
        ),
    });
}

const optimizationPortfolioUpdateSchema: ObjectSchema<IOptimizationPortfolioUpdate> = object({
    is_recommended: boolean().required(),
    orders: getOptimizationOrdersSchema({ isRequired: true }),
    reasons: array().of(
        object({
            /* none of these fields are marked as required as we had some cases where they were not set */
            portfolio_constraint_id: string(),
            portfolio_update_constraint_id: string(),
            portfolio_update_constraint_value: number(),
        }),
    ).required(),
});

const enhancedOptimizationPortfolioUpdateSchema = object({
    nrOfBuys: number().required(),
    nrOfSells: number().required(),
}).concat(optimizationPortfolioUpdateSchema) as Schema<IEnhancedOptimizationPortfolioUpdate>;

export const enhancedPortfolioOptimizationSchema: ObjectSchema<IEnhancedPortfolioOptimization> = object({
    portfolioId: string().required(),
    status: string().oneOf(ALL_ENHANCED_OPTIMIZATION_STATUSES).required() as StringSchema<EnhancedOptimizationStatus>,
    optimizationId: string().required(),
    currency: currencySchema.required(),
    ownerChoice: string().oneOf([
        null,
        ...Object.values(OptimizationOwnerChoice),
    ]) as StringSchema<OptimizationOwnerChoice>,
    portfolio_update: enhancedOptimizationPortfolioUpdateSchema,
    holdingsAtTimeOfOptim: getPortfolioHoldingsSchema({ isRequired: true }),
    estimatedCashAfterOptim: number(),
    instrumentMap: instrumentMapSchema,
    creation_datetime: datetimeStringSchema,
    version_datetime: datetimeStringSchema,
});

export const latestOrOptimizationIdSchema = string()
    .test({
        message: 'Should be either "latest" or an existing optimization id',
        test: (value: string) => {
            if (!value) {
                return true; // mark as valid since this will be picked up by the required condition (if there is any)
            }

            if (value === LATEST_OPTIMIZATION_ID) {
                return true;
            }

            if (value.search(ENTITY_TYPE_TO_ULID_REGEX[EntityType.optimization]) > -1) {
                return true;
            }

            return false;
        },
    });

export const advancedPortfolioFiltersServerSideSchema = object({
    externalId: string(),
    email: email(),
    userLastName: string(),
    portfolioName: string(),
    virtualIban: string(),
    ids: decorateSchema(
        string().matches(
            REGEX_COMMA_SEPARATED_ENTITY_IDS, 'ids should be a comma-separated string',
        ),
        { description: 'A comma-separated list of portfolio ids to optionally filter on.' },
    ),
});
