import { IHeaders, HEADER_NAMES } from '@snipsonian/axios/cjs/header/types';
import { TResponseMapper } from '@snipsonian/axios/cjs/request/types';
import { IDownloadableDocument } from '../../models/common/file.models';

interface IOptions {
    defaultFilename?: string;
}

export function getDownloadFileResponseMapper(
    defaultFilename?: string,
): TResponseMapper<IDownloadableDocument, Blob> {
    return ({ data, headers }) => ({
        data,
        filename: getDownloadedFilenameFromHeaders(headers, { defaultFilename }),
    });
}

export function getDownloadedFilenameFromHeaders(
    headers: IHeaders,
    {
        defaultFilename = 'document',
    }: IOptions = {},
): string {
    if (headers && headers[HEADER_NAMES.CONTENT_DISPOSITION]) {
        /**
         * The "content-disposition" header will for example contain:
         *   attachment; filename=2021-01-14T14-55-26_users.csv
         */

        const parts = headers[HEADER_NAMES.CONTENT_DISPOSITION].split('=');

        return (parts && parts[1])
            ? removeSurroundingQuotes(parts[1])
            : defaultFilename;
    }

    return defaultFilename;
}

function removeSurroundingQuotes(input: string) {
    return input.trim()
        .replaceAll('"', '')
        .replaceAll('\'', '');
}
