import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { EntityType, TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    CORE_API_ENTITY_TYPE_TO_LIST_ROUTE_MAP,
    CORE_API_ENTITY_TYPE_TO_LIST_UI_PAGE_MAP,
    CORE_API_ENTITY_TYPE_TO_PLURAL_ENTITY_NAME_TRANSLATION_MAP,
} from 'config/coreEntities.ui.config';
import { IEntityStillUsedModalData } from 'models/ui/entityStillUsed.ui.models';
import React from 'react';
import { getStore } from 'state';
import { updateListAdvancedSearch } from 'state/ui/uiPages.actions';
import { getEntityTypeBasedOnId, getEntityTypeSingularNameBasedOnId } from 'utils/entities/entityTypeUtils';
import { getInitialAdvancedPolicyFilters } from 'views/portfolioMgmt/Policies/GenericPoliciesList';
import { INITIAL_ADVANCED_PORTFOLIO_FILTERS } from 'views/portfolioMgmt/Portfolios/GenericPortfoliosList';
import { openRouteInNewTab } from 'views/routes';
import { makeStyles, mixins } from 'views/styling';
import TextButton from '../buttons/TextButton';
import Modal from '../layout/Modal';

interface IPublicProps extends IEntityStillUsedModalData {
    open: boolean;
    onClose(): void;
}

type TEntityTypesWithReferencesMap = {
    [key in EntityType]: TEntityUlid[];
};

const CORE_API_ENTITY_TYPE_TO_INITIAL_ADVANCED_LIST_FILTERS_MAP: { [key in EntityType]: TObjectWithProps; } = {
    [EntityType.policy]: getInitialAdvancedPolicyFilters(),
    [EntityType.portfolio]: INITIAL_ADVANCED_PORTFOLIO_FILTERS,
    [EntityType.goal]: null,
    [EntityType.horizon]: null,
    [EntityType.riskProfile]: null,
    [EntityType.portfolioGroup]: null,
    [EntityType.optimization]: null,
    [EntityType.userGroup]: null,
    [EntityType.user]: null,
};

const CORE_API_ENTITY_TYPE_TO_SHOULD_PRESET_IDS_MAP: { [key in EntityType]: boolean } = {
    [EntityType.policy]: true,
    [EntityType.portfolio]: true,
    [EntityType.goal]: false,
    [EntityType.horizon]: false,
    [EntityType.riskProfile]: false,
    [EntityType.portfolioGroup]: false,
    [EntityType.optimization]: false,
    [EntityType.userGroup]: false,
    [EntityType.user]: false,
};

const TRANSLATION_PREFIX = 'common.generic_api_entity.entity_still_used';

const useStyles = makeStyles((theme) => ({
    EntityStillUsed: {
        ...mixins.flexColTopLeft(),

        '& .__redirectButton': {
            paddingTop: theme.spacing(2),
        },
    },
}));

export default function EntityStillUsedModal({
    onClose,
    open,
    usedByIds,
    entityId,
}: IPublicProps) {
    const classes = useStyles();
    const entityTypesWithReferencesMap = mapIdsToEntityTypesWithReferences(usedByIds);
    const { dispatch } = getStore();

    return (
        <Modal
            id="entity_still_used_modal"
            open={open}
            onClose={onClose}
            title={{
                msg: `${TRANSLATION_PREFIX}.modal_title`,
                placeholders: {
                    entityType: getEntityTypeSingularNameBasedOnId(entityId),
                },
            }}
        >
            <ul className={classes.EntityStillUsed}>
                {Object.entries(entityTypesWithReferencesMap).map(([entityType, ids]) => {
                    const coreEntityType = entityType as EntityType;
                    return (
                        <li
                            className="__redirectButton"
                            key={`redirect_to_referencing_${coreEntityType}_button`}
                        >
                            <TextButton
                                id={`redirect_to_referencing_${coreEntityType}_button`}
                                // eslint-disable-next-line max-len
                                label={CORE_API_ENTITY_TYPE_TO_PLURAL_ENTITY_NAME_TRANSLATION_MAP[coreEntityType]}
                                variant="bare"
                                onClick={() => {
                                    /*
                                        Some lists do not have advanced filtering on ids,
                                        so we need to avoid trying to preset them when pressing the link
                                    */
                                    if (CORE_API_ENTITY_TYPE_TO_SHOULD_PRESET_IDS_MAP[coreEntityType]) {
                                        dispatch(updateListAdvancedSearch({
                                            filterValues: {
                                                // eslint-disable-next-line max-len
                                                ...CORE_API_ENTITY_TYPE_TO_INITIAL_ADVANCED_LIST_FILTERS_MAP[coreEntityType],
                                                ids,
                                            },
                                            uiPageKey: CORE_API_ENTITY_TYPE_TO_LIST_UI_PAGE_MAP[coreEntityType],
                                        }));
                                    }
                                    openRouteInNewTab({
                                        routeKey: CORE_API_ENTITY_TYPE_TO_LIST_ROUTE_MAP[coreEntityType],
                                    });
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
        </Modal>
    );
}

function mapIdsToEntityTypesWithReferences(ids: TEntityUlid[]): TEntityTypesWithReferencesMap {
    return ids.reduce(
        (accumulator, entityId) => {
            const entityType = getEntityTypeBasedOnId(entityId);

            accumulator[entityType] = (accumulator[entityType] || []).concat(entityId);

            return accumulator;
        },
        {} as TEntityTypesWithReferencesMap,
    );
}
