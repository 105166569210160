import isSet from '@snipsonian/core/cjs/is/isSet';
import { roundFloat4 } from '@console/common/utils/float/roundFloat';
import {
    IEnhancedPortfolioInstrument,
    IInstrumentEntityData,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/instrument.entity.models';
import { QuantityType } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/optimization.entity.models';
import {
    IPortfolioValuationHoldingInfo,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioValuation.entity.models';
import { IEnhancedPortfolioHoldings } from '../../models/portfolios/portfolioHoldings.models';

export type TInstrumentPriceSource = 'FDA' | 'VALUATION';

export function createEnhancedInstrumentWithoutPercentage({
    quantityType = QuantityType.UNITS,
    quantity,
    instrumentPriceSource,
    instrumentData,
    instrumentValuationHoldingInfo,
}: {
    quantityType?: QuantityType;
    quantity: number;
    /**
     * "instrumentPriceSource" determines which input data is used to get the instrument price
     * - when 'FDA' --> instrumentData (if not provided, the price & the amount will be null !)
     * - when 'VALUATION' --> instrumentValuationHoldingInfo (if not provided, the price & the amount will be null !)
     */
    instrumentPriceSource: TInstrumentPriceSource;
    instrumentData?: IInstrumentEntityData;
    instrumentValuationHoldingInfo?: IPortfolioValuationHoldingInfo;
}): IEnhancedPortfolioInstrument {
    const instrumentPrice = instrumentPriceSource === 'FDA'
        ? instrumentData?.price
        : instrumentValuationHoldingInfo?.quote;

    return {
        name: instrumentData?.name,
        pricePer: instrumentPrice,
        units: calculateInstrumentUnits({
            quantityType,
            quantity,
            pricePer: instrumentPrice,
        }),
        class: instrumentData?.class,
        type: instrumentData?.type,
        percentageWithinHoldings: null,
        amount: calculateInstrumentAmount({
            quantityType,
            quantity,
            pricePer: instrumentPrice,
        }),
        deleted: false,
        edited: false,
        added: false,
        missingData: !isSet(instrumentData?.name),
        missingPrice: !isSet(instrumentPrice),
    };
}

export function calculateInstrumentAmount({
    quantityType = QuantityType.UNITS,
    quantity,
    pricePer,
}: {
    quantityType?: QuantityType;
    quantity: number;
    pricePer: number;
}): number {
    if (quantityType === QuantityType.AMOUNT) {
        return roundFloat4(quantity);
    }

    /* QuantityType.UNITS */
    if (isSet(pricePer)) {
        return roundFloat4(pricePer * quantity);
    }

    return null;
}

export function calculateInstrumentUnits({
    quantityType = QuantityType.UNITS,
    quantity,
    pricePer,
}: {
    quantityType?: QuantityType;
    quantity: number;
    pricePer: number;
}): number {
    if (quantityType === QuantityType.UNITS) {
        return roundFloat4(quantity);
    }

    /* QuantityType.AMOUNT */
    if (isSet(pricePer)) {
        return roundFloat4(quantity / pricePer);
    }

    return null;
}

export function calculateEnhancedPortfolioHoldingsPercentages(
    portfolioHoldings: IEnhancedPortfolioHoldings,
): IEnhancedPortfolioHoldings {
    const instruments = Object.entries(portfolioHoldings.instruments).map(([isin, body]) => ({
        isin,
        amount: body.amount,
        deleted: body.deleted,
    }));

    const isAmountUnknownForAtLeastOneInstrument = instruments.some((instr) => !isSet(instr.amount));

    if (isAmountUnknownForAtLeastOneInstrument) {
        return portfolioHoldings;
    }

    const totalValue = calculateTotalPortfolioValue(portfolioHoldings);

    /* Updating the percentages ... */

    /* eslint-disable no-param-reassign */
    portfolioHoldings.cash.percentageWithinHoldings = roundFloat4(portfolioHoldings.cash.amount / totalValue) || 0;
    portfolioHoldings.instrumentsPercentageWithinHoldings =
        roundFloat4((totalValue - portfolioHoldings.cash.amount) / totalValue) || 0;
    portfolioHoldings.instrumentsTotalValue = roundFloat4(totalValue - portfolioHoldings.cash.amount) || 0;
    instruments.forEach((instrument) => {
        portfolioHoldings.instruments[instrument.isin] = {
            ...portfolioHoldings.instruments[instrument.isin],
            percentageWithinHoldings:
                roundFloat4(portfolioHoldings.instruments[instrument.isin].amount / totalValue) || 0,
        };
    });
    /* eslint-enable no-param-reassign */

    return portfolioHoldings;
}

export function calculateTotalPortfolioValue(
    portfolioHoldings: IEnhancedPortfolioHoldings,
) {
    return Object.entries(portfolioHoldings.instruments).reduce(
        (accumulator, [, body]) => {
            // eslint-disable-next-line no-param-reassign
            accumulator += body.amount;
            return accumulator;
        },
        portfolioHoldings.cash.amount as number,
    );
}
