import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import {
    PolicyAllocationCategory,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { RANGE_FROM_0_TO_1 } from '@console/core-api/typsy/console-api-client/dist/config/range.config';
import {
    IPolicyAllocationBoundariesSettingsRules,
    IPolicySettingRuleBoundedNumberRangeField,
    IPolicySettingsRules,
} from 'models/ui/policySettings.ui.models';
import { allocationCategoriesList } from 'config/portfolioMgmt/policySettings.config';
import { isPolicyAssetClassEnabled } from 'state/appConfig/selectors';
import {
    isCannotDifferRule,
} from 'utils/entities/portfolioMgmt/policySettingsUtils';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import InputSelectField from 'views/common/inputs/base/InputSelectField';
import { IOnChangeTextInputProps } from 'views/common/inputs/base/InputTextField';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import ExtendedInputSlider, { IExtendedInputSliderProps } from 'views/common/inputs/extended/ExtendedInputSlider';
import {
    IOpenAllocationCategoryProps,
    IPolicySettingsFormValues,
} from './types';
import InfeasibleAllocationCategoryBoundariesWarning from '../InfeasibleAllocationCategoryBoundariesWarning';

export function renderPolicyAllocationBoundariesSectionFormContent(
    context: IExtendedInputFormContext<IPolicySettingsFormValues>, {
        setOpenAllocationCategory,
        openAllocationCategory,
    }: IOpenAllocationCategoryProps,
    initialValues: IPolicySettingsFormValues,
    policySettingsRules: IPolicySettingsRules,
) {
    const allocationRules = policySettingsRules.allocationBoundaries;

    return (
        <>
            <InfeasibleAllocationCategoryBoundariesWarning
                overallValidationError={context.overallValidationError}
            />
            <InputWrapper>
                <InputSelectField
                    value={openAllocationCategory}
                    items={allocationCategoriesList}
                    onChange={onChangeOpenAllocationCategory}
                    itemLabelsAreTranslationKeys
                />
            </InputWrapper>

            {renderOpenAllocationCategoryContent()}
        </>
    );

    function renderOpenAllocationCategoryContent() {
        switch (openAllocationCategory) {
            case PolicyAllocationCategory.assetClasses:
                return renderPolicyAllocationAssetClassesFormContent({
                    ...context, allocationRules, initialValues,
                });
            case PolicyAllocationCategory.regionBonds:
                return renderPolicyAllocationRegionBondsFormContent({
                    ...context, allocationRules, initialValues,
                });
            case PolicyAllocationCategory.regionStocks:
                return renderPolicyAllocationRegionStocksFormContent({
                    ...context, allocationRules, initialValues,
                });
            case PolicyAllocationCategory.bondTypes:
                return renderPolicyAllocationBondTypesFormContent({
                    ...context, allocationRules, initialValues,
                });
            case PolicyAllocationCategory.sectors:
                return renderPolicyAllocationSectorsFormContent({
                    ...context, allocationRules, initialValues,
                });
            default: return null;
        }
    }

    function onChangeOpenAllocationCategory({ value }: IOnChangeTextInputProps<PolicyAllocationCategory>) {
        setOpenAllocationCategory(value);
    }
}

function renderPolicyAllocationSectorsFormContent({
    fields,
    allocationRules,
    initialValues,
}: IExtendedInputFormContext<IPolicySettingsFormValues> & {
    allocationRules: IPolicyAllocationBoundariesSettingsRules;
    initialValues: IPolicySettingsFormValues;
}) {
    return (
        <>
            <AllocationSlider
                formField={fields.sectorBasicMaterials}
                initialValue={initialValues.sectorBasicMaterials}
                settingRule={allocationRules.sectorBasicMaterialsRange}
                wrapper={{
                    label: 'fields.sectors.basic_materials',
                }}
            />
            <AllocationSlider
                formField={fields.sectorCommunicationServices}
                initialValue={initialValues.sectorCommunicationServices}
                settingRule={allocationRules.sectorCommunicationServicesRange}
                wrapper={{
                    label: 'fields.sectors.communication_services',
                }}
            />
            <AllocationSlider
                formField={fields.sectorConsumerCyclical}
                initialValue={initialValues.sectorConsumerCyclical}
                settingRule={allocationRules.sectorConsumerCyclicalRange}
                wrapper={{
                    label: 'fields.sectors.consumer_cyclical',
                }}
            />
            <AllocationSlider
                formField={fields.sectorConsumerDefensive}
                initialValue={initialValues.sectorConsumerDefensive}
                settingRule={allocationRules.sectorConsumerDefensiveRange}
                wrapper={{
                    label: 'fields.sectors.consumer_defensive',
                }}
            />
            <AllocationSlider
                formField={fields.sectorEnergy}
                initialValue={initialValues.sectorEnergy}
                settingRule={allocationRules.sectorEnergyRange}
                wrapper={{
                    label: 'fields.sectors.energy',
                }}
            />
            <AllocationSlider
                formField={fields.sectorFinancialServices}
                initialValue={initialValues.sectorFinancialServices}
                settingRule={allocationRules.sectorFinancialServicesRange}
                wrapper={{
                    label: 'fields.sectors.financial_services',
                }}
            />
            <AllocationSlider
                formField={fields.sectorHealthcare}
                initialValue={initialValues.sectorHealthcare}
                settingRule={allocationRules.sectorHealthcareRange}
                wrapper={{
                    label: 'fields.sectors.healthcare',
                }}
            />
            <AllocationSlider
                formField={fields.sectorIndustrials}
                initialValue={initialValues.sectorIndustrials}
                settingRule={allocationRules.sectorIndustrialsRange}
                wrapper={{
                    label: 'fields.sectors.industrials',
                }}
            />
            <AllocationSlider
                formField={fields.sectorRealEstate}
                initialValue={initialValues.sectorRealEstate}
                settingRule={allocationRules.sectorRealEstateRange}
                wrapper={{
                    label: 'fields.sectors.real_estate',
                }}
            />
            <AllocationSlider
                formField={fields.sectorTechnology}
                initialValue={initialValues.sectorTechnology}
                settingRule={allocationRules.sectorTechnologyRange}
                wrapper={{
                    label: 'fields.sectors.technology',
                }}
            />
            <AllocationSlider
                formField={fields.sectorUtilities}
                initialValue={initialValues.sectorUtilities}
                settingRule={allocationRules.sectorUtilitiesRange}
                wrapper={{
                    label: 'fields.sectors.utilities',
                }}
            />
        </>
    );
}

function renderPolicyAllocationBondTypesFormContent({
    fields,
    allocationRules,
    initialValues,
}: IExtendedInputFormContext<IPolicySettingsFormValues> & {
    allocationRules: IPolicyAllocationBoundariesSettingsRules;
    initialValues: IPolicySettingsFormValues;
}) {
    return (
        <>
            <AllocationSlider
                formField={fields.bondTypeConvertible}
                initialValue={initialValues.bondTypeConvertible}
                settingRule={allocationRules.bondTypeConvertibleRange}
                wrapper={{
                    label: 'fields.bond_types.convertible',
                }}
            />
            <AllocationSlider
                formField={fields.bondTypeCorporate}
                initialValue={initialValues.bondTypeCorporate}
                settingRule={allocationRules.bondTypeCorporateRange}
                wrapper={{
                    label: 'fields.bond_types.corporate',
                }}
            />
            <AllocationSlider
                formField={fields.bondTypeGovernment}
                initialValue={initialValues.bondTypeGovernment}
                settingRule={allocationRules.bondTypeGovernmentRange}
                wrapper={{
                    label: 'fields.bond_types.government',
                }}
            />
        </>
    );
}

function renderPolicyAllocationRegionBondsFormContent({
    fields,
    allocationRules,
    initialValues,
}: IExtendedInputFormContext<IPolicySettingsFormValues> & {
    allocationRules: IPolicyAllocationBoundariesSettingsRules;
    initialValues: IPolicySettingsFormValues;
}) {
    return (
        <>
            <AllocationSlider
                formField={fields.regionBondsAsia}
                initialValue={initialValues.regionBondsAsia}
                settingRule={allocationRules.regionBondsAsiaRange}
                wrapper={{
                    label: 'fields.regions.asia_pacific_developed',
                }}
            />
            <AllocationSlider
                formField={fields.regionBondsEmerging}
                initialValue={initialValues.regionBondsEmerging}
                settingRule={allocationRules.regionBondsEmergingRange}
                wrapper={{
                    label: 'fields.regions.emerging',
                }}
            />
            <AllocationSlider
                formField={fields.regionBondsEurope}
                initialValue={initialValues.regionBondsEurope}
                settingRule={allocationRules.regionBondsEuropeRange}
                wrapper={{
                    label: 'fields.regions.europe_developed',
                }}
            />
            <AllocationSlider
                formField={fields.regionBondsAmerica}
                initialValue={initialValues.regionBondsAmerica}
                settingRule={allocationRules.regionBondsAmericaRange}
                wrapper={{
                    label: 'fields.regions.north_america',
                }}
            />
        </>
    );
}

function renderPolicyAllocationRegionStocksFormContent({
    fields,
    allocationRules,
    initialValues,
}: IExtendedInputFormContext<IPolicySettingsFormValues> & {
    allocationRules: IPolicyAllocationBoundariesSettingsRules;
    initialValues: IPolicySettingsFormValues;
}) {
    return (
        <>
            <AllocationSlider
                formField={fields.regionStocksAsia}
                initialValue={initialValues.regionStocksAsia}
                settingRule={allocationRules.regionStocksAsiaRange}
                wrapper={{
                    label: 'fields.regions.asia_pacific_developed',
                }}
            />
            <AllocationSlider
                formField={fields.regionStocksEmerging}
                initialValue={initialValues.regionStocksEmerging}
                settingRule={allocationRules.regionStocksEmergingRange}
                wrapper={{
                    label: 'fields.regions.emerging',
                }}
            />
            <AllocationSlider
                formField={fields.regionStocksEurope}
                initialValue={initialValues.regionStocksEurope}
                settingRule={allocationRules.regionStocksEuropeRange}
                wrapper={{
                    label: 'fields.regions.europe_developed',
                }}
            />
            <AllocationSlider
                formField={fields.regionStocksAmerica}
                initialValue={initialValues.regionStocksAmerica}
                settingRule={allocationRules.regionStocksAmericaRange}
                wrapper={{
                    label: 'fields.regions.north_america',
                }}
            />
        </>
    );
}

function renderPolicyAllocationAssetClassesFormContent({
    fields,
    allocationRules,
    initialValues,
}: IExtendedInputFormContext<IPolicySettingsFormValues> & {
    allocationRules: IPolicyAllocationBoundariesSettingsRules;
    initialValues: IPolicySettingsFormValues;
}) {
    return (
        <>
            <AllocationSlider
                formField={fields.assetClassAlternatives}
                initialValue={initialValues.assetClassAlternatives}
                settingRule={allocationRules.assetClassAlternativesRange}
                wrapper={{
                    label: 'fields.asset_classes.alternatives.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('alternatives')}
            />
            <AllocationSlider
                formField={fields.assetClassBonds}
                initialValue={initialValues.assetClassBonds}
                settingRule={allocationRules.assetClassBondsRange}
                wrapper={{
                    label: 'fields.asset_classes.bonds.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('bonds')}
            />
            <AllocationSlider
                formField={fields.assetClassCash}
                initialValue={initialValues.assetClassCash}
                settingRule={allocationRules.assetClassCashRange}
                wrapper={{
                    label: 'fields.asset_classes.cash.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('cash')}
            />
            <AllocationSlider
                formField={fields.assetClassCommodities}
                initialValue={initialValues.assetClassCommodities}
                settingRule={allocationRules.assetClassCommoditiesRange}
                wrapper={{
                    label: 'fields.asset_classes.commodities.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('commodities')}
            />
            <AllocationSlider
                formField={fields.assetClassCorporate}
                initialValue={initialValues.assetClassCorporate}
                settingRule={allocationRules.assetClassCorporateRange}
                wrapper={{
                    label: 'fields.asset_classes.corporate.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('corporate')}
            />
            <AllocationSlider
                formField={fields.assetClassEquity}
                initialValue={initialValues.assetClassEquity}
                settingRule={allocationRules.assetClassEquityRange}
                wrapper={{
                    label: 'fields.asset_classes.equity.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('equity')}
            />
            <AllocationSlider
                formField={fields.assetClassEurobond}
                initialValue={initialValues.assetClassEurobond}
                settingRule={allocationRules.assetClassEurobondRange}
                wrapper={{
                    label: 'fields.asset_classes.eurobond.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('eurobond')}
            />
            <AllocationSlider
                formField={fields.assetClassFixedIncome}
                initialValue={initialValues.assetClassFixedIncome}
                settingRule={allocationRules.assetClassFixedIncomeRange}
                wrapper={{
                    label: 'fields.asset_classes.fixed_income.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('fixed_income')}
            />
            <AllocationSlider
                formField={fields.assetClassGovernment}
                initialValue={initialValues.assetClassGovernment}
                settingRule={allocationRules.assetClassGovernmentRange}
                wrapper={{
                    label: 'fields.asset_classes.government.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('government')}
            />
            <AllocationSlider
                formField={fields.assetClassMoneyMarket}
                initialValue={initialValues.assetClassMoneyMarket}
                settingRule={allocationRules.assetClassMoneyMarketRange}
                wrapper={{
                    label: 'fields.asset_classes.money_market.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('money_market')}
            />
            <AllocationSlider
                formField={fields.assetClassRealEstate}
                initialValue={initialValues.assetClassRealEstate}
                settingRule={allocationRules.assetClassRealEstateRange}
                wrapper={{
                    label: 'fields.asset_classes.real_estate.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('real_estate')}
            />
            <AllocationSlider
                formField={fields.assetClassStocks}
                initialValue={initialValues.assetClassStocks}
                settingRule={allocationRules.assetClassStocksRange}
                wrapper={{
                    label: 'fields.asset_classes.stocks.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('stocks')}
            />
            <AllocationSlider
                formField={fields.assetClassSukuk}
                initialValue={initialValues.assetClassSukuk}
                settingRule={allocationRules.assetClassSukukRange}
                wrapper={{
                    label: 'fields.asset_classes.sukuk.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('sukuk')}
            />
            <AllocationSlider
                formField={fields.assetClassOther}
                initialValue={initialValues.assetClassOther}
                settingRule={allocationRules.assetClassOtherRange}
                wrapper={{
                    label: 'fields.asset_classes.other.label',
                }}
                isHideable
                isEnabledForTenant={isPolicyAssetClassEnabled('other')}
            />
        </>
    );
}

function AllocationSlider({
    settingRule,
    isHideable = false,
    isEnabledForTenant = true,
    ...otherProps
}: Pick<IExtendedInputSliderProps, 'formField' | 'initialValue' | 'wrapper'> & {
    settingRule: IPolicySettingRuleBoundedNumberRangeField;
    isHideable?: boolean; /* default false */
    isEnabledForTenant?: boolean; /* default true */
}) {
    if (!isEnabledForTenant && !isSet(otherProps.formField.value)) {
        return null;
    }
    return (
        <ExtendedInputSlider
            {...otherProps}
            size="S"
            readOnly={isCannotDifferRule(settingRule.rule)}
            range={settingRule.range}
            absoluteRange={RANGE_FROM_0_TO_1}
            inputHideable={(!isHideable || settingRule.isParentValueSet()) ? false : {
                formFieldValueWhenHidden: null,
            }}
        />
    );
}
