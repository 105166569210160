import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import {
    IEnhancedPortfolioInstrument,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/instrument.entity.models';
import {
    mapPortfolioManagerTypeToUniverseType,
} from '@console/core-api/utils/entities/portfolios/portfolioTypeUtils';
import {
    IEnhancedPortfolioHoldings,
    IEnhancedPortfolioInstruments,
} from '@console/bff/models/portfolios/portfolioHoldings.models';
import { IColValues, IDataItem, IToDynamicCellClassProps, TDataColumns } from 'models/list.models';
import { OPACITY } from 'config/styling/colors';
import { INITIAL_INSTRUMENT_SHARE_NR_OF_UNITS } from 'config/portfolioMgmt/portfolioHoldings.config';
import { portfolioHoldingsEntity } from 'state/entities/portfolioMgmt/portfolioHoldings';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';
import { instrumentsEntity, triggerFetchInstruments } from 'state/entities/portfolioMgmt/instruments';
import { hexToRgba } from 'utils/styling/colorUtils';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import GenericInstrumentsList, {
    getDefaultInstrumentCols,
} from 'views/portfolioMgmt/instruments/GenericInstrumentsList';
import { makeStyles, mixins } from 'views/styling';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IFormValues,
} from 'views/common/inputs/extended/ExtendedInputForm';
import IconButton from 'views/common/buttons/IconButton';
import { AddIcon, TrashIcon } from 'views/common/icons';
import DataTable from 'views/common/list/DataTable';
import TextButton from 'views/common/buttons/TextButton';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';
import { portfolioAddHoldingsSchema } from '../portfolioDetailsSchema';

const LABEL_PREFIX = 'portfolio_mgmt.portfolios.detail.holdings.add';

interface ISelectedInstrumentsFormValues extends IFormValues {
    instruments: IEnhancedPortfolioInstruments;
}

interface IOnSelectInstrumentProps {
    onRemoveInstrument: (isin: string) => void;
    onSelectInstrument: (isin: string) => void;
    noSelectInstruments?: IEnhancedPortfolioInstruments;
    selectedList?: boolean;
}

interface IPublicProps {
    currentInstruments: IEnhancedPortfolioInstruments;
    changeCurrentHoldings: (holdings: Partial<IEnhancedPortfolioHoldings>, shouldOverrideInstruments: boolean) => void;
    closeAddModal: () => void;
}

interface IInstrumentsListCols extends IColValues {
    name: string;
    type: string;
}

interface IInstrumentsListExtraData {
    selected: boolean;
}

function getInstrumentListCols({
    onSelectInstrument,
    onRemoveInstrument,
    noSelectInstruments,
    selectedList,
}: IOnSelectInstrumentProps): TDataColumns<IInstrumentsListCols> {
    return {
        name: getDefaultInstrumentCols({
            desiredCols: [{ colName: 'name', percentWidth: 75 }],
        }).name,
        type: getDefaultInstrumentCols({
            desiredCols: [{ colName: 'type', percentWidth: 10 }],
        }).type,
        action: {
            label: EMPTY_COL_TITLE,
            data: {
                render: ({ item }) => (
                    <>
                        {!selectedList && (
                            <IconButton
                                id="select_instrument"
                                icon={<AddIcon />}
                                size="S"
                                disabled={isSet(noSelectInstruments[item.id])}
                                tooltip={isSet(noSelectInstruments[item.id]) ? {
                                    msg: `${LABEL_PREFIX}.instrument_in_holdings`,
                                } : null}
                                svgSize={20}
                                onClick={() => onSelectInstrument(item.id)}
                                variant="bare"
                            />
                        )}
                        {selectedList && (
                            <IconButton
                                id="remove_instrument"
                                icon={<TrashIcon />}
                                size="S"
                                svgSize={20}
                                onClick={() => onRemoveInstrument(item.id)}
                                variant="bare"
                            />
                        )}
                    </>
                ),
            },
            percentWidth: 15,
        },
    };
}

const useStyles = makeStyles((theme) => ({
    AddHoldings: {
        '& .AddInstrumentsButton': {
            ...mixins.flexRowCenterRight(),
            paddingRight: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingTop: theme.spacing(2),
        },
        '& .__tableCell': {
            borderBottom: `1px solid ${hexToRgba('#253540', OPACITY.EXTREME)}`,
        },
    },
}));

export default function AddPortfolioHoldings({
    changeCurrentHoldings,
    currentInstruments,
    closeAddModal,
}: IPublicProps) {
    const classes = useStyles();
    const initialValues: ISelectedInstrumentsFormValues = {
        instruments: {},
    };
    const { currency } = portfolioHoldingsEntity.select().data;
    const { manager } = portfolioDetailsEntity.select().data.config;
    const universe_type = mapPortfolioManagerTypeToUniverseType(manager);

    useExecuteOnMount({
        execute: () => triggerFetchInstruments({ currency, universe_type }),
    });

    return (
        <div className={classes.AddHoldings}>
            <ExtendedInputForm<ISelectedInstrumentsFormValues>
                name="seletected_instruments_form"
                initialValues={initialValues}
                schema={portfolioAddHoldingsSchema}
                renderFormFields={renderInstrumentSelectScreens}
                submit={null}
            />
        </div>
    );

    function renderInstrumentSelectScreens(context: IExtendedInputFormContext<ISelectedInstrumentsFormValues>) {
        const { fields, setFieldValue } = context;
        const isSelectedInstrumentsListEmpty = (Object.keys(fields.instruments.value).length === 0);

        return (
            <>
                {renderSelectedInstruments()}
                {renderInstrumentsList()}
            </>
        );

        function renderSelectedInstruments() {
            if (isSelectedInstrumentsListEmpty) {
                return null;
            }

            const dataItems: IDataItem<IInstrumentsListCols, IInstrumentsListExtraData>[] =
                Object.entries(fields.instruments.value as IEnhancedPortfolioInstruments).map(([isin, body]) => ({
                    id: isin,
                    colValues: {
                        name: body.name,
                        isin,
                        type: body.type,
                    },
                }));

            return (
                <>
                    <DataTable
                        cols={getInstrumentListCols({
                            onSelectInstrument,
                            onRemoveInstrument,
                            selectedList: true,
                        })}
                        items={dataItems}
                    />
                    <div className="AddInstrumentsButton">
                        <TextButton
                            id="selected-instruments-submit"
                            label={{
                                msg: `${LABEL_PREFIX}.add_to_holdings`,
                            }}
                            variant="filled"
                            color="primary"
                            onClick={() => {
                                addInstrumentsToHoldings();
                            }}
                            disabled={!context.isValid
                                || !context.isDiff
                                || context.isSubmitting}
                        />
                    </div>
                </>
            );

            function addInstrumentsToHoldings() {
                changeCurrentHoldings({
                    instruments: fields.instruments.value as IEnhancedPortfolioInstruments,
                }, false);
                closeAddModal();
            }
        }

        function renderInstrumentsList() {
            return (
                <GenericInstrumentsList<IInstrumentsListCols, IInstrumentsListExtraData>
                    universe_type={universe_type}
                    currency={currency}
                    overrideCols={{
                        cols: getInstrumentListCols({
                            onRemoveInstrument,
                            onSelectInstrument,
                            noSelectInstruments: currentInstruments,
                            selectedList: false,
                        }),
                        toInstrumentListItem: ({ instrument }) => ({
                            id: instrument.id,
                            colValues: {
                                name: instrument.name,
                                isin: instrument.id,
                                type: instrument.type,
                            },
                            extra: {
                                selected:
                                    isSet((fields.instruments.value as IEnhancedPortfolioInstruments)[instrument.id]),
                            },
                        }),
                    }}
                    shouldDisplayRow={shouldDisplayRow}
                    onInstrumentRowClicked={(instrument) => onInstrumentRowClicked(instrument.id)}
                    styleCell={styleTableData}
                />
            );

            function onInstrumentRowClicked(instrumentId: string) {
                if (!isSet(currentInstruments[instrumentId])) {
                    return onSelectInstrument(instrumentId);
                }

                return null;
            }

            function styleTableData({
                dataItem,
            }: IToDynamicCellClassProps<IInstrumentsListCols, IInstrumentsListExtraData>) {
                if (currentInstruments[dataItem.id]) {
                    return [UtilityClass.table.cellDisabled];
                }

                return null;
            }

            function shouldDisplayRow(item: IDataItem<IInstrumentsListCols, IInstrumentsListExtraData>) {
                if (item.extra?.selected) {
                    return false;
                }

                return true;
            }
        }

        function onRemoveInstrument(isin: string) {
            /* eslint-disable no-param-reassign */
            delete (fields.instruments.value as IEnhancedPortfolioInstruments)[isin];
            /* eslint-enable no-param-reassign */
            setFieldValue({
                fieldName: fields.instruments.fieldName,
                value: fields.instruments.value,
            });
        }

        function onSelectInstrument(isin: string) {
            const instrumentToSelect: IEnhancedPortfolioInstrument = instrumentsEntity.select().data?.results.reduce(
                (accumulator, instrument) => {
                    accumulator[instrument.id] = {
                        class: instrument.class,
                        pricePer: instrument.price,
                        name: instrument.name,
                        type: instrument.type,
                        percentageWithinHoldings: 0,
                        amount: INITIAL_INSTRUMENT_SHARE_NR_OF_UNITS * instrument.price,
                        units: INITIAL_INSTRUMENT_SHARE_NR_OF_UNITS,
                        deleted: false,
                        edited: false,
                        added: true,
                        missingData: false,
                        missingPrice: false,
                    };
                    return accumulator;
                },
                {} as IEnhancedPortfolioInstruments,
            )[isin];

            const updatedSelectedInstruments: IEnhancedPortfolioInstruments =
                Object.assign(fields.instruments.value, { [isin]: instrumentToSelect });
            setFieldValue({
                fieldName: fields.instruments.fieldName,
                value: updatedSelectedInstruments,
            });
        }
    }
}
