import isSet from '@snipsonian/core/cjs/is/isSet';
import { mapKeysToKeyMap, TKeyMap } from '@console/common/utils/object/objectUtils';
import { IPolicyPortfolioConstraints } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { IPolicyIvarSpecificFormValues } from 'views/portfolioMgmt/Policies/PolicyDetail/policyFormContents/types';

/** to map BE model to form=FE model */
export function determineExcludedBuyInstrumentsMap({
    investment_universe,
    buy_universe,
}: Pick<IPolicyPortfolioConstraints, 'investment_universe' | 'buy_universe'>): TKeyMap {
    if (!isSet(buy_universe)) {
        /** buy universe not set ==> same as hold universe ==> no instruments excluded */
        return {};
    }

    const buyUniverseMap = mapKeysToKeyMap(buy_universe);

    return investment_universe.reduce(
        (accumulator, holdInstrument) => {
            if (!buyUniverseMap[holdInstrument]) {
                /** hold instrument not in buy universe ==> add instrument to excluded instruments map */
                accumulator[holdInstrument] = true;
            }
            return accumulator;
        },
        {} as TKeyMap,
    );
}

/** to map form=FE model to BE model */
export function determineBuyUniverse({
    investmentUniverse,
    excludedBuyInstrumentsMap,
}: IPolicyIvarSpecificFormValues): string[] {
    if (Object.values(excludedBuyInstrumentsMap).some((isExcluded) => !!isExcluded)) {
        const buyUniverse = investmentUniverse.filter((holdInstrument) => !excludedBuyInstrumentsMap[holdInstrument]);

        if (buyUniverse.length < investmentUniverse.length) {
            return buyUniverse;
        }
    }

    /* "buy" universe is same as "hold" universe */
    return null;
}
