import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import d3 from 'utils/libs/d3';
import { getGenericPerformanceChartConfig } from 'utils/chart/getGenericPerformanceChartConfig';
import { IPolicySimulationFuturePerformanceMarkedDate } from 'models/ui/policySimulation.ui.models';
import { IPolicyPerformanceFuture } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { IAreaDataItem, ILineDataItem } from 'views/common/charts/types';
import { CHART } from 'config/styling/chart';
import { IChartDimensions, IGenericPerformanceChartConfig } from 'models/chart.models';

interface IDate2PolicyFuturePerformanceValuesMap {
    [date: string]: IPolicySimulationFuturePerformanceMarkedDate;
}

export function getPolicySimulationChartConfig({
    chartDimensions,
    futurePerformanceData,
}: {
    chartDimensions: IChartDimensions;
    futurePerformanceData: IPolicyPerformanceFuture;
}): IGenericPerformanceChartConfig<IPolicySimulationFuturePerformanceMarkedDate> {
    const date2valuesMap: IDate2PolicyFuturePerformanceValuesMap = futurePerformanceData.date.reduce(
        (accumulator, dateString, index) => {
            accumulator[dateString] = {
                date: new Date(dateString),
                values: {
                    optimistic: futurePerformanceData.value_amount[2][index],
                    realistic: futurePerformanceData.value_amount[1][index],
                    pessimistic: futurePerformanceData.value_amount[0][index],
                },
            };
            return accumulator;
        },
        {} as IDate2PolicyFuturePerformanceValuesMap,
    );

    const optimisticLineData: ILineDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y: values.optimistic,
        }),
    );
    const realisticLineData: ILineDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y: values.realistic,
        }),
    );
    const pessimisticLineData: ILineDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y: values.pessimistic,
        }),
    );
    const areaData: IAreaDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y0: values.optimistic,
            y1: values.pessimistic,
        }),
    );

    const xDates = optimisticLineData.map((optimisticLineItem) => optimisticLineItem.x);

    const optimisticMinMax = d3.extent(futurePerformanceData.value_amount[2]) as number[];
    const pessimisticMinMax = d3.extent(futurePerformanceData.value_amount[0]) as number[];
    const globalMinMaxAmount = d3.extent(optimisticMinMax.concat(pessimisticMinMax));

    // eslint-disable-next-line max-len
    const baseConfig = getGenericPerformanceChartConfig<IDate2PolicyFuturePerformanceValuesMap, IPolicySimulationFuturePerformanceMarkedDate>({
        chartDimensions,
        xDates,
        globalMinMaxAmount,
        date2valuesMap,
    });

    return {
        ...baseConfig,
        lines: {
            optimistic: {
                data: optimisticLineData,
                color: CHART.FUTURE.OPTIMISTIC_COLOR,
                curveFactory: d3.curveBasis,
            },
            realistic: {
                data: realisticLineData,
                color: CHART.FUTURE.REALISTIC_COLOR,
                curveFactory: d3.curveBasis,
            },
            pessimistic: {
                data: pessimisticLineData,
                color: CHART.FUTURE.PESSIMISTIC_COLOR,
                curveFactory: d3.curveBasis,
            },
        },
        area: {
            data: areaData,
            color: CHART.FUTURE.AREA_COLOR,
            curveFactory: d3.curveBasis,
        },
        markerStartingPos: {
            x: baseConfig.xScale.domain()[1],
            y: {
                pessimistic: {
                    val: getLastItemOfArray(pessimisticLineData).y,
                    color: CHART.FUTURE.PESSIMISTIC_COLOR,
                    className: 'PessimisticMarker',
                },
                realistic: {
                    val: getLastItemOfArray(realisticLineData).y,
                    color: CHART.FUTURE.REALISTIC_COLOR,
                    className: 'RealisticMarker',
                },
                optimistic: {
                    val: getLastItemOfArray(optimisticLineData).y,
                    color: CHART.FUTURE.OPTIMISTIC_COLOR,
                    className: 'OptimisticMarker',
                },
            },
        },
    };
}
