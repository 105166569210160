import { stringLike } from '@typsy/schema-validation/dist/baseSchemas/stringSchema';
import { SchemaErrorType } from '@console/common/utils/schema/types';

export const CURRENCY_REGEX = /^\$[A-Z]{3}$/;
export const ISIN_REGEX = /^[A-Z]{2}[A-Z0-9]{9}[0-9]{1}$/;

export const holdingsItem = () =>
    stringLike()
        .test(
            SchemaErrorType.HoldingsItem,
            // eslint-disable-next-line no-template-curly-in-string
            '${path}: a valid ISIN instrument code or holdings currency must be provided',
            /* It's ok if the value is not set so that this test would not influence any nullable/required tests */
            (value) => value.search(ISIN_REGEX) > -1 || value.search(CURRENCY_REGEX) > -1,
        );
