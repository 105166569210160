import React from 'react';
import { TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { DEFAULT_CLIENT_TAB_KEY } from 'config/tabs.config';
import { clientsEntity, triggerFetchClients } from 'state/entities/userMgmt/clients';
import { getUserConfig } from 'state/appConfig/selectors';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import GenericUsersList from 'views/userMgmt/shared/GenericUsersList';

export default function ClientsList() {
    const { allowPatchingIdpSpecificFields } = getUserConfig();

    return (
        <GenericUsersList
            box={{
                title: 'client_mgmt.title',
            }}
            onUserRowClicked={(user) => openUserDetail(user.id)}
            create={allowPatchingIdpSpecificFields && {
                toRoute: {
                    routeKey: ROUTE_KEY.R_CLIENT_ADD,
                },
                tooltip: 'user_mgmt.users.list.actions.create_user',
            }}
            overrideEntity={{
                asyncListEntity: clientsEntity,
                asyncListEntityFetchTrigger: triggerFetchClients,
                dataNotification: StateChangeNotification.CLIENTS_DATA,
                setStateOnPageNrChange: (pageNr) => ({
                    toState: (draftState) => {
                        // eslint-disable-next-line no-param-reassign
                        draftState.entities.clients.data.pageNr = pageNr;
                    },
                    notificationsToTrigger: [StateChangeNotification.CLIENTS_DATA],
                }),
            }}
            overrideUiVars={{
                uiVarsNotification: StateChangeNotification.CLIENTS_UI_VARS,
                uiPageKey: UiPageKey.clientsList,
            }}
            overrideSimpleSearchTipTranslationKey="client_mgmt.list.filter.simple.tip"
        />
    );

    function openUserDetail(userId: TEntityUlid) {
        redirectTo({
            routeKey: ROUTE_KEY.R_CLIENT_DETAIL,
            params: {
                userId,
                userTab: DEFAULT_CLIENT_TAB_KEY,
            },
        });
    }
}
