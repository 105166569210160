import produce from 'immer';
import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { hasFetchSucceeded } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getOnlyChangedProperties } from '@console/common/utils/object/getOnlyChangedProperties';
import {
    ISingleRiskProfileApiInput,
    TRiskProfile,
    TRiskProfileCreate,
    TRiskProfilePatch,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/riskProfiles.entity.models';
import { EntityType, IBaseIdentifiedEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { DEFAULT_RISK_PROFILE_TAB_KEY } from 'config/tabs.config';
import { api } from 'api';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import {
    flashErrorApiEntityCreate, flashErrorApiEntityPatch,
    flashSuccessApiEntityPatch, flashSuccessApiEntityCreate,
} from 'state/entities/entitiesFlashDispatcher';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { triggerResetRiskProfilesFetch } from 'state/entities/portfolioMgmt/riskProfiles';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { TTitleLabelSelector } from 'views/common/layout/PageTitleBasedOnState';
import {
    apiDetailsEntityAsyncFetchAction,
    apiEntityAsyncDeleteAction,
} from '../genericApiEntity/apiEntityAsyncActions';

export const riskProfileDetailsEntity = getEntitiesManager().registerEntity<TRiskProfile>({
    asyncEntityKey: AsyncEntityKeys.riskProfileDetails,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.RISK_PROFILE_DETAILS_DATA],
    includeUpdaters: true,
});

export const getRiskProfileTitle: TTitleLabelSelector = () => {
    const riskProfileDetails = riskProfileDetailsEntity.select();

    if (hasFetchSucceeded(riskProfileDetails)) {
        return {
            text: getDefaultTranslationFromApiLabel(
                riskProfileDetails.data.name,
            ),
            shouldTranslate: false,
        };
    }

    return 'portfolio_mgmt.risk_profiles.detail.title';
};

export function triggerFetchRiskProfileDetails(apiInput: ISingleRiskProfileApiInput) {
    if (!validateEntityIdBeforeFetch({
        entityId: apiInput.riskProfileId,
        entityType: EntityType.riskProfile,
    })) {
        return null;
    }

    return apiDetailsEntityAsyncFetchAction<TRiskProfile, ISingleRiskProfileApiInput>({
        detailsEntity: riskProfileDetailsEntity,
        entityType: EntityType.riskProfile,
        api: api.riskProfiles.fetchRiskProfileDetails,
        apiInputSelector: () => apiInput,
        refreshMode: () => riskProfileDetailsEntity.select().data.id !== apiInput.riskProfileId,
        resetDataOnTriggerMode: 'always',
    });
}

export function triggerPatchRiskProfileDetails(riskProfileUpdater: (riskProfile: TRiskProfilePatch) => void) {
    return riskProfileDetailsEntity.async.update<TRiskProfilePatch, TRiskProfile>({
        api: api.riskProfiles.patchRiskProfile,
        apiInputSelector: () => ({
            ...getOnlyChangedProperties(
                riskProfileDetailsEntity.select().data,
                produce(riskProfileDetailsEntity.select().data, riskProfileUpdater),
            ),
            id: riskProfileDetailsEntity.select().data.id,
        }),
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetRiskProfilesFetch();
            apiCacheManager.clearAllRiskProfiles();
        },
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
    });
}

export function triggerCreateRiskProfile(riskProfile: TRiskProfileCreate) {
    return riskProfileDetailsEntity.async.create<TRiskProfileCreate, TRiskProfile>({
        api: api.riskProfiles.createRiskProfile,
        apiInputSelector: () => riskProfile,
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetRiskProfilesFetch();
            apiCacheManager.clearAllRiskProfiles();
        },
        onSuccess: ({ apiResult, dispatch }) => {
            flashSuccessApiEntityCreate({ dispatch });

            redirectTo({
                routeKey: ROUTE_KEY.R_RISK_PROFILE_DETAIL,
                params: {
                    riskProfileId: apiResult.id,
                    riskProfileTab: DEFAULT_RISK_PROFILE_TAB_KEY,
                },
            });
        },
        onError: flashErrorApiEntityCreate,
    });
}

export function triggerDeleteRiskProfile(riskProfileIdentifier?: IBaseIdentifiedEntity) {
    return apiEntityAsyncDeleteAction<TRiskProfile>({
        detailsEntity: riskProfileDetailsEntity,
        api: api.riskProfiles.deleteRiskProfile,
        apiInputSelector: () => riskProfileIdentifier || {
            id: riskProfileDetailsEntity.select().data.id,
        },
        onPreSuccess: () => {
            triggerResetRiskProfilesFetch();
            apiCacheManager.clearAllRiskProfiles();
            redirectTo({ routeKey: ROUTE_KEY.R_RISK_PROFILES_LIST });
        },
    });
}
