import {
    IQueryEntityEmbedResponse, IEmbeddedMap,
} from '@console/core-api/typsy/entities/dist/common/entityQuery.models';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';

interface IExtractEmbeddedEntityProps<Result, Embedded = never> {
    data: IEmbeddedMap<Embedded> | IQueryEntityEmbedResponse<Embedded>;
    id: TEntityUlid;
    mapper?: (embeddedEntity: never) => Result;
}

export function extractEmbeddedEntity<Result, Embedded = never>({
    data,
    id,
    mapper,
}: IExtractEmbeddedEntityProps<Result, Embedded>): Result {
    const embeddedMap = getEmbeddedMap(data);

    if (embeddedMap) {
        const embeddedEntity = embeddedMap[id];

        if (embeddedEntity && mapper) {
            return mapper(embeddedEntity);
        }

        return embeddedEntity as Result;
    }

    return null;
}

function getEmbeddedMap<Embedded = never>(
    data: IEmbeddedMap<Embedded> | IQueryEntityEmbedResponse<Embedded>,
): IEmbeddedMap {
    if (data) {
        /* eslint-disable no-underscore-dangle */
        if (isSet((data as IQueryEntityEmbedResponse)._embedded)) {
            // eslint-disable-next-line no-underscore-dangle
            return data._embedded as IEmbeddedMap;
        }
        /* eslint-enable no-underscore-dangle */

        return data as IEmbeddedMap;
    }

    return null;
}
