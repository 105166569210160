import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import {
    IPolicyPerformanceFuture,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { IFetchPolicyPerformanceFutureApiInput } from '@console/core-api/client/portfolioMgmt/policies.api';
import { api } from 'api';
import { getEntitiesManager } from '../entitiesManager';

export const policyFuturePerformanceEntity = getEntitiesManager().registerEntity<IPolicyPerformanceFuture>({
    asyncEntityKey: AsyncEntityKeys.policyPerformanceFuture,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.POLICY_PERFORMANCE_FUTURE_DATA],
});

export const selectPolicyFuturePerformanceEntityData = () => policyFuturePerformanceEntity.select().data;

export function triggerFetchPolicyFuturePerformance({
    forceRefresh = false,
    ...apiInput
}: IFetchPolicyPerformanceFutureApiInput) {
    return policyFuturePerformanceEntity.async.fetch<IFetchPolicyPerformanceFutureApiInput>({
        api: api.policies.fetchPolicyPerformanceFuture,
        apiInputSelector: () => ({
            quantiles: [0.2, 0.5, 0.8],
            ...apiInput,
        }),
        refreshMode: () => forceRefresh
            || selectPolicyFuturePerformanceEntityData().policyId !== apiInput.policyId,
        resetDataOnTriggerMode: () => selectPolicyFuturePerformanceEntityData().policyId !== apiInput.policyId,
    });
}
