import produce from 'immer';
import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { hasFetchSucceeded } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getOnlyChangedProperties } from '@console/common/utils/object/getOnlyChangedProperties';
import { EntityType, IBaseIdentifiedEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    ISingleUserApiInput, TTUserCreate, TTUserPatch, TUser,
} from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/user.entity.models';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { api } from 'api';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { TTitleLabelSelector } from 'views/common/layout/PageTitleBasedOnState';
import {
    flashErrorApiEntityCreate, flashErrorApiEntityPatch,
    flashSuccessApiEntityCreate, flashSuccessApiEntityPatch,
} from '../entitiesFlashDispatcher';
import {
    apiDetailsEntityAsyncFetchAction,
    apiEntityAsyncDeleteAction,
} from '../genericApiEntity/apiEntityAsyncActions';

export const userDetailsEntity = getEntitiesManager().registerEntity<TUser>({
    asyncEntityKey: AsyncEntityKeys.userDetails,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.USER_DETAILS_DATA],
    includeUpdaters: true,
});

export const getSelectedUserName = (): string => {
    const userDetails = userDetailsEntity.select();

    if (hasFetchSucceeded(userDetails)) {
        return getUserFullName(userDetails.data);
    }

    return null;
};

export const getSelectedUserNameLabel: TTitleLabelSelector = () => ({
    text: getSelectedUserName(),
    shouldTranslate: false,
});

export function triggerFetchUserDetails(apiInput: ISingleUserApiInput) {
    if (!validateEntityIdBeforeFetch({ entityId: apiInput.userId, entityType: EntityType.user })) {
        return null;
    }

    return apiDetailsEntityAsyncFetchAction<TUser, ISingleUserApiInput>({
        detailsEntity: userDetailsEntity,
        entityType: EntityType.user,
        api: api.users.fetchUserDetails,
        apiInputSelector: () => apiInput,
        refreshMode: () => userDetailsEntity.select().data.id !== apiInput.userId,
        resetDataOnTriggerMode: 'always',
    });
}

export function triggerPatchUserDetails(userUpdater: (currentUser: TTUserPatch) => void, {
    onPreSuccess,
}: {
    onPreSuccess?(): void;
} = {}) {
    return userDetailsEntity.async.update<TTUserPatch, TUser>({
        api: api.users.patchUser,
        apiInputSelector: () => ({
            ...getOnlyChangedProperties(
                userDetailsEntity.select().data,
                produce(userDetailsEntity.select().data, userUpdater),
            ),
            id: userDetailsEntity.select().data.id,
        }),
        updateDataOnSuccess: true,
        onPreSuccess: ({ apiResult }) => {
            apiCacheManager.resetUser(apiResult);

            if (onPreSuccess) {
                onPreSuccess();
            }
        },
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
    });
}

export function triggerClearUserCounterAccount() {
    return triggerPatchUserDetails((currentUser) => {
        currentUser.counter_account = null;
    });
}

export function triggerCreateUser({
    user,
    onPreSuccess,
    onSuccess,
}: {
    user: TTUserCreate;
    onPreSuccess?(): void;
    onSuccess?({ apiResult }: { apiResult: TUser }): void;
}) {
    return userDetailsEntity.async.create<TTUserCreate, TUser>({
        api: api.users.createUser,
        apiInputSelector: () => user,
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            if (onPreSuccess) {
                onPreSuccess();
            }
        },
        onSuccess: ({ apiResult, dispatch }) => {
            flashSuccessApiEntityCreate({ dispatch });
            if (onSuccess) {
                onSuccess({ apiResult });
            }
        },
        onError: flashErrorApiEntityCreate,
    });
}

export function triggerDeleteUser({
    userIdentifier,
    onPreSuccess,
}: {
    userIdentifier?: IBaseIdentifiedEntity;
    onPreSuccess?(): void;
}) {
    const identifier = userIdentifier || {
        id: userDetailsEntity.select().data.id,
    };

    return apiEntityAsyncDeleteAction<TUser>({
        detailsEntity: userDetailsEntity,
        api: api.users.deleteUser,
        apiInputSelector: () => identifier,
        onPreSuccess: () => {
            apiCacheManager.clearUser({ userId: identifier.id });

            if (onPreSuccess) {
                onPreSuccess();
            }
        },
    });
}
