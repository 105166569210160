import React from 'react';
import produce from 'immer';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import isSet from '@snipsonian/core/cjs/is/isSet';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { stringComparerAscendingIgnoreCase } from '@snipsonian/core/cjs/array/sorting/comparers';
import { Locale } from '@console/common/config/i18n.config';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    IEnhancedApiEntityVersionsComparisonField,
    IVersionsFieldChange,
} from '@console/core-api/models/apiEntityVersions.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getLocale } from 'state/i18n/selectors';
import { IObserveProps, observe } from 'views/observe';
import ApiEntityAsyncValueField from 'views/common/genericApiEntity/versions/ApiEntityAsyncValueField';
import { getFieldNameTranslationIfAny } from 'views/common/genericApiEntity/apiEntityHelper';

const TRANSLATION_PREFIX = 'common.generic_api_entity.versions.list.comparison';

interface IPublicProps {
    rootComparisonField: IEnhancedApiEntityVersionsComparisonField;
    entityType: EntityType;
}

export default observe<IPublicProps>(
    [StateChangeNotification.I18N_TRANSLATIONS],
    ApiEntityVersionsComparisonFields,
);

function ApiEntityVersionsComparisonFields({
    rootComparisonField,
    entityType,
    state,
}: IPublicProps & IObserveProps) {
    const translatedRootComparisonField = searchDisplayNameForAllFields({
        rootComparisonField,
        entityType,
        locale: getLocale(state),
    });

    const topFields = getNestedComparisonFieldEntriesToShow(translatedRootComparisonField)
        .sort(comparisonFieldEntriesComparer);

    if (topFields.length === 0) {
        return (
            <div className="__changeRow">
                <Translate msg={`${TRANSLATION_PREFIX}.no_relevant_changes`} />
            </div>
        );
    }

    return (
        <>
            {topFields.map(([topFieldName, topComparisonField], index) => {
                const key = `comparison-field_${topFieldName}_${index}`;

                return (
                    <div
                        key={key}
                        className="__changeRow"
                    >
                        <ComparisonField
                            fieldName={topFieldName}
                            comparisonField={topComparisonField}
                            isRootField
                        />
                    </div>
                );
            })}
        </>
    );
}

function getNestedComparisonFieldEntriesToShow(
    comparisonField: IEnhancedApiEntityVersionsComparisonField,
): [string, IEnhancedApiEntityVersionsComparisonField][] {
    if (!hasNestedFields(comparisonField)) {
        return [];
    }

    return Object.entries(comparisonField.nestedFields)
        .reduce(
            (accumulator, [nestedFieldName, nestedComparisonField]) => {
                if (showComparisonFieldLevel(nestedComparisonField)) {
                    accumulator.push([nestedFieldName, nestedComparisonField]);
                } else {
                    accumulator.push(
                        ...getNestedComparisonFieldEntriesToShow(nestedComparisonField),
                    );
                }
                return accumulator;
            },
            [] as [string, IEnhancedApiEntityVersionsComparisonField][],
        );
}

/**
 * For each field within the tree.
 * - when it has a translated display name: that field level will be shown in the diff tree
 * - when no translated name:
 *   - and the field does not have nested fields: that field will be shown (using the api field name)
 *                                                to be able to show its changes.
 *   - and the field has nested fields: that field level will not be shown in the tree. Its nested fields
 *                                      will be shown instead.
 */
function showComparisonFieldLevel(
    comparisonField: IEnhancedApiEntityVersionsComparisonField,
): boolean {
    if (comparisonField.isNameTranslated) {
        return true;
    }

    return !hasNestedFields(comparisonField);
}

function ComparisonField({
    fieldName,
    comparisonField,
    isRootField = false,
}: {
    fieldName: string;
    comparisonField: IEnhancedApiEntityVersionsComparisonField;
    isRootField?: boolean;
}) {
    return (
        <div className="__nestedChangeRow">
            <ComparisonFieldName comparisonField={comparisonField} isRootField={isRootField} />

            <div className="__fieldContent">
                {comparisonField.changes.length > 0 && (
                    <ComparisonFieldChanges
                        fieldName={fieldName}
                        comparisonField={comparisonField}
                    />
                )}

                {getNestedComparisonFieldEntriesToShow(comparisonField)
                    .sort(comparisonFieldEntriesComparer)
                    .map(([nestedFieldName, nestedComparisonField], index) => {
                        const key = `nested-comparison-field_${nestedFieldName}_${index}`;

                        return (
                            <ComparisonField
                                fieldName={nestedFieldName}
                                key={key}
                                comparisonField={nestedComparisonField}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
}

function ComparisonFieldName({
    comparisonField,
    isRootField,
}: {
    comparisonField: IEnhancedApiEntityVersionsComparisonField;
    isRootField: boolean;
}) {
    const nameToDisplay = hasNestedFields(comparisonField)
        ? comparisonField.displayFieldName
        // final field in the chain
        : `${comparisonField.displayFieldName}:`;

    return (
        <div className="__fieldName">
            {!isRootField && <NavigateNextIcon fontSize="small" />}
            {nameToDisplay}
        </div>
    );
}

function ComparisonFieldChanges({
    fieldName,
    comparisonField,
}: {
    fieldName: string;
    comparisonField: IEnhancedApiEntityVersionsComparisonField;
}) {
    return (
        <div className="__fieldChanges">
            {comparisonField.changes.map((change, index) => {
                const key = `change_${fieldName}_${index}`;

                return (
                    <ComparisonFieldChange
                        key={key}
                        fieldName={fieldName}
                        change={change}
                    />
                );
            })}
        </div>
    );
}

function ComparisonFieldChange({
    fieldName,
    change,
}: {
    fieldName: string;
    change: IVersionsFieldChange;
}) {
    const { changeType, value, oldValue } = change;

    if (changeType === 'changed') {
        return (
            <div>
                <ApiEntityAsyncValueField
                    value={oldValue}
                    fieldName={fieldName}
                    className="__oldValue"
                />
                &nbsp;
                <ComparisonFieldChangeAction action="to" />
                &nbsp;
                <ApiEntityAsyncValueField
                    value={value}
                    fieldName={fieldName}
                    className="__newValue"
                />
            </div>
        );
    }

    if (changeType === 'added') {
        return (
            <div>
                <ApiEntityAsyncValueField
                    value={value}
                    fieldName={fieldName}
                    className="__newValue"
                />
                &nbsp;
                <ComparisonFieldChangeAction action="added" />
            </div>
        );
    }

    if (changeType === 'removed') {
        return (
            <div>
                <ApiEntityAsyncValueField
                    value={value}
                    fieldName={fieldName}
                    className="__newValue"
                />
                &nbsp;
                <ComparisonFieldChangeAction action="removed" />
            </div>
        );
    }

    if (changeType === 'set') {
        return (
            <div>
                <ApiEntityAsyncValueField
                    value={value}
                    fieldName={fieldName}
                    className="__newValue"
                />
                &nbsp;
                <ComparisonFieldChangeAction action="set" />
            </div>
        );
    }

    throw new Error(`Unexpected comparison change type ${changeType}`);
}

function ComparisonFieldChangeAction({
    action,
}: {
    action: 'to' | 'added' | 'removed' | 'set',
}) {
    return (
        <span className="__changeAction"><Translate msg={`${TRANSLATION_PREFIX}.${action}`} /></span>
    );
}

function searchDisplayNameForAllFields({
    rootComparisonField,
    entityType,
    locale,
}: IPublicProps & { locale: Locale }): IEnhancedApiEntityVersionsComparisonField {
    return produce<IEnhancedApiEntityVersionsComparisonField>(rootComparisonField, (draftRootComparisonField) => {
        Object.entries(draftRootComparisonField.nestedFields)
            .forEach(([topFieldName, topComparisonField]) => {
                searchDisplayNameForComparisonFieldRecursive({
                    fieldName: topFieldName,
                    comparisonField: topComparisonField,
                    entityType,
                    locale,
                });
            });
    });
}

function searchDisplayNameForComparisonFieldRecursive({
    fieldName,
    comparisonField,
    entityType,
    locale,
}: {
    fieldName: string;
    comparisonField: IEnhancedApiEntityVersionsComparisonField;
    entityType: EntityType;
    locale: Locale;
}) {
    const translatedFieldName = getFieldNameTranslationIfAny({
        fieldName,
        entityType,
        locale,
    });

    if (translatedFieldName) {
        // eslint-disable-next-line no-param-reassign
        comparisonField.isNameTranslated = true;
    }

    // eslint-disable-next-line no-param-reassign
    comparisonField.displayFieldName = translatedFieldName || optimizeApiFieldForDisplay(fieldName);

    if (hasNestedFields(comparisonField)) {
        Object.entries(comparisonField.nestedFields)
            .forEach(([nestedFieldName, nestedComparisonField]) => {
                searchDisplayNameForComparisonFieldRecursive({
                    fieldName: nestedFieldName,
                    comparisonField: nestedComparisonField,
                    entityType,
                    locale,
                });
            });
    }
}

function optimizeApiFieldForDisplay(fieldName: string) {
    return fieldName.replace(/_/g, ' ');
}

function hasNestedFields(comparisonField: IEnhancedApiEntityVersionsComparisonField): boolean {
    return isSet(comparisonField.nestedFields);
}

function comparisonFieldEntriesComparer(
    fieldA: [string, IEnhancedApiEntityVersionsComparisonField],
    fieldB: [string, IEnhancedApiEntityVersionsComparisonField],
) {
    return stringComparerAscendingIgnoreCase(fieldA[1].displayFieldName, fieldB[1].displayFieldName);
}
