import isSet from '@snipsonian/core/cjs/is/isSet';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { PeriodType } from '@console/common/models/periodFilter.models';
import { FIRST_PAGE_NR } from '@console/core-api/typsy/console-api-client/dist/config/consoleApi.config';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    IAdvancedPortfolioOptimizationsFilters,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioOptimization.entity.models';
import {
    TEnhancedPortfolioOptimizations,
    TFetchEnhancedPortfolioOptimizationsApiClientInput,
} from '@console/bff/models/portfolios/enhancedPortfolioOptimizations.models';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { api } from 'api';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';

export const portfolioOptimizationsEntity = getEntitiesManager().registerEntity<TEnhancedPortfolioOptimizations>({
    asyncEntityKey: AsyncEntityKeys.portfolioOptimizations,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_OPTIMIZATIONS_DATA],
});

export function triggerFetchPortfolioOptimizations({
    forceRefresh = false,
    portfolioId,
    ...apiInput
}: TFetchEnhancedPortfolioOptimizationsApiClientInput & IForceStateRefreshFilter) {
    if (!validateEntityIdBeforeFetch({ entityId: portfolioId, entityType: EntityType.portfolio })) {
        return null;
    }

    return portfolioOptimizationsEntity.async.fetch<TFetchEnhancedPortfolioOptimizationsApiClientInput>({
        api: api.bff.portfolios.fetchEnhancedPortfolioOptimizations,
        apiInputSelector: ({ state }) => {
            const { startDate, endDate, ...otherApiInput } = apiInput;

            const optionalPeriodApiInput = isSet(startDate) && isSet(endDate)
                ? {
                    startDate,
                    endDate,
                }
                : {};

            // eslint-disable-next-line max-len
            return enrichApiInputWithListPageVars<TFetchEnhancedPortfolioOptimizationsApiClientInput, IAdvancedPortfolioOptimizationsFilters>({
                pageKey: UiPageKey.portfolioOptimizationsList,
                state,
                apiInput: {
                    portfolioId,
                    ...optionalPeriodApiInput,
                    ...otherApiInput,
                },
                mapAdvancedFilterValues: (advancedFilterValues) => ({
                    ownerChoice: advancedFilterValues.ownerChoice,
                    type: advancedFilterValues.type,
                    ...(advancedFilterValues.period.type !== PeriodType.ALL_TIME
                        ? {
                            startDate: advancedFilterValues.period.startDate,
                            endDate: advancedFilterValues.period.endDate,
                        } : {}),
                }),
            });
        },
        mapApiResponse: ({ response }) => {
            if (response.pageNr > FIRST_PAGE_NR) {
                /* not the first page was retrieved >> append results to existing results array */
                return {
                    ...response,
                    results: portfolioOptimizationsEntity.select().data.results.concat(response.results),
                };
            }

            /* initial/first page >> just overwrite the data results */
            return response;
        },
        refreshMode: () => forceRefresh || isObjectWithProps(apiInput)
            || isRequestedPortfolioIdDifferentThanBefore(portfolioId),
        resetDataOnTriggerMode: () => isRequestedPortfolioIdDifferentThanBefore(portfolioId),
    });
}

function isRequestedPortfolioIdDifferentThanBefore(portfolioId: string) {
    return portfolioOptimizationsEntity.select().data.portfolioId !== portfolioId;
}
