import splitEvery from 'ramda/src/splitEvery';
import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import {
    IApiEntityPaginatedListResponse, IApiEntityListResponseWithPageNr, IApiEntityListRequest,
} from '../../typsy/console-api-client/dist/models/consoleApiQuery.models';
import {
    IInstrumentEntityData,
    TInstrumentsData, UniverseType,
} from '../../typsy/console-api-client/dist/models/portfolioMgmt/instrument.entity.models';
import {
    MAX_PAGE_ITEM_LIMIT, DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR, LIST_RESPONSE_NO_COUNT,
} from '../../typsy/console-api-client/dist/config/consoleApi.config';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../typsy/console-api-client/dist/utils/fetch/fetchApiEntityUrlParamBuilder';
import { get } from '../coreApiRequestWrapper';
import { TEntityUlid } from '../../typsy/entities/dist/common/entity.models';

export interface IFetchSpecificInstrumentsApiInput extends IApiEntityListRequest {
    instrumentIds?: TEntityUlid[];
    universe_type: UniverseType;
    currency: string;
}

/**
 * Fetches the instrument details for all the given instruments,
 * if needed (more than 100 id's) in chunks of 100 id's which are called in parallel.
 */
export async function fetchAllSpecificInstruments({
    instrumentIds,
    universe_type,
    currency,
    failOnApiError = true,
    underlyingApiRequestConfig,
// eslint-disable-next-line max-len
}: Pick<IFetchSpecificInstrumentsApiInput, 'instrumentIds' | 'universe_type' | 'currency'> & IUnderlyingRequestApiInput & {
    failOnApiError?: boolean;
}): Promise<IApiEntityPaginatedListResponse<IInstrumentEntityData>> {
    const instrumentIdArrayChunks = splitEvery(MAX_PAGE_ITEM_LIMIT, instrumentIds);

    const chunkResults = await Promise.all(
        instrumentIdArrayChunks.map((max100InstrumentIds) => fetchInstruments({
            limit: MAX_PAGE_ITEM_LIMIT,
            instrumentIds: max100InstrumentIds,
            universe_type,
            currency,
            underlyingApiRequestConfig,
        })
            .catch((error) => {
                if (failOnApiError) {
                    return Promise.reject(error);
                }

                return Promise.resolve({
                    next: null,
                    offset: null,
                    count: LIST_RESPONSE_NO_COUNT,
                    results: [],
                });
            })),
    );

    return chunkResults.reduce(
        (accumulator, chunkResult) => {
            accumulator.results = accumulator.results.concat(chunkResult.results);

            return accumulator;
        },
        {
            next: null,
            offset: null,
            results: [],
        } as IApiEntityPaginatedListResponse<IInstrumentEntityData>,
    );
}

export interface IFetchInstrumentsApiInput extends IApiEntityListRequest {
    universe_type: UniverseType;
    currency: string;
    name?: string; // contains check
    instrumentIds?: string[]; // array contains check
    partialInstrumentId?: string; // contains match check
}

export function fetchInstruments({
    name,
    offset,
    instrumentIds,
    partialInstrumentId,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    universe_type,
    currency,
    underlyingApiRequestConfig,
}: IFetchInstrumentsApiInput & IUnderlyingRequestApiInput) {
    return get<TInstrumentsData, IApiEntityListResponseWithPageNr<IInstrumentEntityData>>({
        url: ConsoleApiPath.INSTRUMENTS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .contains({ field: 'name', value: name })
                .fieldIn({ field: 'id', value: instrumentIds })
                .contains({ field: 'id', value: partialInstrumentId })
                .build(),
            universe_type,
            currency,
            offset,
            limit,
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}
