import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { getCurrentBackendDatetime } from '@console/common/utils/date/backendDatetimeUtils';
import {
    IEnhancedPortfolioHoldings,
    IEnhancedPortfolioInstruments,
    IEnhancedPortfolioCash,
} from '@console/bff/models/portfolios/portfolioHoldings.models';
import { PortfolioMoneyType } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';
import { IPortfolioHoldings } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioHoldings.entity.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { APP_COLORS } from 'config/styling/colors';
import {
    portfolioHoldingsEntity,
    triggerFetchPortfolioHoldings,
    triggerPatchPortfolioHoldings,
} from 'state/entities/portfolioMgmt/portfolioHoldings';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';
import { canUserModifyPortfolio } from 'state/auth/apiEntityAuthorization.selectors';
import { isPortfolioLinkedToBroker } from 'utils/entities/portfolioMgmt/portfolioUtils';
import { makeStyles, mixins } from 'views/styling';
import { IObserveProps, observe } from 'views/observe';
import { IRenderDataProps, EntityWrapperNoExtraObserve } from 'views/common/widget/EntityWrapper';
import ExtendedInputForm,
{
    IOnSubmitProps,
    IExtendedInputFormContext,
} from 'views/common/inputs/extended/ExtendedInputForm';
import ContentBox from 'views/common/layout/ContentBox';
import PortfolioHoldingsWrapper from './PortfolioHoldingsWrapper';
import { IPortfolioHoldingsFormValues } from '../portfolioFormContents/types';
import { enhancedPortfolioHoldingsSchema } from '../portfolioDetailsSchema';

const useStyles = makeStyles((theme) => ({
    PortfolioHoldings: {
        '& .ButtonRow': {
            ...mixins.flexRowCenterRight(),
            paddingBottom: theme.spacing(5),
        },
        '& .SingleButton': {
            ...mixins.flexRowCenterLeft(),
            paddingLeft: theme.spacing(5),
        },
        '& .ScreenDescription': {
            padding: theme.spacing(2),
            ...mixins.flexCenter(),
            ...mixins.typoBold({ size: 15 }),
        },
        '& .AdjustedHeight': {
            height: 47,
            ...mixins.flexCenter(),

            '& .input-wrapped': {
                ...mixins.widthMax(),
            },
        },
        '& .CashDisplay': {
            ...mixins.flexRow(),
        },
        '& .CashNameDisplay': {
            paddingRight: theme.spacing(1),
        },
        '& .RecalculateCashBaner': {
            ...mixins.widthMax(),
            ...mixins.flexRow(),
            ...mixins.typo({ color: APP_COLORS.PRIMARY['600'] }),
        },
        '& .IconTextAlign': {
            ...mixins.widthMax(),
            ...mixins.flexRowCenterLeft(),
        },
        '& .IconSpacing': {
            paddingRight: theme.spacing(2),
        },
        '& .RecalculateButtons': {
            ...mixins.flexRowCenterRight(),
        },
        '& .RecalculationWarning': {
            ...mixins.typo({ color: APP_COLORS.FEEDBACK.ERROR }),
        },
        '& .RecalculateRowBackground': {
            backgroundColor: APP_COLORS.SYSTEM.BACKGROUND,
            borderColor: APP_COLORS.SYSTEM.WHITE,
            borderWidth: 10,
            borderStyle: 'solid',
        },
        '& .MissingInstrumentData': {
            ...mixins.typo({ color: APP_COLORS.FEEDBACK.ERROR }),
            ...mixins.flexCenter(),
            ...mixins.widthMax(),
        },
    },
}));

interface IHoldingsProps
    extends Pick<IExtendedInputFormContext<IPortfolioHoldingsFormValues>, 'fields' | 'setFieldValue'> {
    currentHoldings: IEnhancedPortfolioHoldings;
}

function PortfolioHoldings({
    state,
}: IObserveProps) {
    const [isEditActive, setIsEditActive] = useState<boolean>(false);
    const { portfolioId } = useParams<IPossiblePathParams>();
    const classes = useStyles();

    return (
        <div className={classes.PortfolioHoldings}>
            <EntityWrapperNoExtraObserve
                asyncEntitySelector={portfolioHoldingsEntity.select}
                renderData={renderPortfolioHoldings}
            />
        </div>
    );

    function renderPortfolioHoldings({
        data: enhancedPortfolioHoldings,
        translator,
    }: IRenderDataProps<IEnhancedPortfolioHoldings>) {
        const portfolioDetailsData = portfolioDetailsEntity.select().data;
        const isPortfolioVirtual = (portfolioDetailsData.money_type === PortfolioMoneyType.PAPER_MONEY);
        const isReadOnly = !canUserModifyPortfolio(portfolioDetailsData, state)
            || (!isPortfolioVirtual && isPortfolioLinkedToBroker(portfolioDetailsData));
        const initialValues: IPortfolioHoldingsFormValues = {
            cash: enhancedPortfolioHoldings.cash,
            currency: enhancedPortfolioHoldings.currency,
            instruments: enhancedPortfolioHoldings.instruments,
            instrumentsPercentageWithinHoldings: enhancedPortfolioHoldings.instrumentsPercentageWithinHoldings,
            instrumentsTotalValue: enhancedPortfolioHoldings.instrumentsTotalValue,
        };

        return (
            <div>
                {!isEditActive && (
                    <>
                        {renderPortfolioHoldingsList({
                            currentHoldings: enhancedPortfolioHoldings,
                            fields: null,
                            setFieldValue: null,
                        })}
                    </>
                )}
                {isEditActive && (
                    <ExtendedInputForm<IPortfolioHoldingsFormValues>
                        name="edit-holdings-form"
                        schema={enhancedPortfolioHoldingsSchema}
                        initialValues={initialValues}
                        submit={{
                            additionalConditionToDisable: isAnyInstrumentMissingData,
                            onSubmit: updatePortfolioHoldings,
                        }}
                        cancel={{
                            onCancel: () => {
                                setIsEditActive(false);
                            },
                        }}
                        renderFormFields={({ setFieldValue, fields }) => renderPortfolioHoldingsList({
                            currentHoldings: {
                                cash: fields.cash.value as IEnhancedPortfolioCash,
                                instruments: fields.instruments.value as IEnhancedPortfolioInstruments,
                                currency: fields.currency.value as SupportedCurrency,
                                instrumentsTotalValue: fields.instrumentsTotalValue.value as number,
                                instrumentsPercentageWithinHoldings:
                                    fields.instrumentsPercentageWithinHoldings.value as number,
                                valuation_date: enhancedPortfolioHoldings.valuation_date,
                            },
                            setFieldValue,
                            fields,
                        })}
                        placeFormActionsInFixedFooter
                    />
                )}
            </div>
        );

        function isAnyInstrumentMissingData({ values }: IOnSubmitProps<IPortfolioHoldingsFormValues>): boolean {
            const missingData = Object.values(values.instruments).some((instrument) => instrument.missingData);
            console.log('isAnyInstrumentMissingData', missingData);
            return missingData;
        }

        function renderPortfolioHoldingsList({
            currentHoldings,
            setFieldValue,
            fields,
        }: IHoldingsProps) {
            return (
                <>
                    {!isReadOnly && (
                        <div className="ScreenDescription">
                            <Translate msg="portfolio_mgmt.portfolios.detail.holdings.screen_description" />
                        </div>
                    )}
                    <ContentBox>
                        <EntityWrapperNoExtraObserve
                            asyncEntitySelector={portfolioDetailsEntity.select}
                            setMinHeight={false}
                            renderData={() => null}
                        />
                        <PortfolioHoldingsWrapper
                            translator={translator}
                            currentHoldings={currentHoldings}
                            initialHoldings={enhancedPortfolioHoldings}
                            setFieldValue={setFieldValue}
                            setIsEditActive={setIsEditActive}
                            fields={fields}
                            isEditActive={isEditActive}
                            isReadOnly={isReadOnly}
                            portfolioId={portfolioId}
                        />
                    </ContentBox>
                </>
            );
        }

        function updatePortfolioHoldings({ values }: IOnSubmitProps<IPortfolioHoldingsFormValues>) {
            return triggerPatchPortfolioHoldings({
                portfolioUpdater: (currentPortfolio) => {
                    /* eslint-disable no-param-reassign */
                    currentPortfolio.portfolio = {};

                    if (values.instruments && isArrayWithValues(Object.keys(values.instruments))) {
                        currentPortfolio.portfolio = Object.entries(values.instruments).reduce(
                            (accumulator, [isin, body]) => {
                                if (!body.deleted && body.units !== 0) {
                                    accumulator[isin] = body.units;
                                }
                                return accumulator;
                            },
                            {} as IPortfolioHoldings,
                        );
                    }

                    if (values.cash.amount !== null) {
                        currentPortfolio.portfolio[`$${values.currency}`] = values.cash.amount;

                        if (!isPortfolioLinkedToBroker(portfolioDetailsData) && !isSet(currentPortfolio.funded_since)) {
                            currentPortfolio.funded_since = getCurrentBackendDatetime();
                        }
                    }
                    /* eslint-enable no-param-reassign */
                },
                shouldCreateTransactions: isPortfolioVirtual,
            }).then(() => setIsEditActive(false))
                .then(() => triggerFetchPortfolioHoldings({ portfolioId, forceRefresh: true }));
        }
    }
}

export default observe(
    [StateChangeNotification.PORTFOLIO_HOLDINGS],
    PortfolioHoldings,
);
