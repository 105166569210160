import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IGoalEntityData } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/goal.entity.models';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import { goalDetailsEntity, triggerCreateGoal } from 'state/entities/portfolioMgmt/goalDetails';
import { getMultiTranslationsLabelInitialValue } from 'state/appConfig/selectors';
import AddPage from 'views/common/add/AddPage';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import GoalPropertiesForm, { IGoalFormValues, mapGoalFormValuesToApiEntity } from '../GoalDetail/GoalPropertiesForm';

export default function GoalAdd() {
    const initialValues: IGoalFormValues = {
        name: getMultiTranslationsLabelInitialValue(),
        description: getMultiTranslationsLabelInitialValue(),
        type: undefined,
        minHorizonYears: undefined,
        imgUrl: '',
        externalId: '',
    };

    return (
        <AddPage
            titleLabel="portfolio_mgmt.goals.add.title"
            entity={{
                notifications: [StateChangeNotification.GOALS_DATA],
                asyncEntitySelector: goalDetailsEntity.select,
            }}
            cancel={{
                onCancel: redirectToList,
            }}
        >
            <GoalPropertiesForm
                initialValues={initialValues}
                mode="add"
                submit={{
                    onSubmit: onSubmitAdd,
                }}
            />
        </AddPage>
    );

    function onSubmitAdd({ values }: IOnSubmitProps<IGoalFormValues>) {
        return triggerCreateGoal(
            enrichApiEntityDataForCreate<IGoalEntityData>(mapGoalFormValuesToApiEntity(values)),
        );
    }

    function redirectToList() {
        redirectTo({
            routeKey: ROUTE_KEY.R_GOALS_LIST,
        });
    }
}
