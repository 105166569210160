import React from 'react';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { getStore } from 'state';
import { canUserCreatePortfolioReport } from 'state/auth/apiEntityAuthorization.selectors';
import { portfolioGroupDetailsEntity } from 'state/entities/portfolioMgmt/portfolioGroupDetails';
import { openCreatePortfolioReportAppModal } from 'state/ui/appModals.actions';
import { ReportIcon } from 'views/common/icons';
import GenericReportsList, {
    getDefaultReportCols,
    IDefaultPortfolioReportCols,
} from 'views/apps/StoryTeller/GenericReportsList';
import { triggerFetchPortfolioGroupPortfolioReports } from 'state/entities/portfolioMgmt/portfolioReports';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPortfolioGroupReportsCols extends Omit<IDefaultPortfolioReportCols, 'type'> {}

const COLS = getDefaultReportCols({
    desiredCols: [
        { colName: 'name', percentWidth: 30 },
        { colName: 'client', percentWidth: 15 },
        { colName: 'period', percentWidth: 15 },
        { colName: 'language', percentWidth: 5 },
        { colName: 'created', percentWidth: 15 },
        { colName: 'status', percentWidth: 10 },
        { colName: 'actions', percentWidth: 5 },
    ],
});

export default function PortfolioGroupReports() {
    const portfolioGroupDetailsData = portfolioGroupDetailsEntity.select().data;
    const canUserCreateReports = canUserCreatePortfolioReport();

    return (
        <GenericReportsList<IPortfolioGroupReportsCols>
            overrideCols={{
                cols: COLS,
                toReportListItem: ({ report, defaultReportCols }) => ({
                    id: report.id,
                    colValues: defaultReportCols,
                }),
            }}
            asyncListEntityFetchTriggerOverride={(apiInput) => triggerFetchPortfolioGroupPortfolioReports({
                ...apiInput,
                portfolioId: portfolioGroupDetailsData.id,
            })}
            shouldDisplayFilters={false}
            listActions={[{
                id: 'create_portfolio_report_button',
                icon: <ReportIcon />,
                disabled: !canUserCreateReports,
                tooltip: !canUserCreateReports && 'common.action.insufficient_permissions',
                tooltipPlacement: 'left',
                label: 'portfolio_mgmt.portfolio_reports.create.portfolio_group_button_label',
                onClick: () => getStore().dispatch(openCreatePortfolioReportAppModal({
                    extraData: {
                        entityType: EntityType.portfolioGroup,
                        reportsInputData: [{
                            entityCreationDatetime: portfolioGroupDetailsData.creation_datetime,
                            entityId: portfolioGroupDetailsData.id,
                            entityName: portfolioGroupDetailsData.name,
                            portfolioIds: portfolioGroupDetailsData.portfolio_ids,
                        }],
                    },
                })),
            }]}
        />
    );
}
