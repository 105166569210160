import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { IFetchRiskProfilesApiInput } from '@console/core-api/client/portfolioMgmt/riskProfiles.api';
import { TRiskProfilesData } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/riskProfiles.entity.models';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { isRouteLocation } from 'state/ui/selectors';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { ROUTE_KEY } from 'views/routeKeys';

export const riskProfilesEntity = getEntitiesManager().registerEntity<TRiskProfilesData>({
    asyncEntityKey: AsyncEntityKeys.riskProfiles,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.RISK_PROFILES_DATA],
    includeUpdaters: true,
});

export const triggerResetRiskProfilesFetch = () => riskProfilesEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchRiskProfiles({
    forceRefresh = false,
    ...apiInput
}: IFetchRiskProfilesApiInput & IForceStateRefreshFilter = {}) {
    return riskProfilesEntity.async.fetch<IFetchRiskProfilesApiInput>({
        api: api.riskProfiles.fetchRiskProfiles,
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars<IFetchRiskProfilesApiInput, unknown>({
            pageKey: UiPageKey.riskProfilesList,
            state,
            apiInput,
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: riskProfilesEntity.select().data,
        }),
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_RISK_PROFILE_DETAIL,
        }),
        resetDataOnTriggerMode: 'never',
    });
}
