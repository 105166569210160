import isSet from '@snipsonian/core/cjs/is/isSet';
import isString from '@snipsonian/core/cjs/is/isString';
import isDate from '@snipsonian/core/cjs/is/isDate';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import ensureArray from '@snipsonian/core/cjs/array/ensureArray';
import { IUrlParams } from '@snipsonian/core/cjs/url/types';
import { formatDateAsISOString } from '../../../../date/dist/formatting/formatDate';
import { joinParts } from '../../../../core/dist/string/joinParts';
import { IOrderByField, SortOrder } from '../../../../entities/dist/common/entityQuery.models';

/**
 * See https://investsuite.atlassian.net/wiki/spaces/CA/pages/1234239540/Querying+specification+incl.+search+and+filter
 * for the supported query functionalities.
 */

export const ARRAY_SEPARATOR = ', ';

interface IFetchApiEntityBaseQueryProps {
    field: string;
}

interface IFetchApiEntityContainsQueryProps extends IFetchApiEntityBaseQueryProps {
    value: string;
}

interface IFetchApiEntityExactMatchQueryProps extends IFetchApiEntityBaseQueryProps {
    value: string | number | boolean;
}

interface IFetchApiEntityFieldInQueryProps extends IFetchApiEntityBaseQueryProps {
    value: string[];
}

interface IFetchApiEntityArrayContainsQueryProps extends IFetchApiEntityBaseQueryProps {
    value: string[];
}

interface IFetchApiEntityDateInRangeQueryProps extends IFetchApiEntityBaseQueryProps {
    /* if string: should be in the YYYY-MM-DDTHH:mm:ss.sss format */
    startDate: Date | string;
    endDate: Date | string;
}

interface IFetchApiEntityEmbedProps<EmbeddableField> {
    fields: EmbeddableField | EmbeddableField[];
}

interface IFetchApiEntityUrlParamBuilder {
    contains: (containsProps: IFetchApiEntityContainsQueryProps) => IFetchApiEntityUrlParamBuilder;
    exactMatch: (exactMatchProps: IFetchApiEntityExactMatchQueryProps) => IFetchApiEntityUrlParamBuilder;
    isNull: (isNullProps: IFetchApiEntityBaseQueryProps) => IFetchApiEntityUrlParamBuilder;
    /**
     * Will give a positive match if the field has at least 1 of the input values.
     */
    fieldIn: (fieldInProps: IFetchApiEntityFieldInQueryProps) => IFetchApiEntityUrlParamBuilder;
    /**
     * This function makes separate queries for each array entry.
     * Thanks to this the returned results at least have all the elements of the queried array,
     * not only 1 like in the 'fieldIn' query.
     */
    arrayContains: (arrayContainsProps: IFetchApiEntityArrayContainsQueryProps) => IFetchApiEntityUrlParamBuilder;
    /**
     * If you want to find records with a datetime only within given dates
     */
    dateInRange: (dateInRangeProps: IFetchApiEntityDateInRangeQueryProps) => IFetchApiEntityUrlParamBuilder;
    /**
     * If you want to sort on multiple fields, just call this function multiple times.
     * Default order = 'asc'(ending)
     */
    orderBy: (orderByField: IOrderByField) => IFetchApiEntityUrlParamBuilder;
    // eslint-disable-next-line max-len
    embed: <EmbeddableField = string>(embedProps: IFetchApiEntityEmbedProps<EmbeddableField>) => IFetchApiEntityUrlParamBuilder;
    /**
     * Returns an object containing url params.
     * This has the following advantages (vs. just returning the query string):
     * - the result can be added to an existing query-params object via the spread syntax
     * - if the name of the url param to specify the filter/query changes, it only has to be changed here in 1 place
     * - for now only one url param is returned, but this allows that in the future extra params are added
     */
    build: () => IUrlParams;
}

enum QueryOperators {
    AND = 'and',
}

export default function fetchApiEntityUrlParamBuilder(): IFetchApiEntityUrlParamBuilder {
    let filter: string;
    let orderBy: string;
    let embed: string;

    const builder: IFetchApiEntityUrlParamBuilder = {
        contains: ({ field, value }) => {
            if (value) {
                appendFilter(`${field} eq '*${value}*'`, QueryOperators.AND);
            }
            return builder;
        },
        exactMatch: ({ field, value }) => {
            if (isString(value)) {
                if (value) {
                    appendFilter(`${field} eq '${value}'`, QueryOperators.AND);
                }
            // isSet instead of just "if (value)" otherwise e.g. number value 0 would not be appended
            } else if (isSet(value)) {
                appendFilter(`${field} eq ${value}`, QueryOperators.AND);
            }

            return builder;
        },
        isNull: ({ field }) => {
            appendFilter(`${field} is null`, QueryOperators.AND);
            return builder;
        },
        arrayContains: ({ field, value }) => {
            if (value) {
                value.forEach((element) => {
                    appendFilter(`${field} in ['${element}']`, QueryOperators.AND);
                });
            }
            return builder;
        },
        fieldIn: ({ field, value }) => {
            if (isArrayWithValues(value)) {
                appendFilter(`${field} in [${mapArrayOfValuesToString(value)}]`, QueryOperators.AND);
            }
            return builder;
        },
        dateInRange: ({ field, startDate, endDate }) => {
            if (startDate && endDate) {
                const from = isDate(startDate) ? formatDateAsISOString(startDate) : startDate;
                const to = isDate(endDate) ? formatDateAsISOString(endDate) : endDate;

                appendFilter(
                    `${field} in ['${from}' to '${to}']`,
                    QueryOperators.AND,
                );
            }
            return builder;
        },
        orderBy: (orderByField) => {
            if (orderByField) {
                const { field, order = SortOrder.Ascending } = orderByField;

                appendOrderBy(`${field} ${mapSortOrderToApiSort(order)}`);
            }
            return builder;
        },
        embed: <EmbeddableField = string>({ fields }: IFetchApiEntityEmbedProps<EmbeddableField>) => {
            ensureArray(fields).forEach((embedField) => appendEmbed(embedField as unknown as string));
            return builder;
        },
        build: () => {
            const queryParam = !filter && !orderBy
                ? null
                : {
                    query: `${joinQueryPartsThatAreSet(filter, orderBy)}`,
                };

            const embedParam = !embed
                ? null
                : {
                    embed,
                };

            if (!queryParam && !embedParam) {
                return null;
            }

            return {
                ...queryParam,
                ...embedParam,
            };
        },
    };

    return builder;

    function appendFilter(filterPartToAppend: string, operator: QueryOperators) {
        if (!filter) {
            filter = filterPartToAppend;
        } else {
            filter += ` ${operator} ${filterPartToAppend}`;
        }
    }

    function appendOrderBy(orderByToAppend: string) {
        if (!orderBy) {
            orderBy = `orderby ${orderByToAppend}`;
        } else {
            orderBy += `, ${orderByToAppend}`;
        }
    }

    function appendEmbed(embedField: string) {
        if (!embed) {
            embed = embedField;
        } else {
            embed += `,${embedField}`;
        }
    }
}

function joinQueryPartsThatAreSet(...partsToJoin: string[]) {
    return joinParts(
        partsToJoin,
        { joinSeparator: ' ' },
    );
}

function mapSortOrderToApiSort(order: SortOrder): 'asc' | 'desc' {
    return order === SortOrder.Ascending
        ? 'asc'
        : 'desc';
}

function mapArrayOfValuesToString(arrayOfValues: string[]) {
    return arrayOfValues.map((value) => `'${value}'`).join(ARRAY_SEPARATOR);
}
