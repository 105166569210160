import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { UiPageKey } from 'models/state/ui.models';
import { ROUTE_KEY } from 'views/routeKeys';

export const CORE_API_ENTITY_TYPE_TO_LIST_UI_PAGE_MAP: { [key in EntityType]: UiPageKey; } = {
    [EntityType.goal]: UiPageKey.goalsList,
    [EntityType.horizon]: UiPageKey.horizonsList,
    [EntityType.riskProfile]: UiPageKey.riskProfilesList,
    [EntityType.policy]: UiPageKey.policiesList,
    [EntityType.portfolio]: UiPageKey.portfoliosList,
    [EntityType.portfolioGroup]: UiPageKey.portfolioGroupsList,
    [EntityType.optimization]: UiPageKey.portfolioOptimizationsList,
    [EntityType.userGroup]: UiPageKey.userGroupsList,
    [EntityType.user]: UiPageKey.usersList,
};

export const CORE_API_ENTITY_TYPE_TO_LIST_ROUTE_MAP: { [key in EntityType]: ROUTE_KEY; } = {
    [EntityType.goal]: ROUTE_KEY.R_GOALS_LIST,
    [EntityType.horizon]: ROUTE_KEY.R_HORIZONS_LIST,
    [EntityType.riskProfile]: ROUTE_KEY.R_RISK_PROFILES_LIST,
    [EntityType.policy]: ROUTE_KEY.R_POLICIES_LIST,
    [EntityType.portfolio]: ROUTE_KEY.R_PORTFOLIOS_LIST,
    [EntityType.portfolioGroup]: ROUTE_KEY.R_PORTFOLIO_GROUPS_LIST,
    /* if needed, this should be the optimizations tab of ROUTE_KEY.R_PORTFOLIO_DETAIL */
    [EntityType.optimization]: null,
    [EntityType.userGroup]: ROUTE_KEY.R_USER_GROUPS_LIST,
    [EntityType.user]: ROUTE_KEY.R_USERS_LIST,
};

export const CORE_API_ENTITY_TYPE_TO_DETAIL_ROUTE_MAP: { [key in EntityType]: ROUTE_KEY; } = {
    [EntityType.goal]: ROUTE_KEY.R_GOAL_DETAIL,
    [EntityType.horizon]: ROUTE_KEY.R_HORIZON_DETAIL,
    [EntityType.riskProfile]: ROUTE_KEY.R_RISK_PROFILE_DETAIL,
    [EntityType.policy]: ROUTE_KEY.R_POLICY_DETAIL,
    [EntityType.portfolio]: ROUTE_KEY.R_PORTFOLIO_DETAIL,
    [EntityType.portfolioGroup]: ROUTE_KEY.R_PORTFOLIO_GROUP_DETAIL,
    [EntityType.optimization]: ROUTE_KEY.R_PORTFOLIO_OPTIMIZATION_DETAIL,
    [EntityType.userGroup]: ROUTE_KEY.R_USER_GROUP_DETAIL,
    [EntityType.user]: ROUTE_KEY.R_USER_DETAIL,
};

export const CORE_API_ENTITY_TYPE_TO_PLURAL_ENTITY_NAME_TRANSLATION_MAP: { [key in EntityType]: string; } = {
    [EntityType.goal]: 'portfolio_mgmt.goals.title',
    [EntityType.horizon]: 'portfolio_mgmt.horizons.title',
    [EntityType.riskProfile]: 'portfolio_mgmt.risk_profiles.title',
    [EntityType.policy]: 'portfolio_mgmt.policies.title',
    [EntityType.portfolio]: 'portfolio_mgmt.portfolios.title',
    [EntityType.portfolioGroup]: 'portfolio_mgmt.portfolio_groups.title',
    [EntityType.optimization]: null,
    [EntityType.userGroup]: 'user_mgmt.user_groups.title',
    [EntityType.user]: 'user_mgmt.users.title',
};

export const CORE_API_ENTITY_TYPE_TO_SINGULAR_ENTITY_NAME_TRANSLATION_MAP: { [key in EntityType]: string; } = {
    [EntityType.goal]: 'portfolio_mgmt.goals.singular_name',
    [EntityType.horizon]: 'portfolio_mgmt.horizons.singular_name',
    [EntityType.riskProfile]: 'portfolio_mgmt.risk_profiles.singular_name',
    [EntityType.policy]: 'portfolio_mgmt.policies.singular_name',
    [EntityType.portfolio]: 'portfolio_mgmt.portfolios.singular_name',
    [EntityType.portfolioGroup]: 'portfolio_mgmt.portfolio_groups.singular_name',
    [EntityType.optimization]: null,
    [EntityType.userGroup]: 'user_mgmt.user_groups.singular_name',
    [EntityType.user]: 'user_mgmt.users.singular_name',
};

export const CORE_API_ENTITY_TYPE_TO_FIELD_TRANSLATION_PREFIX_MAP: { [key in EntityType]: string; } = {
    [EntityType.goal]: 'portfolio_mgmt.goals.detail.fields',
    [EntityType.horizon]: 'portfolio_mgmt.horizons.detail.fields',
    [EntityType.riskProfile]: 'portfolio_mgmt.risk_profiles.detail.fields',
    [EntityType.policy]: 'portfolio_mgmt.policies.detail.fields',
    [EntityType.portfolio]: 'portfolio_mgmt.portfolios.detail.fields',
    [EntityType.portfolioGroup]: 'portfolio_mgmt.portfolio_groups.detail.fields',
    [EntityType.optimization]: null,
    [EntityType.userGroup]: 'user_mgmt.user_groups.detail.fields',
    [EntityType.user]: 'user_mgmt.users.detail.fields',
};

export const CORE_API_ENTITY_TYPE_TO_ENTITY_NAME_PREFIX: { [key in EntityType]: string; } = {
    [EntityType.goal]: 'goal',
    [EntityType.horizon]: 'horizon',
    [EntityType.riskProfile]: 'risk-profile',
    [EntityType.policy]: 'policy',
    [EntityType.portfolio]: 'portfolio',
    [EntityType.portfolioGroup]: 'portfolio-groups',
    [EntityType.optimization]: 'optimization',
    [EntityType.userGroup]: 'user-group',
    [EntityType.user]: 'user',
};
