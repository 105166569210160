import { object } from '@typsy/schema-validation/dist/baseSchemas/objectSchema';
import { string } from '@typsy/schema-validation/dist/baseSchemas/stringSchema';

interface IMultiTranslationsLabelConfig<Locale extends string = string> {
    locales: Locale[];
    defaultLocale: Locale;
    markDefaultLocaleAsRequired?: boolean; // default true
}

export const multiTranslationsLabel = <Locale extends string = string>({
    locales,
    defaultLocale,
    markDefaultLocaleAsRequired = true,
}: IMultiTranslationsLabelConfig<Locale>) => {
    const localeFields = locales.reduce(
        (accumulator, locale) => {
            if (markDefaultLocaleAsRequired && (locale === defaultLocale)) {
                accumulator[locale] = string().required();
            } else {
                accumulator[locale] = string();
            }
            return accumulator;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {} as Record<string, any>,
    );

    return object({
        ...localeFields,
    });
};
