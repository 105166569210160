import React from 'react';
import { createSelector } from 'reselect';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import addItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/addItemAndGetResultingArray';
import removeItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/removeItemAndGetResultingArray';
import getUniqueArrayValues from '@snipsonian/core/cjs/array/filtering/getUniqueArrayValues';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import {
    OperationPermissionKey,
    ALL_OPERATION_PERMISSION_KEYS,
} from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import { IColValues, IDataItem, IRenderPreRowProps, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { ISimpleFilterToggles, UiPageKey } from 'models/state/ui.models';
import {
    getHiddenOperationPermissionKeysMap,
    OPERATION_PERMISSION_KEY_TO_GROUP_LABEL_MAP,
} from 'config/userMgmt/operationPermissions.config';
import { triggerPatchUserGroupDetails, userGroupDetailsEntity } from 'state/entities/userMgmt/userGroupDetails';
import { getTranslator } from 'state/i18n/selectors';
import doesAnyObjectValueMatchFilter from '@snipsonian/core/cjs/object/verification/doesAnyObjectValueMatchFilter';
import { getTenantSettings } from 'state/appConfig/selectors';
import {
    getOperationPermissionDescription,
    getOperationPermissionDisplayName,
    isUserGroupReadOnly,
} from 'utils/entities/userMgmt/userGroupUtils';
import { observe, IObserveProps } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import ContentTitle from 'views/common/layout/ContentTitle';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IFormValues,
    IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import DataTable from 'views/common/list/DataTable';
import InputToggleFieldWithDescription from 'views/common/inputs/base/InputToggleFieldWithDescription';
import ListPageForFixedItems, { IIsListItemToBeIncludedProps } from 'views/common/list/ListPageForFixedItems';
import { IRenderExtraSimpleFiltersProps } from 'views/common/list/DataSearch';
import InputToggleField from 'views/common/inputs/base/InputToggleField';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import { userGroupPermissionsSchema } from './userGroupDetailsSchema';

const LABEL_PREFIX = 'user_mgmt.user_groups.detail.operation_permissions';
const COL_LABEL_PREFIX = `${LABEL_PREFIX}.columns`;

interface IUserGroupOperationPermissionsFormValues extends IFormValues {
    permissions: OperationPermissionKey[];
}

interface IPermissionsState {
    [permissionKey: string]: boolean;
}

interface IOperationPermissionCols extends IColValues {
    enabled: boolean;
    name: string;
    description: string;
}

interface IOperationPermissionExtraData {
    changeEnabled: (props: IChangeEnabledProps) => void;
    isReadOnly: boolean;
    permissionGroupLabel: string;
}

interface IChangeEnabledProps {
    isEnabled: boolean;
    permissionKey: OperationPermissionKey;
}

interface ITranslatedOperationPermission {
    key: OperationPermissionKey;
    name: string;
    description: string;
}

interface IFilterToggles extends ISimpleFilterToggles {
    onlyEnabledPermissions: boolean;
}

const getUserGroupPermissions = createSelector(
    () => userGroupDetailsEntity.select().data.permissions,
    (userGroupPermissions) => userGroupPermissions || [],
);

const getAllOperationPermissionKeys = createSelector(
    getUserGroupPermissions,
    /* concat the permissions of the user group because maybe there are new ones which are not yet in
     * the configured permissions */
    (userGroupPermissions) => getUniqueArrayValues<OperationPermissionKey>(
        ALL_OPERATION_PERMISSION_KEYS.concat(userGroupPermissions),
    ),
);

const getTranslatedPermissions = createSelector(
    getAllOperationPermissionKeys,
    getTenantSettings,
    getTranslator,
    (allOperationPermissionKeys, tenantSettings, translator): ITranslatedOperationPermission[] => {
        const hiddenOperationPermissionKeysMap = getHiddenOperationPermissionKeysMap(tenantSettings);

        return allOperationPermissionKeys
            .filter((key) => !hiddenOperationPermissionKeysMap[key])
            .map((key) => ({
                key,
                name: getOperationPermissionDisplayName(key, translator),
                description: getOperationPermissionDescription(key, translator),
            }));
    },
);

const COLS: TDataColumns<IOperationPermissionCols, IOperationPermissionExtraData> = {
    enabled: {
        label: {
            msg: `${COL_LABEL_PREFIX}.enabled`,
        },
        data: {
            render: ({ cellValue, item }) => (
                <InputToggleField
                    name={item.id}
                    checked={cellValue as boolean}
                    onChange={({ checked, name }) => item.extra.changeEnabled({
                        isEnabled: checked,
                        permissionKey: name as OperationPermissionKey,
                    })}
                    disabled={item.extra.isReadOnly}
                />
            ),
        },
        percentWidth: 10,
    },
    name: {
        label: {
            msg: `${COL_LABEL_PREFIX}.name`,
        },
        percentWidth: 30,
    },
    description: {
        label: {
            msg: `${COL_LABEL_PREFIX}.description`,
        },
        percentWidth: 60,
    },
};

const useStyles = makeStyles((theme) => ({
    UserGroupOperationPermissions: {
        ...mixins.flexColTopLeft(),

        '& .__permissionGroupName': {
            ...mixins.typoBold(),
        },

        '& .ExtraSimpleFilters': {
            ...mixins.widthMax(),
        },
        '& .__titleAndToggleOnlyEnabledPermissions': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            paddingBottom: theme.spacing(3),
        },
        '& .ContentTitle': {
            marginRight: theme.spacing(1),
        },

        '& .__listPage': {
            ...mixins.widthMax(),
        },

        '& .operationPermissionsForm .__tableRow': {
            // height: theme.spacing(6),
        },
    },
}));

function UserGroupOperationPermissions({ state }: IObserveProps) {
    const classes = useStyles();

    const userGroupData = userGroupDetailsEntity.select().data;
    const userGroupPermissions = userGroupData.permissions || [];
    const isReadOnly = isUserGroupReadOnly(userGroupData);

    const allOperationPermissionKeys = getAllOperationPermissionKeys(state);
    const translatedPermissions = getTranslatedPermissions(state);
    const initialFormValues: IUserGroupOperationPermissionsFormValues = {
        permissions: userGroupPermissions,
    };
    const initialFilterToggles: IFilterToggles = {
        onlyEnabledPermissions: false,
    };

    return (
        <div className={classes.UserGroupOperationPermissions}>
            <ExtendedInputForm<IUserGroupOperationPermissionsFormValues>
                name={ExtendedInputFormName.userGroupOperationPermissions}
                className="operationPermissionsForm"
                labelPrefix={LABEL_PREFIX}
                initialValues={initialFormValues}
                renderFormFields={renderOperationPermissionsList}
                submit={{
                    onSubmit: onSubmitPatch,
                }}
                schema={userGroupPermissionsSchema}
                reset={{}}
                readOnly={isReadOnly}
                placeFormActionsInFixedFooter
            />
        </div>
    );

    function renderOperationPermissionsList({
        fields,
        setFieldValue,
    }: IExtendedInputFormContext<IUserGroupOperationPermissionsFormValues>) {
        const currentPermissions = (fields.permissions.value || []) as OperationPermissionKey[];

        const permissionsState = allOperationPermissionKeys.reduce(
            (accumulator, operationPermissionKey) => {
                accumulator[operationPermissionKey] = currentPermissions.includes(operationPermissionKey);

                return accumulator;
            },
            {} as IPermissionsState,
        );

        return (
            <OperationPermissionsList
                groupName={userGroupData.name}
                permissionsState={permissionsState}
                translatedPermissions={translatedPermissions}
                initialFilterToggles={initialFilterToggles}
                isReadOnly={isReadOnly}
                onChangePermission={onChangePermission}
            />
        );

        function onChangePermission(
            { isEnabled, permissionKey }: IChangeEnabledProps,
        ) {
            /* update the form field, so that the form is validated on every change
             + knows that there has been a change or not) */

            const newPermissions = isEnabled
                ? addItemAndGetResultingArray<OperationPermissionKey>(
                    currentPermissions,
                    permissionKey,
                    { resultInNewArray: true },
                )
                : removeItemAndGetResultingArray<OperationPermissionKey>(
                    currentPermissions,
                    permissionKey,
                    { resultInNewArray: true },
                );

            setFieldValue({
                fieldName: fields.permissions.fieldName,
                value: newPermissions,
            });
        }
    }

    function onSubmitPatch({ values }: IOnSubmitProps<IUserGroupOperationPermissionsFormValues>) {
        return triggerPatchUserGroupDetails({
            id: userGroupData.id,
            permissions: values.permissions,
        });
    }
}

export default observe(
    [],
    UserGroupOperationPermissions,
);

function OperationPermissionsList({
    groupName,
    permissionsState,
    translatedPermissions,
    initialFilterToggles,
    isReadOnly,
    onChangePermission,
}: {
    groupName: string;
    permissionsState: IPermissionsState;
    translatedPermissions: ITranslatedOperationPermission[];
    initialFilterToggles: IFilterToggles;
    isReadOnly: boolean;
    onChangePermission: (changeProps: IChangeEnabledProps) => void;
}) {
    return (
        <ListPageForFixedItems<ITranslatedOperationPermission>
            className="__listPage"
            uiPageKey={UiPageKey.userGroupPermissionsList}
            search={{
                simple: {
                    tipTranslationKey: `${LABEL_PREFIX}.search.tip`,
                    extraFilters: {
                        initialExtraToggles: initialFilterToggles,
                        renderExtraFilters: renderTitleAndExtraFilterToggle,
                    },
                },
                isListItemToBeIncluded,
            }}
            list={{
                items: translatedPermissions,
                renderData: renderOperationPermissionsTable,
            }}
            notificationToTriggerOnUiVarChanges={StateChangeNotification.USERGROUP_PERMISSIONS_UI_VARS}
        />
    );

    function renderTitleAndExtraFilterToggle({
        extraFilterToggles,
        onChangeFilterToggle,
    }: IRenderExtraSimpleFiltersProps<IFilterToggles>) {
        return (
            <div className="__titleAndToggleOnlyEnabledPermissions">
                <ContentTitle
                    label={{
                        msg: `${LABEL_PREFIX}.manage`,
                        placeholders: {
                            groupName,
                        },
                    }}
                />

                <InputToggleFieldWithDescription
                    description={`${LABEL_PREFIX}.only_enabled_permissions_toggle`}
                    name="toggle-only-enabled-permissions"
                    checked={extraFilterToggles.onlyEnabledPermissions}
                    onChange={({ checked }) => {
                        onChangeFilterToggle({
                            onlyEnabledPermissions: checked,
                        });
                    }}
                />
            </div>
        );
    }

    function isListItemToBeIncluded({
        listItem,
        listPageVars,
    }: IIsListItemToBeIncludedProps<ITranslatedOperationPermission, unknown, IFilterToggles>) {
        if (listPageVars && listPageVars.search) {
            if (listPageVars.search.simple) {
                if (listPageVars.search.simple.extraFilterToggles
                    && listPageVars.search.simple.extraFilterToggles.onlyEnabledPermissions
                    && !permissionsState[listItem.key]) {
                    return false;
                }

                return doesAnyObjectValueMatchFilter(
                    listItem as unknown as TAnyObject,
                    listPageVars.search.simple.filterValue,
                );
            }
        }

        return true;
    }

    function renderOperationPermissionsTable({
        data: filteredOperationPermissions,
    }: { data: ITranslatedOperationPermission[] }) {
        const operationPermissionItems: IDataItem<IOperationPermissionCols, IOperationPermissionExtraData>[] =
            filteredOperationPermissions
                .map(({ key: permissionKey, name, description }) => ({
                    id: permissionKey,
                    colValues: {
                        enabled: permissionsState[permissionKey],
                        name,
                        description,
                    },
                    extra: {
                        changeEnabled: changeOperationPermissionEnabled,
                        isReadOnly,
                        permissionGroupLabel: OPERATION_PERMISSION_KEY_TO_GROUP_LABEL_MAP[permissionKey] || '-',
                    },
                }));

        return (
            <DataTable
                cols={COLS}
                items={operationPermissionItems}
                renderPreRow={renderPermissionGroupTitles}
            />
        );
    }

    function renderPermissionGroupTitles({
        dataItem,
        prevDataItem,
        RowComponent,
        CellComponent,
        nrOfCols,
    }: IRenderPreRowProps<IOperationPermissionCols, IOperationPermissionExtraData>) {
        if (prevDataItem?.extra?.permissionGroupLabel !== dataItem.extra.permissionGroupLabel) {
            return (
                <RowComponent>
                    <CellComponent colSpan={nrOfCols} className="__permissionGroupName">
                        <Translate msg={dataItem.extra.permissionGroupLabel} />
                    </CellComponent>
                </RowComponent>
            );
        }
        return null;
    }

    function changeOperationPermissionEnabled(
        { isEnabled, permissionKey }: IChangeEnabledProps,
    ) {
        onChangePermission({ isEnabled, permissionKey });
    }
}
