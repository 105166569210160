import React from 'react';
import clsx from 'clsx';
import { triggerPatchUserGroupDetails, userGroupDetailsEntity } from 'state/entities/userMgmt/userGroupDetails';
import { makeStyles } from 'views/styling';
import { isUserGroupReadOnly } from 'utils/entities/userMgmt/userGroupUtils';
import { readOnlyEntityClass } from 'views/assets/cssInJs/genericClasses';
import PropertiesCategoryBox from 'views/common/detail/PropertiesCategoryBox';
import { TEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { IUserGroupEntityData } from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/userGroup.entity.models';
import { IDetailField } from 'views/common/detail/DetailFieldsList';
import { IEditForm } from 'views/common/detail/DetailFieldsListWithEditModal';
import {
    IUserGroupFormValues,
    mapUserGroupFormValuesToApiEntity,
    renderUserGroupFormFields,
} from './UserGroupPropertiesForm';
import { userGroupDetailsSchema } from './userGroupDetailsSchema';

const useStyles = makeStyles((/* theme */) => ({
    UserGroupDetailsTab: {},
}));

const LABEL_PREFIX = 'user_mgmt.user_groups.detail';

export default function UserGroupProperties() {
    const classes = useStyles();
    const userGroupData = userGroupDetailsEntity.select().data;
    const isReadOnly = isUserGroupReadOnly(userGroupData);

    return (
        <div className={clsx(classes.UserGroupDetailsTab, readOnlyEntityClass(isReadOnly))}>
            <PropertiesCategoryBox<IUserGroupFormValues>
                id="usergroup_details_fields"
                category="details"
                labelPrefix={LABEL_PREFIX}
                isReadOnly={isReadOnly}
                fields={getDetailsFields(userGroupData)}
                editGlobalForm={getEditDetailsForm(userGroupData)}
                centralizeContentBox
            />
        </div>
    );
}

function getDetailsFields(userGroup: TEntity<IUserGroupEntityData>): IDetailField[] {
    return [{
        label: 'name.label',
        value: userGroup.name,
    }, {
        label: 'external_id.label',
        value: userGroup.external_id,
    }];
}

function getEditDetailsForm(userGroup: TEntity<IUserGroupEntityData>): IEditForm<IUserGroupFormValues> {
    return {
        initialValues: {
            name: userGroup.name,
            externalId: userGroup.external_id,
        },
        schema: userGroupDetailsSchema,
        renderFormFields: renderUserGroupFormFields,
        submit: {
            onSubmit: ({ values }) => triggerPatchUserGroupDetails({
                id: userGroup.id,
                ...mapUserGroupFormValuesToApiEntity(values),
            }),
        },
        modalMaxWidth: 'xs',
        modalTitle: `${LABEL_PREFIX}.properties.modal_titles.details`,
        labelPrefix: LABEL_PREFIX,
    };
}
