import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import { IDatePeriod } from '@console/common/typsy/date/dist/period/period.models';
import { TEntityUlid, TEntityDetail } from '../../../../entities/dist/common/entity.models';
import {
    IApiEntityListRequest, IApiEntityListResponseWithPageNr,
} from '../consoleApiQuery.models';
import { ISinglePortfolioApiInput } from './portfolio.entity.models';

export type TPortfolioTransactionsData = IApiEntityListResponseWithPageNr<IPortfolioTransactionEntityData>;
export type TPortfolioTransaction = TEntityDetail<IPortfolioTransactionEntityData>;

export interface IPortfolioTransactionEntityData {
    external_id: string;
    primary_movement: IPortfolioTransactionMovement;
    movements: IPortfolioTransactionMovement[];
    optimization_id: null; // TODO
    portfolio_id: TEntityUlid;
    status: PortfolioTransactionStatus;
    type: PortfolioTransactionType;
    order_type?: PortfolioTransactionOrderType; /* TODO not sure if indeed optional */
    sub_type?: PortfolioTransactionSubType; /* TODO not sure if indeed optional */
}

export interface IPortfolioTransactionMovement {
    datetime: string; /* The date and time at which this movement was last updated. E.g. "2020-09-23T00:00:00+00:00" */
    external_id: string;
    instrument_id: string; // e.g. "$USD"
    quantity: number; // e.g. 5000
    reference_instrument_id?: string;
    reference_quantity: null; // TODO
    status: PortfolioTransactionMovementStatus;
    sub_type?: string; // e.g. "Cash Amount"
    type: PortfolioTransactionMovementType;
    trade_type?: PortfolioTransactionMovementTradeType;
}

export enum PortfolioTransactionMovementStatus {
    PLANNED = 'PLANNED',
    PENDING = 'PENDING',
    PLACED = 'PLACED',
    EXECUTED = 'EXECUTED',
    EXPIRED = 'EXPIRED',
    SETTLED = 'SETTLED',
    NOT_EXECUTED = 'NOT_EXECUTED',
    CANCELLED = 'CANCELLED',
}

export enum PortfolioTransactionMovementType {
    CASH_DEPOSIT = 'CASH_DEPOSIT',
    CASH_DIVIDEND = 'CASH_DIVIDEND',
    CASH_WITHDRAWAL = 'CASH_WITHDRAWAL',
    COUPON = 'COUPON',
    BUY = 'BUY',
    SELL = 'SELL',
    CORPORATE_ACTION_IN = 'CORPORATE_ACTION_IN',
    CORPORATE_ACTION_OUT = 'CORPORATE_ACTION_OUT',
    REVERSE_STOCK_SPLIT = 'REVERSE_STOCK_SPLIT',
    STOCK_DIVIDEND = 'STOCK_DIVIDEND',
    STOCK_SPLIT = 'STOCK_SPLIT',
    TRANSFER_IN = 'TRANSFER_IN',
    TRANSFER_OUT = 'TRANSFER_OUT',
    CUSTODY_FEE = 'CUSTODY_FEE',
    INSTRUMENT_ENTRY_EXIT_FEE = 'INSTRUMENT_ENTRY_EXIT_FEE',
    MANAGEMENT_FEE = 'MANAGEMENT_FEE',
    OTHER_FEE = 'OTHER_FEE',
    OTHER_TAX = 'OTHER_TAX',
    SERVICE_ENTRY_EXIT_FEE = 'SERVICE_ENTRY_EXIT_FEE',
    TRANSACTION_FEE = 'TRANSACTION_FEE',
    WITHHOLDING_TAX = 'WITHHOLDING_TAX',
    INTEREST_RECEIVED = 'INTEREST_RECEIVED',
    INTEREST_PAID = 'INTEREST_PAID',
    OTHER_UNDEFINED = 'OTHER_UNDEFINED',
}

export enum PortfolioTransactionMovementTradeType {
    CANCEL = 'CANCEL',
    REBOOK = 'REBOOK',
    INSTRUMENT_CHANGE = 'INSTRUMENT_CHANGE',
    CORRECTION = 'CORRECTION',
}

export enum PortfolioTransactionStatus {
    PLANNED = 'PLANNED',
    PENDING = 'PENDING',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    COMPLETED = 'COMPLETED',
}

export enum PortfolioTransactionType {
    ORDER = 'ORDER',
    CORPORATE_ACTION = 'CORPORATE_ACTION',
    CASH_TRANSFER = 'CASH_TRANSFER',
    SECURITY_TRANSFER = 'SECURITY_TRANSFER',
    ADMINISTRATIVE = 'ADMINISTRATIVE',
}

export enum PortfolioTransactionOrderType {
    MARKET = 'MARKET',
    LIMIT = 'LIMIT',
    STOP_LIMIT = 'STOP_LIMIT',
    STOP_MARKET = 'STOP_MARKET',
    UNKNOWN = 'UNKNOWN',
}

export enum PortfolioTransactionSubType {
    CASH_DIVIDEND = 'CASH_DIVIDEND',
    DIVIDEND_OPTION = 'DIVIDEND_OPTION',
    CONVERSION = 'CONVERSION',
    INTEREST = 'INTEREST',
    REVERSE_STOCK_SPLIT = 'REVERSE_STOCK_SPLIT',
    CALL_ON_INTERMEDIATE_SECURITIES = 'CALL_ON_INTERMEDIATE_SECURITIES',
    SPIN_OFF = 'SPIN_OFF',
    STOCK_DIVIDEND = 'STOCK_DIVIDEND',
    PARI_PASSU = 'PARI_PASSU',
    STOCK_SPLIT = 'STOCK_SPLIT',
    BONUS_ISSUE = 'BONUS_ISSUE',
    MERGER = 'MERGER',
    DIVIDEND_REINVESTMENT = 'DIVIDEND_REINVESTMENT',
    WARRANT_EXERCISE = 'WARRANT_EXERCISE',
    FINAL_MATURITY = 'FINAL_MATURITY',
    EXCHANGE = 'EXCHANGE',
    PRIORITY_ISSUE = 'PRIORITY_ISSUE',
    REPURCHASE_OFFER = 'REPURCHASE_OFFER',
    TENDER_OFFER = 'TENDER_OFFER',
    INTERMEDIATE_SECURITIES_DISTRIBUTION = 'INTERMEDIATE_SECURITIES_DISTRIBUTION',
    DECREASE_IN_VALUE = 'DECREASE_IN_VALUE',
    CAPITAL_GAINS_DISTRIBUTION = 'CAPITAL_GAINS_DISTRIBUTION',
    LIQUIDATION = 'LIQUIDATION',
    SHARES_PREMIUM_DIVIDEND = 'SHARES_PREMIUM_DIVIDEND',
    FULL_CALL_EARLY_REDEMPTION = 'FULL_CALL_EARLY_REDEMPTION',
    PARTIAL_REDEMPTION_WITHOUT_REDUCTION_OF_NOMINAL = 'PARTIAL_REDEMPTION_WITHOUT_REDUCTION_OF_NOMINAL',
    PARTIAL_REDEMPTION_WITH_REDUCTION_OF_NOMINAL_VALUE = 'PARTIAL_REDEMPTION_WITH_REDUCTION_OF_NOMINAL_VALUE',
    OTHER = 'OTHER',
}

export interface IPortfolioTransactionsApiInput
    extends Pick<ISinglePortfolioApiInput, 'portfolioId'>,
    Omit<IApiEntityListRequest, 'orderBy'>,
    Partial<IPortfolioTransactionsApiFilters>,
    Partial<IDatePeriod>,
    IUnderlyingRequestApiInput {
    transactionTypes?: PortfolioTransactionType[];
    transactionStatuses?: PortfolioTransactionStatus[];
}

export interface IPortfolioTransactionsApiFilters {
    movementTypes: string[];
    movementStatuses: string[];
}

/**
 * As transaction movements have to be sorted...
 * - first on their 'datetime'
 * - and then on the id of the transaction they belong to (to further sort
 *   movements - of different transactions - who have the identical datetime value)
 *   This works because a transaction id is a ULID, and thus will be sorted on their creation time.
 * ...we add the transaction id to this movement interface.
 */
export interface ISortablePortfolioTransactionMovement extends IPortfolioTransactionMovement {
    transactionId: TEntityUlid;
}
