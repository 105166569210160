import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import {
    TPortfolioGroupsData,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolioGroup.entity.models';
import {
    IAdvancedPortfolioGroupsFilters,
    IFetchPortfolioGroupsApiInput,
} from '@console/core-api/client/portfolioMgmt/portfolioGroups.api';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { isRouteLocation } from 'state/ui/selectors';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { getEntitiesManager } from '../entitiesManager';

export const portfolioGroupsEntity = getEntitiesManager().registerEntity<TPortfolioGroupsData>({
    asyncEntityKey: AsyncEntityKeys.portfolioGroups,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_GROUPS],
    includeUpdaters: true,
});

export const triggerResetPortfolioGroupFetch = () => portfolioGroupsEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchPortfolioGroups({
    forceRefresh = false,
    ...apiInput
}: IFetchPortfolioGroupsApiInput & IForceStateRefreshFilter = {}) {
    return portfolioGroupsEntity.async.fetch<IFetchPortfolioGroupsApiInput>({
        api: api.portfolioGroups.fetchPortfolioGroups,
        apiInputSelector: ({ state }) =>
            enrichApiInputWithListPageVars<IFetchPortfolioGroupsApiInput, IAdvancedPortfolioGroupsFilters>({
                pageKey: UiPageKey.portfolioGroupsList,
                state,
                apiInput,
                mapAdvancedFilterValues: (advancedFilterValues) => ({
                    externalId: advancedFilterValues.externalId,
                    portfolioGroupName: advancedFilterValues.portfolioGroupName,
                }),
            }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: portfolioGroupsEntity.select().data,
        }),
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_PORTFOLIO_GROUPS_LIST,
        }),
        resetDataOnTriggerMode: 'never',
    });
}
