import React from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import isUndefined from '@snipsonian/core/cjs/is/isUndefined';
import { Locale } from '@console/common/config/i18n.config';
import { TTUserPatch } from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/user.entity.models';
import {
    triggerClearUserCounterAccount,
    triggerPatchUserDetails,
    userDetailsEntity,
} from 'state/entities/userMgmt/userDetails';
import { canUserModifyUserFeatureFlags, canUserModifyUser } from 'state/auth/apiEntityAuthorization.selectors';
import { getUserFeatureFlagsListEnabledForTenant } from 'state/appConfig/selectors';
import { triggerResetUsersFetch } from 'state/entities/userMgmt/users';
import { formatBooleanForDisplay } from 'utils/boolean/booleanUtils';
import { getUserStatusLabel, isUserFeatureFlagPresent } from 'utils/entities/userMgmt/userUtils';
import { getTranslatedLocale } from 'utils/i18n/localeUtils';
import { getValueIfSet } from 'utils/varia/getValueIfSet';
import { makeStyles, mixins } from 'views/styling';
import { readOnlyEntityClass } from 'views/assets/cssInJs/genericClasses';
import { IDetailField } from 'views/common/detail/DetailFieldsList';
import PropertiesCategoryBox from 'views/common/detail/PropertiesCategoryBox';
import { IEditForm } from 'views/common/detail/DetailFieldsListWithEditModal';
import {
    renderUserCounterAccountFormFields,
    renderUserDetailsFormFields,
    renderUserFeatureFlagsFormFields,
    TUserCounterAccountFormValues,
    TUserDetailsFormValues,
    TUserFeatureFlagsFormValues,
} from './GenericUserAddPropertiesForm';
import {
    getUserCounterAccountSchema,
    getUserDetailsSchema,
    getUserFeatureFlagsSchema,
} from './genericUserDetailsSchema';

const LABEL_PREFIX = 'user_mgmt.users.detail';

const useStyles = makeStyles((theme) => ({
    UserDetailsTab: {
        ...mixins.flexRow({ wrap: 'wrap' }),

        '& .__column': {
            ...mixins.flexColTopLeft(),
            flexGrow: 1,
            padding: theme.spacing(0, 1),
        },
        '& .__box': {
            ...mixins.widthMax(),
            marginBottom: theme.spacing(2),
        },
    },
}));

interface IPublicProps {
    overrideOnPreSuccess?: () => void;
}

export default function GenericUserProperties({
    overrideOnPreSuccess,
}: IPublicProps) {
    const classes = useStyles();
    const userData = userDetailsEntity.select().data;
    const isReadOnly = !canUserModifyUser(userData);
    const shouldAllowFeatureFlagModification = canUserModifyUserFeatureFlags();
    const isCounterAccountSet = isSet(userData.counter_account);
    const userEmail = userData.email;
    const initialDetailsValues = {
        email: userEmail, /* email needed to be able to show it readOnly */
        externalId: userData.external_id,
        firstName: userData.first_name,
        lastName: userData.last_name,
        phone: userData.phone,
        brokerageId: userData.brokerage_user_id,
        language: userData.language as Locale,
    };

    return (
        <div className={clsx(classes.UserDetailsTab, readOnlyEntityClass(isReadOnly))}>
            <div className="__column">
                <PropertiesCategoryBox<TUserDetailsFormValues>
                    id="user_details_fields"
                    category="details"
                    labelPrefix={LABEL_PREFIX}
                    isReadOnly={isReadOnly}
                    fields={getDetailsFields()}
                    maxWidth={600}
                    editGlobalForm={getEditDetailsForm()}
                />
            </div>
            <div className="__column">
                <PropertiesCategoryBox<TUserFeatureFlagsFormValues>
                    id="user_feature_flag_fields"
                    category="feature_flags"
                    labelPrefix={LABEL_PREFIX}
                    isReadOnly={isReadOnly || !shouldAllowFeatureFlagModification}
                    fields={getFeatureFlagFields()}
                    editGlobalForm={getEditFeatureFlagsForm()}
                />
                <PropertiesCategoryBox<TUserCounterAccountFormValues>
                    id="user_counter_account_fields"
                    category="counter_account"
                    labelPrefix={LABEL_PREFIX}
                    isReadOnly={isReadOnly}
                    clearAll={isCounterAccountSet && {
                        confirmationModal: {
                            messageLabel: `${LABEL_PREFIX}.properties.actions.clear_counter_account`,
                        },
                        onClear: triggerClearUserCounterAccount,
                    }}
                    fields={getCounterAccountFields()}
                    editGlobalForm={getEditCounterAccountForm()}
                />
            </div>
        </div>
    );

    function getDetailsFields(): IDetailField[] {
        return [{
            label: 'email.label',
            value: userData.email,
        }, {
            label: 'external_id.label',
            value: userData.external_id,
        }, {
            label: 'first_name.label',
            value: userData.first_name,
        }, {
            label: 'last_name.label',
            value: userData.last_name,
        }, {
            label: 'phone.label',
            value: userData.phone,
        }, {
            label: 'brokerage_user_id.label',
            value: userData.brokerage_user_id,
        }, {
            label: 'language.label',
            value: getTranslatedLocale(userData.language as Locale),
        }, {
            label: 'status.label',
            value: getUserStatusLabel(userData.status),
            shouldTranslateValue: true,
        }];
    }

    function getEditDetailsForm(): IEditForm<TUserDetailsFormValues> {
        return {
            initialValues: initialDetailsValues,
            schema: getUserDetailsSchema({ isCreateMode: false }),
            renderFormFields: renderUserDetailsFormFields,
            submit: {
                onSubmit: (({ values }) => triggerPatchUser((currentUser) => {
                    /* eslint-disable no-param-reassign */
                    currentUser.external_id = values.externalId;
                    currentUser.first_name = values.firstName;
                    currentUser.last_name = values.lastName;
                    currentUser.phone = values.phone;
                    /* ensuring null when the language is being reset, otherwise the field is not sent in the payload */
                    currentUser.language = ensureNullIfUndefined(values.language);
                    currentUser.brokerage_user_id = getValueIfSet(values.brokerageId);
                    /* eslint-enable no-param-reassign */
                })),
            },
            modalMaxWidth: 'sm',
            modalTitle: `${LABEL_PREFIX}.properties.modal_titles.details`,
            labelPrefix: LABEL_PREFIX,
        };
    }

    function getCounterAccountFields(): IDetailField[] {
        return [{
            label: 'bank_id.label',
            value: userData.counter_account?.bank_id,
            condensedValue: true,
            copyValue: true,
        }, {
            label: 'bank_id_type.label',
            value: userData.counter_account?.bank_id_type,
        }, {
            label: 'bank_account_number.label',
            value: userData.counter_account?.bank_account_number,
            condensedValue: true,
            copyValue: true,
        }, {
            label: 'bank_account_number_type.label',
            value: userData.counter_account?.bank_account_number_type,
        }, {
            label: 'payment_reference.label',
            value: userData.counter_account?.payment_reference,
        }];
    }

    function getEditCounterAccountForm(): IEditForm<TUserCounterAccountFormValues> {
        return {
            initialValues: {
                bankId: userData.counter_account?.bank_id || '',
                bankIdType: userData.counter_account?.bank_id_type || undefined,
                bankAccountNumber: userData.counter_account?.bank_account_number || '',
                bankAccountNumberType: userData.counter_account?.bank_account_number_type || undefined,
                paymentReference: userData.counter_account?.payment_reference || '',
            },
            schema: getUserCounterAccountSchema(),
            renderFormFields: renderUserCounterAccountFormFields,
            submit: {
                onSubmit: (({ values }) => triggerPatchUser((currentUser) => {
                    /* eslint-disable no-param-reassign */
                    currentUser.counter_account = {
                        bank_id: isSetString(values.bankId) ? values.bankId : undefined,
                        bank_id_type: values.bankIdType,
                        bank_account_number: values.bankAccountNumber,
                        bank_account_number_type: values.bankAccountNumberType,
                        payment_reference: values.paymentReference,
                    };
                    /* eslint-enable no-param-reassign */
                })),
            },
            modalMaxWidth: 'sm',
            modalTitle: `${LABEL_PREFIX}.properties.modal_titles.counter_account`,
            labelPrefix: LABEL_PREFIX,
        };
    }

    function getFeatureFlagFields(): IDetailField[] {
        return getUserFeatureFlagsListEnabledForTenant()
            .map((featureFlag) => ({
                label: `feature_flags.${featureFlag.toLowerCase()}`,
                value: formatBooleanForDisplay(isUserFeatureFlagPresent(userData, featureFlag)),
                shouldTranslateValue: true,
            }));
    }

    function getEditFeatureFlagsForm(): IEditForm<TUserFeatureFlagsFormValues> {
        return {
            initialValues: {
                featureFlags: userData.feature_flags || [],
            },
            schema: getUserFeatureFlagsSchema(),
            renderFormFields: renderUserFeatureFlagsFormFields,
            submit: {
                onSubmit: (({ values }) => triggerPatchUser((currentUser) => {
                    // eslint-disable-next-line no-param-reassign
                    currentUser.feature_flags = values.featureFlags;
                })),
            },
            modalMaxWidth: 'sm',
            modalTitle: `${LABEL_PREFIX}.properties.modal_titles.feature_flags`,
            labelPrefix: LABEL_PREFIX,
        };
    }

    function triggerPatchUser(userUpdater: (currentUser: TTUserPatch) => void) {
        return triggerPatchUserDetails(userUpdater, {
            onPreSuccess: overrideOnPreSuccess || triggerResetUsersFetch,
        });
    }
}

function ensureNullIfUndefined<T>(someVal: T): T {
    if (isUndefined(someVal)) {
        return null;
    }

    return someVal;
}
