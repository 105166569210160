import {
    AsyncOperation,
    TRefreshMode,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { IFetchUsersApiInput } from '@console/core-api/client/userMgmt/users.api';
import {
    TUsersData,
} from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/user.entity.models';
import { IAdvancedUserFilters } from 'models/ui/user.ui.models';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { CLIENT_TAB_KEY } from 'config/tabs.config';
import { api } from 'api';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { isRouteLocation } from 'state/ui/selectors';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { ROUTE_KEY } from 'views/routeKeys';

export const clientsEntity = getEntitiesManager().registerEntity<TUsersData>({
    asyncEntityKey: AsyncEntityKeys.clients,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.CLIENTS_DATA],
    includeUpdaters: true,
});

export const triggerResetClientsFetch = () => clientsEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchClients({
    forceRefresh = false,
    ...apiInput
}: IFetchUsersApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchClientsGeneric({
        apiInput,
        pageKey: UiPageKey.clientsList,
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_CLIENT_DETAIL,
        }) || isRouteLocation(state, {
            prevParams: {
                userTab: CLIENT_TAB_KEY.ACCESSIBLE_BY,
            },
        }),
    });
}

function triggerFetchClientsGeneric({
    apiInput,
    pageKey,
    refreshMode,
}: {
    apiInput?: IFetchUsersApiInput;
    pageKey: UiPageKey;
    refreshMode: TRefreshMode<IState>;
}) {
    return clientsEntity.async.fetch<IFetchUsersApiInput>({
        api: api.users.fetchUsers,
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars<IFetchUsersApiInput, IAdvancedUserFilters>({
            pageKey,
            state,
            apiInput: {
                ...apiInput,
                isClientSearch: true,
            },
            mapSimpleFilterValue: (simpleFilterValue) => ({
                email: simpleFilterValue,
            }),
            mapAdvancedFilterValues: (advancedFilterValues) => ({
                email: advancedFilterValues.email,
                externalId: advancedFilterValues.externalId,
                brokerageUserId: advancedFilterValues.brokerageUserId,
                virtualIban: advancedFilterValues.virtualIban,
                lastName: advancedFilterValues.lastName,
                phoneNumber: advancedFilterValues.phoneNumber,
            }),
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: clientsEntity.select().data,
        }),
        refreshMode,
        resetDataOnTriggerMode: 'never',
    });
}
