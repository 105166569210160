import { IPortfolioHoldings } from './portfolioHoldings.entity.models';

export interface IBaseOptimization {
    current_solution: IOptimizationSolution;
    data_version: string; // e.g. '2020-11-04'
    error: string;
    external_id?: null; // TODO
    /* optimal_solution will be null when status is 'FAILURE' */
    optimal_solution?: IOptimizationSolution;
    optimization_hash: string; // e.g. "def05a6d8cf0244a22541e23bc1e5d28"
    status: OptimizationStatus;
}

export enum OptimizationStatus {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    INFEASIBLE = 'INFEASIBLE',
    NOT_OPTIMIZED = 'NOT_OPTIMIZED',
    // PENDING = 'PENDING', /* at the moment this is purely a "core-api-internal-status" */
}

export interface IOptimizationSolution {
    benchmark_constraint_violations: null; // TODO
    look_through: IOptimizationLookThrough;
    objective_details: {
        ivar: number;
        pca: number;
        regularise_L2: number;
        trading_cost: number;
        vol: number;
    };
    objective_value: number;
    portfolio: IPortfolioHoldings; // e.g. {$USD: 10000}
    portfolio_constraint_violations: IOptimizationLookThrough & {
        holdings: IPortfolioHoldings;
    };
    solver: {
        solver_name: string;
        mosek: {
            exit_code: string; // e.g. "rescode.ok"
            is_proven_infeasible: boolean;
            is_proven_optimal: boolean;
            status: {
                problem_status: string; // e.g. "prosta.prim_feas"
                solution_status: string; // e.g. "solsta.integer_optimal"
            };
        };
    };
}

export interface IOptimizationLookThrough {
    asset_classes: {
        alternatives: number;
        bonds: number;
        cash: number;
        commodities: number;
        stocks: number;
    };
    bond_types: null; // TODO
    regions: {
        bonds: IOptimizationRegions;
        stocks: IOptimizationRegions;
    };
    sectors: {
        basic_materials: number;
        communication_services: number;
        consumer_cyclical: number;
        consumer_defensive: number;
        energy: number;
        financial_services: number;
        healthcare: number;
        industrials: number;
        real_estate: number;
        technology: number;
        utilities: number;
    };
}

export interface IOptimizationRegions {
    asia_pacific_developed: number;
    emerging: number;
    europe_developed: number;
    north_america: number;
}

export interface IOptimizationPortfolioUpdate {
    is_recommended: boolean;
    orders: IOptimizationOrders;
    reasons: IOptimizationReason[];
}

export interface IOptimizationOrders {
    [isin: string]: IOptimizationInstrumentOrder;
}

export interface IOptimizationInstrumentOrder {
    quantity_type: QuantityType;
    quantity: number; /* if >0 = BUY ; if <0 = SELL */
    shares?: number; /* TODO deprecated --> remove once for CBD also quantity is returned */
    expected_share_price: number;
    expected_transaction_cost: number;
}

export interface IOptimizationReason {
    portfolio_constraint_id?: string;
    portfolio_update_constraint_id?: string;
    portfolio_update_constraint_value?: number;
}

export enum QuantityType {
    UNITS = 'UNITS',
    AMOUNT = 'AMOUNT',
}
