import { RecurringDepositFrequency } from '@console/core-api/typsy/console-api-client/dist/models/common/performance.models';
import { IPerformanceMarkedDateBase } from 'models/chart.models';

export interface IPortfolioPerformancePageVars {
    mode: TPortfolioPerformanceMode; // default 'past'
    past?: {
        period: PortfolioPerformancePastPeriod; // default SINCE_START
        markedDate?: IPortfolioPastPerformanceMarkedDate;
        portfolioStartDate?: string; // format 'YYYY-MM-DD'
    };
    future?: {
        period: PortfolioPerformanceFuturePeriod; // default YEARS_10
        markedDate?: IPortfolioFuturePerformanceMarkedDate;
        recurringDepositAmount?: number;
        recurringDepositFrequency: RecurringDepositFrequency; // default M
        useExpectedReturns: boolean; // default false
    };
}

export type TPortfolioPerformanceMode = 'past' | 'future';

export enum PortfolioPerformancePastPeriod {
    SINCE_START = 'SINCE_START',
    YEARS_3 = 'YEARS_3',
    YEAR = 'YEAR',
    YEAR_TO_DATE = 'YEAR_TO_DATE',
    MONTHS_3 = 'MONTHS_3',
    MONTH = 'MONTH',
    WEEK = 'WEEK',
}

export enum PortfolioPerformanceFuturePeriod {
    YEARS_3 = 'YEARS_3',
    YEARS_5 = 'YEARS_5',
    YEARS_10 = 'YEARS_10',
    YEARS_20 = 'YEARS_20',
}

export interface IPortfolioPastPerformanceMarkedDate extends IPerformanceMarkedDateBase {
    values: {
        value: number;
        invested: number;
        return: number;
        twr: number; // time wighted return percentage
    };
}

export interface IPortfolioFuturePerformanceMarkedDate extends IPerformanceMarkedDateBase {
    values: {
        invested: number;
        optimistic: number;
        realistic: number;
        pessimistic: number;
    };
}
