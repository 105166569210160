import React from 'react';
import clsx from 'clsx';
import { makeStyles } from 'views/styling';
import { canUserModifyHorizon } from 'state/auth/apiEntityAuthorization.selectors';
import { horizonDetailsEntity, triggerPatchHorizonDetails } from 'state/entities/portfolioMgmt/horizonDetails';
import { readOnlyEntityClass } from 'views/assets/cssInJs/genericClasses';
import PropertiesCategoryBox from 'views/common/detail/PropertiesCategoryBox';
import { TEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { IHorizonEntityData } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/horizon.entity.models';
import { IDetailField } from 'views/common/detail/DetailFieldsList';
import { formatBooleanForDisplay } from 'utils/boolean/booleanUtils';
import { IEditForm } from 'views/common/detail/DetailFieldsListWithEditModal';
import { ensureMultiTranslationsLabelHasAllLocales } from 'state/appConfig/selectors';
import {
    IHorizonFormValues,
    mapHorizonFormValuesToApiEntity,
    renderHorizonPropertiesFormFields,
} from './HorizonPropertiesForm';
import { getHorizonDetailsSchema } from './horizonDetailsSchema';

const LABEL_PREFIX = 'portfolio_mgmt.horizons.detail';

const useStyles = makeStyles(() => ({
    HorizonDetailsTab: {},
}));

export default function HorizonPropertiesTab() {
    const classes = useStyles();
    const horizonData = horizonDetailsEntity.select().data;
    const isReadOnly = !canUserModifyHorizon(horizonData);

    return (
        <div className={clsx(classes.HorizonDetailsTab, readOnlyEntityClass(isReadOnly))}>
            <PropertiesCategoryBox<IHorizonFormValues>
                id="horiozon-details-fields"
                category="details"
                isReadOnly={isReadOnly}
                fields={getDetailsFields(horizonData)}
                labelPrefix={LABEL_PREFIX}
                maxWidth={700}
                editGlobalForm={getEditDetailsForm(horizonData)}
                centralizeContentBox
            />
        </div>
    );
}

function getDetailsFields(horizon: TEntity<IHorizonEntityData>): IDetailField[] {
    return [{
        label: 'name.label',
        value: horizon.name,
    }, {
        label: 'description.label',
        value: horizon.description,
        alignValueLeft: true,
    }, {
        label: 'external_id.label',
        value: horizon.external_id,
    }, {
        label: 'years.label',
        value: horizon.years.toString(),
    }, {
        label: 'disabled.label',
        value: formatBooleanForDisplay(horizon.disabled),
        shouldTranslateValue: true,
    }, {
        label: 'disabled_message.label',
        value: horizon.disabled_message,
        alignValueLeft: true,
    }, {
        label: 'warning.label',
        value: formatBooleanForDisplay(horizon.warning),
        shouldTranslateValue: true,
    }, {
        label: 'warning_message.label',
        value: horizon.warning_message,
        alignValueLeft: true,
    }];
}

function getEditDetailsForm(horizon: TEntity<IHorizonEntityData>): IEditForm<IHorizonFormValues> {
    return {
        initialValues: {
            name: ensureMultiTranslationsLabelHasAllLocales(horizon.name),
            description: ensureMultiTranslationsLabelHasAllLocales(horizon.description),
            externalId: horizon.external_id,
            years: horizon.years,
            disabled: horizon.disabled,
            disabledMessage: ensureMultiTranslationsLabelHasAllLocales(horizon.disabled_message),
            warning: horizon.warning,
            warningMessage: ensureMultiTranslationsLabelHasAllLocales(horizon.warning_message),
        },
        schema: getHorizonDetailsSchema(),
        renderFormFields: renderHorizonPropertiesFormFields,
        submit: {
            onSubmit: ({ values }) => triggerPatchHorizonDetails({
                id: horizon.id,
                ...mapHorizonFormValuesToApiEntity(values),
            }),
        },
        modalMaxWidth: 'sm',
        modalTitle: `${LABEL_PREFIX}.properties.modal_titles.details`,
        labelPrefix: LABEL_PREFIX,
    };
}
