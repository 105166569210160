import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import { IExecuteOnRoute, IRoute, IRouteBreadcrumb, TIsRouteEnabled } from 'models/route.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { ISingleUserApiInput } from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/user.entity.models';
import { ISingleUserGroupApiInput } from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/userGroup.entity.models';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { USER_TAB_KEY, CLIENT_TAB_KEY } from 'config/tabs.config';
import { triggerFetchUsers } from 'state/entities/userMgmt/users';
import { triggerFetchUserGroups } from 'state/entities/userMgmt/userGroups';
import { getSelectedUserName, triggerFetchUserDetails } from 'state/entities/userMgmt/userDetails';
import { triggerFetchUserPortfolios } from 'state/entities/portfolioMgmt/userPortfolios';
import { getCurrentRouteParam } from 'state/ui/selectors';
import { getUserGroupTitle, triggerFetchUserGroupDetails } from 'state/entities/userMgmt/userGroupDetails';
import { getPortfolioDetailTabRoute } from 'views/portfolioMgmt/routeConfigs';
import getApiEntityVersionsChildRoute from 'views/common/genericApiEntity/versions/ApiEntityVersions';
import { triggerFetchUserPortfolioReports } from 'state/entities/portfolioMgmt/portfolioReports';
import ClientsList from 'views/userMgmt/Clients/ClientsList';
import { triggerFetchClients } from 'state/entities/userMgmt/clients';
import ClientDetail from 'views/userMgmt/Clients/ClientDetail';
import ClientAdd from 'views/userMgmt/Clients/ClientAdd';
import UsersList from './Users/UsersList';
import UserAdd from './Users/UserAdd';
import UserDetail from './Users/UserDetail';
import UserGroupsList from './UserGroups/UserGroupsList';
import UserGroupAdd from './UserGroups/UserGroupAdd';
import UserGroupDetail from './UserGroups/UserGroupDetail';
import { ROUTE_KEY } from '../routeKeys';

const isUserCreationRouteEnabled: TIsRouteEnabled = ({ tenantSettings }) =>
    tenantSettings?.user.allowPatchingIdpSpecificFields;

export const USER_MGMT_ROUTES: IRoute<ROUTE_KEY>[] = [{
    routeKey: ROUTE_KEY.R_USERS_LIST,
    path: '/users',
    breadcrumb: {
        translationKey: 'user_mgmt.users.breadcrumb',
    },
    exact: true,
    component: UsersList,
    executeOnRoute: [{
        execute: triggerFetchUsers,
    }],
    childRoutes: [
        {
            routeKey: ROUTE_KEY.R_USER_ADD,
            path: '/add',
            breadcrumb: {
                translationKey: 'user_mgmt.users.add.breadcrumb',
            },
            exact: true,
            component: UserAdd,
            requiredPermissions: [OperationPermissionKey.USERS_CREATE],
            isEnabled: isUserCreationRouteEnabled,
        },
        getUserDetailTabRoute({
            routeKey: ROUTE_KEY.R_USER_DETAIL_PORTFOLIOS,
            userTab: USER_TAB_KEY.PORTFOLIOS,
            exact: true,
            childRoutes: [
                getPortfolioDetailTabRoute({
                    routeKey: ROUTE_KEY.R_USER_DETAIL_PORTFOLIO_DETAIL,
                    versionsRouteKey: ROUTE_KEY.R_USER_DETAIL_PORTFOLIO_VERSIONS,
                }),
            ],
        }),
        getUserDetailTabRoute({
            routeKey: ROUTE_KEY.R_USER_DETAIL_PORTFOLIO_REPORTS,
            exact: true,
        }),
        getUserDetailTabRoute({
            childRoutes: [
                getApiEntityVersionsChildRoute({
                    entityType: EntityType.user,
                    entityIdRouteParamName: 'userId',
                    routeKey: ROUTE_KEY.R_USER_VERSIONS,
                }),
            ],
        }),
    ],
}, {
    routeKey: ROUTE_KEY.R_USER_GROUPS_LIST,
    path: '/user-groups',
    breadcrumb: {
        translationKey: 'user_mgmt.user_groups.breadcrumb',
    },
    exact: true,
    component: UserGroupsList,
    executeOnRoute: [{
        execute: triggerFetchUserGroups,
    }],
    childRoutes: [
        {
            routeKey: ROUTE_KEY.R_USER_GROUP_ADD,
            path: '/add',
            breadcrumb: {
                translationKey: 'user_mgmt.user_groups.add.breadcrumb',
            },
            exact: true,
            component: UserGroupAdd,
            requiredPermissions: [OperationPermissionKey.USERGROUPS_CREATE],
        }, {
            routeKey: ROUTE_KEY.R_USER_GROUP_DETAIL,
            path: '/:groupId/:userGroupTab',
            breadcrumb: {
                translationKey: 'user_mgmt.user_groups.detail.breadcrumb',
                translationKeyIfCurrentRoute: 'user_mgmt.user_groups.detail.breadcrumb_if_current_route',
                translationPlaceholdersSelector: ({ pathParams, state, translator, translatorIfNeeded }) => {
                    const userGroupName = getUserGroupTitle();
                    const tabKey = (pathParams && pathParams.userGroupTab)
                        || getCurrentRouteParam(state, 'userGroupTab');

                    return {
                        userGroupName: translatorIfNeeded(userGroupName),
                        userGroupTab: translator(`user_mgmt.user_groups.detail.tabs.${tabKey}.breadcrumb_part`),
                    };
                },
                extraNotifications: [StateChangeNotification.USERGROUP_DETAILS_DATA],
            },
            component: UserGroupDetail,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            executeOnRoute: [<IExecuteOnRoute<ISingleUserGroupApiInput>>{
                execute: triggerFetchUserGroupDetails,
                executeInputSelector: ({ routeLocation }) => ({
                    userGroupId: routeLocation.params.groupId,
                }),
            }],
            childRoutes: [
                getApiEntityVersionsChildRoute({
                    entityType: EntityType.userGroup,
                    entityIdRouteParamName: 'groupId',
                    routeKey: ROUTE_KEY.R_USER_GROUP_VERSIONS,
                }),
            ],
        },
    ],
}, {
    routeKey: ROUTE_KEY.R_CLIENTS_LIST,
    path: '/clients',
    breadcrumb: {
        translationKey: 'client_mgmt.breadcrumb',
    },
    exact: true,
    component: ClientsList,
    isEnabled: ({ tenantSettings }) => tenantSettings?.user?.clientMgmt?.isEnabled,
    executeOnRoute: [{
        execute: triggerFetchClients,
    }],
    childRoutes: [
        {
            routeKey: ROUTE_KEY.R_CLIENT_ADD,
            path: '/add',
            breadcrumb: {
                translationKey: 'user_mgmt.users.add.breadcrumb',
            },
            exact: true,
            component: ClientAdd,
            requiredPermissions: [OperationPermissionKey.USERS_CREATE],
            isEnabled: isUserCreationRouteEnabled,
        },
        getUserDetailTabRoute({
            routeKey: ROUTE_KEY.R_CLIENT_DETAIL_PORTFOLIOS,
            userTab: CLIENT_TAB_KEY.PORTFOLIOS,
            exact: true,
            component: ClientDetail,
            overrideBreadcrumb: {
                translationKey: 'client_mgmt.detail.breadcrumb',
                translationKeyIfCurrentRoute: 'client_mgmt.detail.breadcrumb_if_current_route',
            },
            childRoutes: [
                getPortfolioDetailTabRoute({
                    routeKey: ROUTE_KEY.R_CLIENT_DETAIL_PORTFOLIO_DETAIL,
                    versionsRouteKey: ROUTE_KEY.R_CLIENT_DETAIL_PORTFOLIO_VERSIONS,
                }),
            ],
        }),
        getUserDetailTabRoute({
            routeKey: ROUTE_KEY.R_CLIENT_DETAIL_PORTFOLIO_REPORTS,
            component: ClientDetail,
            exact: true,
            overrideBreadcrumb: {
                translationKey: 'client_mgmt.detail.breadcrumb',
                translationKeyIfCurrentRoute: 'client_mgmt.detail.breadcrumb_if_current_route',
            },
        }),
        getUserDetailTabRoute({
            routeKey: ROUTE_KEY.R_CLIENT_DETAIL,
            component: ClientDetail,
            overrideBreadcrumb: {
                translationKey: 'client_mgmt.detail.breadcrumb',
                translationKeyIfCurrentRoute: 'client_mgmt.detail.breadcrumb_if_current_route',
            },
            childRoutes: [
                getApiEntityVersionsChildRoute({
                    entityType: EntityType.user,
                    entityIdRouteParamName: 'userId',
                    routeKey: ROUTE_KEY.R_CLIENT_VERSIONS,
                }),
            ],
        }),
    ],
}];

function getUserDetailTabRoute({
    routeKey = ROUTE_KEY.R_USER_DETAIL,
    userTab,
    childRoutes,
    exact = false,
    component = UserDetail,
    overrideBreadcrumb = {},
    overrideExecuteOnRoute,
}: {
    routeKey?: ROUTE_KEY;
    userTab?: string;
    childRoutes?: IRoute<ROUTE_KEY>[];
    exact?: boolean;
    component?: React.ComponentType<unknown>;
    overrideBreadcrumb?: Partial<IRouteBreadcrumb>;
    overrideExecuteOnRoute?: IExecuteOnRoute[];
} = {}): IRoute<ROUTE_KEY> {
    return {
        routeKey,
        path: `/:userId/${userTab || ':userTab'}`,
        exact,
        breadcrumb: {
            translationKey: 'user_mgmt.users.detail.breadcrumb',
            translationKeyIfCurrentRoute: 'user_mgmt.users.detail.breadcrumb_if_current_route',
            translationPlaceholdersSelector: ({ pathParams, state, translator }) => {
                const userName = getSelectedUserName()
                    || getCurrentRouteParam(state, 'userId')
                    || (pathParams && pathParams.userId) as string;
                const tabKey = userTab || (pathParams && pathParams.userTab) || getCurrentRouteParam(state, 'userTab');

                return {
                    userName,
                    userTab: translator(`user_mgmt.users.detail.tabs.${tabKey}.breadcrumb_part`),
                };
            },
            extraNotifications: [StateChangeNotification.USER_DETAILS_DATA],
            ...overrideBreadcrumb,
        },
        component,
        childRoutes,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        executeOnRoute: overrideExecuteOnRoute || [<IExecuteOnRoute<ISingleUserApiInput>>{
            execute: triggerFetchUserDetails,
            executeInputSelector: ({ routeLocation }) => ({
                userId: routeLocation.params.userId,
            }),
        }, {
            execute: triggerFetchUserPortfolios,
            shouldExecute: ({ routeLocation }) =>
                (userTab || routeLocation.params.userTab) === USER_TAB_KEY.PORTFOLIOS,
        }, {
            execute: triggerFetchUserPortfolioReports,
            executeInputSelector: ({ routeLocation }) => ({
                userId: routeLocation.params.userId,
            }),
            shouldExecute: ({ routeLocation }) =>
                (userTab || routeLocation.params.userTab) === USER_TAB_KEY.PORTFOLIO_REPORTS,
        }],
    };
}
