import produce from 'immer';
import isSet from '@snipsonian/core/cjs/is/isSet';
import {
    CombineTransactionCostMode,
    IPolicyAssetClasses,
    IPolicyBenchmarkConstraints,
    IPolicyBondTypes,
    IPolicyRegionsConstraints,
    IPolicySectorConstraints,
    PolicyManagerTag,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { RANGE_FROM_0_TO_1, RANGE_FROM_MIN1_TO_1 } from '@console/core-api/typsy/console-api-client/dist/config/range.config';
import { TNumberRange } from '@console/core-api/typsy/console-api-client/dist/models/common/numeric.models';
import {
    isPolicyOfTypeMetaPortfolio,
    isPolicyOfTypeModelPortfolio,
    isPolicyOfTypeRiskBased,
} from '@console/bff/utils/policies/policyTypeUtils';
import {
    IEnhancedPolicyAddEntityData,
    IEnhancedPolicyAlgorithmSettings,
    IEnhancedPolicyConfig,
    TCreatePolicyApiBody,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { IPolicySettingRuleBase, PolicySettingRuleType } from 'models/ui/policySettings.ui.models';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import { getPolicyDetailsMetaInfo } from 'state/entities/portfolioMgmt/policyDetails';
import {
    isPolicySettingRuleBoundedNumberRangeFieldTypeGuard,
    isPolicySettingRuleBoundedSingleNumberFieldTypeGuard,
    isPolicySettingRuleMultiValueFieldTypeGuard,
    isPolicySettingRuleSingleValueFieldTypeGuard,
} from 'utils/entities/portfolioMgmt/policySettingsUtils';
import { TAddPolicyFormValues } from 'views/portfolioMgmt/Policies/PolicyDetail/policyFormContents/types';
import { areSliderValuesSame } from 'views/common/inputs/base/InputSliderField';

interface IInitPolicyForCreateProps {
    addFormValues: TAddPolicyFormValues;
    alwaysIncludeBenchmarkConstraints?: boolean; // default false
}

/* eslint-disable max-len */

export function initPolicyForCreate({
    addFormValues,
    alwaysIncludeBenchmarkConstraints = false,
}: IInitPolicyForCreateProps): TCreatePolicyApiBody {
    const {
        algorithm, measureOfRisk,
        parentPolicyId, selectedParentPolicy,
        name, baseCurrency, benchmarkId,
        minCashAmount, maxCashAmount,
        minPortfolioOptimalityImprovement, maxPortfolioConstraintsViolation,
        minPortfolioValue,
        modelPortfolioComposition, metaPortfolioComposition,
    } = addFormValues;

    const { rules, tenantSettings } = getPolicyDetailsMetaInfo({
        parentPolicy: selectedParentPolicy,
    });

    return enrichApiEntityDataForCreate<IEnhancedPolicyAddEntityData>({
        name,
        external_id: null,
        parent_policy_id: parentPolicyId,
        tags: initPolicyTagsForCreate(),
        risk_profile_id: null,
        config: initPolicyConfigForCreate(),
    });

    function initPolicyTagsForCreate(): PolicyManagerTag[] {
        return getConditionallyParentValueOrDefault(rules.details.tags, []);
    }

    function initPolicyConfigForCreate(): IEnhancedPolicyConfig {
        return {
            algorithm,
            measureOfRisk,
            algorithm_version: 1,
            algorithm_settings: optimizeEnhancedPolicyAlgorithmSettings({
                execution_settings: {
                    base_currency: baseCurrency,
                    benchmark_id: benchmarkId,
                    combine_transaction_cost_components: getConditionallyParentValueOrDefault(rules.executionConstraints.combineTransactionCostMode, CombineTransactionCostMode.SUM),
                    cost_per_transaction_amount: getConditionallyParentValueOrDefault(rules.executionConstraints.costPerTransactionAmount, 0),
                    cost_per_transaction_fraction: getConditionallyParentValueOrDefault(rules.executionConstraints.costPerTransactionFraction, 0),
                    fractional_shares: getConditionallyParentValueOrDefault(rules.executionConstraints.includeFractionalShares, false),
                    transaction_amount: getConditionallyParentValueOrDefault(rules.executionConstraints.transactionAmountRange, tenantSettings.transactionAmount.default),
                },
                portfolio_constraints: {
                    momentum: getConditionallyParentValueOrDefault(rules.portfolioConstraints.momentum, tenantSettings.momentum.default),
                    asset_classes: {
                        /* RANGE_FROM_0_TO_1 */
                        alternatives: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassAlternativesRange, null),
                        bonds: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassBondsRange, null),
                        cash: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassCashRange, null),
                        commodities: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassCommoditiesRange, null),
                        stocks: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassStocksRange, null),
                        corporate: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassCorporateRange, null),
                        equity: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassEquityRange, null),
                        eurobond: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassEurobondRange, null),
                        fixed_income: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassFixedIncomeRange, null),
                        government: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassGovernmentRange, null),
                        money_market: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassMoneyMarketRange, null),
                        real_estate: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassRealEstateRange, null),
                        sukuk: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassSukukRange, null),
                        other: getConditionallyParentValueOrDefault(rules.allocationBoundaries.assetClassOtherRange, null),
                    },
                    bond_types: {
                        /* RANGE_FROM_0_TO_1 */
                        convertible: getConditionallyParentValueOrDefault(rules.allocationBoundaries.bondTypeConvertibleRange, null),
                        corporate: getConditionallyParentValueOrDefault(rules.allocationBoundaries.bondTypeCorporateRange, null),
                        government: getConditionallyParentValueOrDefault(rules.allocationBoundaries.bondTypeGovernmentRange, null),
                    },
                    holdings: {
                        cash_amount: [minCashAmount, maxCashAmount],
                        cash_fraction: getConditionallyParentValueOrDefault(rules.portfolioConstraints.cashFractionRange, RANGE_FROM_0_TO_1),
                        position_amount: getConditionallyParentValueOrDefault(rules.portfolioConstraints.positionAmountRange, tenantSettings.positionAmount.default),
                        position_fraction: getConditionallyParentValueOrDefault(rules.portfolioConstraints.positionFractionRange, RANGE_FROM_0_TO_1),
                    },
                    investment_universe: isPolicyOfTypeRiskBased(algorithm)
                        ? getConditionallyParentValueOrDefault(rules.universe.instruments, [])
                        : [],
                    buy_universe: isPolicyOfTypeRiskBased(algorithm)
                        ? getConditionallyParentValueOrDefault(rules.universe.buyInstruments, null)
                        : null,
                    regions: {
                        bonds: {
                            /* RANGE_FROM_0_TO_1 */
                            asia_pacific_developed: getConditionallyParentValueOrDefault(rules.allocationBoundaries.regionBondsAsiaRange, null),
                            emerging: getConditionallyParentValueOrDefault(rules.allocationBoundaries.regionBondsEmergingRange, null),
                            europe_developed: getConditionallyParentValueOrDefault(rules.allocationBoundaries.regionBondsEuropeRange, null),
                            north_america: getConditionallyParentValueOrDefault(rules.allocationBoundaries.regionBondsAmericaRange, null),
                        },
                        stocks: {
                            /* RANGE_FROM_0_TO_1 */
                            asia_pacific_developed: getConditionallyParentValueOrDefault(rules.allocationBoundaries.regionStocksAsiaRange, null),
                            emerging: getConditionallyParentValueOrDefault(rules.allocationBoundaries.regionStocksEmergingRange, null),
                            europe_developed: getConditionallyParentValueOrDefault(rules.allocationBoundaries.regionStocksEuropeRange, null),
                            north_america: getConditionallyParentValueOrDefault(rules.allocationBoundaries.regionStocksAmericaRange, null),
                        },
                    },
                    sectors: {
                        /* RANGE_FROM_0_TO_1 */
                        basic_materials: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorBasicMaterialsRange, null),
                        communication_services: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorCommunicationServicesRange, null),
                        consumer_cyclical: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorConsumerCyclicalRange, null),
                        consumer_defensive: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorConsumerDefensiveRange, null),
                        energy: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorEnergyRange, null),
                        financial_services: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorFinancialServicesRange, null),
                        healthcare: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorHealthcareRange, null),
                        industrials: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorIndustrialsRange, null),
                        real_estate: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorRealEstateRange, null),
                        technology: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorTechnologyRange, null),
                        utilities: getConditionallyParentValueOrDefault(rules.allocationBoundaries.sectorUtilitiesRange, null),
                    },
                },
                portfolio_update_constraints: {
                    holdings: {
                        cash_fraction: getConditionallyParentValueOrDefault(rules.portfolioUpdateConstraints.cashFractionRange, RANGE_FROM_0_TO_1),
                    },
                    max_portfolio_constraint_violation_pctpoints: maxPortfolioConstraintsViolation,
                    min_portfolio_update_optimality_improvement_fraction: minPortfolioOptimalityImprovement,
                    min_portfolio_value: minPortfolioValue,
                },
                benchmark_constraints: initBenchmarkConstraintsForCreate(),
                model_portfolio_settings: isPolicyOfTypeModelPortfolio(algorithm)
                    ? { model_portfolio: modelPortfolioComposition }
                    : null,
                meta_portfolio_settings: isPolicyOfTypeMetaPortfolio(algorithm)
                    ? { meta_portfolio: metaPortfolioComposition }
                    : null,
            }),
        };
    }

    function initBenchmarkConstraintsForCreate(): IPolicyBenchmarkConstraints {
        if (!alwaysIncludeBenchmarkConstraints
            && shouldBenchmarkConstraintsBeNullBasedOnSelectedBenchmark({ benchmarkId })) {
            return null;
        }

        return {
            asset_classes: {
                /* RANGE_FROM_MIN1_TO_1 */
                alternatives: getConditionallyParentValueOrDefault(rules.benchmark.assetClassAlternativesRange, null),
                bonds: getConditionallyParentValueOrDefault(rules.benchmark.assetClassBondsRange, null),
                cash: getConditionallyParentValueOrDefault(rules.benchmark.assetClassCashRange, null),
                commodities: getConditionallyParentValueOrDefault(rules.benchmark.assetClassCommoditiesRange, null),
                stocks: getConditionallyParentValueOrDefault(rules.benchmark.assetClassStocksRange, null),
                corporate: getConditionallyParentValueOrDefault(rules.benchmark.assetClassCorporateRange, null),
                equity: getConditionallyParentValueOrDefault(rules.benchmark.assetClassEquityRange, null),
                eurobond: getConditionallyParentValueOrDefault(rules.benchmark.assetClassEurobondRange, null),
                fixed_income: getConditionallyParentValueOrDefault(rules.benchmark.assetClassFixedIncomeRange, null),
                government: getConditionallyParentValueOrDefault(rules.benchmark.assetClassGovernmentRange, null),
                money_market: getConditionallyParentValueOrDefault(rules.benchmark.assetClassMoneyMarketRange, null),
                real_estate: getConditionallyParentValueOrDefault(rules.benchmark.assetClassRealEstateRange, null),
                sukuk: getConditionallyParentValueOrDefault(rules.benchmark.assetClassSukukRange, null),
                other: getConditionallyParentValueOrDefault(rules.benchmark.assetClassOtherRange, null),
            },
            bond_types: {
                /* RANGE_FROM_MIN1_TO_1 */
                convertible: getConditionallyParentValueOrDefault(rules.benchmark.bondTypeConvertibleRange, null),
                corporate: getConditionallyParentValueOrDefault(rules.benchmark.bondTypeCorporateRange, null),
                government: getConditionallyParentValueOrDefault(rules.benchmark.bondTypeGovernmentRange, null),
            },
            regions: {
                bonds: {
                    /* RANGE_FROM_MIN1_TO_1 */
                    asia_pacific_developed: getConditionallyParentValueOrDefault(rules.benchmark.regionBondsAsiaRange, null),
                    emerging: getConditionallyParentValueOrDefault(rules.benchmark.regionBondsEmergingRange, null),
                    europe_developed: getConditionallyParentValueOrDefault(rules.benchmark.regionBondsEuropeRange, null),
                    north_america: getConditionallyParentValueOrDefault(rules.benchmark.regionBondsAmericaRange, null),
                },
                stocks: {
                    /* RANGE_FROM_MIN1_TO_1 */
                    asia_pacific_developed: getConditionallyParentValueOrDefault(rules.benchmark.regionStocksAsiaRange, null),
                    emerging: getConditionallyParentValueOrDefault(rules.benchmark.regionStocksEmergingRange, null),
                    europe_developed: getConditionallyParentValueOrDefault(rules.benchmark.regionStocksEuropeRange, null),
                    north_america: getConditionallyParentValueOrDefault(rules.benchmark.regionStocksAmericaRange, null),
                },
            },
            sectors: {
                /* RANGE_FROM_MIN1_TO_1 */
                basic_materials: getConditionallyParentValueOrDefault(rules.benchmark.sectorBasicMaterialsRange, null),
                communication_services: getConditionallyParentValueOrDefault(rules.benchmark.sectorCommunicationServicesRange, null),
                consumer_cyclical: getConditionallyParentValueOrDefault(rules.benchmark.sectorConsumerCyclicalRange, null),
                consumer_defensive: getConditionallyParentValueOrDefault(rules.benchmark.sectorConsumerDefensiveRange, null),
                energy: getConditionallyParentValueOrDefault(rules.benchmark.sectorEnergyRange, null),
                financial_services: getConditionallyParentValueOrDefault(rules.benchmark.sectorFinancialServicesRange, null),
                healthcare: getConditionallyParentValueOrDefault(rules.benchmark.sectorHealthcareRange, null),
                industrials: getConditionallyParentValueOrDefault(rules.benchmark.sectorIndustrialsRange, null),
                real_estate: getConditionallyParentValueOrDefault(rules.benchmark.sectorRealEstateRange, null),
                technology: getConditionallyParentValueOrDefault(rules.benchmark.sectorTechnologyRange, null),
                utilities: getConditionallyParentValueOrDefault(rules.benchmark.sectorUtilitiesRange, null),
            },
            holdings: {
                position_fraction: getConditionallyParentValueOrDefault(rules.benchmark.positionFractionRange, RANGE_FROM_MIN1_TO_1),
            },
            relative_ivar_weight: getConditionallyParentValueOrDefault(rules.benchmark.relativeIvarWeight, 0),
        };
    }

    function getConditionallyParentValueOrDefault<Value>(
        settingRule: IPolicySettingRuleBase<Value>,
        defaultValue: Value,
    ): Value {
        switch (settingRule.rule) {
            case PolicySettingRuleType.CANNOT_DIFFER:
            case PolicySettingRuleType.MORE_ITEMS_ALLOWED:
            case PolicySettingRuleType.LESS_ITEMS_ALLOWED:
                return getBoundaryValue<Value>(settingRule) || defaultValue;
            case PolicySettingRuleType.WITHIN_BOUNDARIES: {
                if (!rules.hasParentPolicy) {
                    return defaultValue;
                }
                return getBoundaryValue<Value>(settingRule) || defaultValue;
            }
            default:
                return defaultValue;
        }
    }
}

/* eslint-enable max-len */

function getBoundaryValue<Value>(settingRule: IPolicySettingRuleBase<Value>): Value {
    if (isPolicySettingRuleMultiValueFieldTypeGuard<Value>(settingRule)) {
        return settingRule.boundaryValues as unknown as Value;
    }
    if (isPolicySettingRuleSingleValueFieldTypeGuard<Value>(settingRule)
        || isPolicySettingRuleBoundedSingleNumberFieldTypeGuard(settingRule)) {
        return settingRule.boundaryValue as Value;
    }
    if (isPolicySettingRuleBoundedNumberRangeFieldTypeGuard(settingRule)) {
        return [settingRule.boundaryMin, settingRule.boundaryMax] as unknown as Value;
    }

    return null;
}

export function shouldBenchmarkConstraintsBeNullBasedOnSelectedBenchmark({
    benchmarkId,
}: {
    benchmarkId: string;
}): boolean {
    if (!isSet(benchmarkId)) {
        return true;
    }

    return false;
}

/**
 * Before creating/patching the policy config settings, we optimize them a bit:
 * - e.g. making the entire "sectors" null when all the fields within are null
 */
export function optimizeEnhancedPolicyAlgorithmSettings(
    enhancedPolicyAlgorithmSettings: IEnhancedPolicyAlgorithmSettings,
): IEnhancedPolicyAlgorithmSettings {
    return produce(enhancedPolicyAlgorithmSettings, (newSettings) => {
        optimizeConstraints({
            constraintsToOptimize: newSettings.portfolio_constraints,
            maxSliderRange: RANGE_FROM_0_TO_1,
        });
        optimizeConstraints({
            constraintsToOptimize: newSettings.benchmark_constraints,
            maxSliderRange: RANGE_FROM_MIN1_TO_1,
        });
    });

    function optimizeConstraints({
        constraintsToOptimize,
        maxSliderRange,
    }: {
        constraintsToOptimize: {
            asset_classes: IPolicyAssetClasses;
            regions: IPolicyRegionsConstraints;
            bond_types: IPolicyBondTypes;
            sectors: IPolicySectorConstraints;
        };
        maxSliderRange: TNumberRange;
    }) {
        if (!constraintsToOptimize) {
            return;
        }

        /**
         * What is being optimized?
         *
         * - asset_classes
         *   > we don't do anything
         *   > they can be enabled/disabled one by one (disabled = null)
         *
         * - bond_types / sectors
         *   > can't be turned off one by one
         *   > either the entire object is absent (p.s. the console-api does not allow null for the object)
         *     OR all fields within have to be present
         *
         * - regions
         *   > similar to bond_types / sectors
         *   > but if one of regions.bonds or regions.stocks is set (all fields within have to be present)
         *     then also the other one has to be present
         *   > if both are not set, then the entire regions object can be absent
         */

        if (!isAnySliderSet(constraintsToOptimize.bond_types as unknown as Record<string, TNumberRange>)) {
            delete constraintsToOptimize.bond_types;
        }

        if (!isAnySliderSet(constraintsToOptimize.sectors as unknown as Record<string, TNumberRange>)) {
            delete constraintsToOptimize.sectors;
        }

        if (!isAnySliderSet(constraintsToOptimize.regions.bonds as unknown as Record<string, TNumberRange>)
            && !isAnySliderSet(constraintsToOptimize.regions.stocks as unknown as Record<string, TNumberRange>)) {
            delete constraintsToOptimize.regions;
        }

        function isAnySliderSet(obj: Record<string, TNumberRange>): boolean {
            if (obj) {
                if (Object.values(obj).some((val) => isSet(val) && !areSliderValuesSame(val, maxSliderRange))) {
                    return true;
                }
            }

            return false;
        }
    }
}
