import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import {
    PortfolioManagerType, PortfolioMoneyType,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';

export interface ICreatePortfolioReportModalData {
    entityType: TPortfolioReportEntityType; // the entity that the report is being generated for
    reportsInputData: ISingleReportInputData[];
}

export type TPortfolioReportEntityType = EntityType.portfolio | EntityType.portfolioGroup;

export interface ISingleReportInputData {
    entityId: string;
    entityName: string;
    entityCreationDatetime: string;

    // Must be provided only for portfolio groups
    portfolioIds?: string[];

    // Must be provided only for portfolios
    userId?: string;
    preferredLanguage?: string;
    portfolioManagerType?: PortfolioManagerType;
    portfolioMoneyType?: PortfolioMoneyType;
}

export enum ReportEntityType {
    Portfolio = 'Portfolio',
    PortfolioGroup = 'PortfolioGroup',
}
