import React from 'react';
import clsx from 'clsx';
import {
    PortfolioManagerType,
    PortfolioMoneyType,
    PortfolioStatus,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';
import { EnhancedOptimizationStatus } from '@console/bff/models/enhancedOptimization.models';
import { ReportEntityType } from 'models/ui/portfolioReport.ui.models';
import { PolicyHierarchyLevel } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { getPortfolioCombinedTypeLabel } from 'utils/entities/portfolioMgmt/portfolioUtils';
import { makeStyles, mixins } from 'views/styling';
import {
    PortfolioReportIcon, PortfolioGroupsIcon,
    PfMandateRoboAdvisoryRealIcon, PfMandateRoboAdvisoryVirtualIcon,
    PfMandateRoboDiscretionaryRealIcon, PfMandateRoboDiscretionaryVirtualIcon,
    PfMandateSelfRealIcon, PfMandateSelfVirtualIcon,
    PfMandateAdvisorDiscretionaryRealIcon, PfMandateAdvisorDiscretionaryVirtualIcon,
    BasePolicyIcon, ChildPolicyIcon, ICustomIconProps, WarningIcon, OptimizationPendingStatusIcon,
    OptimizationErrorStatusIcon, OptimizationNotRecommendedStatusIcon, OptimizationRecommendedStatusIcon,
    PortfolioInactiveStatusIcon, PortfolioWaitingStatusIcon, PortfolioActiveStatusIcon,
} from 'views/common/icons';
import Tooltip from 'views/common/widget/Tooltip';

interface IDefaultIndicatorConfig {
    label: string;
    Icon: (props: ICustomIconProps) => JSX.Element;
}

const INDICATOR_DEFAULT: { [type: string]: { [value: string]: IDefaultIndicatorConfig } } = {
    portfolioStatus: {
        [PortfolioStatus.ACTIVE]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.portfolio.active',
            Icon: PortfolioActiveStatusIcon,
        },
        [PortfolioStatus.INACTIVE]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.portfolio.inactive',
            Icon: PortfolioInactiveStatusIcon,
        },
        [PortfolioStatus.WAITING_FOR_FUNDS]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.portfolio.waiting_for_funds',
            Icon: PortfolioWaitingStatusIcon,
        },
        [PortfolioStatus.WAITING_FOR_POLICY]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.portfolio.waiting_for_policy',
            Icon: PortfolioInactiveStatusIcon,
        },
        [PortfolioStatus.WAITING_FOR_ACCOUNT]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.portfolio.waiting_for_account',
            Icon: PortfolioInactiveStatusIcon,
        },
    },
    optimizationStatus: {
        [EnhancedOptimizationStatus.RECOMMENDED]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.optimization.recommended',
            Icon: OptimizationRecommendedStatusIcon,
        },
        [EnhancedOptimizationStatus.NOT_RECOMMENDED]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.optimization.not_recommended',
            Icon: OptimizationNotRecommendedStatusIcon,
        },
        [EnhancedOptimizationStatus.FAILURE]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.optimization.failure',
            Icon: OptimizationErrorStatusIcon,
        },
        [EnhancedOptimizationStatus.INFEASIBLE]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.optimization.infeasible',
            Icon: OptimizationErrorStatusIcon,
        },
        [EnhancedOptimizationStatus.PENDING]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.optimization.pending',
            Icon: OptimizationPendingStatusIcon,
        },
        [EnhancedOptimizationStatus.NOT_OPTIMIZED]: {
            label: 'portfolio_mgmt.portfolios.data.enhanced_status.optimization.not_optimized',
            Icon: OptimizationErrorStatusIcon,
        },

        // This status is empty, because we do not expect to see them in Console Web -> Console BFF enhances this
        [EnhancedOptimizationStatus.SUCCESS]: {
            label: 'success',
            Icon: WarningIcon,
        },
    },
    reportEntityType: {
        [ReportEntityType.Portfolio]: {
            label: 'apps.story_teller.reports.type_indicators.portfolio',
            Icon: PortfolioReportIcon,
        },
        [ReportEntityType.PortfolioGroup]: {
            label: 'apps.story_teller.reports.type_indicators.portfolio_group',
            Icon: PortfolioGroupsIcon,
        },
    },
    policyHierarchyLevel: {
        [PolicyHierarchyLevel.Base]: {
            label: 'portfolio_mgmt.policies.hierarchy_level.base',
            Icon: BasePolicyIcon,
        },
        [PolicyHierarchyLevel.Child]: {
            label: 'portfolio_mgmt.policies.hierarchy_level.child',
            Icon: ChildPolicyIcon,
        },
    },
};

const INDICATOR_CUSTOM = {
    portfolioType: {
        [PortfolioManagerType.ADVISOR_DISCRETIONARY]: getPortfolioTypeIndicatorConfig({
            managerType: PortfolioManagerType.ADVISOR_DISCRETIONARY,
            RealIcon: PfMandateAdvisorDiscretionaryRealIcon,
            VirtualIcon: PfMandateAdvisorDiscretionaryVirtualIcon,
        }),
        [PortfolioManagerType.ROBO_ADVISOR_ADVISORY]: getPortfolioTypeIndicatorConfig({
            managerType: PortfolioManagerType.ROBO_ADVISOR_ADVISORY,
            RealIcon: PfMandateRoboAdvisoryRealIcon,
            VirtualIcon: PfMandateRoboAdvisoryVirtualIcon,
        }),
        [PortfolioManagerType.ROBO_ADVISOR_DISCRETIONARY]: getPortfolioTypeIndicatorConfig({
            managerType: PortfolioManagerType.ROBO_ADVISOR_DISCRETIONARY,
            RealIcon: PfMandateRoboDiscretionaryRealIcon,
            VirtualIcon: PfMandateRoboDiscretionaryVirtualIcon,
        }),
        [PortfolioManagerType.USER_MANAGED]: getPortfolioTypeIndicatorConfig({
            managerType: PortfolioManagerType.USER_MANAGED,
            RealIcon: PfMandateSelfRealIcon,
            VirtualIcon: PfMandateSelfVirtualIcon,
        }),
    },
};

const useStyles = makeStyles(() => ({
    Indicator: {
        ...mixins.widthHeightPixelsSame(32),
    },
    ReportIndicator: {
        '& svg': {
            ...mixins.widthHeightPixelsSame(32),
        },

        '&.portfolioReport': {
            ...mixins.widthHeightPixelsSame(42),
            borderRadius: '50%',
            color: '#7440C3',
            background: 'rgb(151, 81, 255, 0.08)',

            '& svg': {
                transform: 'translate(-5px, 0px)',
            },
        },
        '&.portfolioGroupReport': {
            ...mixins.widthHeightPixelsSame(42),
            borderRadius: '50%',
            color: '#466AD7',
            background: 'rgb(106, 177, 255, 0.15)',

            '& svg': {
                transform: 'translate(0px, 4px)',
            },
        },
    },
}));

export function PortfolioStatusIndicator({
    portfolioStatus,
}: {
    portfolioStatus: PortfolioStatus;
}) {
    return (
        <DefaultIndicator type="portfolioStatus" value={portfolioStatus} />
    );
}

export function OptimizationStatusIndicator({
    enhancedOptimizationStatus,
}: {
    enhancedOptimizationStatus: EnhancedOptimizationStatus;
}) {
    return (
        <DefaultIndicator type="optimizationStatus" value={enhancedOptimizationStatus} />
    );
}

export function PolicyHierarchyLevelIndicator({
    policyHierarchyLevel,
}: {
    policyHierarchyLevel: PolicyHierarchyLevel;
}) {
    return (
        <DefaultIndicator type="policyHierarchyLevel" value={policyHierarchyLevel} />
    );
}

export function PortfolioTypeIndicator({
    managerType,
    moneyType,
}: {
    managerType: PortfolioManagerType;
    moneyType: PortfolioMoneyType;
}) {
    const classes = useStyles();

    if (!managerType || !moneyType || !INDICATOR_CUSTOM.portfolioType[managerType]) {
        return null;
    }

    const { label, Icon } = INDICATOR_CUSTOM.portfolioType[managerType][moneyType];

    if (!Icon) {
        return null;
    }

    return (
        <Tooltip
            label={label}
            placement="top-start"
        >
            <Icon className={classes.Indicator} />
        </Tooltip>
    );
}

export function ReportTypeIndicator({ reportType }: {
    reportType: ReportEntityType;
}) {
    const classes = useStyles();

    if (!reportType) {
        return null;
    }

    const { label, Icon } = INDICATOR_DEFAULT.reportEntityType[reportType];

    if (!Icon) {
        return null;
    }

    const reportTypeClass = reportType === ReportEntityType.Portfolio
        ? 'portfolioReport'
        : 'portfolioGroupReport';

    return (
        <Tooltip
            label={label}
            placement="top-start"
        >
            <div className={clsx(classes.ReportIndicator, reportTypeClass)}>
                <Icon />
            </div>
        </Tooltip>
    );
}

function DefaultIndicator({
    type,
    value,
}: {
    type: 'portfolioStatus' | 'optimizationStatus' | 'policyHierarchyLevel';
    value: string;
}) {
    const classes = useStyles();
    if (!value) {
        return null;
    }

    const { label, Icon } = INDICATOR_DEFAULT[type][value];

    if (!Icon) {
        return null;
    }

    return (
        <Tooltip
            label={label}
            placement="top-start"
        >
            <Icon className={classes.Indicator} />
        </Tooltip>
    );
}

function getPortfolioTypeIndicatorConfig({
    managerType,
    RealIcon,
    VirtualIcon,
}: {
    managerType: PortfolioManagerType;
    RealIcon: (props: ICustomIconProps) => JSX.Element;
    VirtualIcon: (props: ICustomIconProps) => JSX.Element;
}) {
    return {
        [PortfolioMoneyType.REAL_MONEY]: {
            label: getPortfolioCombinedTypeLabel({
                managerType,
                moneyType: PortfolioMoneyType.REAL_MONEY,
            }),
            Icon: RealIcon,
        },
        [PortfolioMoneyType.PAPER_MONEY]: {
            label: getPortfolioCombinedTypeLabel({
                managerType,
                moneyType: PortfolioMoneyType.PAPER_MONEY,
            }),
            Icon: VirtualIcon,
        },
    };
}
