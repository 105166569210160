import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import { IDepositOrWithdrawPortfolioCashInput } from '../../typsy/console-api-client/dist/models/portfolioMgmt/portfolioCash.entity.models';
import { TPortfolio } from '../../typsy/console-api-client/dist/models/portfolioMgmt/portfolio.entity.models';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import { get } from '../coreApiRequestWrapper';

export function depositOrWithdrawPortfolioCash({
    portfolioId,
    amount,
    underlyingApiRequestConfig,
}: IDepositOrWithdrawPortfolioCashInput & IUnderlyingRequestApiInput) {
    /* even though this endpoint changes the cash, it is a GET */
    return get<TPortfolio>({
        url: ConsoleApiPath.PORTFOLIO_CASH,
        pathParams: {
            portfolioId,
        },
        queryParams: {
            amount,
        },
        ...underlyingApiRequestConfig,
    });
}
