import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import d3 from 'utils/libs/d3';
import {
    getGenericPerformanceChartConfig,
} from 'utils/chart/getGenericPerformanceChartConfig';
import { IAreaDataItem, ILineDataItem } from 'views/common/charts/types';
import { CHART } from 'config/styling/chart';
import {
    IInstrumentPerformancePast,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/instrumentPerformancePast.entity.models';
import { IInstrumentPerformancePastMarkedDate } from 'models/ui/instrument.ui.models';
import { IChartDimensions, IGenericPerformanceChartConfig } from 'models/chart.models';

interface IDate2InstrumentPerformanceValuesMap {
    [date: string]: IInstrumentPerformancePastMarkedDate;
}

export function getInstrumentsPerformanceChartConfig({
    chartDimensions,
    performanceData,
}: {
    chartDimensions: IChartDimensions
    performanceData: IInstrumentPerformancePast;
}): IGenericPerformanceChartConfig<IInstrumentPerformancePastMarkedDate> {
    const date2valuesMap: IDate2InstrumentPerformanceValuesMap = performanceData.date.reduce(
        (accumulator, dateString, index) => {
            accumulator[dateString] = {
                date: new Date(dateString),
                values: {
                    instrument: performanceData?.value[performanceData.isin][index],
                },
            };
            return accumulator;
        },
        {} as IDate2InstrumentPerformanceValuesMap,
    );

    const instrumentLineData: ILineDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y: values.instrument,
        }),
    );
    const areaData: IAreaDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y0: values.instrument,
            y1: 0,
        }),
    );

    const xDates = instrumentLineData.map((instrumentLineItem) => instrumentLineItem.x);

    const instrumentMinMax = d3.extent(performanceData?.value[performanceData.isin]) as number[];
    const globalMinMaxAmount = d3.extent(instrumentMinMax.concat([0, 0]));

    // eslint-disable-next-line max-len
    const baseConfig = getGenericPerformanceChartConfig<IDate2InstrumentPerformanceValuesMap, IInstrumentPerformancePastMarkedDate>({
        chartDimensions,
        xDates,
        globalMinMaxAmount,
        date2valuesMap,
    });

    return {
        ...baseConfig,
        lines: {
            instrument: {
                data: instrumentLineData,
                color: CHART.FUTURE.OPTIMISTIC_COLOR,
            },
        },
        area: {
            data: areaData,
            color: CHART.FUTURE.AREA_COLOR,
        },
        markerStartingPos: {
            x: baseConfig.xScale.domain()[1],
            y: {
                instrument: {
                    val: getLastItemOfArray(instrumentLineData).y,
                    color: CHART.FUTURE.OPTIMISTIC_COLOR,
                    className: 'InstrumentMarker',
                },
            },
        },
    };
}
