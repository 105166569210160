import {
    IRegisteredEntity,
    ITriggerAsyncEntityFetchProps,
    ITriggerAsyncEntityRemoveProps,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import {
    TConsoleApiErrorClientSide,
} from '@typsy/console-api-client/dist/models/consoleApiError.models';
import { BaseApiErrorCode, IBaseApiErrorClientSide } from '@console/api-base/server/error/apiBaseError.models';
import { getApiErrorCode } from '@console/core-api/typsy/console-api-client/dist/utils/error/clientSideErrorUtils';
import { isClientSideApiErrorTypeGuard } from '@console/api-base/client/error/isClientSideApiErrorTypeGuard';
import {
    EntityType, IBaseIdentifiedEntity, IBaseEntity,
} from '@console/core-api/typsy/entities/dist/common/entity.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getStore } from 'state';
import { openEntityStillUsedAppModal } from 'state/ui/appModals.actions';
import {
    extractEntityIdFromEntityNotFoundErrorDetailText,
    extractEntityIdsFromBusinessLogicErrorDetailText,
} from 'utils/error/entityAsyncErrorUtils';
import { redirectTo } from 'views/routes';
import {
    CORE_API_ENTITY_TYPE_TO_DETAIL_ROUTE_MAP,
    CORE_API_ENTITY_TYPE_TO_LIST_ROUTE_MAP,
} from 'config/coreEntities.ui.config';
import {
    flashErrorApiEntityDelete,
    flashErrorEntityNotFound,
    flashSuccessApiEntityDelete,
} from '../entitiesFlashDispatcher';

export function apiDetailsEntityAsyncFetchAction<EntityData extends IBaseEntity, FetchFilter>({
    detailsEntity,
    entityType,
    ...fetchProps
}: {
    detailsEntity: IRegisteredEntity<IState, EntityData, StateChangeNotification, IBaseApiErrorClientSide>;
    entityType: EntityType;
} & ITriggerAsyncEntityFetchProps<IState, FetchFilter, StateChangeNotification, EntityData, EntityData>) {
    return detailsEntity.async.fetch<FetchFilter>(fetchProps)
        .catch((apiError) => {
            if (isClientSideApiErrorTypeGuard(apiError)) {
                if (getApiErrorCode(apiError) === BaseApiErrorCode.ENTITY_NOT_FOUND) {
                    flashErrorEntityNotFound(
                        extractEntityIdFromEntityNotFoundErrorDetailText(
                            apiError as unknown as TConsoleApiErrorClientSide,
                        ),
                    );

                    /*
                        The re-direct to the list page of the missing entity type should only happen if the user
                        was visiting the detail page of the same entity type.

                        If this check is not present the user can be redirected in a confusing way e.g. from
                        Portfolio Detail to Users List, because the user associated with the portfolio
                        (whose details we try to fetch) is not found. This would not be correct behaviour.
                    */
                    if (getStore().getState().ui.currentRoute.location.routeKey
                        === CORE_API_ENTITY_TYPE_TO_DETAIL_ROUTE_MAP[entityType]) {
                        redirectTo({
                            routeKey: CORE_API_ENTITY_TYPE_TO_LIST_ROUTE_MAP[entityType],
                        });
                    }
                }
            }
        });
}

export function apiEntityAsyncDeleteAction<EntityData extends IBaseEntity>({
    detailsEntity,
    ...deleteProps
}: {
    detailsEntity: IRegisteredEntity<IState, EntityData, StateChangeNotification, IBaseApiErrorClientSide>;
} & ITriggerAsyncEntityRemoveProps<IState, IBaseIdentifiedEntity, StateChangeNotification, unknown, unknown>) {
    return detailsEntity.async.remove<IBaseIdentifiedEntity, unknown>({
        ...deleteProps,
        onSuccess: ({ dispatch, ...otherSuccessProps }) => {
            flashSuccessApiEntityDelete({ dispatch });

            if (deleteProps.onSuccess) {
                deleteProps.onSuccess({ dispatch, ...otherSuccessProps });
            }
        },
        onError: ({ error, dispatch, ...otherErrorProps }) => {
            if (getApiErrorCode<BaseApiErrorCode>(error) !== BaseApiErrorCode.ENTITY_STILL_USED) {
                flashErrorApiEntityDelete({ error, dispatch });
            }

            if (deleteProps.onError) {
                deleteProps.onError({ error, dispatch, ...otherErrorProps });
            }
        },
    }).catch((apiError) => {
        if (isClientSideApiErrorTypeGuard(apiError)) {
            if (getApiErrorCode(apiError) === BaseApiErrorCode.ENTITY_STILL_USED) {
                getStore().dispatch(openEntityStillUsedAppModal({
                    extraData: {
                        usedByIds: extractEntityIdsFromBusinessLogicErrorDetailText(
                            apiError as unknown as TConsoleApiErrorClientSide,
                        ),
                        entityId: detailsEntity.select().data.id,
                    },
                }));
            }
        }
    });
}
