import React, { useState } from 'react';
import { triggerUpdatePortfolioReport } from 'state/entities/portfolioMgmt/portfolioReports';
import TextButton from 'views/common/buttons/TextButton';
import InputTextField from 'views/common/inputs/base/InputTextField';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import Modal from 'views/common/layout/Modal';
import { makeStyles, mixins } from 'views/styling';
import { IMultiTranslationsLabel } from '@console/core-api/typsy/console-api-client/dist/models/common/i18n.models';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { getDefaultUserLocaleConfig } from 'state/appConfig/selectors';

const TRANSLATION_PREFIX = 'portfolio_mgmt.portfolio_reports.create.edit_modal.name';
const MODAL_ID = 'edit_portfolio_report_name_modal';

interface IPublicProps {
    onClose(): void;
    open: boolean;
    portfolioReportId: string | undefined;
    currentPortfolioReportName: IMultiTranslationsLabel | undefined;
}

const useStyles = makeStyles((theme) => ({
    EditPortfolioReportName: {
        '& .__current .input-wrapped': {
            marginLeft: theme.spacing(1),
            minHeight: 40,
        },

        '& .__actions': {
            ...mixins.flexRowCenterRight(),
            ...mixins.widthMax(),
        },
    },
}));

export default function EditPortfolioReportName({
    onClose,
    open,
    portfolioReportId,
    currentPortfolioReportName,
}: IPublicProps) {
    const classes = useStyles();
    const [name, setName] = useState('');

    return (
        <Modal
            id={MODAL_ID}
            onClose={onCloseIntent}
            open={open}
            title={`${TRANSLATION_PREFIX}.title`}
            emphasizeTitle
            className={classes.EditPortfolioReportName}
        >
            <div className="__form">
                <InputWrapper label={`${TRANSLATION_PREFIX}.current_name`} className="__current">
                    {getDefaultTranslationFromApiLabel(currentPortfolioReportName)}
                </InputWrapper>

                <InputWrapper label={`${TRANSLATION_PREFIX}.new_name`}>
                    <InputTextField
                        onChange={(changeProps) => setName(changeProps.value)}
                        value={name}
                        placeholder={`${TRANSLATION_PREFIX}.placeholder`}
                    />
                </InputWrapper>
            </div>

            <div className="__actions">
                <TextButton
                    id={`${MODAL_ID}_back`}
                    label="common.action.cancel"
                    variant="bare"
                    noMargin={false}
                    color="secondary"
                    onClick={onCloseIntent}
                />

                <TextButton
                    id={`${MODAL_ID}_save`}
                    label="common.action.save"
                    variant="filled"
                    color="primary"
                    onClick={onSave}
                    disabled={!name}
                />
            </div>
        </Modal>
    );

    function onSave() {
        const newNameLabel = {
            ...currentPortfolioReportName,
            [getDefaultUserLocaleConfig()]: name,
        };
        triggerUpdatePortfolioReport({
            portfolioReportId,
            name: newNameLabel,
        })
            .then(onCloseIntent);
    }

    function onCloseIntent() {
        onClose();
        setName('');
    }
}
