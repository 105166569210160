import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { anyComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import addItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/addItemAndGetResultingArray';
import removeItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/removeItemAndGetResultingArray';
import { PolicyManagerTag, TPolicy } from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import {
    findOrFetchSpecificPolicy,
    triggerFetchPoliciesForSearch,
} from 'state/entities/portfolioMgmt/policies';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import { IExtendedInputFormContext, IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import { doPortfolioTagsMatchTheLinkedPolicyTags } from 'utils/entities/portfolioMgmt/portfolioUtils';
import ExtendedSelectOneViaModal from 'views/common/inputs/extended/ExtendedSelectOneViaModal';
import { IRenderSelectedItemProps } from 'views/common/inputs/base/SelectOneViaModal';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import useAsyncFetch from 'utils/react/hooks/useAsyncFetch';
import GenericPolicySelect, { IOnPolicySelectedProps } from 'views/portfolioMgmt/Policies/GenericPolicySelect';
import ExtendedInputWrapper from 'views/common/inputs/extended/ExtendedInputWrapper';
import InputCheckboxField, { IOnChangeCheckboxProps } from 'views/common/inputs/base/InputCheckboxField';
import { getPolicyManagerTagLabel } from 'utils/entities/portfolioMgmt/policyUtils';
import Alert from 'views/common/widget/Alert';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import { portfolioPolicySchema } from '../portfolioDetailsSchema';
import {
    selectItemComparerOnLabelAscending,
    IPortfolioFormValues,
    TPortfolioPolicyFormValues,
    TPortfolioAmountFormValues,
    TPortfolioRoboRelatedFormValues,
} from './types';

export function PortfolioRoboRelatedFormContent(
    context: IExtendedInputFormContext<TPortfolioRoboRelatedFormValues>,
) {
    const currentPolicyId = (context.fields.policy.value as TPolicy)?.id;
    const currentPolicyName = (context.fields.policy.value as TPolicy)?.name;
    const currentPolicy: TPortfolioPolicyFormValues = context.fields.policy.value
        ? {
            policy: context.fields.policy.value,
            managerType: context.fields.managerType.value,
        }
        : null;

    const [asyncLinkedPolicy] = useAsyncFetch({
        fetcher: () => findOrFetchSpecificPolicy({ policyId: currentPolicyId }),
        shouldFetch: () => isSet(currentPolicyId) && !isSet(currentPolicyName),
        deps: [currentPolicyId],
    });

    const isTagMismatch = !doPortfolioTagsMatchTheLinkedPolicyTags({
        portfolioTags: context.fields.tags.value as PolicyManagerTag[],
        asyncLinkedPolicy,
    });

    return (
        <>
            <PortfolioGoalFormContent
                {...context}
            />

            <PortfolioHorizonFormContent
                {...context}
            />

            <ExtendedSelectOneViaModal<TPortfolioPolicyFormValues>
                formField={context.fields.policy}
                label="fields.policy_id.label"
                tooltip="fields.policy_id.tip"
                renderSelectedItem={renderSelectedPortfolioPolicy}
                currentSelectedItem={currentPolicy}
                remove={{
                    shouldAllow: false,
                }}
                edit={{
                    modalTitle: 'fields.policy_id.select',
                    modalMaxWidth: 'md',
                    initialValues: {
                        policy: context.fields.policy.value,
                        managerType: context.fields.managerType.value,
                    },
                    schema: portfolioPolicySchema,
                    renderFormFields: renderSelectPolicyFormContent,
                    submit: {
                        onSubmit: onSelectPortfolioPolicy,
                    },
                }}
            />

            <PortfolioManagerTagsFormContent
                {...context}
            />
            {isTagMismatch && (
                <Alert
                    severity="warning"
                    message="portfolio_mgmt.portfolios.detail.fields.manager_tags.tag_mismatch_warning"
                />
            )}

            <PortfolioAmountsFormContent
                {...context}
            />
        </>
    );

    function renderSelectedPortfolioPolicy({ item }: IRenderSelectedItemProps<TPortfolioPolicyFormValues>) {
        if (isSet((item.policy as TPolicy)?.name)) {
            return (
                <div>{(item.policy as TPolicy)?.name}</div>
            );
        }

        if (!asyncLinkedPolicy || !asyncLinkedPolicy.data) {
            return (
                <div>{(item.policy as TPolicy)?.id}</div>
            );
        }

        return (
            <div>{asyncLinkedPolicy.data.name}</div>
        );
    }

    function renderSelectPolicyFormContent(
        selectPolicyFormContext: IExtendedInputFormContext<TPortfolioPolicyFormValues>,
    ) {
        return (
            <PortfolioPolicyFormContent
                {...selectPolicyFormContext}
            />
        );
    }

    function onSelectPortfolioPolicy({ values }: IOnSubmitProps<TPortfolioPolicyFormValues>) {
        asyncLinkedPolicy.data = null;

        context.setFieldValue({
            fieldName: context.fields.policy.fieldName,
            value: values.policy,
        });

        return Promise.resolve();
    }
}

export function PortfolioGoalFormContent({
    fields,
}: IExtendedInputFormContext<Pick<IPortfolioFormValues, 'goalId'>>) {
    const [asyncAllGoals] = useAsyncFetchOnMount({
        fetcher: apiCacheManager.fetchAllGoals,
    });

    return (
        <ExtendedInputSelect
            formField={fields.goalId}
            wrapper={{
                label: 'fields.goal_id.label',
            }}
            items={[]}
            async={{
                itemsData: asyncAllGoals,
                dataToSelectItemsMapper: mapGoalsToSelectItems,
            }}
        />
    );

    function mapGoalsToSelectItems(): IInputSelectItem<string>[] {
        return asyncAllGoals.data.results
            .map((goal) => ({
                value: goal.id,
                label: getDefaultTranslationFromApiLabel(goal.name),
            }))
            .sort(selectItemComparerOnLabelAscending);
    }
}

export function PortfolioHorizonFormContent({
    fields,
}: IExtendedInputFormContext<Pick<IPortfolioFormValues, 'horizonId'>>) {
    const [asyncAllHorizons] = useAsyncFetchOnMount({
        fetcher: apiCacheManager.fetchAllHorizons,
    });

    return (
        <ExtendedInputSelect
            formField={fields.horizonId}
            wrapper={{
                label: 'fields.horizon_id.label',
            }}
            items={[]}
            async={{
                itemsData: asyncAllHorizons,
                dataToSelectItemsMapper: mapHorizonsToSelectItems,
            }}
        />
    );

    function mapHorizonsToSelectItems(): IInputSelectItem<string>[] {
        return asyncAllHorizons.data.results
            .sort((horizonA, horizonB) =>
                anyComparerAscending(horizonA.years, horizonB.years))
            .map((horizon) => ({
                value: horizon.id,
                label: getDefaultTranslationFromApiLabel(horizon.name),
            }));
    }
}

export function PortfolioPolicyFormContent({
    fields,
    setFieldValue,
    initialValues,
}: IExtendedInputFormContext<TPortfolioPolicyFormValues>) {
    useExecuteOnMount({
        execute: () => triggerFetchPoliciesForSearch(),
    });

    const policyField = fields.policy;

    return (
        <GenericPolicySelect
            selectedPolicyId={(policyField.value as TPolicy)?.id}
            onPolicySelected={updateFormWithSelectedPolicy}
            initialSelectedPolicyId={(initialValues.policy as TPolicy)?.id}
        />
    );

    function updateFormWithSelectedPolicy({ policy }: IOnPolicySelectedProps) {
        setFieldValue({
            fieldName: policyField.fieldName,
            value: policy,
        });
    }
}

export function PortfolioAmountsFormContent({
    fields,
}: IExtendedInputFormContext<TPortfolioAmountFormValues>) {
    return (
        <>
            <ExtendedInputText<string>
                formField={fields.startAmount}
                readOnly
                hideIfValueUnset
                wrapper={{
                    label: 'fields.start_amount.label',
                    tooltip: 'fields.start_amount.tip',
                }}
            />

            <ExtendedInputText<string>
                formField={fields.recurringAmount}
                readOnly
                hideIfValueUnset
                wrapper={{
                    label: 'fields.recurring_deposit_amount.label',
                }}
            />

            <ExtendedInputText<string>
                formField={fields.divestAmount}
                readOnly
                hideIfValueUnset
                wrapper={{
                    label: 'fields.divest_amount.label',
                }}
            />
        </>
    );
}

export function PortfolioManagerTagsFormContent({
    fields,
    setFieldValue,
}: IExtendedInputFormContext<Pick<IPortfolioFormValues, 'tags'>>) {
    const currentTags = fields.tags.value as PolicyManagerTag[];

    return (
        <ExtendedInputWrapper
            label="fields.manager_tags.label"
            tooltip="fields.manager_tags.tip"
            shouldStyleInputWrapped={false}
        >
            {Object.values(PolicyManagerTag).map((managerTag) => {
                const key = `manager-tag_${managerTag}`;

                return (
                    <InputCheckboxField<PolicyManagerTag>
                        key={key}
                        name={managerTag}
                        checked={currentTags.indexOf(managerTag) > -1}
                        description={getPolicyManagerTagLabel(managerTag)}
                        onChange={onChangeManagerTagCheckbox}
                    />
                );
            })}
        </ExtendedInputWrapper>
    );

    function onChangeManagerTagCheckbox({ name: managerTag, checked }: IOnChangeCheckboxProps<PolicyManagerTag>) {
        const newTags = checked
            ? addItemAndGetResultingArray<PolicyManagerTag>(currentTags, managerTag, { resultInNewArray: true })
            : removeItemAndGetResultingArray<PolicyManagerTag>(currentTags, managerTag, { resultInNewArray: true });

        setFieldValue({
            fieldName: fields.tags.fieldName,
            value: newTags,
        });
    }
}
