import React from 'react';
import { useParams } from 'react-router-dom';
import { EntityType, IBaseEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import {
    DEFAULT_PORTFOLIO_GROUP_DETAILS_TAB_KEY,
    PORTFOLIO_GROUP_DETAILS_TAB_KEY,
} from 'config/tabs.config';
import { isStorytellerEnabled } from 'state/appConfig/selectors';
import {
    getPortfolioGroupTitle,
    portfolioGroupDetailsEntity,
    triggerDeletePortfolioGroup,
} from 'state/entities/portfolioMgmt/portfolioGroupDetails';
import DetailPage,
{
    redirectToCurrentRouteButOverrideParams,
    TDetailPageTitleConfig,
} from 'views/common/detail/DetailPage';
import { getApiEntityDeleteAction } from 'views/common/genericApiEntity/apiEntityHelper';
import { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import PortfolioGroupAccessibleBy from './PortfolioGroupAccessibleBy';
import PortfolioGroupDetails from './PortfolioGroupDetails';
import PortfolioGroupPortfolios from './PortfolioGroupPortfolios';
import PortfolioGroupReports from './PortfolioGroupReports';

const PORTFOLIO_GROUP_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: getPortfolioGroupTitle,
    notifications: [StateChangeNotification.PORTFOLIO_GROUP_DETAILS_DATA],
};

function getPortfolioGroupDetailsTabs(shouldDisplayStoryteller: boolean): ITabItem[] {
    return [{
        key: PORTFOLIO_GROUP_DETAILS_TAB_KEY.PORTFOLIOS,
        label: 'portfolio_mgmt.portfolio_groups.detail.tabs.portfolios.title',
        component: PortfolioGroupPortfolios,
    }, {
        key: PORTFOLIO_GROUP_DETAILS_TAB_KEY.DETAILS,
        label: 'portfolio_mgmt.portfolio_groups.detail.tabs.details.title',
        component: PortfolioGroupDetails,
    }, {
        key: PORTFOLIO_GROUP_DETAILS_TAB_KEY.ACCESSIBLE_BY,
        label: 'portfolio_mgmt.portfolio_groups.detail.tabs.accessible_by.title',
        component: PortfolioGroupAccessibleBy,
    }, {
        key: PORTFOLIO_GROUP_DETAILS_TAB_KEY.GROUP_REPORTS,
        label: 'portfolio_mgmt.portfolio_groups.detail.tabs.group_reports.title',
        component: PortfolioGroupReports,
        hidden: !shouldDisplayStoryteller,
    }];
}

export default function PortfolioGroupDetail() {
    const {
        portfolioGroupTab = DEFAULT_PORTFOLIO_GROUP_DETAILS_TAB_KEY,
    } = useParams<IPossiblePathParams>();

    return (
        <DetailPage
            title={PORTFOLIO_GROUP_TITLE_CONFIG}
            tabsWithEntityWrapper={{
                items: getPortfolioGroupDetailsTabs(isStorytellerEnabled()),
                activeTabKey: portfolioGroupTab,
                onTabChange: tabChanged,
                entity: {
                    notifications: [StateChangeNotification.PORTFOLIO_GROUP_DETAILS_DATA],
                    asyncEntitySelector: portfolioGroupDetailsEntity.select,
                    loaderPosition: 'fixed',
                },
            }}
            footer={{
                apiEntityVersion: {
                    apiEntitySelector: () => portfolioGroupDetailsEntity.select().data,
                },
            }}
            actions={getPortfolioGroupActions}
            entityId={{
                idSelector: ({ urlParams }) => urlParams.portfolioGroupId as string,
            }}
        />
    );

    function tabChanged({ tabKey }: IOnTabChangeProps) {
        redirectToCurrentRouteButOverrideParams({
            newParams: {
                portfolioGroupTab: tabKey,
            },
        });
    }

    function getPortfolioGroupActions(apiEntity: IBaseEntity) {
        return [getApiEntityDeleteAction({
            apiEntity,
            apiEntityType: EntityType.portfolioGroup,
            deleteTrigger: triggerDeletePortfolioGroup,
        })];
    }
}
