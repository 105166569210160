import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { SortOrder } from '@console/core-api/typsy/entities/dist/common/entityQuery.models';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { TSortFunction } from '@console/common/models/sort.models';
import { anyComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import {
    IColValues,
    IDataItem, TDataItemType,
    IDataColumn, TColumnAlignment,
    IDataItemAction,
    IRenderPreRowProps,
    IDataItemSubRow,
    ISortColumn, IActiveSortColumn,
    ISelectData, IRenderPreHeaderProps,
} from 'models/list.models';
import { APP_COLORS, OPACITY } from 'config/styling/colors';
import { hexToRgba } from 'utils/styling/colorUtils';
import { makeStyles, mixins } from 'views/styling';
import DataTableRow, { IDataTableRowProps, BaseTableRow, BaseTableCell } from 'views/common/list/DataTableRow';
import { TABLE_CELL_BORDER } from 'views/assets/cssInJs/utilityClasses/table';
import Text from 'views/common/widget/Text';
import InfoIconTooltip from 'views/common/widget/InfoIconTooltip';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';
import { IActionItem } from '../buttons/ActionButtons';
import { ArrowDownIcon, ArrowUpIcon } from '../icons';
import BaseButton from '../buttons/BaseButton';
import InputCheckboxField from '../inputs/base/InputCheckboxField';

interface IPublicProps<
    ColValues extends IColValues,
    ExtraData extends TObjectWithProps,
    AsyncItemData = unknown,
    // eslint-disable-next-line max-len
> extends Pick<IDataTableRowProps<ColValues, ExtraData, AsyncItemData>, 'cols' | 'shouldFetchAsyncItem' | 'asyncItemFetcher' | 'toDynamicCellClass'> {
    className?: string;
    items: IDataItem<ColValues, ExtraData>[];
    onItemRowClicked?: (item: IDataItem<ColValues, ExtraData>) => void; // not clickable if not provided
    itemActions?: IDataItemAction[]; // TODO
    isFixedHeader?: boolean; // to make the header sticky - default false
    noHeader?: boolean; // default false
    renderPreHeader?: (renderProps: IRenderPreHeaderProps) => ReactNode;
    isItemClickable?: (item: IDataItem<ColValues, ExtraData>) => boolean;
    shouldDisplayRow?: (item: IDataItem<ColValues, ExtraData>) => boolean;
    renderPreRow?: (renderProps: IRenderPreRowProps<ColValues, ExtraData>) => ReactNode;
    subRow?: IDataItemSubRow<ColValues, ExtraData, AsyncItemData>;
    alternatingRowBackgrounds?: boolean; // default false
    getActions?: (item: IDataItem<ColValues, ExtraData>) => IActionItem[];
    clientSideSortingOverride?: TSortFunction<IDataItem<ColValues, ExtraData>>;
    serverSideSorting?: {
        activeSortColumn: IActiveSortColumn<ColValues> | (() => IActiveSortColumn<ColValues>);
        onSelectSortColumn: (selectedSortCol: IActiveSortColumn<ColValues>) => void;
    };
    getRowClasses?: (item: IDataItem<ColValues, ExtraData>) => string;
    selectData?: ISelectData<IDataItem<ColValues, ExtraData>> & {
        isItemSelectable?: (item: IDataItem<ColValues, ExtraData>) => boolean;
    };
}

const useStyles = makeStyles((theme) => ({
    DataTable: {
        '& .__container': {
            boxShadow: 'unset',
            overflow: 'visible',
        },
        '& .__table': {},

        '& .__tableRow': {
            border: 0,
            height: 64,
        },
        '& .__tableRow.small': {
            height: 48,
        },
        '& .__tableRow.clickable': {
            cursor: 'pointer',
        },
        '& .__alternateBgs .__tableRow.even': {
            background: `${hexToRgba(APP_COLORS.PRIMARY['100'], OPACITY.HIGH)}`,
        },
        '& .__tableRow.noResults': {},
        '& .__tableCell': {
            border: 0,
            padding: theme.spacing(1, 2),
            position: 'relative',

            '& .__inlineCollapseButton': {
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(0),
            },
        },
        '& :not(.__alternateBgs) .__tableCell': {
            borderTop: TABLE_CELL_BORDER,
        },
        '& .__tableCell:first-child': {
            paddingLeft: theme.spacing(3),
        },
        '& .__tableCell:last-child': {
            paddingRight: theme.spacing(3),
        },
        '& .__tableHeader': {},
        '& .__tableHeader .__tableRow': {
            height: 32,
        },
        '& .__tableHeader .__tableCell': {
            ...mixins.typoSmallCaps({ color: hexToRgba(APP_COLORS.SYSTEM.ICON, 1) }),
            borderTop: 0,
            paddingTop: 0,
            paddingBottom: 0,
            lineHeight: '1rem',
        },
        '& .__tableHeaderCellContent': {
            ...mixins.flexRow({
                alignMain: 'center',
                alignCross: 'center',
            }),
        },
        '& .__tableCell.MuiTableCell-alignLeft .__tableHeaderCellContent': {
            justifyContent: 'flex-start', // alignMain
        },
        '& .__tableCell.MuiTableCell-alignRight .__tableHeaderCellContent': {
            justifyContent: 'flex-end', // alignMain
        },

        '& .__tableHeader .__tableCell .__sortHeader': {
            display: 'inline-flex',
            alignItems: 'center',

            ...mixins.typoSmallCaps({ color: hexToRgba(APP_COLORS.SYSTEM.ICON, 1) }),
            padding: 0,

            '&:hover': {
                '& svg': {
                    opacity: 1,
                    // transform: 'translateY(-2px)',
                },
            },

            '& svg': {
                width: 24,
                height: 24,
                fill: 'currentColor',
                transition: 'all .2s ease-in-out',
                marginTop: -2,
                opacity: 0.3,
            },

            '&.active svg': {
                opacity: 1,
                color: APP_COLORS.FEEDBACK.SUCCESS,
            },
        },

        '& .__tableBody': {},
        '& .__tableBody .__tableRow:not(.noResults):not(.__tableSubRow):hover': {
            color: APP_COLORS.PRIMARY['500'],
        },
        '& .__tableBody .__tableCell': {
            ...mixins.typo({ size: 16 }),
            color: 'inherit',
        },

        '& .__noHeader .__tableRow:first-child .__tableCell': {
            borderTop: 0,
        },

        '& .__tableCell .extra-actions-button': {
            background: 'none',
            border: 'none',

            '&:hover': {
                background: 'none',
                boxShadow: 'none',
                border: 'none',

                '& svg': {
                    fill: APP_COLORS.PRIMARY['500'],
                },
            },

            '& svg': {
                fill: APP_COLORS.TEXT['500'],
            },
        },
    },
}));

export default function DataTable<
    ColValues extends IColValues,
    ExtraData extends TObjectWithProps,
    AsyncItemData = unknown,
>({
    className,
    cols,
    items,
    onItemRowClicked,
    isFixedHeader = false,
    noHeader = false,
    renderPreHeader,
    shouldFetchAsyncItem,
    asyncItemFetcher,
    toDynamicCellClass,
    isItemClickable = () => true,
    renderPreRow,
    subRow,
    alternatingRowBackgrounds,
    getActions,
    shouldDisplayRow = () => true,
    clientSideSortingOverride,
    serverSideSorting,
    getRowClasses,
    selectData,
}: IPublicProps<ColValues, ExtraData, AsyncItemData>) {
    const allCols = appendExtraCols();
    validateCols();

    const columnKeys = Object.keys(allCols);
    const withSorting = hasSorting();

    const [
        sortColumn,
        setSortColumn,
    ] = useState<ISortColumn<ColValues, ExtraData>>(withSorting && getDefaultSortColumn());
    const classes = useStyles();

    const areItemsClickable = !!onItemRowClicked;
    const sortedItems = getFullySortedItems();

    const nrOfCols = getNrOfCols();

    return (
        <div className={clsx(classes.DataTable, className)}>
            <TableContainer component={Paper} className="__container">
                <Table
                    stickyHeader={isFixedHeader}
                    className={clsx(
                        '__table',
                        noHeader && '__noHeader',
                        alternatingRowBackgrounds && '__alternateBgs',
                    )}
                    aria-label="data table"
                >
                    {!noHeader && (
                        <TableHead className="__tableHeader">
                            {renderPreHeader && renderPreHeader({
                                RowComponent: BaseTableRow,
                                CellComponent: BaseTableCell,
                                nrOfCols,
                            })}
                            <BaseTableRow>
                                {columnKeys.map((columnKey) => {
                                    const column = allCols[columnKey];
                                    const {
                                        className: labelClassName,
                                        tooltip: labelTooltip,
                                        ...labelFields
                                    } = column.label;

                                    return (
                                        <BaseTableCell
                                            key={`data-table-header_${columnKey}`}
                                            align={getColumnAlignment(column)}
                                            className={clsx(labelClassName)}
                                        >
                                            <div className="__tableHeaderCellContent">
                                                {column.sort
                                                    ? renderSortableColumnHeader({
                                                        columnKey,
                                                        column,
                                                    })
                                                    : (
                                                        <Text label={labelFields} />
                                                    )
                                                }
                                                {labelTooltip && <InfoIconTooltip {...labelTooltip} />}
                                            </div>
                                        </BaseTableCell>
                                    );
                                })}
                            </BaseTableRow>
                        </TableHead>
                    )}

                    <TableBody className="__tableBody">
                        {sortedItems.length === 0 && (
                            <BaseTableRow className="noResults">
                                <BaseTableCell
                                    colSpan={columnKeys.length}
                                >
                                    <Translate msg="common.data_table.no_results" />
                                </BaseTableCell>
                            </BaseTableRow>
                        )}
                        {sortedItems.map((dataItem, itemIndex) => {
                            const itemClickable = areItemsClickable && isItemClickable(dataItem);
                            const prevDataItem = itemIndex === 0
                                ? null
                                : sortedItems[itemIndex - 1];

                            const onCellClick = itemClickable
                                ? () => onItemRowClicked(dataItem)
                                : undefined;
                            if (!shouldDisplayRow(dataItem)) {
                                return null;
                            }

                            const rowClasses = getRowClasses ? getRowClasses(dataItem) : '';

                            return (
                                <React.Fragment key={`data-table-row_${dataItem.id}`}>
                                    {renderPreRow && renderPreRow({
                                        dataItem,
                                        prevDataItem,
                                        RowComponent: BaseTableRow,
                                        CellComponent: BaseTableCell,
                                        nrOfCols,
                                    })}

                                    <DataTableRow
                                        className={clsx(
                                            itemClickable && 'clickable',
                                            itemIndex % 2 === 0 && 'even',
                                            rowClasses,
                                        )}
                                        columnKeys={columnKeys}
                                        cols={allCols}
                                        dataItem={dataItem}
                                        prevDataItem={prevDataItem}
                                        itemIndex={itemIndex}
                                        onCellClick={onCellClick}
                                        getColumnAlignment={getColumnAlignment}
                                        shouldFetchAsyncItem={shouldFetchAsyncItem}
                                        asyncItemFetcher={asyncItemFetcher}
                                        toDynamicCellClass={toDynamicCellClass}
                                        subRow={subRow}
                                        nrOfCols={nrOfCols}
                                        getActions={getActions}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

    function getFullySortedItems() {
        if (withSorting) {
            const itemsToSort = sortColumn?.clientSide
                ? getSortedItems(items)
                : items;

            return clientSideSortingOverride
                ? itemsToSort.sort(clientSideSortingOverride)
                : itemsToSort;
        }

        return items;
    }

    function getNrOfCols() {
        let colCount = columnKeys.length;
        if (subRow?.isCollapsible && subRow?.shouldAddExtraColumnForCollapsibleIcon) { colCount += 1; }
        if (getActions) { colCount += 1; }
        return colCount;
    }

    function getColumnAlignment(column: IDataColumn<ColValues, ExtraData, AsyncItemData>): TColumnAlignment {
        return column.align || determineColumnAlignmentBasedOnType(column);
    }

    function determineColumnAlignmentBasedOnType(
        column: IDataColumn<ColValues, ExtraData, AsyncItemData>,
    ): TColumnAlignment {
        switch (getColumnDataType(column)) {
            case 'number': return 'right';
            case 'boolean': return 'center';
            default: return 'left';
        }
    }

    function getColumnDataType(column: IDataColumn<ColValues, ExtraData, AsyncItemData>): TDataItemType {
        return (column.data && column.data.type) || 'string';
    }

    function hasSorting(): boolean {
        return columnKeys.findIndex((key) => {
            const column = allCols[key];
            return !!column.sort;
        }) !== -1;
    }

    function getSortedItems(unsortedItems: IDataItem<ColValues, ExtraData>[]): IDataItem<ColValues, ExtraData>[] {
        if (sortColumn?.clientSide?.alwaysOnTopFilter) {
            const itemsAlwaysOnTop = unsortedItems.filter(sortColumn.clientSide.alwaysOnTopFilter);
            const sortableItems = unsortedItems.filter((item) => !sortColumn.clientSide.alwaysOnTopFilter(item));

            return [
                ...itemsAlwaysOnTop,
                ...getSortedItemsInOrder(sortableItems),
            ];
        }

        return getSortedItemsInOrder(unsortedItems);

        function getSortedItemsInOrder(itemsToSort: IDataItem<ColValues, ExtraData>[]) {
            const initialSortedItems = itemsToSort.sort(
                sortColumn?.clientSide?.sort ||
                getDefaultSortFunction(sortColumn.colKey),
            );
            return sortColumn.order === SortOrder.Ascending ?
                initialSortedItems : initialSortedItems.reverse();
        }
    }

    function getDefaultSortColumn(): ISortColumn<ColValues, ExtraData> | null {
        if (serverSideSorting?.activeSortColumn) {
            const activeSortColumn = typeof serverSideSorting.activeSortColumn === 'function'
                ? serverSideSorting.activeSortColumn()
                : serverSideSorting.activeSortColumn;

            if (activeSortColumn) {
                return {
                    order: activeSortColumn.order,
                    colKey: activeSortColumn.colKey,
                    clientSide: null,
                };
            }
        }

        const columnKeyWithDefaultSorting = columnKeys.find((key) => {
            const column = allCols[key];
            return column?.sort?.isDefault;
        });

        if (!columnKeyWithDefaultSorting) {
            return null;
        }

        const sortInfo = allCols[columnKeyWithDefaultSorting].sort;

        return {
            order: sortInfo.initialOrder,
            colKey: columnKeyWithDefaultSorting,
            clientSide: sortInfo?.clientSide && {
                sort: getSortFunction({
                    customSort: sortInfo?.clientSide?.customSort,
                    columnKey: columnKeyWithDefaultSorting,
                }),
                alwaysOnTopFilter: sortInfo?.clientSide?.alwaysOnTopFilter,
            },
        };
    }

    function getSortFunction({
        customSort,
        columnKey,
    }: {
        customSort: TSortFunction<IDataItem<ColValues, ExtraData>>;
        columnKey: keyof ColValues;
    }): TSortFunction<IDataItem<ColValues, ExtraData>> {
        if (customSort) {
            return customSort;
        }
        const columnDataType = getColumnDataType(allCols[columnKey]);
        if (columnDataType === 'string') {
            return getPremadeStringSortFunction(columnKey);
        }
        return getDefaultSortFunction(columnKey);
    }

    function getDefaultSortFunction(columnKey: keyof ColValues) {
        return (a: IDataItem<ColValues, ExtraData>, b: IDataItem<ColValues, ExtraData>) => (
            anyComparerAscending(
                a.colValues[columnKey],
                b.colValues[columnKey],
            )
        );
    }

    function getPremadeStringSortFunction(columnKey: keyof ColValues) {
        return (a: IDataItem<ColValues, ExtraData>, b: IDataItem<ColValues, ExtraData>) => (
            anyComparerAscending(
                a.colValues[columnKey].toString().toLowerCase(),
                b.colValues[columnKey].toString().toLowerCase(),
            )
        );
    }

    function renderSortableColumnHeader({
        columnKey,
        column,
    }: {
        columnKey: string;
        column: IDataColumn<ColValues, ExtraData, AsyncItemData>;
    }) {
        const active = sortColumn?.colKey === columnKey;
        const { className: labelClassName, ...labelFields } = column.label;

        return (
            <BaseButton
                id={`${columnKey}-sort-action`}
                disabled={false}
                color="text"
                addBoxShadowOnHover={false}
                variant="bare"
                classNames={[clsx('__sortHeader', active && 'active')]}
                onClick={() => onSortColumn(column, columnKey)}
            >
                <Text label={labelFields} />
                {active
                    ? sortColumn.order === SortOrder.Ascending
                        ? <ArrowUpIcon />
                        : <ArrowDownIcon />
                    : column.sort.initialOrder === SortOrder.Ascending
                        ? <ArrowUpIcon />
                        : <ArrowDownIcon />
                }
            </BaseButton>
        );
    }

    function onSortColumn(column: IDataColumn<ColValues, ExtraData, AsyncItemData>, columnKey: keyof ColValues) {
        let order: SortOrder;

        if (sortColumn && sortColumn.colKey === columnKey) {
            /* the selected column was already being sorted */

            if (column.sort.disable3rdClickToReset || sortColumn.order === column.sort.initialOrder) {
                order = sortColumn.order === SortOrder.Ascending
                    ? SortOrder.Descending
                    : SortOrder.Ascending;

                setSortColumn({
                    ...sortColumn,
                    order,
                });
            } else {
                order = null;

                /* 3rd click on the column --> clear sorting to revert to initial back-end sorting */
                setSortColumn(null);
            }
        } else {
            /* first sort click on this column */

            order = column.sort.initialOrder;

            setSortColumn({
                colKey: columnKey,
                order,
                clientSide: column?.sort?.clientSide && {
                    sort: getSortFunction({
                        customSort: column.sort?.clientSide?.customSort,
                        columnKey,
                    }),
                    alwaysOnTopFilter: column.sort?.clientSide?.alwaysOnTopFilter,
                },
            });
        }

        if (column.sort.serverSide && serverSideSorting) {
            serverSideSorting.onSelectSortColumn(order
                ? {
                    colKey: columnKey,
                    order,
                    serverSide: {
                        field: column.sort.serverSide.field,
                    },
                }
                : null);
        }
    }

    function validateCols() {
        const {
            atLeastOneColWithClientSideSorting,
            atLeastOneColWithServerSideSorting,
        } = Object.values(allCols)
            .reduce(
                (accumulator, dataCol) => {
                    if (dataCol.sort) {
                        if (dataCol.sort.clientSide) {
                            accumulator.atLeastOneColWithClientSideSorting = true;
                        }
                        if (dataCol.sort.serverSide) {
                            accumulator.atLeastOneColWithServerSideSorting = true;
                        }
                    }
                    return accumulator;
                },
                {
                    atLeastOneColWithClientSideSorting: false,
                    atLeastOneColWithServerSideSorting: false,
                },
            );

        if (atLeastOneColWithClientSideSorting && atLeastOneColWithServerSideSorting) {
            throw new Error(
                'When configuring "sort" on columns, you may not use both "clientSide" or "serverSide"!',
            );
        }

        if (atLeastOneColWithServerSideSorting && !serverSideSorting) {
            throw new Error(
                'When having "sort" on a column with "serverSide", you als need "serverSideSorting" on the table!',
            );
        }
    }

    function appendExtraCols() {
        if (selectData) {
            return {
                select: {
                    label: EMPTY_COL_TITLE,
                    data: {
                        render: ({ item }: { item: IDataItem<ColValues, ExtraData> }) => {
                            const checked = selectData.selectedItemIds.includes(item.id);

                            const maxSelectionReached = !checked &&
                                selectData.maxSelection &&
                                selectData.maxSelection === selectData.selectedItemIds.length;

                            if (selectData.isItemSelectable && !selectData.isItemSelectable(item)) {
                                return null;
                            }

                            return (
                                <InputCheckboxField
                                    name={item.id}
                                    checked={checked}
                                    onChange={selectData.getOnSelectForItem(item)}
                                    disabled={maxSelectionReached}
                                />
                            );
                        },
                    },
                    percentWidth: 5,
                },
                ...cols,
            };
        }
        return cols;
    }
}
