import isSetString from '@snipsonian/core/cjs/string/isSetString';
import {
    CombineTransactionCostMode,
    IPolicyAlgorithmSettings,
    IPolicyAssetClasses, IPolicyBondTypes,
    IPolicyHoldingsConstraints, IPolicyRegionRanges, IPolicySectorConstraints,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';
import { TNumberRange } from '@console/core-api/typsy/console-api-client/dist/models/common/numeric.models';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { diffObjects, IDetectedDiffs } from '@console/common/utils/object/diffObjects';
import {
    IEnhancedPolicyAlgorithmSettings,
    TEnhancedPolicy,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';

export interface IDivergingPolicySetting {
    section: string;
    subSection?: string;
    field: {
        name: string;
        numberRangeElementType?: TNumberRangeElementType;
    };
    parentValue: string;
    divergingValue: string;
}

export type TNumberRangeElementType = 'min' | 'max';

export function determineDivergingPolicySettingsIfAny(policyDetailsData: TEnhancedPolicy): {
    areThereDivergingSettings: boolean;
    settings?: IDivergingPolicySetting[];
} {
    if (!policyDetailsData || !isSetString(policyDetailsData.parent_policy_id)) {
        return {
            areThereDivergingSettings: false,
        };
    }

    const { areDiffsDetected, diffs } = diffObjects(
        mapPolicyEntityDataToPolicySettingsForDisplay(policyDetailsData.coerced_config.algorithm_settings),
        mapPolicyEntityDataToPolicySettingsForDisplay(policyDetailsData.config.algorithm_settings),
        { deepDiff: true },
    );

    if (!areDiffsDetected) {
        return {
            areThereDivergingSettings: false,
        };
    }

    return {
        areThereDivergingSettings: areDiffsDetected,
        settings: mapDetectedDiffsToDivergingPolicySettings(diffs),
    };
}

function mapDetectedDiffsToDivergingPolicySettings(diffs: IDetectedDiffs[]): IDivergingPolicySetting[] {
    return diffs.map((diff) => {
        const numberRangeElementType = getNumberRangeElementType(diff);

        return {
            section: diff.propChain[0],
            subSection: diff.propChain.length > 2 ? diff.propChain[1] : null,
            field: {
                name: diff.propChain[diff.propChain.length - 1],
                numberRangeElementType,
            },
            parentValue: diff.master as string,
            divergingValue: diff.slave as string,
        };
    });
}

function getNumberRangeElementType(diff: IDetectedDiffs): TNumberRangeElementType {
    if (diff.propChain[diff.propChain.length - 1] === '0') {
        diff.propChain.pop();
        return 'min';
    }
    if (diff.propChain[diff.propChain.length - 1] === '1') {
        diff.propChain.pop();
        return 'max';
    }

    return null;
}

/* eslint-disable max-len */

// TODO rename IPolicyConfigForDisplay so that it is purely for the diffing ?
//      or can we re-use some other interface ?

interface IPolicyConfigForDisplay {
    universe: string[];
    execution_settings: {
        base_currency: SupportedCurrency;
        combine_transaction_cost_components: CombineTransactionCostMode;
        cost_per_transaction_amount: number;
        cost_per_transaction_fraction: number;
        fractional_shares: boolean;
        transaction_amount: TNumberRange;
    };
    portfolio_constraints: IPolicyHoldingsConstraints;
    portfolio_update_constraints: {
        cash_fraction: TNumberRange;
        max_portfolio_constraint_violation_pctpoints: number;
        min_portfolio_update_optimality_improvement_fraction: number;
        min_portfolio_value: number;
    };
    benchmark: {
        id: string;
        position_fraction: TNumberRange;
    } & IPolicyAllocationBoundariesForDisplay;
    allocation_boundaries: IPolicyAllocationBoundariesForDisplay;
}

interface IPolicyAllocationBoundariesForDisplay {
    asset_classes: IPolicyAssetClasses;
    region_bonds: IPolicyRegionRanges;
    region_stocks: IPolicyRegionRanges;
    bond_types: IPolicyBondTypes;
    sectors: IPolicySectorConstraints;
}

function mapPolicyEntityDataToPolicySettingsForDisplay(
    settings: IEnhancedPolicyAlgorithmSettings | IPolicyAlgorithmSettings,
): IPolicyConfigForDisplay {
    return {
        universe: settings.portfolio_constraints.investment_universe,
        execution_settings: {
            base_currency: settings.execution_settings.base_currency,
            combine_transaction_cost_components: settings.execution_settings.combine_transaction_cost_components,
            cost_per_transaction_amount: settings.execution_settings.cost_per_transaction_amount,
            cost_per_transaction_fraction: settings.execution_settings.cost_per_transaction_fraction,
            fractional_shares: settings.execution_settings.fractional_shares,
            transaction_amount: settings.execution_settings.transaction_amount,
        },
        portfolio_constraints: settings.portfolio_constraints.holdings,
        portfolio_update_constraints: {
            cash_fraction: settings.portfolio_update_constraints.holdings.cash_fraction,
            max_portfolio_constraint_violation_pctpoints: settings.portfolio_update_constraints.max_portfolio_constraint_violation_pctpoints,
            min_portfolio_update_optimality_improvement_fraction: settings.portfolio_update_constraints.min_portfolio_update_optimality_improvement_fraction,
            min_portfolio_value: settings.portfolio_update_constraints.min_portfolio_value,
        },
        benchmark: {
            id: settings.execution_settings?.benchmark_id,
            asset_classes: settings.benchmark_constraints?.asset_classes,
            region_bonds: settings.benchmark_constraints?.regions?.bonds,
            region_stocks: settings.benchmark_constraints?.regions?.stocks,
            bond_types: settings.benchmark_constraints?.bond_types,
            sectors: settings.benchmark_constraints?.sectors,
            position_fraction: settings.benchmark_constraints?.holdings.position_fraction,
        },
        allocation_boundaries: {
            asset_classes: settings.portfolio_constraints.asset_classes,
            region_bonds: settings.portfolio_constraints.regions?.bonds,
            region_stocks: settings.portfolio_constraints.regions?.stocks,
            bond_types: settings.portfolio_constraints.bond_types,
            sectors: settings.portfolio_constraints.sectors,
        },
    };
}

/* eslint-enable max-len */
