import React from 'react';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { Locale } from '@console/common/config/i18n.config';
import { SortOrder } from '@console/core-api/typsy/entities/dist/common/entityQuery.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IColValues, IDataItem, IToDynamicCellClassProps, TDataColumns } from 'models/list.models';
import { IAdvancedUserFilters } from 'models/ui/user.ui.models';
import {
    TUser,
    TUsersData,
    UserFeatureFlag,
} from '@console/core-api/typsy/console-api-client/dist/models/userMgmt/user.entity.models';
import { IFetchUsersApiInput } from '@console/core-api/client/userMgmt/users.api';
import { UiPageKey } from 'models/state/ui.models';
import { usersEntity, triggerFetchUsers } from 'state/entities/userMgmt/users';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import { getUserFullName, getUserFeatureFlagLabel } from 'utils/entities/userMgmt/userUtils';
import { getTranslatedLocale } from 'utils/i18n/localeUtils';
import DataTable from 'views/common/list/DataTable';
import { IRenderAdvancedWithSchemaFiltersProps } from 'views/common/list/DataSearch';
import InputGroup from 'views/common/inputs/base/InputGroup';
import ListPageForApiEntity, {
    IListPageForApiEntityProps, ISortConfigFunctions,
} from 'views/common/list/ListPageForApiEntity';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import { IFormValues } from 'views/common/inputs/extended/types';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ExtendedInputSearchableSelect from 'views/common/inputs/extended/ExtendedInputSearchableSelect';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import { simpleUserFilterSchema, advancedUserFiltersSchema } from 'views/userMgmt/shared/userFiltersSchemas';

export const DEFAULT_USERS_LIST_COL_TRANSLATION_PREFIX = 'user_mgmt.users.list.columns';

interface IPublicProps<ColValues extends IColValues, ExtraUserItemData extends TObjectWithProps = unknown>
    // eslint-disable-next-line max-len
    extends Pick<IListPageForApiEntityProps<IFetchUsersApiInput, IAdvancedUserFilters, TUsersData>, 'className' | 'create' | 'actions' | 'box' | 'includeRefreshButton'> {
    overrideEntity?: Partial<IOverrideEntity>;
    overrideUiVars?: IOverrideUiVars;
    overrideCols?: IOverrideCols<ColValues, ExtraUserItemData>;
    overrideSimpleSearchTipTranslationKey?: string;
    onUserRowClicked?: (user: IDataItem<ColValues, ExtraUserItemData>) => void;
    isUserRowClickable?: (user: IDataItem<ColValues, ExtraUserItemData>) => boolean;
}

// eslint-disable-next-line max-len
interface IOverrideEntity extends Pick<IListPageForApiEntityProps<IFetchUsersApiInput, IAdvancedUserFilters, TUsersData>, 'asyncListEntity' | 'asyncListEntityFetchTrigger' | 'setStateOnPageNrChange'> {
    dataNotification: StateChangeNotification; // default USERS_DATA
}

// eslint-disable-next-line max-len
interface IOverrideUiVars extends Pick<IListPageForApiEntityProps<IFetchUsersApiInput, IAdvancedUserFilters, TUsersData>, 'uiPageKey'> {
    uiVarsNotification: StateChangeNotification; // default USERS_UI_VARS
}

interface IOverrideCols<ColValues extends IColValues, ExtraUserItemData extends TObjectWithProps> {
    cols: TDataColumns<ColValues, ExtraUserItemData>;
    toUserListItem: TToUserListItem<ColValues, ExtraUserItemData>;
}

type TToUserListItem<ColValues extends IColValues, ExtraUserItemData> =
    (props: IToUserListItemProps) => IDataItem<ColValues, ExtraUserItemData>;

interface IToUserListItemProps {
    user: TUser;
    defaultUserCols: IDefaultUserCols;
}

function toDefaultUserListItem({ user, defaultUserCols }: IToUserListItemProps): IDataItem<IDefaultUserCols> {
    return {
        id: user.id,
        colValues: defaultUserCols,
    };
}

interface IAdvancedWithSchemaUserFilters extends IAdvancedUserFilters, IFormValues {}

const INITIAL_ADVANCED_USER_FILTERS: IAdvancedWithSchemaUserFilters = {
    email: '',
    externalId: '',
    virtualIban: '',
    brokerageUserId: '',
    lastName: '',
    phoneNumber: '',
    featureFlags: [],
};

export interface IDefaultUserCols extends IColValues {
    email: string;
    externalId: string;
    name: string;
    updated: string;
}

interface IDesiredCol {
    colName: keyof IDefaultUserCols;
    percentWidth: number;
}

const DEFAULT_DESIRED_USER_COLS: IDesiredCol[] = [
    { colName: 'name', percentWidth: 25 },
    { colName: 'email', percentWidth: 30 },
    { colName: 'externalId', percentWidth: 15 },
    { colName: 'language', percentWidth: 15 },
    { colName: 'updated', percentWidth: 15 },
];

export function getDefaultUsersCols({
    desiredCols = DEFAULT_DESIRED_USER_COLS,
}: {
    desiredCols?: IDesiredCol[];
} = {}): TDataColumns<Partial<IDefaultUserCols>> {
    return desiredCols.reduce(
        (accumulator, { colName, percentWidth }) => {
            if (percentWidth > 0) {
                switch (colName) {
                    case 'name': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_USERS_LIST_COL_TRANSLATION_PREFIX}.name`,
                            },
                            data: {
                                className: UtilityClass.table.cellBold,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'email': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_USERS_LIST_COL_TRANSLATION_PREFIX}.email`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'externalId': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_USERS_LIST_COL_TRANSLATION_PREFIX}.external_id`,
                            },
                            align: 'right',
                            percentWidth,
                            sort: {
                                initialOrder: SortOrder.Ascending,
                                serverSide: {
                                    field: 'external_id',
                                },
                            },
                        };
                        break;
                    }
                    case 'language': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_USERS_LIST_COL_TRANSLATION_PREFIX}.language`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'updated': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_USERS_LIST_COL_TRANSLATION_PREFIX}.updated`,
                            },
                            percentWidth,
                            sort: {
                                initialOrder: SortOrder.Descending,
                                serverSide: {
                                    field: 'version_datetime',
                                },
                            },
                        };
                        break;
                    }
                    default: throw new Error(`Unexpected col name '${colName}'`);
                }
            }

            return accumulator;
        },
        {} as TDataColumns<Partial<IDefaultUserCols>>,
    );
}

export default function GenericUsersList<
    ColValues extends IColValues = IDefaultUserCols,
    ExtraUserItemData extends TObjectWithProps = unknown,
>({
    overrideEntity = {},
    overrideUiVars,
    overrideCols,
    overrideSimpleSearchTipTranslationKey,
    onUserRowClicked,
    isUserRowClickable,
    ...otherListPageProps
}: IPublicProps<ColValues, ExtraUserItemData>) {
    const entityConfig: IOverrideEntity = {
        dataNotification: StateChangeNotification.USERS_DATA,
        asyncListEntity: usersEntity,
        asyncListEntityFetchTrigger: triggerFetchUsers,
        setStateOnPageNrChange: (pageNr) => ({
            toState: (draftState) => {
                // eslint-disable-next-line no-param-reassign
                draftState.entities.users.data.pageNr = pageNr;
            },
            notificationsToTrigger: [StateChangeNotification.USERS_DATA],
        }),
        ...overrideEntity,
    };

    const uiVarsConfig: IOverrideUiVars = overrideUiVars || {
        uiVarsNotification: StateChangeNotification.USERS_UI_VARS,
        uiPageKey: UiPageKey.usersList,
    };

    const colsConfig: IOverrideCols<ColValues, ExtraUserItemData> = overrideCols || {
        cols: getDefaultUsersCols() as TDataColumns<ColValues>,
        toUserListItem: toDefaultUserListItem as unknown as TToUserListItem<ColValues, ExtraUserItemData>,
    };

    let sortConfigFunctions: ISortConfigFunctions;

    return (
        <ListPageForApiEntity
            notifications={[entityConfig.dataNotification, uiVarsConfig.uiVarsNotification]}

            asyncListEntity={entityConfig.asyncListEntity}
            asyncListEntityFetchTrigger={entityConfig.asyncListEntityFetchTrigger}
            setStateOnPageNrChange={entityConfig.setStateOnPageNrChange}

            uiPageKey={uiVarsConfig.uiPageKey}
            notificationToTriggerOnUiVarChanges={uiVarsConfig.uiVarsNotification}

            list={{
                renderData: renderUsersList,
            }}

            search={{
                simple: {
                    tipTranslationKey: overrideSimpleSearchTipTranslationKey
                        || 'user_mgmt.users.list.filter.simple.tip',
                    mapSimpleSearchInputToFetchFilter,
                    schema: simpleUserFilterSchema,
                },
                advancedWithSchema: {
                    initialValues: INITIAL_ADVANCED_USER_FILTERS,
                    renderFilters: renderAdvancedUsersFilters,
                    schema: advancedUserFiltersSchema,
                },
            }}

            sort={{
                getSortConfigFunctionsCallback: (newSortConfigFunctions) => {
                    sortConfigFunctions = newSortConfigFunctions;
                },
            }}

            {...otherListPageProps}
        />
    );

    function renderUsersList({ data }: { data: TUser[] }) {
        if (!data) {
            return null;
        }

        const userItems: IDataItem<ColValues, ExtraUserItemData>[] = data.map((user) => colsConfig.toUserListItem({
            user,
            defaultUserCols: {
                externalId: user.external_id,
                name: getUserFullName(user),
                email: user.email,
                language: getTranslatedLocale(user.language as Locale),
                updated: formatDateRelativeToNow({ date: user.version_datetime }),
            },
        }));

        return (
            <DataTable
                cols={colsConfig.cols}
                items={userItems}
                onItemRowClicked={onUserRowClicked}
                isItemClickable={isUserRowClickable}
                toDynamicCellClass={styleItemIfNotClickable}
                serverSideSorting={{
                    activeSortColumn: sortConfigFunctions.getActiveSortColumn,
                    onSelectSortColumn: (selectedSortCol) => {
                        sortConfigFunctions.setStateOnSortColumnChange(selectedSortCol);
                    },
                }}
            />
        );
    }

    function styleItemIfNotClickable({ dataItem }: IToDynamicCellClassProps<ColValues, ExtraUserItemData>) {
        if (isUserRowClickable && !isUserRowClickable(dataItem)) {
            return [UtilityClass.table.cellDisabled];
        }

        return null;
    }

    function renderAdvancedUsersFilters({
        fields,
    }: IRenderAdvancedWithSchemaFiltersProps<IAdvancedWithSchemaUserFilters>) {
        const featureFlagItems: IInputSelectItem<UserFeatureFlag>[] = Object.values(UserFeatureFlag).map((value) => ({
            value,
            label: getUserFeatureFlagLabel(value),
        }));

        return (
            <>
                <InputGroup>
                    <ExtendedInputText
                        formField={fields.email}
                        wrapper={{
                            label: 'user_mgmt.users.list.filter.advanced.email.label',
                        }}
                        placeholder="user_mgmt.users.list.filter.advanced.email.tip"
                    />
                    <ExtendedInputText
                        formField={fields.externalId}
                        wrapper={{
                            label: 'user_mgmt.users.list.filter.advanced.external_id.label',
                        }}
                        placeholder="user_mgmt.users.list.filter.advanced.external_id.tip"
                    />
                    <ExtendedInputText
                        formField={fields.lastName}
                        wrapper={{
                            label: 'user_mgmt.users.list.filter.advanced.last_name.label',
                        }}
                        placeholder="user_mgmt.users.list.filter.advanced.last_name.tip"
                    />
                </InputGroup>
                <InputGroup>
                    <ExtendedInputText
                        formField={fields.phoneNumber}
                        wrapper={{
                            label: 'user_mgmt.users.list.filter.advanced.phone_number.label',
                        }}
                        placeholder="user_mgmt.users.list.filter.advanced.phone_number.tip"
                    />
                    <ExtendedInputText
                        formField={fields.virtualIban}
                        wrapper={{
                            label: 'user_mgmt.users.list.filter.advanced.virtual_iban.label',
                        }}
                        placeholder="user_mgmt.users.list.filter.advanced.virtual_iban.tip"
                    />
                    <ExtendedInputText
                        formField={fields.brokerageUserId}
                        wrapper={{
                            label: 'user_mgmt.users.list.filter.advanced.brokerage_user_id.label',
                        }}
                        placeholder="user_mgmt.users.list.filter.advanced.brokerage_user_id.tip"
                    />
                    <ExtendedInputSearchableSelect<UserFeatureFlag[]>
                        formField={fields.featureFlags}
                        wrapper={{
                            label: 'user_mgmt.users.list.filter.advanced.feature_flags.label',
                        }}
                        placeholder="user_mgmt.users.list.filter.advanced.feature_flags.tip"
                        items={featureFlagItems}
                        itemLabelsAreTranslationKeys
                    />
                </InputGroup>
            </>
        );
    }

    function mapSimpleSearchInputToFetchFilter(simpleInput: string) {
        return {
            email: simpleInput,
        };
    }
}
