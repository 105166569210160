import { SupportedCurrency } from '@console/common/config/currencies.config';
import { TNumberRange } from '@console/core-api/typsy/console-api-client/dist/models/common/numeric.models';
import {
    CombineTransactionCostMode, PolicyManagerTag,
} from '@console/core-api/typsy/console-api-client/dist/models/portfolioMgmt/policy.entity.models';

export interface IPolicySettingsRules {
    hasParentPolicy: boolean;
    parent: {
        isRequired: boolean;
    };
    details: {
        tags: IPolicySettingRuleMultiValueField<PolicyManagerTag>;
        // TODO risk_profile_id ?
    };
    universe: {
        instruments: IPolicySettingRuleMultiValueField<string>;
        buyInstruments: IPolicySettingRuleMultiValueField<string>;
    };
    executionConstraints: {
        currency: IPolicySettingRuleSingleValueField<SupportedCurrency>;
        transactionAmountRange: IPolicySettingRuleBoundedNumberRangeField;
        costPerTransactionAmount: IPolicySettingRuleBoundedSingleNumberField;
        costPerTransactionFraction: IPolicySettingRuleBoundedSingleNumberField;
        combineTransactionCostMode: IPolicySettingRuleSingleValueField<CombineTransactionCostMode>;
        includeFractionalShares: IPolicySettingRuleSingleValueField<boolean>;
    };
    portfolioConstraints: {
        momentum: IPolicySettingRuleBoundedNumberRangeField;
        positionAmountRange: IPolicySettingRuleBoundedNumberRangeField;
        positionFractionRange: IPolicySettingRuleBoundedNumberRangeField;
        cashAmountRange: IPolicySettingRuleBoundedNumberRangeField;
        cashFractionRange: IPolicySettingRuleBoundedNumberRangeField;
    };
    portfolioUpdateConstraints: {
        minPortfolioOptimalityImprovementFraction: IPolicySettingRuleBoundedSingleNumberField;
        maxPortfolioConstraintsViolationFraction: IPolicySettingRuleBoundedSingleNumberField;
        cashFractionRange: IPolicySettingRuleBoundedNumberRangeField;
        minPortfolioValue: IPolicySettingRuleBoundedSingleNumberField;
    };
    benchmark: {
        id: IPolicySettingRuleSingleValueField<string>;
        positionFractionRange: IPolicySettingRuleBoundedNumberRangeField; // e.g. [0, 0.25]
        relativeIvarWeight: IPolicySettingRuleBoundedSingleNumberField;
    } & IPolicyAllocationBoundariesSettingsRules;
    allocationBoundaries: IPolicyAllocationBoundariesSettingsRules;
}

export interface IPolicyAllocationBoundariesSettingsRules {
    assetClassAlternativesRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassBondsRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassCashRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassCommoditiesRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassStocksRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassCorporateRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassEquityRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassEurobondRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassFixedIncomeRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassGovernmentRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassMoneyMarketRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassRealEstateRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassSukukRange: IPolicySettingRuleBoundedNumberRangeField;
    assetClassOtherRange: IPolicySettingRuleBoundedNumberRangeField;

    regionBondsAsiaRange: IPolicySettingRuleBoundedNumberRangeField;
    regionBondsEmergingRange: IPolicySettingRuleBoundedNumberRangeField;
    regionBondsEuropeRange: IPolicySettingRuleBoundedNumberRangeField;
    regionBondsAmericaRange: IPolicySettingRuleBoundedNumberRangeField;

    regionStocksAsiaRange: IPolicySettingRuleBoundedNumberRangeField;
    regionStocksEmergingRange: IPolicySettingRuleBoundedNumberRangeField;
    regionStocksEuropeRange: IPolicySettingRuleBoundedNumberRangeField;
    regionStocksAmericaRange: IPolicySettingRuleBoundedNumberRangeField;

    bondTypeConvertibleRange: IPolicySettingRuleBoundedNumberRangeField;
    bondTypeCorporateRange: IPolicySettingRuleBoundedNumberRangeField;
    bondTypeGovernmentRange: IPolicySettingRuleBoundedNumberRangeField;

    sectorBasicMaterialsRange: IPolicySettingRuleBoundedNumberRangeField;
    sectorCommunicationServicesRange: IPolicySettingRuleBoundedNumberRangeField;
    sectorConsumerCyclicalRange: IPolicySettingRuleBoundedNumberRangeField;
    sectorConsumerDefensiveRange: IPolicySettingRuleBoundedNumberRangeField;
    sectorEnergyRange: IPolicySettingRuleBoundedNumberRangeField;
    sectorFinancialServicesRange: IPolicySettingRuleBoundedNumberRangeField;
    sectorHealthcareRange: IPolicySettingRuleBoundedNumberRangeField;
    sectorIndustrialsRange: IPolicySettingRuleBoundedNumberRangeField;
    sectorRealEstateRange: IPolicySettingRuleBoundedNumberRangeField;
    sectorTechnologyRange: IPolicySettingRuleBoundedNumberRangeField;
    sectorUtilitiesRange: IPolicySettingRuleBoundedNumberRangeField;
}

export interface IPolicySettingRuleMultiValueField<Value> extends IPolicySettingRuleBase<Value[]> {
    fieldType: PolicySettingFieldType.MULTI_VALUE;
    boundaryValues: Value[];
}

export interface IPolicySettingRuleSingleValueField<Value> extends IPolicySettingRuleBase<Value> {
    fieldType: PolicySettingFieldType.SINGLE_VALUE;
    boundaryValue: Value;
}

export interface IPolicySettingRuleBoundedNumberRangeField extends IPolicySettingRuleBase<TNumberRange> {
    fieldType: PolicySettingFieldType.BOUNDED_NUMBER_RANGE;
    boundaryMin: number;
    boundaryMax: number;
    range: TNumberRange;
    numberType: PolicySettingNumberType;
}

export interface IPolicySettingRuleBoundedSingleNumberField
    extends Omit<IPolicySettingRuleSingleValueField<number>, 'fieldType'>,
    Omit<IPolicySettingRuleBoundedNumberRangeField, 'fieldType' | 'parentValue'> {
    fieldType: PolicySettingFieldType.BOUNDED_SINGLE_NUMBER;
}

export interface IPolicySettingRuleBase<Value> {
    rule: PolicySettingRuleType;
    fieldType: PolicySettingFieldType;
    parentValue: Value;
    isParentValueSet: () => boolean;
}

export enum PolicySettingFieldType {
    MULTI_VALUE = 'MULTI_VALUE', /* list/array of values */
    SINGLE_VALUE = 'SINGLE_VALUE',
    BOUNDED_NUMBER_RANGE = 'BOUNDED_NUMBER_RANGE', /* number range that has a min and max boundary */
    BOUNDED_SINGLE_NUMBER = 'BOUNDED_SINGLE_NUMBER', /* single number that has a min and max boundary */
}

/* the different situations that a policy field can be in compared to its parent field (if the policy has a parent) */
export enum PolicySettingRuleType {
    NONE = 'NONE',
    WITHIN_BOUNDARIES = 'WITHIN_BOUNDARIES',
    CANNOT_DIFFER = 'CANNOT_DIFFER',
    MORE_ITEMS_ALLOWED = 'MORE_ITEMS_ALLOWED',
    LESS_ITEMS_ALLOWED = 'LESS_ITEMS_ALLOWED',
}

export enum PolicySettingNumberType {
    AMOUNT = 'AMOUNT',
    PERCENTAGE_TO_1 = 'PERCENTAGE_TO_1', /* for percentages that are 1-based (e.g. 0.274 is actually 27,4%) */
    /* p.s. currently no need for a 'PERCENTAGE_TO_100' type */
}
