import React, { useState } from 'react';
import {
    PolicyAlgorithmType,
    TEnhancedPolicyComposition,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { DEFAULT_POLICY_META_COMPOSITION_TAB_KEY, POLICY_META_COMPOSITION_TAB_KEY } from 'config/tabs.config';
import { getPoliciesConfig } from 'state/appConfig/selectors';
import Tabs, { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import { IAddCompositionElementProps, ISelectedCompositionElementState } from './types';
import AddMetaPortfolioCompositionTab from './AddMetaPortfolioCompositionTab';

interface IAddMetaPortfolioCompositionProps {
    selectedElementState: ISelectedCompositionElementState;
    setSelectedElementState: (elementState: ISelectedCompositionElementState) => void;
    currentComposition: TEnhancedPolicyComposition;
    triggerListEntityFetchBasedOnTab: (tabKey: string) => Promise<unknown>;
}

export default function AddMetaPortfolioCompositionElement({
    selectedElementState,
    setSelectedElementState,
    currentComposition,
    triggerListEntityFetchBasedOnTab,
}: IAddMetaPortfolioCompositionProps) {
    const [activeMetaCompositionTab, setActiveMetaCompositionTab] = useState(DEFAULT_POLICY_META_COMPOSITION_TAB_KEY);
    const { allowPortfoliosInMetaPolicyComposition } = getPoliciesConfig();
    const selectedCompositionElementId = Object.keys(selectedElementState)[0];
    const policyMetaCompositionTabs: ITabItem[] = getPolicyMetaPortfolioCompositionTabs();

    return (
        <div>
            <Tabs
                items={policyMetaCompositionTabs}
                activeTabKey={activeMetaCompositionTab}
                onTabChange={tabChanged}
            />
        </div>
    );

    function setSelectedCompositionElement({
        compositionElementId,
        compositionElementName,
    }: IAddCompositionElementProps) {
        setSelectedElementState({
            [compositionElementId]: compositionElementName,
        });
    }

    function tabChanged({ tabKey }: IOnTabChangeProps) {
        triggerListEntityFetchBasedOnTab(tabKey);
        setActiveMetaCompositionTab(tabKey);
    }

    function getPolicyMetaPortfolioCompositionTabs(): ITabItem[] {
        const commonTabProps = { setSelectedCompositionElement, currentComposition, selectedCompositionElementId };
        const tabs = [{
            ...getPolicyMetaCompositionTabInfo(POLICY_META_COMPOSITION_TAB_KEY.RISK_BASED_POLICIES),
            // TODO
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
                <AddMetaPortfolioCompositionTab
                    listEntityType={EntityType.policy}
                    policyAlgorithmType={PolicyAlgorithmType.RISK_BASED}
                    {...commonTabProps}
                />
            ),
        }, {
            ...getPolicyMetaCompositionTabInfo(POLICY_META_COMPOSITION_TAB_KEY.MODEL_PORTFOLIO_POLICIES),
            // TODO
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
                <AddMetaPortfolioCompositionTab
                    listEntityType={EntityType.policy}
                    policyAlgorithmType={PolicyAlgorithmType.MODEL_PORTFOLIO}
                    {...commonTabProps}
                />
            ),
        }];

        if (allowPortfoliosInMetaPolicyComposition) {
            tabs.push({
                ...getPolicyMetaCompositionTabInfo(POLICY_META_COMPOSITION_TAB_KEY.PORTFOLIOS),
                // TODO
                // eslint-disable-next-line react/no-unstable-nested-components
                component: () => (
                    <AddMetaPortfolioCompositionTab
                        listEntityType={EntityType.portfolio}
                        {...commonTabProps}
                    />
                ),
            });
        }

        return tabs;
    }
}

function getPolicyMetaCompositionTabInfo(tabKey: string): Pick<ITabItem, 'key' | 'wrapWithContentBox' | 'label'> {
    return {
        key: tabKey,
        label: `portfolio_mgmt.policies.composition.meta_portfolio.tabs.${tabKey}`,
        wrapWithContentBox: false,
    };
}
