import React from 'react';
import { TEntityUlid } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { DEFAULT_USER_TAB_KEY } from 'config/tabs.config';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import GenericUsersList from 'views/userMgmt/shared/GenericUsersList';
import { getUserConfig } from 'state/appConfig/selectors';

export default function UsersList() {
    const { allowPatchingIdpSpecificFields } = getUserConfig();

    return (
        <GenericUsersList
            box={{
                title: 'user_mgmt.users.title',
            }}
            onUserRowClicked={(user) => openUserDetail(user.id)}
            create={allowPatchingIdpSpecificFields && {
                toRoute: {
                    routeKey: ROUTE_KEY.R_USER_ADD,
                },
                tooltip: 'user_mgmt.users.list.actions.create_user',
            }}
        />
    );

    function openUserDetail(userId: TEntityUlid) {
        redirectTo({
            routeKey: ROUTE_KEY.R_USER_DETAIL,
            params: {
                userId,
                userTab: DEFAULT_USER_TAB_KEY,
            },
        });
    }
}
