import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import getUniqueArrayValues from '@snipsonian/core/cjs/array/filtering/getUniqueArrayValues';
import { addOrRemoveItemFromArray } from '@console/common/utils/array/addOrRemoveItemFromArray';
import { EntityType, TEntityUlid, IBaseIdentifiedEntity, IBaseEntity } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import {
    AccessibleByEntityType,
    AccessibleByPermissionType,
    IAccessibleByState,
    IChangeAccessProps,
} from 'models/ui/apiEntityAccessibleBy.ui.models';
import { IFetchUsersApiInput } from '@console/core-api/client/userMgmt/users.api';
import { IFetchUserGroupsApiInput } from '@console/core-api/client/userMgmt/userGroups.api';
import { canUserModifyAccessibleByOfApiEntity } from 'state/auth/apiEntityAuthorization.selectors';
import { getAccessibleByPageVars } from 'state/ui/uiPages.selectors';
import { triggerFetchUsersForAccessibleBy } from 'state/entities/userMgmt/users';
import { triggerFetchUserGroupsForAccessibleBy } from 'state/entities/userMgmt/userGroups';
import { getStore } from 'state';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import { getUserId } from 'state/auth/selectors';
import { IObserveProps, observe } from 'views/observe';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IFormValues,
    IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import { getDefaultModifyPermissionOfEntity, getEntityTypeBasedOnId } from 'utils/entities/entityTypeUtils';
import { accessibleBySchema } from './accessibleBySchema';
import AccessibleByEntityWrapper from './AccessibleByEntityWrapper';

interface IAccessibleByFormValues extends IFormValues {
    readableBy: TEntityUlid[];
    modifiableBy: TEntityUlid[];
}

interface IAccessibleByPatchData extends IBaseIdentifiedEntity {
    readable_by: TEntityUlid[];
    modifiable_by: TEntityUlid[];
}

interface IAccessibleByProps {
    nameForDisplay: string;
    entityDetailsData: IBaseEntity;
    /** Optional. By default, the related MODIFY permission of the input entity type will be taken. */
    operationPermissionKey?: OperationPermissionKey;
    asyncEntityPatchTrigger: (data?: IAccessibleByPatchData) => Promise<unknown>;
}

function AccessibleBy({
    entityDetailsData,
    state,
    operationPermissionKey,
    nameForDisplay,
    asyncEntityPatchTrigger,
}: IAccessibleByProps & IObserveProps) {
    const isReadOnly = hasAuthenticatedUserSelectedOwnUserEntity() /* a user can't modify it's own 'readable_by' list */
        || !canUserModifyAccessibleByOfApiEntity(
            entityDetailsData,
            operationPermissionKey || getDefaultModifyPermissionOfEntity(entityDetailsData),
            state,
        );

    const initialFormValues: IAccessibleByFormValues = {
        readableBy: entityDetailsData.readable_by || [],
        modifiableBy: entityDetailsData.modifiable_by || [],
    };

    useAsyncFetchOnMount({
        fetcher: () => {
            triggerFetchAccessibleByListByUsingFilters({
                forceRefresh: false,
            });
            return Promise.resolve();
        },
    });

    return (
        <ExtendedInputForm<IAccessibleByFormValues>
            name="accessible-by-form"
            initialValues={initialFormValues}
            submit={{
                onSubmit: triggerPatchEntity,
            }}
            renderFormFields={renderEntityList}
            schema={accessibleBySchema}
            readOnly={isReadOnly}
            reset={{}}
            placeFormActionsInFixedFooter
        />
    );

    function hasAuthenticatedUserSelectedOwnUserEntity() {
        return getUserId(state) === entityDetailsData.id;
    }

    function triggerFetchAccessibleByListByUsingFilters({
        apiInput = {},
        entityType,
        showOnlyEnabledPermissions,
        forceRefresh = true,
        currentUserOrGroupIdsHavingPermissions,
    }: {
        apiInput?: IFetchUsersApiInput | IFetchUserGroupsApiInput;
        entityType?: AccessibleByEntityType;
        showOnlyEnabledPermissions?: boolean;
        forceRefresh?: boolean;
        currentUserOrGroupIdsHavingPermissions?: TEntityUlid[];
    }) {
        const currentState = getStore().getState();
        const entityTypeToFetch = entityType || getAccessibleByPageVars(currentState).entityType;
        const shouldFilterOnIds = isSet(showOnlyEnabledPermissions)
            ? showOnlyEnabledPermissions
            : getAccessibleByPageVars(currentState).showOnlyEnabledPermissions;

        // TODO further filter unique ids based on entityType

        if (entityTypeToFetch === AccessibleByEntityType.Users) {
            return triggerFetchUsersForAccessibleBy({
                ...apiInput,
                userIds: shouldFilterOnIds ? getUserIdsToFilterOn() : null,
                forceRefresh,
            });
        }

        /* entityType === AccessibleByEntityType.UserGroups */
        return triggerFetchUserGroupsForAccessibleBy({
            ...apiInput,
            userGroupIds: shouldFilterOnIds ? getUserGroupIdsToFilterOn() : null,
            forceRefresh,
        });

        function getUserOrGroupIdsToFilterOn() {
            return getUniqueArrayValues(
                currentUserOrGroupIdsHavingPermissions
                || initialFormValues.readableBy.concat(initialFormValues.modifiableBy),
            );
        }

        function getUserIdsToFilterOn() {
            return getUserOrGroupIdsToFilterOn().filter(
                (entityId) => getEntityTypeBasedOnId(entityId) === EntityType.user,
            );
        }

        function getUserGroupIdsToFilterOn() {
            return getUserOrGroupIdsToFilterOn().filter(
                (entityId) => getEntityTypeBasedOnId(entityId) === EntityType.userGroup,
            );
        }
    }

    function triggerPatchEntity({ values }: IOnSubmitProps<IAccessibleByFormValues>) {
        return asyncEntityPatchTrigger({
            id: entityDetailsData.id,
            modifiable_by: values.modifiableBy,
            readable_by: values.readableBy,
        });
    }

    function renderEntityList({
        fields,
        setFieldValue,
    }: IExtendedInputFormContext<IAccessibleByFormValues>) {
        const currentReadableByIds = (fields.readableBy.value || []) as TEntityUlid[];
        const currentModifiableByIds = (fields.modifiableBy.value || []) as TEntityUlid[];

        const readableByState = currentReadableByIds.reduce(
            (accumulator, entityId) => {
                accumulator[entityId] = true;

                return accumulator;
            },
            {} as IAccessibleByState,
        );

        const modifiableByState = currentModifiableByIds.reduce(
            (accumulator, entityId) => {
                accumulator[entityId] = true;

                return accumulator;
            },
            {} as IAccessibleByState,
        );

        return (
            <AccessibleByEntityWrapper
                nameForDisplay={nameForDisplay}
                isReadOnly={isReadOnly}
                readableByState={readableByState}
                modifiableByState={modifiableByState}
                onChangeAccess={onChangeAccess}
                triggerFetchAccessibleByListByUsingFilters={(props) => triggerFetchAccessibleByListByUsingFilters({
                    ...props,
                    currentUserOrGroupIdsHavingPermissions: currentReadableByIds.concat(currentModifiableByIds),
                })}
            />
        );

        function onChangeAccess({ isEnabled, entityId, permissionType }: IChangeAccessProps) {
            if (permissionType === AccessibleByPermissionType.Read) {
                const newReadableByIds = addOrRemoveItemFromArray<TEntityUlid>(
                    isEnabled,
                    currentReadableByIds,
                    entityId,
                    { resultInNewArray: true },
                );
                setFieldValue({
                    fieldName: fields.readableBy.fieldName,
                    value: newReadableByIds,
                });
            } else if (permissionType === AccessibleByPermissionType.Modify) {
                const newModifiableByIds = addOrRemoveItemFromArray<TEntityUlid>(
                    isEnabled,
                    currentModifiableByIds,
                    entityId,
                    { resultInNewArray: true },
                );
                setFieldValue({
                    fieldName: fields.modifiableBy.fieldName,
                    value: newModifiableByIds,
                });
            }
        }
    }
}

export default observe<IAccessibleByProps>(
    [],
    AccessibleBy,
);
