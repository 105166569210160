import { IBaseIdentifiedEntity, TEntityUlid } from '../../typsy/entities/dist/common/entity.models';
import { IApiEntityListRequest, IApiEntityListResponse } from '../../typsy/console-api-client/dist/models/consoleApiQuery.models';
import {
    ISingleUserGroupApiInput,
    TUserGroupsData, IUserGroupEntityData,
    TUserGroup, TUserGroupPatch, TUserGroupCreate,
} from '../../typsy/console-api-client/dist/models/userMgmt/userGroup.entity.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../typsy/console-api-client/dist/config/consoleApi.config';
import { ConsoleApiPath } from '../../typsy/console-api-client/dist/config/consoleApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../typsy/console-api-client/dist/utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../typsy/console-api-client/dist/utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export interface IFetchUserGroupsApiInput extends IApiEntityListRequest {
    userGroupIds?: TEntityUlid[]; // field in check
}

export function fetchUserGroups({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    userGroupIds = null,
    orderBy,
    count,
}: IFetchUserGroupsApiInput = {}) {
    return get<TUserGroupsData, IApiEntityListResponse<IUserGroupEntityData>>({
        url: ConsoleApiPath.USERGROUPS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .orderBy(orderBy)
                .fieldIn({ field: 'id', value: userGroupIds })
                .build(),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function fetchUserGroupDetails({ userGroupId }: ISingleUserGroupApiInput) {
    return get<TUserGroup>({
        url: ConsoleApiPath.USERGROUP_DETAILS,
        pathParams: {
            userGroupId,
        },
    });
}

export function patchUserGroup({
    id,
    ...patchFields
}: TUserGroupPatch) {
    return patch<TUserGroup>({
        url: ConsoleApiPath.USERGROUP_DETAILS,
        pathParams: {
            userGroupId: id,
        },
        body: patchFields,
    });
}

export function createUserGroup(userGroupToCreate: TUserGroupCreate) {
    return post<TUserGroup>({
        url: ConsoleApiPath.USERGROUPS,
        body: userGroupToCreate,
    });
}

export function deleteUserGroup({ id }: IBaseIdentifiedEntity) {
    return remove<unknown>({
        url: ConsoleApiPath.USERGROUP_DETAILS,
        pathParams: {
            userGroupId: id,
        },
    });
}
