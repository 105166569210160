import { currencyArraySchema, object } from '@console/common/utils/schema';
import { EntityType } from '@console/core-api/typsy/entities/dist/common/entity.models';
import { ENTITY_TYPE_TO_ULID_REGEX } from '@console/core-api/typsy/entities/dist/common/entityUlid.config';
import { entityIdArray } from '@console/core-api/utils/schema/entityIdSchema';
import {
    advancedPolicyFiltersServerSideSchema,
    policyManagerTagsSchema,
} from '@console/bff/server/utils/schemas/policy.schemas';

export const POLICY_ID_REGEX = ENTITY_TYPE_TO_ULID_REGEX[EntityType.policy];

export const advancedPolicyFiltersClientSideSchema = advancedPolicyFiltersServerSideSchema
    .omit(['currencies', 'tags', 'ids'])
    .concat(object({
        currencies: currencyArraySchema,
        tags: policyManagerTagsSchema,
        ids: entityIdArray({
            entityType: EntityType.policy,
            entityName: 'policy',
        }),
    }));
